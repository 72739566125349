import React from "react";
import { Box, HStack, VStack } from "@chakra-ui/react";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import Link from "src/components/Link/Link";
import { AREA_ROUTE, PERSONAL_ROUTE } from "src/routes";
import { FormattedMessage } from "react-intl";
const ClearAndProtect = () => {
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />
      <VStack w="100%" spacing="10" paddingTop={"30px"}>
        <HStack flexWrap="wrap">
          <Link
            to={PERSONAL_ROUTE}
            borderRadius={15}
            py={1}
            isSecondary
            maxW="none"
            width="fit-content"
            padding={"5px 10px"}
          >
            <FormattedMessage id="personal" />
          </Link>
          <Link
            to={AREA_ROUTE}
            borderRadius={15}
            py={1}
            isSecondary
            maxW="none"
            width="fit-content"
            padding={"5px 10px"}
          >
            <FormattedMessage id="area" />
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ClearAndProtect;
