import {
  BoxProps,
  HStack,
  useToast,
  Box,
  Modal,
  ModalBody,
  Input,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { ReactInstance, useEffect } from "react";
import {
  AiOutlineReload,
  AiFillPrinter,
  AiFillCopy,
  AiFillMail,
} from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import ValueShowButton from "./Values/ValueShowButton";
import { FormattedMessage, useIntl } from "react-intl";
import { useReactToPrint } from "react-to-print";
import Link from "src/components/Link/Link";
import { emailMe } from "src/api/index";
import Print from "src/components/Print/Print";
import DeleteButton from "src/components/DeleteModel";

const ActionBar: React.FC<
  BoxProps & {
    onDelete?: () => void;
    onResort?: () => void;
    textToCopy?: string;
    title?: string;
    keyTitle?: string;
    canDelete?: boolean;
    canRestart?: boolean;
  }
> = ({ onDelete, onResort, textToCopy, title, keyTitle, canDelete = true, canRestart = true, ...props }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const toast = useToast();
  const intl = useIntl();

  const componentRef = React.useRef<ReactInstance>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
    onAfterPrint: () => setOpen(false),
  });

  const handleEmail = async () => {
    const textToEmail = document.getElementById("text-to-mail");
    if (!textToEmail) return;
    toast({
      title: intl.formatMessage({ id: "wait" }),
      status: "info",
      duration: 1000,
      isClosable: true,
    });
    const response: any = await emailMe(
      textToEmail.innerHTML,
      textToEmail.getAttribute("data-subject")!
    );
    if (response?.messageId) {
      toast({
        title: intl.formatMessage({ id: "emailSuccess" }),
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: intl.formatMessage({ id: "wentWrong" }),
        status: "error",
        duration: 2000,
      });
    }
  };

  useEffect(() => { }, [textToCopy]);

  const fireToast = () => {
    toast({
      position: "top",
      duration: 2000,
      isClosable: true,
      render: () => (
        <Box
          color="white"
          width={"200px"}
          py={"5px"}
          textAlign={"center"}
          bg="brand.800"
        >
          {intl.formatMessage({ id: "values.copy" })}
        </Box>
      ),
    });
  };
  const copyItems = (e: any) => {
    if (textToCopy) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textToCopy);
        return fireToast();
      } else {
        let copyText: HTMLInputElement | null =
          document.querySelector("#printText");
        copyText!.select();
        document.execCommand("copy");
        return fireToast();
      }
    }
  };

  const deleteButtonRef = React.useRef();

  return (
    <>
      <Input id={"printText"} opacity={0} value={textToCopy} display='none' />
      <HStack
        justifyContent={["flex-start", "space-between"]}
        {...props}
        margin='0 auto'
        width={["260px", "fit-content"]}
        wrap='wrap'>
        <ValueShowButton
          icon={<AiOutlineReload />}
          text={intl.formatMessage({ id: "Restart", defaultMessage: "إعادة" })}
          aria-label='re-sort'
          onClick={onResort}
          isDisabled={!canRestart}
          margin='5px'
        />
        <ValueShowButton
          icon={<AiFillPrinter />}
          text={intl.formatMessage({ id: "print", defaultMessage: "طباعة" })}
          aria-label='print'
          margin='5px'
          onClick={async () => {
            await componentRef.current;
            setOpen(true);
          }}
        />
        <ValueShowButton
          icon={<AiFillCopy />}
          text={intl.formatMessage({ id: "copy", defaultMessage: "نسخ" })}
          onClick={(e: any) => copyItems(e)}
          aria-label='copy'
          margin='5px'
        />
        <ValueShowButton
          icon={<AiFillMail />}
          text='EMAIL'
          aria-label='email'
          margin='5px'
          onClick={handleEmail}
        />
        <ValueShowButton
          icon={<DeleteButton remove={onDelete} ref={deleteButtonRef} />}
          text={intl.formatMessage({ id: "delete", defaultMessage: "حذف" })}
          aria-label='delete'
          disabled={!canDelete}
          color='#e97575'
          margin='5px'
          onClick={() => {deleteButtonRef!.current!.openModal()}}
        />
      </HStack>
      <Modal isOpen={open} isCentered onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"10px"}
          minW={{ base: "300px", md: "fit-content" }}
          height='fit-content'
          maxH='90vh'
          overflowY='auto'>
          <ModalBody>
            <Box overflow={"auto"} paddingTop='10px' margin='0'>
              <Print
                keyTitle={keyTitle}
                title={title}
                textToCopy={textToCopy}
                ref={(el) => (componentRef.current = el!)}
              />
            </Box>
            <Box width={"100%"} textAlign={"center"}>
              <Link
                mb={"20px"}
                onClick={() => {
                  handlePrint && handlePrint();
                }}
                asBtn={true}>
                <FormattedMessage id='story.edit' defaultMessage={"تم"} />
              </Link>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ActionBar;
