import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Text,
  Box,
  Icon,
  RadioGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Grid,
  VStack,
  Textarea,
  Spinner,
  Center,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowUp,
  FiArrowRight,
  FiPlus,
} from "react-icons/fi";
import { HiOutlineDocumentText, HiOutlinePencil } from "react-icons/hi";
import { FaExclamationCircle } from "react-icons/fa";
import Modal from "src/components/Modal";
import { playMedia, stopMedia } from "src/store/media";
import { useDispatch } from "src/store";
import useLocale from "src/providers/useLocale";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { BiSearch } from "react-icons/bi";
import {
  Chakra,
  SalAudio,
  Poster,
  Wave,
  Solfeggio,
  Balance,
  WaveCategory,
} from "../types";
import PosterContainer from "./PosterContainer";
import { AudioItem } from "./AudioItem";
import { get } from "src/api";

const CreateFilmModal = ({ disclosureProps, posters, onCreate }) => {
  const [selectedPosters, setSelectedPosters] = useState<Poster[]>([]);
  const [waveCategories, setWaveCategories] = useState<WaveCategory[]>([]);
  const [selectedWaveCategory, setSelectedWaveCategory] =
    useState<WaveCategory>();
  const [waves, setWaves] = useState<Wave[]>([]);
  const [chakras, setChakras] = useState<Chakra[]>([]);
  const [solfeggios, setSolfeggios] = useState<Solfeggio[]>([]);
  const [balances, setBalances] = useState<Balance[]>([]);
  const [salAudios, setSalAudios] = useState<SalAudio[]>([]);
  const [selectedSalAudio, setSelectedSalAudio] = useState<SalAudio>();
  const [expandedWaves, setExpandedWaves] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredWaves, setFilteredWaves] = useState<Wave[]>([]);
  const [shouldFilterWaves, setShouldFilterWaves] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [loadingWaveAudios, setLoadingWaveAudios] = useState(false);

  const [selectedOrigin, setSelectedOrigin] = useState("");

  const handleOriginClick = (origin) => {
    setSelectedOrigin(origin);
  };

  const handleWaveCategoryClick = (category) => {
    setSelectedWaveCategory(category);
    setWaves(category.waves);
  };

  const [currentTab, setCurrentTab] = useState<number>(0);
  const { formatMessage } = useIntl();
  const [locale] = useLocale();
  const dispatch = useDispatch();

  const MusicOrigins = [
    formatMessage({ id: "chakras" }),
    formatMessage({ id: "solfeggio" }),
    formatMessage({ id: "waves" }),
    formatMessage({ id: "balance Portal" }),
  ];

  const frequencyNames = {
    en: {
      174: "Pain Reduction and Security",
      963: "Oneness and Perfection",
      852: "Intuition and Spiritual Order",
      741: "Solutions",
      639: "Reconnecting and Balancing Relations",
      528: "Transformations and Miracles",
      417: "Facilitating Change",
      396: "Turning Grief into Joy",
      285: "Original Form, Energy, Rejuv.",
    },
    ar: {
      174: "تقليل الألم والأمان",
      963: "الوحدة والكمال",
      852: "الحدس والنظام الروحي",
      741: "الحلول",
      639: "إعادة الاتصال وتوازن العلاقات",
      528: "التحولات والمعجزات",
      417: "تيسير التغيير",
      396: "تحويل الحزن إلى فرح",
      285: "الشكل الأصلي، الطاقة، التجديد.",
    },
  };

  useEffect(() => {
    try {
      const fetchWaveCategories = async () => {
        const fetchedWaveCategories: any = await get("/wave/allCategories");
        if (fetchedWaveCategories) {
          setWaveCategories(fetchedWaveCategories);
        }
      };

      const fetchChakras = async () => {
        const fetchedChakras: any = await get("/chakra");
        if (fetchedChakras) {
          const formattedChakras = fetchedChakras.map((chakra) => ({
            id: `chakra-${chakra.id}`,
            type: "chakra",
            name: chakra.name,
            audioUrl: chakra.audioUrl,
          }));
          setChakras(formattedChakras);
        }
      };

      const fetchSolfeggios = async () => {
        const fetchedSolfeggios: any = await get("/solfeggio");
        if (fetchedSolfeggios) {
          const formattedSolfeggios = fetchedSolfeggios.map((solfeggio) => ({
            id: `solfeggio-${solfeggio.id}`,
            type: "solfeggio",
            name:
              locale === "en"
                ? frequencyNames.en[solfeggio.frequency]
                : frequencyNames.ar[solfeggio.frequency],
            audioUrl: solfeggio.translations[0].audioUrl,
          }));
          setSolfeggios(formattedSolfeggios);
        }
      };

      const fetchBalances = async () => {
        const fetchedBalances: any = await get("/balance");
        if (fetchedBalances) {
          const formattedBalances = fetchedBalances.map((balance) => ({
            id: `balance-${balance.id}`,
            type: "balance",
            name: balance.name,
            audioUrl: balance.url,
          }));
          setBalances(formattedBalances);
        }
      };

      fetchWaveCategories();
      fetchChakras();
      fetchSolfeggios();
      fetchBalances();
      setLoading(false);
    } catch (error) {
      console.error("Error in fetching posters or waves:", error);
      setLoading(false);
    }
  }, [locale]);

  const handleTitleDescriptionNext = () => {
    setCurrentTab(1);
  };

  const handleFocusPostersNext = async () => {
    setCurrentTab(2);
  };

  const handleTabClick = (index) => {
    setCurrentTab(index);
  };

  const handleWaveClick = async (wave) => {
    try {
      setLoadingWaveAudios(true);
      const response: any = await get(`/wave/${wave.id}`);

      const fetchedWaveAudios = response.mediaFiles;
      setExpandedWaves((prevExpandedWaves) => {
        return {
          ...Object.fromEntries(
            Object.keys(prevExpandedWaves).map((key) => [key, false])
          ),
          [wave.id]: !prevExpandedWaves[wave.id],
        };
      });

      const formattedWaveAudios = fetchedWaveAudios.map((audio) => ({
        id: `wave-${audio.id}`,
        type: "wave",
        name: audio.name,
        audioUrl: audio.withMusicUrl,
      }));
      setSalAudios(formattedWaveAudios);
      setLoadingWaveAudios(false);
    } catch (error) {
      console.error("Error in handleWaveClick:", error);
    } finally {
      setLoadingWaveAudios(false);
    }
  };

  const handleSalAudioSelect = (audio) => {
    if (selectedSalAudio && selectedSalAudio.id === audio.id) {
      setSelectedSalAudio(undefined);
    } else {
      setSelectedSalAudio({
        id: audio.id,
        name: audio.name,
        audioUrl: audio.audioUrl,
      });
    }
  };

  //function to play media
  const play = (source: string, id: number, type: string) => {
    dispatch(
      playMedia({
        source: source,
        type: "audio",
        id: `${type}-${id}`,
        loop: true,
        name: type,
      })
    );
  };

  const stop = () => {
    dispatch(stopMedia());
  };

  const handleSearch = () => {
    const trimmedSearchTerm = searchTerm.trim();
    stop();

    if (trimmedSearchTerm === "") {
      // If the search term is cleared, reset the filtered list and flag
      setFilteredWaves([]);
      setShouldFilterWaves(false);
    } else {
      // If there's a search term, filter the waves accordingly
      const filtered = waves.filter((wave) => {
        if (wave.name) {
          return wave.name
            .toLowerCase()
            .includes(trimmedSearchTerm.toLowerCase());
        }
        return false;
      });
      setFilteredWaves(filtered);
      setShouldFilterWaves(true);
    }
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleClearSearch = () => {
    // When the search term is cleared, reset the filtered list and flag
    setFilteredWaves([]);
    setShouldFilterWaves(false);
    setSearchTerm(""); // Clear the search term
  };

  const handlePosterClick = (poster) => {
    setSelectedPosters((prevSelected) => {
      if (prevSelected.includes(poster)) {
        return prevSelected.filter(
          (selectedPoster) => selectedPoster.id !== poster.id
        );
      } else {
        return [...prevSelected, poster];
      }
    });
  };

  return (
    <Modal
      disclosureProps={disclosureProps}
      btnProps={{
        mt: "2",
        mb: "4",
        children: formatMessage({
          id: "focusFilm.create",
        }),
        color: "brand.800",
        variant: "link",
        rightIcon: <FiPlus />,
      }}
      bodyProps={{
        children: (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {formatMessage({ id: "focusFilm.create" })}
              </ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  stop();
                  disclosureProps.onClose();
                }}
              />
              {loading ? (
                <Center w="100%" h="20vh">
                  <Spinner size="xl" thickness="4px" color="blue.500" />
                </Center>
              ) : (
                <Tabs
                  index={currentTab}
                  onChange={(index) => setCurrentTab(index)}
                >
                  <TabList>
                    <Tab
                      onClick={() => {
                        stop();
                      }}
                    >
                      {formatMessage({ id: "focusFilm.create.title_tab" })}
                    </Tab>
                    <Tab
                      onClick={() => {
                        stop();
                      }}
                      isDisabled={!title || title === ""}
                    >
                      {formatMessage({ id: "focusFilm.create.posters_tab" })}
                    </Tab>
                    <Tab
                      isDisabled={
                        selectedPosters.length === 0 || !title || title === ""
                      }
                    >
                      {formatMessage({ id: "focusFilm.create.music_tab" })}
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ModalBody>
                        <VStack spacing="4">
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <Icon
                                as={HiOutlineDocumentText}
                                color="gray.500"
                              />
                            </InputLeftElement>
                            <Input
                              placeholder={formatMessage({
                                id: "focusFilm.create.title_input",
                              })}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </InputGroup>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <Icon as={HiOutlinePencil} color="gray.500" />
                            </InputLeftElement>
                            <Textarea
                              placeholder={formatMessage({
                                id: "focusFilm.create.description_input",
                              })}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              resize="none"
                              mb="4"
                              borderRadius="md"
                              p="2"
                              h="100px"
                              paddingLeft="2rem"
                              paddingTop="1rem"
                              borderColor="gray.300"
                              _focus={{ borderColor: "blue.500" }}
                            />
                          </InputGroup>
                        </VStack>
                      </ModalBody>
                      <ModalFooter marginTop={10}>
                        <Button
                          variant="ghost"
                          onClick={() => {
                            stop();
                            disclosureProps.onClose();
                          }}
                        >
                          {formatMessage({
                            id: "focusFilm.create.close_btn",
                          })}
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={handleTitleDescriptionNext}
                          isDisabled={!title || title === ""}
                        >
                          {formatMessage({ id: "focusFilm.create.next_btn" })}
                        </Button>
                      </ModalFooter>
                    </TabPanel>
                    <TabPanel>
                      <ModalBody>
                        {posters.length > 0 ? (
                          <Grid
                            templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
                            gap={2}
                          >
                            {posters.map((poster: any) =>
                              poster.coverUrl ? (
                                <PosterContainer
                                  key={poster.id}
                                  poster={poster}
                                  onClick={handlePosterClick}
                                  isSelected={selectedPosters.includes(poster)}
                                />
                              ) : null
                            )}
                          </Grid>
                        ) : (
                          <Center>
                            <VStack>
                              <FaExclamationCircle size={60} color="red" />
                              <Box pb={4}>
                                <Text
                                  color="gray.500"
                                  fontStyle="italic"
                                  textAlign="center"
                                >
                                  {formatMessage({
                                    id: "focusFilm.create.noFocusPoster",
                                  })}
                                </Text>
                                <Text
                                  textAlign="center"
                                  color="gray.500"
                                  fontStyle="italic"
                                  noOfLines={2}
                                >
                                  {formatMessage({
                                    id: "focusFilm.create.noFocusPoster.tip",
                                  })}
                                </Text>
                              </Box>
                            </VStack>
                          </Center>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          variant="ghost"
                          onClick={() => {
                            stop();
                            disclosureProps.onClose();
                          }}
                        >
                          {formatMessage({
                            id: "focusFilm.create.close_btn",
                          })}
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={handleFocusPostersNext}
                          isDisabled={
                            selectedPosters.length === 0 ||
                            !title ||
                            title === ""
                          }
                        >
                          {formatMessage({ id: "focusFilm.create.next_btn" })}
                        </Button>
                      </ModalFooter>
                    </TabPanel>
                    <TabPanel>
                      <ModalBody mb={5}>
                        {MusicOrigins.map((origin, index) => (
                          <Box
                            key={index}
                            borderWidth="1px"
                            borderRadius="lg"
                            m="2"
                            p="2"
                            onClick={() => handleOriginClick(origin)}
                            cursor="pointer"
                            _hover={{ bg: "blue.100", borderColor: "blue.500" }}
                            display={selectedOrigin ? "none" : "block"}
                          >
                            <Box
                              overflow="hidden"
                              display="flex"
                              justifyContent="space-between"
                              cursor="pointer"
                            >
                              <Text fontSize="lg" fontWeight="bold">
                                {origin}
                              </Text>
                              <Icon
                                as={
                                  locale === "en" ? FiArrowRight : FiArrowLeft
                                }
                                boxSize={6}
                                color="gray.500"
                                transform="translateX(0)"
                                transition="transform 0.3s ease"
                              />
                            </Box>
                          </Box>
                        ))}
                        {selectedOrigin && (
                          <Box>
                            <Flex
                              justify="start"
                              align="center"
                              onClick={() => {
                                if (selectedWaveCategory) {
                                  setSelectedWaveCategory(null);
                                  setWaves([]);
                                } else {
                                  setSelectedOrigin("");
                                }
                                stop();
                              }}
                              cursor="pointer"
                              mb={4}
                            >
                              <Icon
                                as={
                                  locale === "en" ? FiArrowLeft : FiArrowRight
                                }
                                boxSize={5}
                                color="gray.500"
                                _hover={{
                                  color: "blue.500",
                                }}
                              />
                              <Text
                                p={1}
                                fontSize="sm"
                                fontWeight="bold"
                                _hover={{
                                  textDecoration: "underline",
                                  color: "blue.500",
                                  cursor: "pointer",
                                }}
                              >
                                {selectedWaveCategory
                                  ? `${selectedOrigin} - ${selectedWaveCategory.name}`
                                  : selectedOrigin}
                              </Text>
                            </Flex>
                            <Box
                              display={
                                selectedOrigin ===
                                formatMessage({ id: "waves" })
                                  ? "block"
                                  : "none"
                              }
                            >
                              {waveCategories.map((category) => (
                                <Box
                                  key={category.id}
                                  borderWidth="1px"
                                  borderRadius="lg"
                                  m="2"
                                  p="2"
                                  onClick={() =>
                                    handleWaveCategoryClick(category)
                                  }
                                  cursor="pointer"
                                  _hover={{
                                    bg: "blue.100",
                                    borderColor: "blue.500",
                                  }}
                                  display={
                                    waves.length !== 0 ? "none" : "block"
                                  }
                                >
                                  <Box
                                    overflow="hidden"
                                    display="flex"
                                    justifyContent="space-between"
                                    cursor="pointer"
                                  >
                                    <Text fontSize="lg" fontWeight="bold">
                                      {category.name}
                                    </Text>
                                    <Icon
                                      as={
                                        locale === "en"
                                          ? FiArrowRight
                                          : FiArrowLeft
                                      }
                                      boxSize={6}
                                      color="gray.500"
                                      transform="translateX(0)"
                                      transition="transform 0.3s ease"
                                    />
                                  </Box>
                                </Box>
                              ))}
                              {selectedWaveCategory && (
                                <Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb="4"
                                  >
                                    <InputGroup>
                                      <Input
                                        placeholder={formatMessage({
                                          id: "focusFilm.create.search_input",
                                        })}
                                        value={searchTerm}
                                        onChange={(e) => {
                                          if (e.target.value) {
                                            setSearchTerm(e.target.value);
                                          } else {
                                            setSearchTerm("");
                                            setShouldFilterWaves(false);
                                          }
                                        }}
                                        onKeyDown={handleEnterKeyPress}
                                        pr="4rem"
                                        borderRadius="full"
                                        _focus={{
                                          borderColor: "blue.500",
                                          boxShadow: "0 0 0 1px #3182ce",
                                        }}
                                      />
                                      <InputRightElement width="3.5rem">
                                        {searchTerm && (
                                          <CloseIcon
                                            color="gray.400"
                                            cursor="pointer"
                                            onClick={handleClearSearch}
                                          />
                                        )}
                                      </InputRightElement>
                                      <InputLeftElement
                                        pointerEvents="none"
                                        children={
                                          <SearchIcon color="gray.400" />
                                        }
                                      />
                                    </InputGroup>
                                    <Button
                                      h="100%"
                                      size="lg"
                                      borderRadius="full"
                                      right="0"
                                      onClick={handleSearch}
                                      colorScheme="blue"
                                      ml="2"
                                      p={3}
                                    >
                                      <BiSearch size={22} />
                                    </Button>
                                  </Box>
                                  <Box overflowY="auto" maxH="300px">
                                    {(shouldFilterWaves
                                      ? filteredWaves
                                      : waves
                                    ).map((wave) => {
                                      if (wave.name) {
                                        return (
                                          <Box
                                            key={wave.id}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            m="2"
                                            p="2"
                                          >
                                            <Box
                                              overflow="hidden"
                                              display="flex"
                                              justifyContent="space-between"
                                              cursor="pointer"
                                              onClick={() =>
                                                handleWaveClick(wave)
                                              }
                                            >
                                              <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                mb="2"
                                              >
                                                {wave.name}
                                              </Text>
                                              <Icon
                                                as={
                                                  expandedWaves[wave.id]
                                                    ? FiArrowUp
                                                    : FiArrowDown
                                                }
                                                boxSize={6}
                                                color="gray.500"
                                              />
                                            </Box>
                                            {expandedWaves[wave.id] &&
                                              salAudios && (
                                                <Box ml="6">
                                                  <RadioGroup
                                                    value={selectedSalAudio?.id}
                                                    onChange={(value) => {
                                                      const selectedFile =
                                                        salAudios.find(
                                                          (file) =>
                                                            file.id.toString() ===
                                                            value
                                                        );
                                                      handleSalAudioSelect(
                                                        selectedFile
                                                      );
                                                    }}
                                                  >
                                                    {salAudios.map((audio) => (
                                                      <AudioItem
                                                        key={audio.id}
                                                        audio={audio}
                                                        onPlay={(source, id) =>
                                                          play(
                                                            source,
                                                            id,
                                                            "wave"
                                                          )
                                                        }
                                                        onStop={() => stop()}
                                                        onSelect={(
                                                          selectedAudio
                                                        ) =>
                                                          handleSalAudioSelect(
                                                            selectedAudio
                                                          )
                                                        }
                                                        isSelected={
                                                          selectedSalAudio?.id ===
                                                          audio.id
                                                        }
                                                        isLoading={
                                                          loadingWaveAudios
                                                        }
                                                      />
                                                    ))}
                                                  </RadioGroup>
                                                </Box>
                                              )}
                                          </Box>
                                        );
                                      }
                                      return null; // Explicit return here for the case when `wave.name` is falsy
                                    })}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Box
                              display={
                                selectedOrigin ===
                                formatMessage({ id: "chakras" })
                                  ? "block"
                                  : "none"
                              }
                            >
                              {chakras.map((chakra) => (
                                <AudioItem
                                  key={chakra.id}
                                  audio={chakra}
                                  onPlay={(source, id) =>
                                    play(source, id, "chakra")
                                  }
                                  onStop={() => stop()}
                                  onSelect={(selectedAudio) =>
                                    handleSalAudioSelect(selectedAudio)
                                  }
                                  isSelected={
                                    selectedSalAudio?.id === chakra.id
                                  }
                                  isLoading={false}
                                />
                              ))}
                            </Box>
                            <Box
                              display={
                                selectedOrigin ===
                                formatMessage({ id: "solfeggio" })
                                  ? "block"
                                  : "none"
                              }
                            >
                              {solfeggios.map((solfeggio) => (
                                <AudioItem
                                  key={solfeggio.id}
                                  audio={solfeggio}
                                  onPlay={(source, id) =>
                                    play(source, id, "solfeggio")
                                  }
                                  onStop={() => stop()}
                                  onSelect={(selectedAudio) =>
                                    handleSalAudioSelect(selectedAudio)
                                  }
                                  isSelected={
                                    selectedSalAudio?.id === solfeggio.id
                                  }
                                  isLoading={false}
                                />
                              ))}
                            </Box>
                            <Box
                              display={
                                selectedOrigin ===
                                formatMessage({ id: "balance Portal" })
                                  ? "block"
                                  : "none"
                              }
                            >
                              {balances.map((balance) => (
                                <AudioItem
                                  key={balance.id}
                                  audio={balance}
                                  onPlay={(source, id) =>
                                    play(source, id, "balance")
                                  }
                                  onStop={() => stop()}
                                  onSelect={(selectedAudio) =>
                                    handleSalAudioSelect(selectedAudio)
                                  }
                                  isSelected={
                                    selectedSalAudio?.id === balance.id
                                  }
                                  isLoading={false}
                                />
                              ))}
                            </Box>
                          </Box>
                        )}
                      </ModalBody>
                      <ModalFooter marginTop="10px">
                        <Button
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => {
                            stop();
                            disclosureProps.onClose();
                          }}
                        >
                          {formatMessage({
                            id: "focusFilm.create.cancel_btn",
                          })}
                        </Button>
                        <Button
                          colorScheme="green"
                          onClick={() => {
                            setSelectedPosters([]);
                            setSelectedSalAudio(undefined);
                            setSearchTerm("");
                            setShouldFilterWaves(false);
                            setTitle("");
                            setDescription("");
                            setCurrentTab(0);

                            onCreate({
                              title: title,
                              description: description,
                              posters: selectedPosters,
                              audioName: selectedSalAudio?.name,
                              audioUrl: selectedSalAudio?.audioUrl,
                              createdAt: new Date().toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "2-digit",
                                }
                              ),
                            });
                            stop();
                            disclosureProps.onClose();
                          }}
                          isDisabled={
                            selectedPosters.length === 0 ||
                            !selectedSalAudio ||
                            !title ||
                            title === ""
                          }
                        >
                          {formatMessage({
                            id: "focusFilm.create.create_btn",
                          })}
                        </Button>
                      </ModalFooter>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </ModalContent>
          </>
        ),
      }}
    />
  );
};

export default CreateFilmModal;
