import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
  visible: true,
  playing: false,
  current: "",
  currentGif: "",
  name: "",
  type: "",
  id: "",
  loop: false,
  disableSeeking: false,
  isBtn: false,
  smBtn: false,
  PeBtn: false,
  anotherPlace: false,
  stop: false,
  hereValue: "",
  display: false,
  maximize: false,
  value: "noValue",
  color: "transparent",
  showTime: 0,
  repeat: 0,
  repeatTimeId: 0 as any,
  showTimeId: 0,

  blink: false,
  endDate: String(new Date()),
  startDate: String(new Date()),
  clear: true,
  isPlaylist: false,
  playlist: [],
  playlistId: 0,
  currentPlaylistTrackIndex: 0,
  frBtn: false,
  baBtn: false,
  entityName: "",
  isStatic: false,
  volume: 1,
};
type State = typeof initialState;
type MediaAction = PayloadAction<{
  source: string;
  type: string;
  id: string;
  gif?: string;
  name: string;
  loop?: boolean;
  disableSeeking?: boolean;
}>;
type playlistAction = PayloadAction<{
  playlist: any[];
  startIndex?: number;
  playlistId: number;
}>;
const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    stopMedia(state: State) {
      state.playing = false;
      state.current = "";
      state.type = "";
      state.id = "";
      state.visible = false;
      state.currentGif = "";
      state.name = "";
      state.loop = false;
      state.disableSeeking = false;
      state.PeBtn = false;
      state.frBtn = false;
      state.baBtn = false;
      state.isStatic = false;
    },
    playMedia(state: State, { payload }: MediaAction) {
      state.current = payload.source;
      state.type = payload.type;
      state.id = payload.id;
      state.visible = !("" + payload.id)?.includes("wave");
      state.currentGif = payload.gif ?? "";
      state.name = payload.name;
      state.loop = payload.loop ?? false;
      state.disableSeeking = payload.disableSeeking ?? false;
      state.playing = true;
      state.isPlaylist = false;
      state.frBtn = payload.frBtn ?? false;
      state.baBtn = payload.baBtn ?? false;
      state.PeBtn = payload.PeBtn ?? false;
      state.entityName = payload.entityName ?? "";
      state.smBtn = payload.smBtn ?? false;
    },
    playPlaylist(state: State, { payload }: playlistAction) {
      state.playlistId = payload.playlistId;
      state.isPlaylist = true;
      state.visible = true;
      state.playing = true;
      state.disableSeeking = true;
      state.type = "playlistAudio";
      state.playlist = payload.playlist;
      state.currentPlaylistTrackIndex = payload.startIndex ?? 0;

      let { id, name, slug, frequency, audioUrl, entityName, gif } =
        payload.startIndex
          ? payload.playlist[payload.startIndex]
          : payload.playlist[0];

      state.current = audioUrl;
      state.id = id!;
      state.name = name || slug || frequency;
      state.frBtn = false;
      state.baBtn = false;
      state.PeBtn = false;
      state.smBtn = false;
      state.entityName = entityName ?? "";
      state.isStatic = payload.isStatic ?? false;
      state.currentGif = gif ?? "";
    },
    playNextTrack(state, { payload }) {
      state.playlist = state.isStatic
        ? state.playlist
        : payload.playlists.find((playlist) => playlist.id === state.playlistId)
            .tracks;

      let currentTrackIndex = state.playlist.findIndex(
        (track) => track.audioUrl === state.current
      );

      let nextTrack = state.isStatic
        ? state.playlist[currentTrackIndex + 1]
        : payload.playlists.find((playlist) => playlist.id === state.playlistId)
            .tracks[currentTrackIndex + 1];

      if (nextTrack) {
        state.current = nextTrack.audioUrl;
        state.id = nextTrack.id!;
        state.name = nextTrack.name || nextTrack.slug || nextTrack.frequency;
        // state.currentPlaylistTrackIndex++;
        state.entityName = nextTrack.entityName ?? "";
        state.currentGif = nextTrack.gif ?? "";
      } else {
        state.playing = false;
        state.current = "";
        state.type = "";
        state.id = "";
        state.visible = false;
        state.currentGif = "";
        state.name = "";
        state.loop = false;
        state.disableSeeking = false;
        state.PeBtn = false;
        state.frBtn = false;
        state.baBtn = false;
        state.smBtn = false;
        state.isStatic = false;
      }
    },
    playPreviousTrack(state, { payload }) {
      state.playlist = state.isStatic
        ? state.playlist
        : payload.playlists.find((playlist) => playlist.id === state.playlistId)
            .tracks;

      let currentTrackIndex = state.playlist.findIndex(
        (track) => track.audioUrl === state.current
      );

      let previousTrack = state.isStatic
        ? state.playlist[currentTrackIndex - 1]
        : payload.playlists.find((playlist) => playlist.id === state.playlistId)
            .tracks[currentTrackIndex - 1];

      if (previousTrack) {
        state.current = previousTrack.audioUrl;
        state.id = previousTrack.id!;
        state.name =
          previousTrack.name || previousTrack.slug || previousTrack.frequency;
        // state.currentPlaylistTrackIndex--;
        state.entityName = previousTrack.entityName ?? "";
        state.currentGif = previousTrack.gif ?? "";
      } else {
        state.current = state.playlist[0].audioUrl;
        state.id = state.playlist[0].id!;
        state.name =
          state.playlist[0].name ||
          state.playlist[0].slug ||
          state.playlist[0].frequency;
        // state.currentPlaylistTrackIndex = 0;
        state.entityName = state.playlist[0].entityName ?? "";
        state.currentGif = state.playlist[0].gif ?? "";
      }
    },
    setVolume(state, { payload }) {
      state.volume = payload.volume;
    },
    togglePlay(state: State, { payload }: PayloadAction<{ value?: boolean }>) {
      state.playing = payload.value ?? !state.playing;
    },
    toggleIsBtn(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.isBtn = payload.value ?? !state.isBtn;
    },
    togglePeBtn(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.PeBtn = payload.value ?? !state.PeBtn;
    },
    playArea(state) {
      state.PeBtn = true;
      state.display = true;
      state.maximize = true;
    },
    toggleArea(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.PeBtn = payload.value ?? !state.PeBtn;
      state.display = payload.value ?? !state.display;
      state.maximize = payload.value ?? !state.maximize;
    },
    toggleClearArea(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.clear = payload.value ?? !state.clear;
    },
    changeName(state, { payload }: PayloadAction<{ value?: string }>) {
      state.name = payload.value ?? "";
    },
    toggleAnotherPlace(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.anotherPlace = payload.value ?? !state.anotherPlace;
    },
    setHereValue(state, { payload }: PayloadAction<{ value: string }>) {
      state.hereValue = payload.value;
    },
    setShowTime(state, { payload }: PayloadAction<{ value: number }>) {
      state.showTime = payload.value;
    },
    setRepeat(state, { payload }: PayloadAction<{ value: number }>) {
      state.repeat = payload.value;
    },
    setStop(state, { payload }: PayloadAction<{ value: boolean }>) {
      state.stop = payload.value;
    },
    setValue(state, { payload }: PayloadAction<{ value: string }>) {
      state.value = payload.value;
    },
    setColor(state, { payload }: PayloadAction<{ value: string }>) {
      state.color = payload.value;
    },
    setRepeatTimeId(state, { payload }: PayloadAction<{ value: any }>) {
      state.repeatTimeId = payload.value;
    },
    setShowTimeId(state, { payload }: PayloadAction<{ value: number }>) {
      state.showTimeId = payload.value;
    },
    setMaximize(state, { payload }: PayloadAction<{ value: boolean }>) {
      state.maximize = payload.value;
    },
    setDisplay(state, { payload }: PayloadAction<{ value: boolean }>) {
      state.display = payload.value;
    },
    toggleBlinkValue(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.blink = payload.value ?? !state.blink;
    },
    setMessage(
      state,
      { payload }: PayloadAction<{ value: string | undefined }>
    ) {
      state.messageValue = payload.value;
      const localStorageMessage =
        window.localStorage.getItem("intentionMessage");
      window.localStorage.setItem(
        "intentionMessage",
        localStorageMessage || payload.value || ""
      );
    },
    endDateValue(state, { payload }: PayloadAction<{ value: string }>) {
      state.endDate = payload.value;
      window.localStorage.removeItem("intentionStartDate");
    },
    startDateValue(state, { payload }: PayloadAction<{ value: string }>) {
      state.startDate = payload.value;
      window.localStorage.setItem(
        "intentionStartDate",
        new Date(payload.value).toISOString() || ""
      );
    },
    toggleVisible(state, { payload }: PayloadAction<{ value?: boolean }>) {
      state.visible = payload.value ?? !state.visible;
    },
    activateSmButton(state) {
      state.smBtn = true;
    },
    deactivateSmButton(state) {
      state.smBtn = false;
    },
    toggleSmBtn(state) {
      state.smBtn = !state.smBtn;
    },
  },
});
export const {
  togglePlay,
  playMedia,
  stopMedia,
  setStop,
  toggleIsBtn,
  togglePeBtn,
  toggleBlinkValue,
  setValue,
  setHereValue,
  endDateValue,
  startDateValue,
  toggleAnotherPlace,
  setColor,
  setDisplay,
  setMaximize,
  setMessage,
  setRepeatTimeId,
  setRepeat,
  setShowTime,
  setShowTimeId,
  toggleVisible,
  changeName,
  playArea,
  toggleClearArea,
  toggleArea,
  playPlaylist,
  playNextTrack,
  playPreviousTrack,
  setVolume,
  activateSmButton,
  deactivateSmButton,
  toggleSmBtn,
} = mediaSlice.actions;
export default mediaSlice.reducer;
