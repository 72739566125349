import { Box } from "@chakra-ui/react";
import React from "react";
import { affirmationBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";
import CreateStory from "./CreateStory";
import StoryItems from "./StoryItems";

const Story: React.FC = () => {
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />
      <CreateStory />
      <StoryItems />
    </Box>
  );
};

export default Story;
