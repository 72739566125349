import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { descSize, titleSize } from "src/api";
import { FormattedMessage } from "react-intl";

const points = Array.from(Array(11).keys());
const Description = () => {
  return (
    <Box pl={["2"]} fontWeight="bold">
      <VStack alignItems="center">
        <Box>
          <Text
            fontSize={titleSize}
            fontWeight="bold"
            color="#1f3f93"
            textAlign="center"
          >
            <FormattedMessage id="descriptioin.title" />
          </Text>
        </Box>
        <Box pt="8">
          <Text color="#8b8b8b" fontSize={descSize}>
            <FormattedMessage id="description.content" />
          </Text>
        </Box>
        <Box pl="8" pt="3" alignSelf="start">
          <ul>
            {points.map((_, i) => (
              <li key={`description.point${i + 1}`}>
                <Text color="#8b8b8b" fontSize={descSize}>
                  <FormattedMessage id={`description.point${i + 1}`} />
                </Text>
              </li>
            ))}
          </ul>
        </Box>
        <Box pt="3" alignSelf="start" color="black">
          <Text textDecoration="underline" fontSize={descSize}>
            <FormattedMessage id="description.note.title" />
          </Text>
        </Box>
        <Box pt="3" alignSelf="start" color="black">
          <Text color="#8b8b8b" fontSize={descSize}>
            <FormattedMessage
              id="description.note.content"
              values={{
                br: <br />,
              }}
            />
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default Description;
