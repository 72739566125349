import { QueryFunction } from "react-query";
import { get as apiGet } from "src/api";
import { PureToneData, OtherSourceData } from "src/types";

const getPureTones: QueryFunction<PureToneData> = ({ queryKey }) => {
  const [, locale] = queryKey;
  return apiGet<PureToneData>("/pure-tone", locale);
};
const getOtherSources: QueryFunction<OtherSourceData> = ({ queryKey }) => {
  const [, locale] = queryKey;
  return apiGet<OtherSourceData>("/other-sources", locale);
};

export { getPureTones, getOtherSources };
