import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import { frequencyBg } from "src/assets/icons";
import Breadcrumb from "src/components/Breadcrumb/Breadcrumb";

interface Props {
  route?: string;
  children?: React.ReactNode;
  noBread?: any;
  breadTitle?: string;
}

const BaseView: React.FC<Props> = ({
  route,
  children,
  noBread,
  breadTitle,
}) => {
  const { locale } = useIntl();

  return (
    <Box>
      {!noBread && <Breadcrumb breadTitle={breadTitle} />}

      {children}

      <Box
        overflow={"hidden"}
        width={"767px"}
        height={"767px"}
        position={"absolute"}
        bottom={0}
        right={0}
        zIndex={-1}
      >
        <Image
          overflow={"hidden"}
          position={"absolute"}
          right={"-279px"}
          bottom={"-278px"}
          width={"767px"}
          height={"767px"}
          src={frequencyBg}
          transform={locale === "en" ? "scaleX(-1)" : undefined}
        />
      </Box>
    </Box>
  );
};

export default BaseView;
