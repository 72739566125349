import { Box, BoxProps, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "src/components/Card";
import { RiShareBoxLine } from "react-icons/ri";
import { useIntl } from "react-intl";
import { AiOutlineReload } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BiArchiveOut } from "react-icons/bi";
import { BiArchive } from "react-icons/bi";
import { BiCopy } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteButton from "../DeleteModel";

type ItemCardProps = {
  resort?: () => void;
  remove?: () => void;
  edit?: () => void;
  archive?: () => void;
  view?: () => void;
  unarchive?: () => void;
  douplicate?: () => void;
  share?: () => void;
  isSharedWithMe?: boolean;
  divChild?: any;
  permission?: any;
  isPlaying?: any;
  onPlay?: any;
  onPause?: any;
} & BoxProps;

const ItemCard: React.FC<ItemCardProps> = ({
  children,
  resort,
  remove,
  edit,
  archive,
  unarchive,
  onClick,
  view,
  divChild,
  douplicate,
  share,
  isSharedWithMe,
  permission,
  isPlaying,
  onPlay,
  onPause,
  ...props
}) => {
  const color = useColorModeValue("gray.700", "gray.800");
  const canUpdate = permission === "update";
  const { formatMessage } = useIntl();
  const [restoreLoading, setRestoreLoading] = useState(false);

  return (
    <>
      <Card
        my='2'
        _hover={{ bg: "gray.200", cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e);
        }}
        {...props}
        display='flex'
        alignItems='center'
        justifyContent='space-between'>
        {divChild ? (
          <Box d='inline-block' color={color}>
            {children}
          </Box>
        ) : (
          <Text d='inline-block' color={color}>
            {children}
          </Text>
        )}
        <Box display='inline-flex' my='auto' alignSelf={"flex-end"}>
          {isSharedWithMe && <p>{formatMessage({ id: "shared" })}</p>}
          {douplicate ? (
            <IconButton
              // color="#e97575"
              mx={2}
              onClick={(e) => {
                e.stopPropagation();
                // remove();
                douplicate();
              }}
              variant='link'
              aria-label='delete'
              fontSize='23px'
              minW={1}
              icon={<BiCopy />}
              h='5'
            />
          ) : null}
          {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && edit ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                edit();
              }}
              me='1'
              mx={2}
              color='brand.800'
              variant='link'
              aria-label='re-answer'
              fontSize='23px'
              minW={1}
              icon={<BiEdit />}
              h='5'
            />
          ) : null}
          {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && resort ? (
            <IconButton
              mx={2}
              me='1'
              minW={1}
              isLoading={restoreLoading}
              onClick={(e) => {
                e.stopPropagation();
                setRestoreLoading(true);
                try {
                  resort().then(() => {
                    setRestoreLoading(false);
                  });
                } catch (e) {
                  setRestoreLoading(false);
                }
              }}
              color='brand.800'
              variant='link'
              aria-label='re-answer'
              fontSize='23px'
              icon={<AiOutlineReload />}
              h='5'
            />
          ) : null}
          {view ? (
            <IconButton
              me='1'
              mx={2}
              minW={1}
              onClick={(e) => {
                e.stopPropagation();
                view();
              }}
              color='brand.800'
              variant='link'
              aria-label='re-answer'
              fontSize='23px'
              icon={<AiFillEye />}
              h='5'
            />
          ) : null}
          {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && archive ? (
            <IconButton
              mx={2}
              me='1'
              minW={1}
              onClick={(e) => {
                e.stopPropagation();
                archive();
              }}
              color='brand.800'
              variant='link'
              aria-label='re-answer'
              fontSize='23px'
              icon={<BiArchive />}
              h='5'
            />
          ) : null}
          {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && unarchive ? (
            <IconButton
              mx={2}
              me='1'
              minW={1}
              onClick={(e) => {
                e.stopPropagation();
                unarchive();
              }}
              color='brand.800'
              variant='link'
              aria-label='re-answer'
              fontSize='23px'
              icon={<BiArchiveOut />}
              h='5'
            />
          ) : null}
          {!isSharedWithMe && share && (
            <IconButton
              // visibility={shareIconVisibility}
              // color="#e97575"
              onClick={(e: any) => {
                e.stopPropagation();
                share();
              }}
              variant='link'
              aria-label='delete'
              fontSize='23px'
              minW={1}
              icon={<RiShareBoxLine className='remove-on-print' />}
              h='5'
            />
          )}
          {onPlay && onPause ? (
            isPlaying ? (
              <IconButton
                me='1'
                mx={2}
                minW={1}
                onClick={(e) => {
                  e.stopPropagation();
                  onPause();
                }}
                color='brand.800'
                variant='link'
                aria-label='re-answer'
                fontSize='23px'
                icon={<PauseIcon />}
                h='5'
              />
            ) : (
              <IconButton
                me='1'
                mx={2}
                minW={1}
                onClick={(e) => {
                  e.stopPropagation();
                  onPlay();
                }}
                color='brand.800'
                variant='link'
                aria-label='re-answer'
                fontSize='23px'
                icon={<PlayArrowIcon />}
                h='5'
              />
            )
          ) : null}
          {remove && !isSharedWithMe ? <DeleteButton remove={remove} /> : null}
        </Box>
      </Card>
    </>
  );
};
export default ItemCard;
