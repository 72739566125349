import { Box, IconButton, IconButtonProps, Text } from "@chakra-ui/react";
import React from "react";

type ValueShowButtonProps = {
  text: string;
  icon: any;
  "aria-label": string;
} & IconButtonProps;
const ValueShowButton: React.FC<ValueShowButtonProps> = ({
  "aria-label": ariaLabel,
  icon,
  text,
  ...props
}) => {
  return (
    <Box d="flex" flexDir="column" alignItems="center">
      <IconButton
        fontSize="30px"
        w="70px"
        h="70px"
        aria-label={ariaLabel}
        color="brand.800"
        icon={icon}
        {...props}
      />
      <Text color="gray.900">{text}</Text>
    </Box>
  );
};

export default ValueShowButton;
