import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import Breadcrumb from "src/components/Breadcrumb/Breadcrumb";

interface Props {
  children?: React.ReactNode;
  imageSrc?: any;
  noBread?: any;
  breadTitle?: string;
}
const BaseView: React.FC<Props> = ({ children, imageSrc, noBread, breadTitle }) => {
  const { locale } = useIntl();
  return (
    <Box>
      {!noBread && <Breadcrumb breadTitle={breadTitle} />}
      {children}
      {imageSrc && (
        <Image
          className={locale === "en" ? "rotate-image" : "image"}
          src={imageSrc}
          position="absolute"
          bottom={0}
          right={0}
          zIndex={-1}
          transform={locale === "en" ? "scaleX(-1)" : undefined}
          alt={"Image for desgin purposes"}
        />
      )}
    </Box>
  );
};

export default BaseView;
