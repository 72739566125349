// a function to check if a plan has any weight in its actions
// false if no weight

import { Plan } from "src/types";

export const checkPoints = (plan: Plan): boolean => {
  if (!plan.aspects) return false;

  for (const aspect of plan.aspects) {
    for (const goal of aspect.goals) {
      if (!goal.actions) continue;

      for (const action of goal.actions) {
        if (action.weight) {
          return true;
        }
      }
    }
  }

  return false;
};
