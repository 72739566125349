import React, { useState, FormEvent } from "react";
import {
  FormControl,
  Box,
  Input,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import DarkHeader from "src/layout/DarkHeader";
import { post } from "src/api/index";
import { useHistory } from "react-router-dom";
import { LOGIN_ROUTE } from "src/routes";
import useLocale from "src/providers/useLocale";

const ForgetReset = () => {
  //for localization
  const { formatMessage } = useIntl();

  //email to send in request
  const [data, setData] = useState<any>({});

  //history object
  const history = useHistory();

  //processing state
  const [processing, setProcessing] = useState(false);

  //currnet locale
  const [locale] = useLocale();

  //error state
  const [error, setError] = useState("");

  //function to handle change
  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setData({ ...data, [target.name]: target.value });
  };

  //function to handle submittion
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (processing) return;
    //if the passwords dont match ignore
    if (data.password !== data.confirmPassword) {
      setError("conPwMisMatch");
      return;
    }
    //make the call with the email
    setProcessing(true);
    const token = new URLSearchParams(window.location.search).get("user");
    const response: any = await post(
      "/auth/forget/reset",
      { password: data.password, user: token },
      locale
    ).catch((err) => {
      const errMessage = err.message
        ? err.message[0]
        : formatMessage({ id: "wentWrong" });
      setError(errMessage);
      setProcessing(false);
    });
    setProcessing(false);
    if (response) {
      if (response.message === "success") {
        // login(response);
        //don't login the user instead redirect him to the login page
        history.push(LOGIN_ROUTE);
      } else {
        setError(formatMessage({ id: "invalidToken" }));
      }
    }
  };

  return (
    <>
      <DarkHeader />
      <VStack width="100%" padding="50px 0">
        <Box margin="40px 0">
          <Text
            fontSize="42px"
            color="brand.900"
            fontWeight="bold"
            textAlign="center"
          >
            {formatMessage({ id: "resetPass" })}
          </Text>
        </Box>
        <FormControl
          as="form"
          padding="30px"
          maxWidth="500px"
          onSubmit={handleSubmit}
        >
          {error && (
            <Box margin="20px 0">
              <Text color="crimson">{error}</Text>
            </Box>
          )}
          <Box margin="10px 0">
            <Input
              name="password"
              id="password"
              type="password"
              placeholder={formatMessage({ id: "password" })}
              required
              onChange={handleChange}
              height="50px"
            />
          </Box>
          <Box margin="10px 0">
            <Input
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              placeholder={formatMessage({ id: "confirmPassword" })}
              required
              onChange={handleChange}
              height="50px"
            />
          </Box>
          <Box margin="60px 0 30px 0" textAlign="center">
            <Button
              height="50px"
              minWidth="80px"
              type="submit"
              background="brand.900"
              color="white"
              _hover={{ filter: "brightness(120%)" }}
              disabled={processing}
            >
              {formatMessage({ id: "submit" })}
            </Button>
          </Box>
        </FormControl>
      </VStack>
    </>
  );
};

export default ForgetReset;
