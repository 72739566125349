import React, { useEffect } from "react";
//@ts-ignore
import { GoSell } from "@tap-payments/gosell";
import { PaymentIntent } from "src/types";

type GoSellPaymentProps = {
  gatewayConfig: PaymentIntent;
};

const GoSellPayment: React.FC<GoSellPaymentProps> = ({ gatewayConfig }) => {
  useEffect(() => {
    //once the component mounts open the payment page
    //it will not mount unless the user has clicked on the subscribe button to pay
    //Why timeout? Because the component seems to need some time to mount, also it sets up the config only when
    //it's first rendered, thus this is the only approach to go for when you need a varying order amount
    setTimeout(() => {
      GoSell.openPaymentPage();
    }, 1000);
  }, []);

  return <GoSell {...gatewayConfig} />;
};

export default GoSellPayment;
