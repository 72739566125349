import React, { ErrorInfo } from "react";
import CrashImg from "src/assets/images/crash.jpg";
import { Box, Text, Image, Button } from "@chakra-ui/react";
import useIsDev from "src/helpers/useIsDev";

type CrashState = {
  crashed: boolean;
};

class Crash extends React.Component<{}, CrashState> {
  constructor(props: any) {
    super(props);

    this.state = {
      crashed: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ crashed: true });
  }

  render() {
    return (
      <>
        {this.state.crashed ? (
          <Box textAlign="center">
            <Box
              padding="20px"
              display="flex"
              flexDir="column"
              alignItems="center"
            >
              <Text color="#1f3f93" fontSize="72px">
                Whoops, The App's Crashed!
              </Text>
            </Box>
            <Box>
              <Button
                padding="10px"
                border="none"
                borderRadius="5px"
                bg="#1f3f93"
                color="white"
                as={"a"}
                href="/"
                textDecoration="none"
                minW="200px"
              >
                <Text fontFamily="Calibri" fontSize="20px" fontWeight="bold">
                  Back To Home Page
                </Text>
              </Button>
            </Box>
            <Box
              marginTop="100px"
              maxWidth="600px"
              marginLeft="auto"
              marginRight="auto"
            >
              <Image src={CrashImg} width="100%" />
            </Box>
          </Box>
        ) : (
          this.props.children
        )}
      </>
    );
  }
}
const CrashWrapper = ({ children }: { children: React.ReactNode }) => {
  const isDev = useIsDev();
  if (isDev) {
    return children;
  }
  return <Crash>{children}</Crash>;
};
export default CrashWrapper;
