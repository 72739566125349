import { deleteMethod, post, put } from "src/api";
import { Story } from "src/types";

export const createStory: (story: Story) => Promise<Story> = (story) =>
  post<Story>("/story", story);

export const deleteStory: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/story/${id!}`);

export const editStory: (story: Story) => Promise<Story> = (story) =>
  put<Story>(`/story/${story.id!}`, story);
