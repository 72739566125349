import { QueryFunction } from "react-query";
import { get } from "src/api";
import { StoryList } from "src/types";

const getList: [string, QueryFunction<StoryList>] = [
  "listStory",
  () => get<StoryList>("/story"),
];

export { getList };
