import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssistantState {
  focusProgram: string | null;
  isChatOpen: boolean;
}

const initialState: AssistantState = {
  focusProgram: null,
  isChatOpen: false,
};

const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setFocusProgram: (state, action: PayloadAction<string>) => {
      state.focusProgram = action.payload;
    },
    toggleChatWindow: (state) => {
      state.isChatOpen = !state.isChatOpen;
    },
  },
});

export const { setFocusProgram, toggleChatWindow } = assistantSlice.actions;
export default assistantSlice.reducer;
