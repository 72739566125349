import { useIntl } from "react-intl";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  VStack,
  ModalOverlay,
  Box,
  Text,
} from "@chakra-ui/react";

const ViewProblem = (props: any) => {
  const { open, problemName, goal, startDate, firstStep, close } = props;

  const { formatMessage } = useIntl();

  return (
    <Box>
      <Modal isCentered isOpen={open} onClose={close}>
        <ModalOverlay />
        <ModalContent
          p={"24px 70px 44px"}
          maxWidth={"720px"}
          width={"100%"}
          borderRadius={"30px"}
        >
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Box
                width={"100%"}
                h={"46px"}
                pl={"5px"}
                display={"flex"}
                alignItems={"center"}
                borderRadius={"10px"}
                boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
                background={"#f9f9f9"}
              >
                <Text mx="5px" fontFamily={"tajwal"} lineHeight={"1.38"}>
                  {" "}
                  {formatMessage({ id: "problem.problem" })} :{" "}
                </Text>
                <Text
                  mx="5px"
                  color={"#8b8b8b"}
                  fontFamily={"tajwal"}
                  lineHeight={"1.38"}
                >
                  {" "}
                  {problemName}{" "}
                </Text>
              </Box>
              <Box
                width={"100%"}
                h={"46px"}
                pl={"5px"}
                display={"flex"}
                alignItems={"center"}
                borderRadius={"10px"}
                boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
                background={"#f9f9f9"}
              >
                <Text mx="5px" fontFamily={"tajwal"} lineHeight={"1.38"}>
                  {" "}
                  {formatMessage({ id: "problem.goal" })} :{" "}
                </Text>
                <Text
                  mx="5px"
                  color={"#8b8b8b"}
                  fontFamily={"tajwal"}
                  lineHeight={"1.38"}
                >
                  {" "}
                  {goal}{" "}
                </Text>
              </Box>
              <Box
                width={"100%"}
                h={"46px"}
                pl={"5px"}
                display={"flex"}
                alignItems={"center"}
                borderRadius={"10px"}
                boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
                background={"#f9f9f9"}
              >
                <Text mx="5px" fontFamily={"tajwal"} lineHeight={"1.38"}>
                  {" "}
                  {formatMessage({ id: "problem.plan" })} :{" "}
                </Text>
                <Text
                  mx="5px"
                  color={"#8b8b8b"}
                  fontFamily={"tajwal"}
                  lineHeight={"1.38"}
                >
                  {" "}
                  {firstStep}{" "}
                </Text>
              </Box>
              <Box
                width={"100%"}
                h={"46px"}
                pl={"5px"}
                display={"flex"}
                alignItems={"center"}
                borderRadius={"10px"}
                boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
                background={"#f9f9f9"}
              >
                <Text mx="5px" fontFamily={"tajwal"} lineHeight={"1.38"}>
                  {" "}
                  {formatMessage({ id: "problem.date" })} :{" "}
                </Text>
                <Text
                  mx="5px"
                  color={"#8b8b8b"}
                  fontFamily={"tajwal"}
                  lineHeight={"1.38"}
                >
                  {" "}
                  {startDate}{" "}
                </Text>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewProblem;
