import { Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { VideoType } from "./Videos";
import Video from "./Video";
import styles from "./videos.module.css";
import { FormattedMessage } from "react-intl";
interface SectionProps {
  title: string;
  videos: VideoType[];
}
const Section = ({ title, videos }: SectionProps) => {
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionTitle}>{title}</h3>
        {videos.length > 3 &&
          (!isSeeMore ? (
            <div className={styles.seeMoreBtnContainer}>
              <button
                className={`${styles.seeMoreBtn} ${styles.smallScreen}`}
                onClick={() => setIsSeeMore(true)}
              >
                <FormattedMessage id={"seeMore"} />
              </button>
            </div>
          ) : (
            <div className={styles.seeMoreBtnContainer}>
              <button
                className={`${styles.seeMoreBtn} ${styles.smallScreen}`}
                onClick={() => setIsSeeMore(false)}
              >
                <FormattedMessage id={"seeLess"} />
              </button>
            </div>
          ))}
      </div>
      <div className={styles.videosContainer}>
        {isSeeMore
          ? videos.map((vid) => {
              return <Video key={vid.id} video={{ ...vid }} />;
            })
          : videos.slice(0, 3).map((vid) => {
              return <Video key={vid.id} video={{ ...vid }} />;
            })}
      </div>
      {videos.length > 3 &&
        (!isSeeMore ? (
          <div className={styles.seeMoreBtnContainer}>
            <button
              className={`${styles.seeMoreBtn} ${styles.bigScreen}`}
              onClick={() => setIsSeeMore(true)}
            >
              <FormattedMessage id={"seeMore"} />
            </button>
          </div>
        ) : (
          <div className={styles.seeMoreBtnContainer}>
            <button
              className={`${styles.seeMoreBtn} ${styles.bigScreen}`}
              onClick={() => setIsSeeMore(false)}
            >
              <FormattedMessage id={"seeLess"} />
            </button>
          </div>
        ))}
    </div>
  );
};

export default Section;
