import React, { useEffect, useState } from "react";
import BaseView from "../BaseView";
import Plan from "./containers/Plan";
import usePlanApi from "./usePlanApi";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  useDisclosure,
  useToast,
  Text,
  Button,
  Input,
  Center,
  SimpleGrid,
  HStack,
  Select,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
} from "@chakra-ui/react";
import { valuesBg } from "src/assets/icons";
import { Plan as PlanType } from "src/types";
import { FiPlus } from "react-icons/fi";
import PrimaryButton from "src/components/PrimaryButton";
import PlanContent from "./PlanContent";
import { useAppContext } from "../../../Providers";
import SharingModal from "src/components/SharingModal/SharingModal";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { getPlanGroup } from "src/api/prepration/salPlan/query";
import NewItemCard from "src/components/NewItemCard/NewItemCard";
import {
  deleteGroup,
  addPlanToGroup,
  removePlanFromGroup,
} from "src/api/prepration/salPlan/mutation";
import { de } from "date-fns/locale";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const SalPlan = () => {
  const context = useAppContext();
  useEffect(() => {
    context.background?.set("#fff");
  });

  const { planListQuery, sharedListQuery } = usePlanApi();

  // =================================================================================================
  // Because we get all plans from different endpoints that fetchs them from different tables in the DB
  // we merge them, add isShared flag, sort them back by thier order in the plans table to display them i a proper order
  // =================================================================================================

  const sharedPlans = sharedListQuery.data || [];
  const userPlans = planListQuery.data || [];
  // console.log([...sharedPlans, ...userPlans])
  const allPlans = [...sharedPlans, ...userPlans]
    .map((plan) =>
      plan.entity
        ? {
            ...plan,
            id: plan.entity.id,
            shareId: plan.id,
            name: plan.entity.name,
            isShared: true,
            createdAt: plan.entity.createdAt,
          }
        : plan
    )
    .sort((p1, p2) => Number(p2.createdAt) - Number(p1.createdAt));
  // console.log(allPlans)
  const {
    isOpen: isSharingModalOpen,
    onClose: onSharingModalClose,
    onOpen: onSharingModalOpen,
  } = useDisclosure();

  const editPlanDisclosure = useDisclosure({ defaultIsOpen: false });
  const [currentPlan, setCurrentPlan] = useState<PlanType>({ id: 0, name: "" });
  const { formatMessage } = useIntl();
  const { createPlan, douplicatePlan, planGroupsQuery, addNewGroupMutation } =
    usePlanApi(currentPlan.id);

  const [editPlanValue, setEditPlanValue] = useState<PlanType>();
  const [newPlanName, setNewPlanName] = useState("");
  const [newPlanGroupId, setNewPlanGroupId] = useState(0);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState(-1);
  const [selectedPlanId, setSelectedPlanId] = useState(-1); // for change folder modal
  const [isShared, setIsShared] = useState(false); // for change folder modal

  const [showRemoveFromFolder, setShowRemoveFromFolder] = useState(false); // for change folder modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onEdit = (plan?: PlanType) => {
    if (plan) {
      setEditPlanValue(plan);
      editPlanDisclosure.onOpen();
    }
  };

  const toast = useToast();
  const onShare: any = ({ id, name }) => {
    onSharingModalOpen();
    setCurrentPlan({ id, name });
  };
  const setCurrentPlanvValues = (plan) => {
    setCurrentPlan(plan);
    // onOpen()
  };

  const createNewPlan = async (name: string) => {
    const groupId = newPlanGroupId - 1;
    try {
      if (groupId >= 0) await createPlan({ name, groupId });
      else await createPlan({ name });
      toast({
        title: formatMessage({ id: "plan.add.success" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.add.failed" }),
        status: "error",
      });
    }
  };

  const addNewGroup = async (name: string) => {
    try {
      await addNewGroupMutation({ name });
      toast({
        title: formatMessage({ id: "new folder added successfully" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "error" }),
        status: "error",
      });
    }
  };

  const changePlanFolderMutation = useMutation(addPlanToGroup, {
    onSuccess: () => queryClient.invalidateQueries("planGroups"),
  });

  const handleChangePlanFolder = async (e: any) => {
    e.preventDefault();
    try {
      await changePlanFolderMutation.mutateAsync({
        planId: selectedPlanId,
        groupId: selectedFolderId,
        entityType: isShared ? "sharedPlan" : "salPlan",
      });
      toast({
        title: formatMessage({ id: "plan.add.success" }),
        status: "success",
      });
      onClose();
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.add.failed" }),
        status: "error",
      });
    }
  };

  const removePlanFromFolderMutation = useMutation(removePlanFromGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries("planGroups");
      queryClient.invalidateQueries("listPlans");
      queryClient.invalidateQueries("listSharedPlans");
    },
  });

  const handleRemovePlanFromFolder = async () => {
    try {
      await removePlanFromFolderMutation.mutateAsync({
        planId: selectedPlanId,
        entityType: isShared ? "sharedPlan" : "salPlan",
      });
      toast({
        title: formatMessage({ id: "Plan Removed From Folder" }),
        status: "success",
      });
      onClose();
    } catch (error) {
      toast({
        title: formatMessage({ id: "error" }),
        status: "error",
      });
    }
  };

  const douplicateCurrentPlan = async (id: string) => {
    try {
      await douplicatePlan(id);
      toast({
        title: formatMessage({ id: "plan.add.success" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.add.failed" }),
        status: "error",
      });
    }
  };

  const queryClient = useQueryClient();

  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess: () => queryClient.invalidateQueries("planGroups"),
  });

  // douplicatePlan

  if (planGroupsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseView imageSrc={valuesBg}>
      <Box minW="100%">
        <Accordion
          allowToggle
          __css={{ borderColor: "white", background: "white" }}
          className="remove-on-print"
        >
          <AccordionItem>
            <AccordionButton
              py="0"
              onClick={() => {
                setTimeout(() => {
                  document.getElementById(`addNewPlanInput`)?.focus();
                }, 50);
              }}
            >
              <Box flex="1" textAlign="left">
                <Button
                  fontFamily="Calibri (Body)"
                  fontWeight="bold"
                  variant="link"
                  color="brand.800"
                  rightIcon={<FiPlus />}
                >
                  <FormattedMessage
                    id="plan.create"
                    defaultMessage="إضافة خطة جديدة"
                  />
                </Button>
              </Box>
              <br />
              <br />
            </AccordionButton>
            <AccordionPanel>
              {/* <AddNewActionFormOut goalId={goal.id!} planId={currentPlan.id!} /> */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  createNewPlan(newPlanName);
                  setNewPlanName("");
                }}
              >
                <HStack flexWrap="wrap">
                  <Box>
                    <Text
                      d="inline-block"
                      color="brand.900"
                      fontSize="18px"
                      fontWeight="bold"
                      fontFamily="Calibri (Body)"
                    >
                      <FormattedMessage
                        id="purposes.create.add_plan"
                        defaultMessage="ادخل عنوان للخطة"
                      />
                    </Text>
                    <Input
                      variant="flushed"
                      value={newPlanName}
                      onChange={(e) => setNewPlanName(e.target.value)}
                      id="addNewPlanInput"
                      required
                    />
                  </Box>
                  <Box>
                    <Text
                      d="inline-block"
                      color="brand.900"
                      fontSize="18px"
                      fontWeight="bold"
                      fontFamily="Calibri (Body)"
                    >
                      <FormattedMessage id="choose a folder for the plan" />
                    </Text>
                    <Select
                      size="lg"
                      placeholder={formatMessage({
                        id: "Without Folder",
                      })}
                      onChange={(e) => {
                        setNewPlanGroupId(Number(e.target.value));
                      }}
                    >
                      {[...planGroupsQuery.data].reverse().map((group) => (
                        <option value={group.id + 1}>{group.name}</option>
                      ))}
                    </Select>
                  </Box>
                </HStack>

                <Center my={"32px"}>
                  <PrimaryButton type="submit">
                    <FormattedMessage id="save" defaultMessage="حفظ" />
                  </PrimaryButton>
                </Center>
              </form>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              py="0"
              onClick={() => {
                setTimeout(() => {
                  document.getElementById(`addNewGroupInput`)?.focus();
                }, 50);
              }}
            >
              <Box flex="1" textAlign="left">
                <Button
                  fontFamily="Calibri (Body)"
                  fontWeight="bold"
                  variant="link"
                  color="brand.800"
                  rightIcon={<FiPlus />}
                >
                  <FormattedMessage id="Add New Folder" />
                </Button>
              </Box>
              <br />
              <br />
            </AccordionButton>
            <AccordionPanel>
              {/* <AddNewActionFormOut goalId={goal.id!} planId={currentPlan.id!} /> */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  addNewGroup(newGroupName);
                  setNewGroupName("");
                }}
              >
                <Text
                  d="inline-block"
                  color="brand.900"
                  fontSize="18px"
                  fontWeight="bold"
                  fontFamily="Calibri (Body)"
                >
                  <FormattedMessage id="Add Name For New Folder" />
                </Text>
                <Input
                  variant="flushed"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  id="addNewGroupInput"
                  required
                />
                <Center my={"32px"}>
                  <PrimaryButton type="submit">
                    <FormattedMessage id="save" defaultMessage="حفظ" />
                  </PrimaryButton>
                </Center>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* {(planListQuery.isLoading || sharedListQuery.isLoading) ? (
          <div>Loading....</div>
        ) : (planListQuery.isError || sharedListQuery.isError) ? (
          <>
            <div> planListQuery?.error?.message </div>
            <div> sharedListQuery?.error?.message </div>
          </>
        ) : (

          [...planListQuery.data!, ...sharedListQuery.data!].sort((plan1, plan2) => plan2.id - plan1.id)
          planListQuery
            .data!.map((plan) => {
              return (
        <Plan
          setId={setCurrentPlanvValues}
          key={plan.id}
          plan={plan}
          editPlanValue={editPlanValue}
          onEdit={onEdit}
          editPlanDiclosure={editPlanDisclosure}
          removeButton
          onShare={() => { onShare({ id: plan.id, name: "plan" }) }} // name here is the name of the section
        />
        );
            })
        .reverse()
        )} */}
        {/* {sharedListQuery.isLoading ? (
          <div>Loading....</div>
        ) : sharedListQuery.isError ? (
          sharedListQuery.error.message
        ) : (
          sharedListQuery
            .data!.filter(sharedItem => sharedItem.entityName === "salplan").map((sharedPlan) => {
              return (
                <Plan
                  setId={setCurrentPlanvValues}
                  key={sharedPlan.id}
                  plan={{ id: sharedPlan.entity.id, name: sharedPlan.entity.name }} // just for ui not the entire plan 
                  editPlanValue={editPlanValue}
                  onEdit={onEdit}
                  editPlanDiclosure={editPlanDisclosure}
                  sharedProps={sharedPlan} // name here is the name of the section
                  isSharedWithMe={true}
                />
              );
            })
            .reverse()
        )} */}
        {/* {sharedListQuery.isLoading ? (
          <div>Loading....</div>
        ) : sharedListQuery.isError ? (
          sharedListQuery.error.message
        ) : (
          sharedListQuery
            .data!.filter(sharedItem => sharedItem.entityName === "salplan").map((sharedPlan) => {
              return (
                // <div>12</div>
                <Plan
                  setId={setCurrentPlanvValues}
                  key={sharedPlan.id}
                  plan={{ id: sharedPlan.entity.id, name: sharedPlan.entity.name }} // just for ui not the entire plan 
                  editPlanValue={editPlanValue}
                  onEdit={onEdit}
                  editPlanDiclosure={editPlanDisclosure}
                  sharedProps={sharedPlan} // name here is the name of the section
                />
              );
            })
            .reverse()
        )} */}

        <hr />

        <Accordion allowToggle>
          {[...planGroupsQuery.data].reverse().map((group) => (
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton as="div" borderRadius="10px" p={0}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        alignItems="center"
                      >
                        <NewItemCard
                          // py=""
                          borderRadius="10px"
                          bg="#FFF"
                          py="2"
                          boxShadow=""
                          hoverStyles={false}
                          title={`${group.name}`}
                          remove={() => {
                            deleteGroupMutation
                              .mutateAsync({ id: group.id })
                              .then(() => {
                                setSelectedFolderId(-1);
                              });
                          }}
                        ></NewItemCard>
                        {isExpanded ? (
                          <IoIosArrowUp cursor="pointer" size={30} />
                        ) : (
                          <IoIosArrowDown
                            cursor="pointer"
                            fontSize="12px"
                            size={30}
                          />
                        )}
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {(group.plans || group.sharedPlans) &&
                      [...group.plans, ...group.sharedPlans]
                        .sort((a, b) => {
                          if (
                            a.groupJoinedAt !== "0" &&
                            b.groupJoinedAt !== "0"
                          )
                            return b.groupJoinedAt - a.groupJoinedAt;
                          return b.createdAt - a.createdAt;
                        })
                        .map((plan: any) => (
                          <Plan
                            setId={setCurrentPlanvValues}
                            key={plan.isShared ? plan.entity.id : plan.id}
                            plan={plan.isShared ? plan.entity : plan} // just for ui not the entire plan
                            editPlanValue={editPlanValue}
                            onEdit={onEdit}
                            editPlanDiclosure={editPlanDisclosure}
                            sharedProps={plan.isShared ? plan : undefined} // name here is the name of the section
                            isSharedWithMe={plan.isShared}
                            removeButton={!plan.isShared}
                            onShare={
                              !plan.isShared
                                ? () => {
                                    onShare({ id: plan.id, name: "plan" });
                                  }
                                : undefined
                            } // name here is the name of the section
                            openChangeFolderModal={() => {
                              console.log(plan);
                              setSelectedFolderId(-1);
                              setIsShared(plan.isShared ?? false);
                              setSelectedPlanId(
                                plan.isShared ? plan.shareId : plan.id
                              );
                              setShowRemoveFromFolder(true);
                              onOpen();
                            }}
                            groupId={group.id}
                          />
                        ))}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>

        <br />
        <br />

        {allPlans.map((plan) => (
          <Plan
            setId={setCurrentPlanvValues}
            key={plan.isShared ? plan.entity.id : plan.id}
            plan={plan.isShared ? plan.entity : plan} // just for ui not the entire plan
            editPlanValue={editPlanValue}
            onEdit={onEdit}
            editPlanDiclosure={editPlanDisclosure}
            sharedProps={plan.isShared ? plan : undefined} // name here is the name of the section
            isSharedWithMe={plan.isShared}
            removeButton={!plan.isShared}
            onShare={
              !plan.isShared
                ? () => {
                    onShare({ id: plan.id, name: "plan" });
                  }
                : undefined
            } // name here is the name of the section
            openChangeFolderModal={() => {
              console.log(plan);
              setSelectedFolderId(-1);
              setSelectedPlanId(plan.isShared ? plan.shareId : plan.id);
              setIsShared(plan.isShared ?? false);
              setShowRemoveFromFolder(false);
              onOpen();
            }}
          />
        ))}

        <SharingModal
          isOpen={isSharingModalOpen}
          onClose={onSharingModalClose}
          sharedEntity={{ name: "salplan", id: currentPlan.id }}
        />

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage id="Add Plan to Folder" />
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleChangePlanFolder}>
              <ModalBody>
                <FormControl>
                  <HStack>
                    <Box>
                      <FormLabel>
                        <FormattedMessage id="Folder" />
                      </FormLabel>
                      <Select
                        value={selectedFolderId}
                        placeholder={formatMessage({
                          id: "Select Folder",
                        })}
                        onChange={(e) => {
                          setSelectedFolderId(Number(e.target.value));
                        }}
                      >
                        {[...planGroupsQuery.data].reverse().map((group) => (
                          <option value={group.id}>{group.name}</option>
                        ))}
                      </Select>
                    </Box>
                  </HStack>
                </FormControl>
              </ModalBody>

              <ModalFooter
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button colorScheme="blue" type="submit">
                  <FormattedMessage id="Add to Folder" />
                </Button>
                {showRemoveFromFolder && (
                  <Button
                    colorScheme="red"
                    onClick={handleRemovePlanFromFolder}
                  >
                    <FormattedMessage id="Remove from the folder" />
                  </Button>
                )}

                <Button
                  variant="ghost"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Box>
    </BaseView>
  );
};

SalPlan.PlanContent = PlanContent;
export default SalPlan;

// {
//   sharedListQuery.data?.filter(sharedItem => sharedItem.entityName === "salplan").map((sharedPlan) => {
//     return (
//       <Plan
//           setId = { setCurrentPlanvValues }
//           key = { sharedPlan.id }
//           plan = {{ id: sharedPlan.entity.id, name: sharedPlan.entity.name }} // just for ui not the entire plan
//           editPlanValue = { editPlanValue }
//           onEdit = { onEdit }
//           editPlanDiclosure = { editPlanDisclosure }
//           sharedProps = { sharedPlan } // name here is the name of the section
//           isSharedWithMe = { true}
// //       />
// <Plan
//   setId={setCurrentPlanvValues}
//   key={plan.id}
//   plan={plan}
//   editPlanValue={editPlanValue}
//   onEdit={onEdit}
//   editPlanDiclosure={editPlanDisclosure}
//   removeButton
//   onShare={() => { onShare({ id: plan.id, name: "plan" }) }} // name here is the name of the section
// />
//     );
//   }).reverse()
// }
