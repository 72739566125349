import { Box } from "@chakra-ui/layout";
import {
    Editable,
    EditableInput,
    EditablePreview,
    useToast
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import usePlaylists from "./usePlaylists";

function PlaylistName(props) {
    const [name, setName] = useState<string>(props.name);
    const { editMutation } = usePlaylists()
    const toast = useToast();
    const { formatMessage } = useIntl();
    const updateName = useCallback(async (name: string) => {
        const res = await editMutation({ id: props.id, name })
        if (res?.id) {
            toast({
                title: formatMessage({ id: "saved" }),
                status: "success",
                isClosable: true,
            });
        } else if (res?.error === "Conflict") {
            toast({
                title: formatMessage({ id: "duplicate" }),
                status: "error",
                isClosable: true,
            });
            setName(props.name)
        } else {
            toast({
                title: formatMessage({ id: "wentWrong" }),
                status: "error",
                isClosable: true,
            });
        }
    }, [editMutation, formatMessage, props.id, props.name, toast])
    return (<Box>
        <Editable
            value={name}
            onChange={(e) => {
                setName(e);
            }}
            onSubmit={(name) => {
                if (name) {
                    updateName(name)
                } else {
                    setName(props.name)
                }
            }}
            onClick={(e) => { e.stopPropagation() }}
        >
            <EditablePreview
                onChange={(e) => e.stopPropagation()} />
            <EditableInput
                onChange={(e) => e.stopPropagation()}
                textAlign={"left"}
                maxLength={30}
            />
        </Editable>
    </Box>);
}

export default PlaylistName;

