import {
  Box,
  HStack,
  Input,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, FormEvent } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import DeleteModel from "src/components/DeleteModel";
import Modal from "src/components/Modal";
import PrimaryButton from "src/components/PrimaryButton";
import { Goal } from "src/types";

type GoalItemProps = {
  goal: Goal;
  remove: () => void;
  edit?: (goal: Goal) => void;
};
const GoalItem: React.FC<GoalItemProps> = ({ goal, edit, remove }) => {
  const disclosureProps = useDisclosure();
  const [localGoal, setLocalGoal] = useState<Goal>(goal);

  const submit = () => {
    edit && edit(localGoal);
    disclosureProps.onClose();
  };

  return (
    <Box my='4px' py='8px'>
      <Text d='block' color='#8b8b8b' fontFamily='tajawl'>
        {goal.name}
      </Text>
      <Box d='inline-block' float='right' mt='-20px'>
        <HStack>
          {edit ? (
            <Modal
              disclosureProps={disclosureProps}
              btnProps={{
                rightIcon: <BiEdit />,
                variant: "link",
                fontSize: "20px",
                color: "brand.800",
                minW: 1,
              }}
              bodyProps={{
                children: (
                  <Box mt={5}>
                    <form
                      onSubmit={(e: FormEvent) => {
                        e.preventDefault();
                        if (localGoal.name) submit();
                      }}>
                      <Input
                        value={localGoal.name}
                        onChange={(e) =>
                          setLocalGoal((old) => {
                            return { ...old, name: e.target.value };
                          })
                        }
                      />
                    </form>

                    <Box d='flex' justifyContent='center' mt={5}>
                      <PrimaryButton
                        disabled={localGoal.name === "" || localGoal.name === goal.name}
                        onClick={submit}>
                        <FormattedMessage id='edit' defaultMessage='تعديل' />
                      </PrimaryButton>
                    </Box>
                  </Box>
                ),
              }}
            />
          ) : null}
          {remove ? <DeleteModel remove={remove} /> : null}
        </HStack>
      </Box>
    </Box>
  );
};

export default GoalItem;
