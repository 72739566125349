import React, { useState } from "react";
import {
  Wrap,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  VStack,
  ModalOverlay,
  Heading,
  Box,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { cameraIcon } from "src/assets/icons";
import InputWithAdd from "src/components/InputWithAdd";
import SubHeader from "src/components/SubHeader";
import Link from "src/components/Link/Link";
import { getList } from "src/api/affirmation/Stroy/query";
import * as mutation from "src/api/affirmation/Stroy/mutation";
import { useMutation, useQueryClient } from "react-query";
import TextLoop from "react-text-loop";
import { uploadFile, MEDIA_URL } from "src/api";
import { useSelector } from "src/store";

let imgFile = "";

const ViewStoryModal = (props: any) => {
  const client = useQueryClient();
  const [storyTime, setStoryTime] = useState<number>(0);
  const [storyNameState, setStoryName] = useState<string>("");
  const [storyImgState, setStroryImg] = useState<string>("");
  const [storyIdState, setStoryIdState] = useState<number>(0);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  //current user
  const user = useSelector((state) => state.auth.user);

  // const {toggleFrBtn} = useContext(NavBtnsContext)

  const { isOpen, modalClose, storyName, storyImg, storyId, edit, duration } =
    props;
  const { mutateAsync: editMutation } = useMutation(mutation.editStory, {
    onSuccess: () => client.refetchQueries(getList[0]),
  });
  const resetinput = () => {
    setDisableSubmit(true);
    setStoryName("");
  };
  const resetBTN = (e: any) => {
    setDisableSubmit(false);
    setStoryName(e.target.value);
  };

  const resetTime = () => {
    setStoryTime(0);
    setDisableSubmit(true);
  };
  const uploadImage = async (e: any) => {
    await uploadFile(
      e.target.files[0],
      () => {},
      (url: string) => setStroryImg(`${MEDIA_URL}/${url}`),
      undefined,
      user?.accessToken
    );
    setDisableSubmit(false);
  };
  React.useEffect(() => {
    setStoryTime(duration);
    setStoryName(storyName);
    setStroryImg(storyImg);
    setStoryIdState(storyId);
  }, [storyName, storyId, duration, storyImg]);
  const submitBtn = () => {
    if (edit) {
      editMutation({
        id: storyIdState,
        name: storyNameState,
        imageUrl: storyImgState,
        duration: storyTime,
      });
    }
    modalClose();
    setStoryTime(0);
    // toggleFrBtn && toggleFrBtn()
  };

  // resetBtns&&resetBtns()
  return (
    <Modal isCentered isOpen={isOpen} onClose={modalClose}>
      <ModalOverlay />
      <ModalContent
        px={"10px"}
        maxWidth={"720px"}
        width={"100%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          {edit ? (
            <VStack>
              <SubHeader
                fontSize={"24px"}
                mt={"20px"}
                width={"100%"}
                textAlign={"left"}
                formatId={"story.name"}
              />
              <Box w={"100%"}>
                <InputWithAdd
                  value={storyNameState}
                  style={{ width: "80%" }}
                  noAdd
                  mt={"-20px"}
                  mb={"20px"}
                  onChange={(e: any) =>
                    e.target.value === "" ? resetinput() : resetBTN(e)
                  }
                />{" "}
              </Box>
              <SubHeader
                fontSize={"24px"}
                mt={"20px"}
                width={"100%"}
                textAlign={"left"}
                formatId={"period"}
              />
              <Wrap mt={"20px"} mb={"24px"}>
                <Link
                  asBtn={true}
                  mx={"5px"}
                  lineHeight={"1.38"}
                  onClick={() =>
                    storyTime !== 5 ? setStoryTime(5) : resetTime()
                  }
                  isSecondary={!(storyTime === 5)}
                  fontSize={"16px"}
                  fontWeight={"normal"}
                  maxWidth={"112px"}
                  maxHeight={"32px"}
                  borderRadius={15}
                  py={1}
                >
                  <FormattedMessage id={"minutes"} /> 5
                </Link>
                <Link
                  asBtn={true}
                  mx={"5px"}
                  lineHeight={"1.38"}
                  onClick={() =>
                    storyTime !== 10 ? setStoryTime(10) : resetTime()
                  }
                  isSecondary={!(storyTime === 10)}
                  fontSize={"16px"}
                  fontWeight={"normal"}
                  maxWidth={"112px"}
                  maxHeight={"32px"}
                  borderRadius={15}
                  py={1}
                >
                  <FormattedMessage id={"minutes"} /> 10
                </Link>
                <Link
                  asBtn={true}
                  mx={"5px"}
                  lineHeight={"1.38"}
                  onClick={() =>
                    storyTime !== 15 ? setStoryTime(15) : resetTime()
                  }
                  isSecondary={!(storyTime === 15)}
                  fontSize={"16px"}
                  fontWeight={"normal"}
                  maxWidth={"112px"}
                  maxHeight={"32px"}
                  borderRadius={15}
                  py={1}
                >
                  <FormattedMessage id={"minutes"} /> 15
                </Link>
                <Link
                  asBtn={true}
                  mx={"5px"}
                  lineHeight={"1.38"}
                  onClick={() =>
                    storyTime !== 20 ? setStoryTime(20) : resetTime()
                  }
                  isSecondary={!(storyTime === 20)}
                  fontSize={"16px"}
                  fontWeight={"normal"}
                  maxWidth={"112px"}
                  maxHeight={"32px"}
                  borderRadius={15}
                  py={1}
                >
                  <FormattedMessage id={"minutes"} /> 20
                </Link>
                <Link
                  asBtn={true}
                  mx={"5px"}
                  lineHeight={"1.38"}
                  onClick={() =>
                    storyTime !== 30 ? setStoryTime(30) : resetTime()
                  }
                  isSecondary={!(storyTime === 30)}
                  fontSize={"16px"}
                  fontWeight={"normal"}
                  maxWidth={"112px"}
                  maxHeight={"32px"}
                  borderRadius={15}
                  py={1}
                >
                  <FormattedMessage id={"minutes"} /> 30
                </Link>
              </Wrap>
              <VStack maxWidth={"592px"} align={"center"} maxHeight={"349px"}>
                <Box
                  mb={"32px"}
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  maxWidth={"182px"}
                  backgroundImage={
                    storyImgState === ""
                      ? `url( ${storyImg} )`
                      : `url( ${storyImgState} )`
                  }
                  width={"100%"}
                  height={"182px"}
                  borderRadius={"50%"}
                  position={"relative"}
                >
                  <img
                    style={{ position: "absolute", left: "71px", top: "164px" }}
                    src={cameraIcon}
                    alt="Add a Story button"
                  />
                  <Input
                    value={imgFile}
                    style={{
                      width: "24px",
                      height: "24px",
                      opacity: "0",
                      borderRadius: "50%",
                      left: "67px",
                      top: "167px",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                    onChange={uploadImage}
                    type="file"
                    accept="image/*"
                  />
                </Box>
                <div>
                  <Link
                    disabled={disableSubmit}
                    mb={"33px"}
                    onClick={submitBtn}
                    asBtn={true}
                  >
                    <FormattedMessage id="story.edit" defaultMessage={"تم"} />
                  </Link>
                </div>
              </VStack>
            </VStack>
          ) : (
            <VStack spacing={2}>
              <Heading fontSize={"30px"} fontWeight={"bold"} textStyle={"h5"}>
                {storyName}
              </Heading>
              <Box
                mb={"32px"}
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                maxWidth={"182px"}
                backgroundImage={`url( ${storyImg} )`}
                width={"100%"}
                height={"182px"}
                borderRadius={"50%"}
                position={"relative"}
              ></Box>
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <div>
                  <Text
                    color={"#2895ff"}
                    fontFamily={"Calibri (Body)"}
                    fontSize={"24px"}
                  >
                    <FormattedMessage
                      id="story.iloveyou"
                      defaultMessage={"احبك"}
                    />
                  </Text>
                </div>
                <div>
                  <Text
                    color={"#2895ff"}
                    fontFamily={"Calibri (Body)"}
                    fontSize={"24px"}
                  >
                    <FormattedMessage
                      id="story.forgiveme"
                      defaultMessage={"سامحنى"}
                    />
                  </Text>
                </div>
                <div>
                  <Text
                    color={"#2895ff"}
                    fontFamily={"Calibri (Body)"}
                    fontSize={"24px"}
                  >
                    <FormattedMessage
                      id="story.iamsorry"
                      defaultMessage={"اسف"}
                    />
                  </Text>
                </div>
                <div>
                  <Text
                    color={"#2895ff"}
                    fontFamily={"Calibri (Body)"}
                    fontSize={"24px"}
                  >
                    <FormattedMessage
                      id="story.thanks"
                      defaultMessage={"شكرا"}
                    />
                  </Text>
                </div>
              </TextLoop>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewStoryModal;
