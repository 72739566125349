import React from "react";
import { Box, Text, IconButton, Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector, useDispatch } from "src/store";
import { toggleChatWindow } from "src/store/assistant";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import ProgramToggler from "./ProgramToggler";

const Header = ({ onClose }) => {
  const dispatch = useDispatch();
  const { focusProgram: assistantFocusProgram, isChatOpen } = useSelector(
    (state) => ({
      focusProgram: state.assistant.focusProgram,
      isChatOpen: state.assistant.isChatOpen,
    })
  );

  const { locale } = useIntl();

  return (
    <Box
      className="header"
      as="header"
      padding="1rem 1rem 0.3rem 1rem"
      height="fit-content"
      color="#fff"
      bgColor="#1f3f93"
      text-align="center"
      fontSize={"16px"}
      fontWeight="bold"
      boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {locale === "en" ? (
          <IconButton
            display="flex"
            justifyContent="start"
            aria-label="Home"
            icon={<IoArrowBackCircleOutline size={30} />}
            onClick={() => dispatch(toggleChatWindow())}
            variant="ghost"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            visibility={isChatOpen ? "visible" : "hidden"}
          />
        ) : (
          <IconButton
            display="flex"
            justifyContent="start"
            aria-label="Close"
            icon={<RiCloseCircleLine size={30} />}
            onClick={() => {
              onClose();
              if (isChatOpen) {
                dispatch(toggleChatWindow());
              }
            }}
            variant="ghost"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          />
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 800 800"
            height="28px"
            width="28px"
            role="img"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#ffffff"
              d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z"
            ></path>
          </svg>
          <Text>
            <FormattedMessage id="sal_assistant.header" />
          </Text>
        </Box>

        {locale === "en" ? (
          <IconButton
            display="flex"
            justifyContent="end"
            aria-label="Close"
            icon={<RiCloseCircleLine size={30} />}
            onClick={() => {
              onClose();
              if (isChatOpen) {
                dispatch(toggleChatWindow());
              }
            }}
            variant="ghost"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          />
        ) : (
          <IconButton
            display="flex"
            justifyContent="end"
            aria-label="Home"
            icon={<IoArrowBackCircleOutline size={30} />}
            onClick={() => dispatch(toggleChatWindow())}
            variant="ghost"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            visibility={isChatOpen ? "visible" : "hidden"}
          />
        )}
      </Flex>
      {assistantFocusProgram && <ProgramToggler />}
    </Box>
  );
};

export default Header;
