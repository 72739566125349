import { useQuery } from "react-query";
import { useIntl } from "react-intl";
import {
  getSolfeggio,
  getChakras,
  getAllWaves,
  getSearchWaves,
  getTraining,
  getProtection,
} from "./index";
import { useSelector } from "../store";

export const useGetSolfeggio = () => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["solfeggio", locale], () => getSolfeggio(token, locale));
};
export const useGetChakras = () => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["chakras", locale], () => getChakras(token, locale));
};
export const useGetAllWaves = () => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["allWaves", locale], () => getAllWaves(token, locale));
};
export const useGetSearchWaves = (searchValue: string) => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["searchWaves", searchValue, locale], () =>
    getSearchWaves(searchValue, token, locale)
  );
};
export const useGetTraining = () => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["training", locale], () => getTraining(token, locale));
};
export const useGetProtection = () => {
  const { locale } = useIntl();
  const token = useSelector((state) => state.auth.user?.accessToken);
  return useQuery(["protection", locale], () => getProtection(token, locale));
};
