import React, { useEffect, useRef, useState } from "react";
import { HStack, VStack, Text, Box } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import Link from "src/components/Link/Link";
import SliderComponent from "src/components/Slider/SliderComponent";
import { useSelector, useDispatch } from "src/store";
import {
  setColor,
  setStop,
  setRepeat,
  setShowTime,
  setRepeatTimeId,
  changeName,
} from "src/store/media";
const ShowsubliminalForm = () => {
  const [disabled, setDisabledBtn] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const { value, color, stop, repeat, repeatTimeId, showTime, showTimeId } = useSelector(
    (state) => ({
      value: state.media.value,
      color: state.media.color,
      stop: state.media.stop,
      repeat: state.media.repeat,
      repeatTimeId: state.media.repeatTimeId,
      showTime: state.media.showTime,
      showTimeId: state.media.showTimeId,
    }),
  );
  const [disableAll, setDiableAll] = useState(false)
  const intl = useIntl();
  useEffect(()=>{
    let status = document.createElement('div');
    status.id = 'status';
    status.textContent = 'waiting';
    status.style.position = "absolute";
    status.style.opacity = '0';
    status.style.color='white';
    const cont = document.getElementById('root');
    cont!.appendChild(status);
  }, []);
  let textRef = document.getElementById("subliminalTextToFlash");
  React.useEffect(() => {
    stop && setDisabledBtn(false);
  }, [value, color, stop]);
  let stopFunc = false;
  const stopFuncRef = useRef(stopFunc);
  stopFuncRef.current = stopFunc;
  const status = document.getElementById('status');
  let repeatTimeIdVar;
  let showTimeIdVar;
  async function doSomethingUntilConditionChanges() {
    while (!conditionMet()) {
      await performAsyncOperation();
      let breakFlag = false;
      for (let i=0; i < 60; i++){
        if(conditionMet()){
          breakFlag=true;
          break;
        }else{
          await new Promise(resolve => setTimeout(resolve, repeat/60));
        }
      }
      if(breakFlag){
        break;
      }
    }
  }
  async function performAsyncOperation() {
    if(textRef){
      textRef.style.opacity = "1";
      textRef.style.top = `${Math.round(Math.random() * window.innerHeight)}px`;
      textRef.style.left = `${Math.round(Math.random() * window.innerHeight)}px`;
      await new Promise(resolve => setTimeout(resolve, showTime));
      textRef.style.opacity = "0";
    }
  }
  function conditionMet() {
    return document.getElementById('status')?.style.opacity === "0.01";
  }
  const ShowTextHandler = async () => {
  if(!stop){
    status!.style.opacity = '0';
    doSomethingUntilConditionChanges()
      .then(() => {
        console.log("Condition changed. Task complete.");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    if (textRef && color) {
      textRef.style.color = color;
    }
    dispatch(setRepeatTimeId({value: repeatTimeIdVar}));
    // dispatch(setShowTime({value: showTimeIdVar}));
    dispatch(changeName({ value: "subliminal" }));
    dispatch(setStop({ value: true }));
  } else {
    dispatch(changeName({ value: "" }));
    dispatch(setStop({ value: false }));
    setDisabledBtn(true);
    dispatch(setColor({ value: "transparent" }));
    dispatch(setShowTime({ value: 0 }));
    dispatch(setRepeat({ value: 0 }));
    clearTimeout(repeatTimeId);
    clearTimeout(showTimeId);

    textRef!.style.opacity = "0";
    textRef!.style.color = "transparent";
    stopFuncRef.current = true;
    console.log(stopFuncRef.current)
    status!.style.opacity = "0.01";
  }
  };

  return (
    <VStack
      w='100%'
      borderRadius={"20px"}
      boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
      my={"1rem"}
      padding={" 10px 0 19px 16px"}
      alignItems={"center"}
      maxWidth={"720px"}
      bg={"#f9f9f9"}>
      <VStack w='100%' align={"start"}>
        <Text textStyle={"subliminalFormHeader"}>
          <FormattedMessage
            id='subliminal.showmessageperiod'
            defaultMessage={"مدة الظهور / ثانية"}
          />
        </Text>
        <Box display={"flex"} flexWrap={"wrap"} gap={"8px"} >
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 50 }))}
            isSecondary={!(showTime === 50)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            maxHeight={"32px"}
            borderRadius={15}
            disabled={disableAll}
            py={1}>
            <FormattedMessage id={"second"} /> 0.05
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 100 }))}
            isSecondary={!(showTime === 100)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 0.10
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 300 }))}
            isSecondary={!(showTime === 300)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 0.30
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 500 }))}
            isSecondary={!(showTime === 500)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 0.50
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 1000 }))}
            isSecondary={!(showTime === 1000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 1
          </Link>
                    <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 10000 }))}
            isSecondary={!(showTime === 10000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 10
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 20000 }))}
            isSecondary={!(showTime === 20000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 20
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setShowTime({ value: 30000 }))}
            isSecondary={!(showTime === 30000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            disabled={disableAll}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 30
          </Link>
        </Box>
      </VStack>

      <VStack w='100%' align={"start"}>
        <Text textStyle={"subliminalFormHeader"}>
          <FormattedMessage id='subliminal.frequencyperiod' defaultMessage={"مدة التردد / ثانية"} />
        </Text>
        <Box display={"flex"} flexWrap={"wrap"} gap={"8px"} >
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setRepeat({ value: 1000 }))}
            isSecondary={!(repeat === 1000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 1
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setRepeat({ value: 10000 }))}
            isSecondary={!(repeat === 10000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 10
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setRepeat({ value: 20000 }))}
            isSecondary={!(repeat === 20000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 20
          </Link>
          <Link
            asBtn={true}
            lineHeight={"1.38"}
            onClick={() => dispatch(setRepeat({ value: 30000 }))}
            isSecondary={!(repeat === 30000)}
            fontSize={"16px"}
            fontWeight={"normal"}
            maxWidth={"112px"}
            maxHeight={"32px"}
            borderRadius={15}
            py={1}>
            <FormattedMessage id={"second"} /> 30
          </Link>
        </Box>
        <HStack>
          <SliderComponent
            name='title'
            noSlider
            content={intl.formatMessage({ id: "subliminal.phrasecolor" })}
            subliminal
            onChangeColor={(newColor: any) => {
              stop
                ? dispatch(setColor({ value: "transparent" }))
                : dispatch(setColor({ value: newColor }));
              if (value && showTime && repeat) {
                setDisabledBtn(false);
              }
            }}
          />
        </HStack>
      </VStack>
      <div>
        <Link disabled={disabled} onClick={ShowTextHandler} asBtn={true} my={-4}>
          {stop ? (
            <FormattedMessage id='stop' defaultMessage={"إيقاف"} />
          ) : (
            <FormattedMessage id='subliminal.play' defaultMessage={"تشغيل"} />
          )}
        </Link>
      </div>
    </VStack>
  );
};

export default ShowsubliminalForm;
