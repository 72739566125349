import React from "react";
import {
  Document,
  Text,
  View,
  Page,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const getBottomValue = (vAlign: string) => {
  switch (vAlign) {
    case "center":
      return "40%";
    // break;
    case "flex-start":
      return "80%";
    case "flex-end":
      return "0";
    default:
      return "40%";
  }
};

const PDFDoc: React.FC<any> = ({
  nameSize,
  nameColor,
  name,
  cover,
  Valign,
  Halign,
}) => {
  const styles = StyleSheet.create({
    page: {
      // padding: 35,
    },
    title: {
      // fontWeight: 'bold',
      color: nameColor,
      fontSize: `${+nameSize.split("px")[0] * 0.7}pt`,
      textAlign: Halign,
      fontFamily: "Droid",
      marginHorizontal: -2.5,
    },
    img: {
      borderRadius: 200,
      width: "100pt",
      height: "100pt",
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "white",
    },
    bgImg: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "silver",
      width: "100vw",
      height: "100vh",
      position: "absolute",
      left: 0,
      top: 0,
    },
    content: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      bottom: getBottomValue(Valign),
    },
  });
  return (
    <Document>
      <Page size={[555.28, 841.89]} style={styles.page} orientation="landscape">
        <View style={styles.bgImg}>
          <Image source={cover} style={{ width: "auto" }} />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>{name}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDoc;
