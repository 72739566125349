import {
  Box,
  Spinner,
  useDisclosure,
  useToast,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "src/store";
import { deleteMethod, get } from "src/api";
import InfiniteScroll from "react-infinite-scroll-component";
import RatingModal from "src/components/Rating/RatingModal";
import SingleReview from "./SingleReview";

const PureToneReviews = ({ entityId, entityName }) => {
  const intl = useIntl();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allRatings, setAllRatings] = useState<any>();

  const [ratingsPage, setRatingsPage] = useState<number>(1);

  const [myRating, setMyRating] = useState<any>();

  const [totalRating, setTotalRating] = useState<any>();

  const currentUser: any = useSelector((state) => state.auth.user);

  const userIsAdmin = currentUser?.role?.name === "Admin";

  const fetchRatings = useCallback(async () => {
    const initialAllRatings = await get(
      `/rating/allRating/${entityName}/${entityId}`
    );
    const initialMyRating = await get(
      `/rating/myRating/${entityName}/${entityId}`
    );
    const initialTotalRating = await get(
      `/rating/totalRating/${entityName}/${entityId}`
    );

    initialAllRatings && setAllRatings(initialAllRatings);
    initialMyRating && setMyRating(initialMyRating);
    initialTotalRating && setTotalRating(initialTotalRating);
  }, [entityId, entityName]);

  const fetchMoreRatings = useCallback(async () => {
    const nextLoadOfRating: any = await get(
      `/rating/allRating/${entityName}/${entityId}?page=${ratingsPage}`
    );
    if (nextLoadOfRating?.length) {
      setAllRatings((currentRatings) =>
        currentRatings.concat(nextLoadOfRating)
      );
      setRatingsPage((ratingsPage) => ratingsPage + 1);
    } else {
    }
  }, [entityId, entityName, ratingsPage]);

  useEffect(() => {
    fetchRatings();
  }, [fetchRatings]);

  const deleteRating = useCallback(
    (id) => {
      deleteMethod(`/rating/${id}`)
        .then((res: any) => {
          if (res?.success) {
            toast({
              title: intl.formatMessage({ id: "deleted" }),
              status: "success",
              isClosable: true,
            });
            fetchRatings();
          } else {
            toast({
              title: intl.formatMessage({ id: "wentWrong" }),
              status: "error",
              isClosable: true,
            });
          }
          onClose();
        })
        .catch((err) => {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        });
    },
    [fetchRatings, intl, onClose, toast]
  );

  return (
    <Box>
      <Box d={["block", "block", "flex"]} mt="2">
        <Box w="100%">
          {myRating &&
            Object.keys(myRating).length === 0 &&
            allRatings.length === 0 && (
              <Flex
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src={"/reviews_placeholder.png"}
                  h={"8rem"}
                  w={"10rem"}
                  mb={4}
                />
                <Text>
                  {intl.formatMessage({ id: "pure_tones.no_reviews" })}
                </Text>
              </Flex>
            )}
          {myRating && Object.keys(myRating).length !== 0 ? (
            <SingleReview
              id={myRating.id}
              user={myRating.user}
              rating={myRating.rating}
              description={myRating.description}
              date={myRating.updatedAt}
              canEdit={true}
              canDelete={true}
              openModal={onOpen}
              deleteRating={deleteRating}
            />
          ) : null}
          <Box d="flex" flexDir={"column-reverse"}>
            {allRatings?.length ? (
              <InfiniteScroll
                dataLength={allRatings.length}
                next={fetchMoreRatings}
                hasMore={totalRating?.totalCount - 1 > allRatings?.length} // -1 because allRatings has all ratings except current user's rating
                loader={<Spinner alignSelf={"center"} />}
              >
                {allRatings.map(
                  ({ id, user, rating, description, updatedAt }) => (
                    <SingleReview
                      key={id}
                      id={id}
                      user={user}
                      rating={rating}
                      description={description}
                      date={updatedAt}
                      canEdit={false}
                      canDelete={userIsAdmin}
                      openModal={null}
                      deleteRating={deleteRating}
                    />
                  )
                )}
              </InfiniteScroll>
            ) : null}
          </Box>
          <RatingModal
            isOpen={isOpen}
            onClose={onClose}
            entityId={entityId}
            entityName={entityName}
            fetchRatings={fetchRatings}
            currentUserRating={myRating}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PureToneReviews;
