import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ValuesList } from "src/types";
import ValueItem from "./ValueItem";
import ActionBar from "../ActionBar";
import AccessPermission from "../../../components/AccessPermission/AccessPermission";

type ValueShowType = {
  disclosureProps: UseDisclosureReturn;
  valuesList?: ValuesList;
  setValuesList: (valuesList: ValuesList) => void;
  onResortClicked: () => void;
  onDeleteClicked: () => void;
  onEditClicked: (value: ValuesList) => void;
  currentName?: string;
  isShared?: boolean;
  permission?: any;
  owner?: any;
};
const ValuesShow: React.FC<ValueShowType> = ({
  valuesList,
  disclosureProps,
  setValuesList,
  onResortClicked,
  onDeleteClicked,
  onEditClicked,
  currentName,
  isShared = false,
  permission,
  owner
}) => {
  const canEdit = permission === "update"
  const [textToCopy, setTextToCopy] = useState<string>();
  useEffect(() => {
    let valuesString: any = [];
    if (!valuesList) return;
    valuesList?.values?.forEach((value) => {
      valuesString.push(value.name);
      valuesString.push("");
    });
    setTextToCopy(valuesString.join("\n"));
    return () => setTextToCopy("");
  }, [textToCopy, valuesList]);

  const { formatMessage } = useIntl();

  if (!valuesList) return null;

  return (
    <Modal {...disclosureProps} size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius="25px" padding="20px 0">
        <ModalCloseButton />
        <ModalHeader
          fontFamily="Calibri (Body)"
          fontSize="18px"
          fontWeight="bold"
          color="brand.900"
        >
          {valuesList.name}
        </ModalHeader>

        <ModalBody
          p="6"
          color="gray.900"
          fontSize="18px"
          fontFamily="Calibri (Body)"
        >
          {isShared ? <Box mb="4">
            <Box>
              <div>
                <p>
                  <span>{formatMessage({ id: "sharedBy" })}</span> <span>{owner?.name}</span>
                </p>
                <small>{owner?.email}</small>
              </div>
              <AccessPermission permission={permission} />
            </Box>
          </Box> : null}
          <Text>
            <FormattedMessage
              id="values.order"
              defaultMessage="ترتيب القيم كالآتي"
            />
          </Text>
          {valuesList?.values?.map((value, idx) => {
            return (
              <ValueItem
                idx={idx}
                key={idx}
                value={value.name}
                canEdit={!isShared || (isShared && canEdit)}
                edit={(newValue) => {
                  const editedValueList: ValuesList = {
                    ...valuesList!,
                    values: [
                      ...valuesList.values.slice(0, idx),
                      { ...valuesList.values[idx], name: newValue },
                      ...valuesList.values.slice(idx + 1),
                    ],
                  };
                  onEditClicked(editedValueList);
                  setValuesList(editedValueList);
                }}
              />
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Box d="flex" flex="1" justifyContent="center">
            <ActionBar
              onResort={onResortClicked}
              textToCopy={textToCopy}
              onDelete={onDeleteClicked}
              canDelete={!isShared}
              canRestart={!isShared || (isShared && canEdit)}
              title={formatMessage(
                { id: "print.values" },
                { name: currentName }
              )}
            />
          </Box>
        </ModalFooter>
        <div style={{ display: "none" }}>
          <Box
            id="text-to-mail"
            padding="20px"
            data-subject={formatMessage({ id: "myValues" })}
          >
            <Box dir="auto">
              <Text
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  fontSize: "18px",
                }}
              >
                {formatMessage({ id: "myValues" })}: ({valuesList.name})
              </Text>
            </Box>
            <Box dir="auto" style={{ textAlign: "start", paddingTop: "20px" }}>
              {textToCopy?.split("\n").map((txt, idx) => (
                <Text display="block" fontSize="16px" key={idx}>
                  {txt}
                </Text>
              ))}
            </Box>
          </Box>
        </div>
      </ModalContent>
    </Modal>
  );
};
export default ValuesShow;
