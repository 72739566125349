import {
  Input,
  InputRightElement,
  InputGroup,
  IconButton,
  InputGroupProps,
  ButtonProps,
} from "@chakra-ui/react";
import React, { useEffect, useCallback } from "react";
import { IoMdAddCircle } from "react-icons/io";

type InputWithAddProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  inputDisabled?: boolean;
  buttonProps?: ButtonProps;
  id?: string;
  noAdd?: any;
  magic?: boolean;
  DevRef?: any;
  name?: string;
  inputType?: string;
  pattern?: string;
} & InputGroupProps;
const InputWithAdd: React.FC<InputWithAddProps> = ({
  value,
  onChange,
  onClick,
  inputDisabled,
  disabled,
  buttonProps,
  id,
  noAdd,
  magic,
  DevRef,
  name,
  inputType,
  pattern,
  ...props
}) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const inputELement = document.querySelector(
        "#input-add-op"
      )! as HTMLInputElement;
      if (e.code === "Enter" && document.activeElement === inputELement) {
        onClick && onClick();
      }
    },
    [onClick]
  );

  useEffect(() => {
    if (!magic) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (!magic) {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [handleKeyDown, magic]);

  return (
    <div
      id="form-input-add"
      onSubmit={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      <InputGroup {...props}>
        <Input
          pattern={pattern}
          type={inputType}
          name={name}
          ref={DevRef}
          variant="flushed"
          pr="3rem"
          value={inputType ? value?.replace(/[^\d]/, "") : value}
          onChange={onChange}
          disabled={inputDisabled}
          placeholder={props.placeholder}
          id={id || "input-add-op"}
        />
        {!noAdd && (
          <InputRightElement w="3rem">
            <IconButton
              variant="link"
              disabled={disabled}
              color="brand.800"
              fontSize="28px"
              aria-label="add"
              onClick={onClick}
              icon={<IoMdAddCircle />}
              {...buttonProps}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </div>
  );
};
export default InputWithAdd;
