import React, { useState, useEffect } from "react";
import { Text, VStack, useDisclosure, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import DescriptionModal from "./DescriptionModal";
import { get } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import CalibrationModal from "../Affirmation/Calibration/CalibrationModal";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { useDispatch } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import Category from "./Category";

type SectionProps = {
  categories: {
    route?: string;
    name: string;
  }[];
  slug: string;
  title: string;
  mainRoute: string;
  notifications: any;
};

const Section: React.FC<SectionProps> = ({
  categories,
  title,
  slug,
  mainRoute,
  notifications
}) => {
  const { push } = useHistory();
  const { isOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const [content, setContent] = useState("");
  const { locale } = useIntl();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  useEffect(() => {
    get<any>("/description/" + slug.toLowerCase()).then((res) => {
      setContent(res?.content);
    });
  }, [locale, slug]);
  return (
    <VStack
      style={{
        marginTop: "0.8em",
        marginBottom: "0em",
        alignItems: "initial",
        marginRight: "0.5em",
        marginLeft: "0.5em",
      }}
    >
      <Button
        style={{
          padding: "11% 0 11% 0",
          width: "240px",
          backgroundColor: "#1f3f93",
          borderRadius: "12px",
          boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.35)",
          justifyContent: "",
        }}
        onClick={() => {
          push(mainRoute);
        }}
      >
        <Text
          textAlign="center"
          fontWeight="normal"
          style={{
            color: "white",
            fontSize: "18pt",
            fontFamily: "Calibri (Body)",
          }}
        >
          <FormattedMessage id={title} />
        </Text>
      </Button>
      <DescriptionModal
        finalRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        slug={slug}
        content={content}
      />
      <ul
        style={{
          listStyleType: "none",
          padding: "0",
          margin: "0",
        }}
      >
        {categories?.map((category) => {
          return <Category key={category.route} category={category} notifications={notifications ? notifications[category.name] : null} />;
        })}
      </ul>
      <CalibrationModal
        children={
          <Button
            onClick={() => {
              calibrationFunc && calibrationFunc();
              setModalOpen(false);
            }}
            color={"white"}
            width={localStorage.getItem("locale") === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"20px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={
                  localStorage.getItem("locale") === "ar"
                    ? { marginRight: "1rem" }
                    : { marginLeft: "1px" }
                }
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
        }
        open={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </VStack>
  );
};

export default Section;
