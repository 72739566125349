import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { ValuesList } from "src/types";

const getList: [string, QueryFunction<ValuesList[]>] = [
  "listValue",
  () => get<ValuesList[]>("/value"),
];
const getSharedList: [string, QueryFunction<ValuesList[]>] = [
  "sharedListValue",
  () => get("/shared/details?entityName=value"),
];

// /shared/shared-with/{entityName}/{entityId}

const getOne = (id: number | undefined) =>
  [
    ["value", id],
    () => get<ValuesList>(`/value/${id}`),
    { enabled: !(id === undefined) },
  ] as [
    [string, number],
    QueryFunction<ValuesList>,
    UseQueryOptions<ValuesList, Error>
  ];

export { getSharedList, getList, getOne };
