import React, { useState, useEffect } from "react";
import { VStack, Text } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "src/api";
import DarkHeader from "src/layout/DarkHeader";
import { useDispatch } from "src/store";
import { login } from "src/store/auth";
import useLocale from "src/providers/useLocale";

const Verify = () => {
  //control the displayed message content and it's color
  const [status, setStatus] = useState({ message: "wait", color: "grey" });
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [locale] = useLocale();
  const handleLogin = (loginData: { email: string; password: string }) => {
    if (processing) return;
    setProcessing(true);
    post("/auth/login", { ...loginData, logoutAll: false }, locale)
      .then((res: any) => {
        setProcessing(false);
        if (res?.id) {
          const redirectUrl =
            res.subscriptionStatus === "approved" ? "/" : "/subscription";
          window.localStorage.setItem("__sal_auth", res.accessToken);
          // Send the authorization token to the service worker
          if (navigator.serviceWorker.controller) {
            const message = window.localStorage.getItem("__sal_auth");
            navigator.serviceWorker.controller.postMessage({
              type: "fromPage",
              message: message,
            });
          }
          dispatch(login({ user: res }));
          if (redirectUrl && window.location.pathname !== redirectUrl) {
            history.push(redirectUrl);
          }
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
        }
        if (res?.message) {
          if (Array.isArray(res.message)) {
            setErrors(res.message);
          } else {
            setErrors([res.message]);
          }
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
        }
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    const verifyEmail = () => {
      //creates array of tuples for search params (iterator) not an object
      const params = new URLSearchParams(location.search);
      //the object that will contain search params
      const data: any = {};
      for (const param of params) {
        //create an object with that tupe
        data[param[0]] = param[1];
      }
      //verify the token
      post("/auth/verify", data)
        .then((res: any) => {
          if (res && res.id) {
            //Display a success message, message here is an id in translations.json files
            setStatus({ message: "verifySuccess", color: "green" });
            //Login the user and redirect him to /subscription for choosing a plan

            const redirectUrl =
              res.subscriptionStatus === "approved" ? "/" : "/subscription";
            window.localStorage.setItem("__sal_auth", res.accessToken);
            // Send the authorization token to the service worker
            if (navigator.serviceWorker.controller) {
              const message = window.localStorage.getItem("__sal_auth");
              navigator.serviceWorker.controller.postMessage({
                type: "fromPage",
                message: message,
              });
            }
            dispatch(login({ user: res }));
            if (redirectUrl && window.location.pathname !== redirectUrl) {
              history.push(redirectUrl);
            }

            // dispatch(login({ user: res, redirectUrl: "/subscription" }));
            // handleLogin({ email: res.email, password: res.password })
          } else {
            //Display an error message
            setStatus({ message: "invalidToken", color: "red" });
          }
        })
        .catch(() => {
          setStatus({ message: "wentWrong", color: "red" });
        });
    };
    verifyEmail();
  }, [dispatch, location.search]);

  return (
    <>
      <DarkHeader />
      <VStack width="100%" textAlign="center" padding="150px 0 50px 0">
        <Text color={status.color}>
          <FormattedMessage id={status.message} />
        </Text>
      </VStack>
    </>
  );
};

export default Verify;
