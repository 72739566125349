import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/react";
import { useState } from "react";

type UseUIStateParams = {
  setCurrentMission: (id: number | undefined) => void;
};
export type DetailsDisclosureProps = Omit<UseDisclosureReturn, "onOpen"> & {
  onOpen: (id: number) => void;
};
export type CreateEditDisclosureProps = Omit<UseDisclosureReturn, "onOpen"> & {
  onOpen: (isEdit?: boolean, id?: number) => void;
  isEdit?: boolean;
  // id?: number;
};
export type UseUIStateReturn = {
  detailsDisclosure: DetailsDisclosureProps;
  createEditDisclosure: CreateEditDisclosureProps;
};
export const useUIState: (params: UseUIStateParams) => UseUIStateReturn = ({
  setCurrentMission,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>();
  const createEditDisclosure = useDisclosure();
  const detailsDisclosure = useDisclosure();

  const detailsOnOpen = (id: number) => {
    setCurrentMission(id);
    detailsDisclosure.onOpen();
  };
  const detailsOnClose = () => {
    setCurrentMission(undefined);
    detailsDisclosure.onClose();
  };

  const createEditOnOpen = (isEdit?: boolean, id?: number) => {
    setIsEdit(isEdit);
    setCurrentMission(id);
    createEditDisclosure.onOpen();
  };

  const createEditOnClose = () => {
    setIsEdit(undefined);
    setCurrentMission(undefined);
    createEditDisclosure.onClose();
  };
  return {
    detailsDisclosure: {
      ...detailsDisclosure,
      onOpen: detailsOnOpen,
      onClose: detailsOnClose,
    },
    createEditDisclosure: {
      ...createEditDisclosure,
      onOpen: createEditOnOpen,
      onClose: createEditOnClose,
      isEdit,
    },
  };
};
