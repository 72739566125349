import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { Vision, VisionListItem } from "src/types";

export const getList: [string, QueryFunction<VisionListItem[]>] = [
  "listVision",
  () => get<VisionListItem[]>("/wish"),
];

export const getSharedList: [string, QueryFunction<VisionListItem[]>] = [
  "sharedListVision",
  () => get<VisionListItem[]>("/shared/details?entityName=wish"),
];
export const vision = (id: number | undefined) =>
  [
    ["vision", id],
    () => get<Vision>(`/wish/${id}`),
    { enabled: !(id === undefined) },
  ] as [
    [string, number],
    QueryFunction<Vision>,
    UseQueryOptions<Vision, Error>
  ];
