import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import ImageList from "./ImageList";
import SearchBar from "./SearchBox";

const API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY;

// import Modal from 'src/components/Modal';
// import Modal from "src/components/Modal/Modal";
const UnsplashModal = ({ isOpen, onClose, setCoverURL }) => {
  const [images, setImages] = useState([]);
  const { formatMessage } = useIntl();

  const onSearchSubmit = async (term: string, page = 1) => {
    const response = await axios.get("https://api.unsplash.com/search/photos", {
      params: {
        query: term,
        page,
        per_page: 30,
        orientation: "landscape",
      },
      headers: {
        Authorization: `Client-ID ${API_KEY}`,
      },
    });
    if (Array.isArray(response.data?.results)) {
      setImages(response.data.results);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      scrollBehavior={"outside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{formatMessage({ id: "ChoosefromUnsplash" })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SearchBar onSubmit={onSearchSubmit} />
          <ImageList images={images} onClick={setCoverURL} onClose={onClose} />

          {/* <Lorem count={2} /> */}
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant='ghost'>Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnsplashModal;
