import { Center, useToast, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalFooter, Button } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import usePlaylists from './usePlaylists'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { stopMedia } from 'src/store/media';

const DeletePlaylistModal = ({ isOpen, onClose, itemName, itemId }) => {
    const toast = useToast();
    const { deleteMutation } = usePlaylists()
    const { formatMessage } = useIntl();
    const deletePlaylist = useCallback(async (id) => {
        const res = await deleteMutation(id)
        if (res) {
            toast({
                title: formatMessage({ id: "deleted" }),
                status: "success",
                isClosable: true,
            });
        } else {
            toast({
                title: formatMessage({ id: "wentWrong" }),
                status: "error",
                isClosable: true,
            });
        }
        onClose()

    }, [deleteMutation, formatMessage, onClose, toast],)
    const dispatch = useDispatch();
    const { isPlaylist, playlistId, playing } = useSelector((state) => ({ isPlaylist: state.media.isPlaylist, playlistId: state.media.playlistId, playing: state.media.playing }));
    const closeMedia = () => dispatch(stopMedia());
    return (
        <div>
            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                motionPreset='slideInBottom'
            >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <FormattedMessage id="deletePlaylist" />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Center>
                            <Text d="flex" flexDirection={"column"} textAlign="center">
                                <FormattedMessage id="playlistDeleteConfirmationMessage" />
                                <Text mx="1" fontWeight="bold">{itemName}</Text>
                            </Text>
                        </Center>
                        {(isPlaylist && playlistId === itemId && playing) ? <Text fontSize="sm" textAlign="center" mt="5" color="#606060" fontWeight="bold"><FormattedMessage id="currentPlaylistConfirmationMessage" /></Text> : null}
                    </ModalBody>
                    <ModalFooter d="flex" justifyContent="space-between">
                        <Button width="20" m="2" onClick={() => { isPlaylist && playlistId === itemId && closeMedia(); deletePlaylist(itemId); }} bg="#e97575" color="white">
                            <FormattedMessage id="ok" />
                        </Button>
                        <Button width="20" m="2" onClick={() => { onClose() }} variant="outline" >
                            <FormattedMessage id="cancel" />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default DeletePlaylistModal