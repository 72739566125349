import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import PDFDoc from "./PDFDoc";
import { BASE_MEDIA } from "src/api";

// =================================================

const generatePdfDocument = async (
  card: any,
  locale: string,
  Valign: string,
  Halign: string
) => {
  const blob = await pdf(
    <PDFDoc
      nameSize={card.nameSize}
      nameColor={card.nameColor}
      contentSize={card.contentSize}
      contentColor={card.contentColor}
      imageUrl={card.imageUrl ? `${BASE_MEDIA}/${card.imageUrl}` : null}
      goals={[]}
      name={card.name}
      cover={
        card.coverUrl?.includes("http")
          ? card.coverUrl
          : `${BASE_MEDIA}/${card.coverUrl}`
      }
      locale={locale}
      Valign={Valign}
      Halign={Halign}
    />
  ).toBlob();
  saveAs(blob, `${card.name}.pdf`);
};

// =================================================

export default generatePdfDocument;
