import React, {
  useState,
  CSSProperties,
  ChangeEvent,
  useEffect,
  FormEvent,
} from "react";
import { Input, Box, IconButton, useToast, Text } from "@chakra-ui/react";
import { FaEdit, FaSave } from "react-icons/fa";
import { put, get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { useIntl } from "react-intl";
import ReactFlagsSelect from "react-flags-select";
import {
  countries,
  getCountryId,
  getCountryName,
} from "src/views/Register/countries";
import Flag from "react-flagpack";
import { useDispatch, useSelector } from "src/store";
import { update } from "src/store/auth";

type EditableProps = {
  value: string;
  styles?: CSSProperties;
  endpoint: string;
  name: string;
  openModal?: () => void;
  type?: string;
};

const Editable: React.FC<EditableProps> = ({
  value,
  styles,
  endpoint,
  name,
  openModal,
  type,
}) => {
  //value state
  const [val, setVal] = useState(value);

  //editing state
  const [editing, setEditing] = useState(false);

  //toasting success and error messages
  const toast = useToast();

  //formatting messages
  const { formatMessage } = useIntl();

  //current locale
  const [locale] = useLocale();

  //processing state
  const [processing, setProcessing] = useState(false);

  //current user
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const update = (user) => dispatch(updateAction({ user }));
  //options to display if it's a select input
  const [options, setOptions] = useState<any>([]);

  const [selectedCountryCode, setSelectedCountryCode] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>();

  //function to fetch countries
  const fetchCountries = async () => {
    const response = await get("/country");
    if (Array.isArray(response)) setOptions(response);
  };

  useEffect(() => {
    if (name === "country") fetchCountries();
  }, []);

  //handle saving
  const save = async (e: FormEvent) => {
    e.preventDefault();
    if (!val) return;
    setProcessing(true);
    const body = { [name]: val };
    var updateBody;
    if (name === "country") {
      updateBody = { countryId: getCountryId(selectedCountryCode)?.id };
    } else {
      updateBody = body;
    }
    const response = await put(endpoint, updateBody, locale, user?.accessToken);
    if (response?.user.id) {
      toast({
        title: response?.message || formatMessage({ id: "saved" }),
        status: "success",
        duration: 4000,
      });
      dispatch(update(response.user));
      window.localStorage.setItem("__sal_auth", response.token);
    } else
      toast({
        title: response?.message || formatMessage({ id: "wentWrong" }),
        status: "error",
        duration: 4000,
      });
    setEditing(false);
    setProcessing(false);
  };

  //text styles that apply only when the field is an open modal
  const textStyles = {
    color: "#2bb0d9",
    paddingBottom: "10px",
  };

  //function to handle input change
  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    //validations for phone number
    if (name === "phone") {
      let strictVal = target.value.slice(0, 13);
      target.value = strictVal;
    }
    setVal(target.value);
  };

  const getCountryCode = (countryName: string) => {
    return (
      Object.keys(countries).find((key) => countries[key] === countryName) ||
      "ZZ"
    );
  };

  return (
    <>
      {editing ? (
        <Box
          d="flex"
          alignItems="center"
          margin="5px 0"
          as="form"
          onSubmit={save}
        >
          {(type === "text" || type === "email") && (
            <Input
              type={type}
              value={val}
              onChange={handleChange}
              required
              name={name}
              style={styles}
              minWidth={{ lg: "200px" }}
              dir="auto"
              textAlign="left"
              disabled={processing}
            />
          )}
          {type === "select" && (
            // <Select value={val} onChange={handleChange} required name={name} style={styles} width={{lg: "200px"}} dir='auto' textAlign="left">
            //   {
            //     options.map(({id, name}: {id: number; name: string}) => (
            //       <option key={id} value={name} style={{textTransform: 'capitalize'}}>{name}</option>
            //     ))
            //   }
            // </Select>
            <ReactFlagsSelect
              selected={selectedCountryCode}
              searchable={true}
              onSelect={(code) => {
                setSelectedCountry(getCountryName(code));
                setSelectedCountryCode(code);
              }}
            />
          )}
          <IconButton
            type="submit"
            color="brand.900"
            margin="0 5px"
            icon={<FaSave />}
            size={"sm"}
            variant="ghost"
            aria-label="icon-button"
            disabled={processing}
          />
        </Box>
      ) : (
        <Box d="flex" alignItems="center" margin="5px 0">
          {openModal ? (
            <>
              <Text
                style={textStyles}
                onClick={openModal}
                cursor="pointer"
                transition="250ms ease-out"
                _hover={{ filter: "brightness(120%)" }}
                width={{ lg: "200px" }}
                dir="auto"
                textAlign="left"
              >
                {value}
              </Text>
              <IconButton
                color="brand.900"
                margin="0 5px"
                icon={<FaEdit />}
                size={"sm"}
                variant="ghost"
                onClick={() => {
                  openModal();
                }}
                aria-label="icon-button"
              />
            </>
          ) : (
            <Box d="flex" justifyContent="space-between" minW="200px">
              {/* {name === "country" && <span > {getUnicodeFlagIcon(getCountryCode(value)) }</span>} */}
              {/* <span className="fp bo"></span> */}
              {name === "country" && (
                <Flag
                  code={getCountryCode(value)}
                  gradient="real-linear"
                  size="m"
                  hasDropShadow
                />
              )}
              <Text
                style={styles}
                cursor="default"
                minWidth={
                  name === "country" ? { lg: "165px" } : { lg: "200px" }
                }
                dir="auto"
                textAlign="left"
              >
                {value}
              </Text>
            </Box>
          )}
          {!openModal && (
            <IconButton
              color="brand.900"
              margin="0 5px"
              icon={<FaEdit />}
              size={"sm"}
              variant="ghost"
              onClick={() => setEditing(true)}
              aria-label="icon-button"
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Editable;
