import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import { valuesBg } from "src/assets/icons";
import Breadcrumb from "src/components/Breadcrumb/Breadcrumb";

const BaseView: React.FC = ({ children }) => {
  const { locale } = useIntl();

  return (
    <Box>
      <Breadcrumb />
      {children}
      <Image
        src={valuesBg}
        position="absolute"
        bottom={0}
        right={0}
        zIndex={-1}
        transform={locale === "en" ? "scaleX(-1)" : undefined}
      />
    </Box>
  );
};

export default BaseView;
