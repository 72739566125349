import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { FiPlus } from "react-icons/fi";

const NewItemButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      mt="2"
      mb="4"
      color="brand.800"
      fontFamily="Calibri (Body)"
      fontWeight="bold"
      variant="link"
      rightIcon={<FiPlus />}
      {...props}
    >
      {children}
    </Button>
  );
};
export default NewItemButton;
