import React, { useState, useEffect } from "react";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Description } from "../../types";
import Healing from "./Healing/Healing";
import { HEALING_ROUTE } from "src/routes";
import Link from "src/components/Link/Link";
import { get, titleSize, descSize } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";
import { communicationsBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";
import Session from "./Session/Session";
import Archive from "./Session/Archive";

const Communication = () => {
  let locale = useLocale()[0];

  const { formatMessage } = useIntl();

  const [content, setContent] = useState("Loading ...");
  useEffect(() => {
    get<Description>("/description/communication").then((obj) => {
      setContent(obj?.content || formatMessage({ id: "failFetch" }));
    });
  }, [formatMessage, locale]);

  return (
    <Box>
      <BaseView noBread imageSrc={communicationsBg} />
      <VStack alignItems="start" flexWrap="wrap">
        <Box>
          <Text fontSize={titleSize} fontWeight="bold" color="#1f3f93">
            <FormattedMessage id="communication" />
          </Text>
        </Box>
        <Box>
          <Text color="#8b8b8b" fontSize={descSize}>
            {content}
          </Text>
        </Box>
        <VStack w="100%" marginTop="30px !important">
          <HStack flexWrap="wrap" alignItems="center">
            <Link
              to={HEALING_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="healingguide" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

Communication.Healing = Healing;
Communication.Session = Session;
Communication.Archive = Archive;

export default Communication;
