import React, { useState, FormEvent } from "react";
import { post } from "src/api/index";
import {
  Link,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  CloseButton,
  useDisclosure,
  ModalOverlay,
  FormControl,
  Input,
  VStack,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { REGISTER_ROUTE, FORGET_ROUTE } from "src/routes";
import { useIntl } from "react-intl";
import DarkHeader from "src/layout/DarkHeader";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import { FAQ_ROUTE } from "src/routes";
import useLocale from "src/providers/useLocale";
import Description from "../Description/Description";
import SendNote from "src/components/SendNote/SendNote";
import { useDispatch, useSelector } from "src/store";
import { login } from "src/store/auth";
import { useHistory } from "react-router-dom";

type RegData = {
  email?: string;
  password?: string;
};

const Register = () => {
  //tracking changes for inputs
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState<RegData>({});

  // modal disclosure
  const { isOpen, onClose } = useDisclosure();

  //validation errors
  const [errors, setErrors] = useState<string[]>([]);

  //logout all status
  const [logoutAll, setLogoutAll] = useState(false);

  //currnet locale
  const [locale] = useLocale();

  //logoutAll modal disclosure
  const disclosure = useDisclosure();

  //login function
  const user = useSelector((state) => state.auth.user);

  //state
  const [processing, setProcessing] = useState(false);

  //for formatting messages
  const { formatMessage } = useIntl();
  const history = useHistory();
  // const {update} = useContext(AuthContext)

  //method for handling inputs
  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setLoginData({ ...loginData, [target.name]: target.value });
  };

  //handling submit
  const handleSubmit = (e?: FormEvent, outAll?: boolean) => {
    e?.preventDefault();
    if (processing) return;
    setProcessing(true);
    post(
      "/auth/login",
      { ...loginData, logoutAll: outAll ? outAll : logoutAll },
      locale
    )
      .then((res: any) => {
        setProcessing(false);
        if (res?.id) {
          const redirectUrl =
            res.subscriptionStatus === "approved" ? "/portal" : "/subscription";
          window.localStorage.setItem("__sal_auth", res.accessToken);
          window.localStorage.setItem("__sal_auth_refresh", res.refreshToken);
          if (navigator.serviceWorker.controller) {
            const message = window.localStorage.getItem("__sal_auth");
            navigator.serviceWorker.controller.postMessage({
              type: "fromPage",
              message: message,
            });
          }
          dispatch(login({ user: res }));
          if (redirectUrl && window.location.pathname !== redirectUrl) {
            history.push(redirectUrl);
          }
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
          document
            .querySelector("form")
            ?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        if (res?.statusCode === 409) {
          disclosure.onOpen();
        }
        if (res?.message) {
          if (Array.isArray(res.message)) {
            setErrors(res.message);
          } else {
            setErrors([res.message]);
          }
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
        }
        document
          .querySelector("form")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      })
      .catch((err) => {
        setProcessing(false);
      });
  };
  //
  // useEffect(() => {
  //   //when a usr accepts to log out from all devices make the login request again with the logoutAll param set to true
  //   if (logoutAll) {
  //     handleSubmit();
  //   }
  // }, [logoutAll]);
  if (user && user?.subscriptionStatus === "approved") {
    return <Redirect to={"/portal"} />;
  }
  return (
    <>
      <Modal isCentered {...disclosure} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent
          px={"10px"}
          maxWidth={"609px"}
          width={"100%"}
          borderRadius={"30px"}
          paddingBottom="30px"
          marginTop="150px"
        >
          <ModalHeader>
            <CloseButton onClick={disclosure.onClose} />
          </ModalHeader>
          <ModalBody display="flex" flexDir="column" alignItems="center">
            <Text py={"15px"} textAlign={"center"} textStyle={"h5"}>
              {formatMessage({ id: "devices2" })}
            </Text>
            <Box marginTop="30px">
              <Button
                background="brand.900"
                color="white"
                onClick={(e) => {
                  setLogoutAll(true);
                  handleSubmit(e, true);
                }}
              >
                {formatMessage({ id: "logoutAll" })}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <DarkHeader />
      <VStack
        width="100%"
        padding="50px 0"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        <Box margin="40px 0">
          <Text fontWeight="bold" fontSize="42px" color="brand.900">
            {formatMessage({ id: "login" })}
          </Text>
        </Box>
        <FormControl
          boxShadow="0 0 10px 2px silver"
          padding={{ base: "20px", xl: "30px 80px" }}
          borderRadius="15px"
          maxWidth={{ base: "90%", md: "700px" }}
          onSubmit={handleSubmit}
          as="form"
        >
          {errors.length > 0 && (
            <Box
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              color="crimson"
            >
              {errors.map((err, idx) => (
                <Text margin="5px 0" key={idx}>
                  -{err}
                </Text>
              ))}
            </Box>
          )}
          <Box margin="30px 0">
            <Input
              type="email"
              placeholder={formatMessage({ id: "email" })}
              name="email"
              autoComplete={"email"}
              id="login-email"
              padding="25px 20px"
              onChange={handleChange}
            />
          </Box>
          <Box margin="30px 0">
            <Input
              type="password"
              autoComplete={"current-password"}
              placeholder={formatMessage({ id: "password" })}
              name="password"
              id="login-password"
              padding="25px 20px"
              onChange={handleChange}
            />
          </Box>
          <Box margin="60px 0 30px 0" textAlign="center">
            <Button
              height="50px"
              minWidth="80px"
              type="submit"
              background="brand.900"
              color="white"
              _hover={{ filter: "brightness(120%)" }}
              disabled={processing}
            >
              {formatMessage({ id: "submit" })}
            </Button>
          </Box>
          {/* <Box
            margin="40px 0 10px 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <a href="https://smartsway.com/profile/Terms-Conditions.aspx?Req=SAL">
              {formatMessage({ id: "needRegister" })}
            </a>
          </Box> */}
          {/* <Box
            margin="10px 0 10px 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <a
              href="https://smartsway.com/profile/ForgetPassword.aspx"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage({ id: "needReset" })}
            </a>
          </Box> */}
          <Box
            margin="10px 0 0 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <Link as={ReactRouterLink} to={FORGET_ROUTE}>
              {formatMessage({ id: "needReset" })}
            </Link>
          </Box>
          <Box
            margin="40px 0 10px 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <Link as={ReactRouterLink} to={REGISTER_ROUTE}>
              {formatMessage({ id: "needRegister" })}
            </Link>
          </Box>
          <Box
            margin="10px 0 15px 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://t.me/SmartsWay125"}
            >
              {formatMessage({ id: "contactSupport" })}
            </a>
          </Box>
          <Box
            margin="10px 0 0 0"
            textAlign="start"
            fontSize="14px"
            color="#6fbff7"
            _hover={{ textDecoration: "underline" }}
          >
            <Link as={ReactRouterLink} to={FAQ_ROUTE}>
              {formatMessage({ id: "faq" })}
            </Link>
          </Box>
        </FormControl>
        <Box paddingX={["8", "12", "24"]} paddingY="18">
          <Description />
        </Box>
      </VStack>
      {/* https://smartsway.com/profile/ForgetPassword.aspx */}
      {/* <Box w="100%" d="flex" alignItems="center" justifyContent="center">
      <Link as="button" onClick={()=>{onOpen()}}  color="brand.900" textStyle="smallBold" px="5">
        <FormattedMessage id="feedback" />
      </Link>
      <Link as={ReactRouterLink} to={FAQ_ROUTE} color="brand.900" textStyle="smallBold" px="5">
        <FormattedMessage id="faq" />
      </Link>
      <Link as={ReactRouterLink} to={DESCRIPTION_ROUTE} color="brand.900" textStyle="smallBold" px="5" dir="ltr">
        <FormattedMessage id="description" />
      </Link>
    </Box> */}
      <SendNote isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Register;
