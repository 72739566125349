import { countries as countriesSVG } from "country-flags-svg";
// This file used to get the index of the country in an
export const countries = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia, Plurinational State of",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Democratic Republic of the Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  PF: "French Polynesia",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  HT: "Haiti",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Republic of Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  MX: "Mexico",
  FM: "Micronesia, Federated States of",
  MD: "Republic of Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RO: "Romania",
  RU: "Russian",
  RW: "Rwanda",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela, Bolivarian Republic of",
  VN: "Viet Nam",
  VI: "Virgin Islands",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

const countrisValues = Object.values(countries);
export const serverCountries = [
  {
    id: 1,
    name: "Afghanistan",
    region: "Southern and Central Asia",
  },
  {
    id: 2,
    name: "Albania",
    region: "Southern Europe",
  },
  {
    id: 3,
    name: "Algeria",
    region: "Northern Africa",
  },
  {
    id: 4,
    name: "American Samoa",
    region: "Polynesia",
  },
  {
    id: 5,
    name: "Andorra",
    region: "Southern Europe",
  },
  {
    id: 6,
    name: "Angola",
    region: "Central Africa",
  },
  {
    id: 7,
    name: "Anguilla",
    region: "Caribbean",
  },
  {
    id: 8,
    name: "Antarctica",
    region: "Antarctica",
  },
  {
    id: 9,
    name: "Antigua and Barbuda",
    region: "Caribbean",
  },
  {
    id: 10,
    name: "Argentina",
    region: "South America",
  },
  {
    id: 11,
    name: "Armenia",
    region: "Middle East",
  },
  {
    id: 12,
    name: "Aruba",
    region: "Caribbean",
  },
  {
    id: 13,
    name: "Australia",
    region: "Australia and New Zealand",
  },
  {
    id: 14,
    name: "Austria",
    region: "Western Europe",
  },
  {
    id: 15,
    name: "Azerbaijan",
    region: "Middle East",
  },
  {
    id: 16,
    name: "Bahamas",
    region: "Caribbean",
  },
  {
    id: 17,
    name: "Bahrain",
    region: "Gulf",
  },
  {
    id: 18,
    name: "Bangladesh",
    region: "Southern and Central Asia",
  },
  {
    id: 19,
    name: "Barbados",
    region: "Caribbean",
  },
  {
    id: 20,
    name: "Belarus",
    region: "Eastern Europe",
  },
  {
    id: 21,
    name: "Belgium",
    region: "Western Europe",
  },
  {
    id: 22,
    name: "Belize",
    region: "Central America",
  },
  {
    id: 23,
    name: "Benin",
    region: "Western Africa",
  },
  {
    id: 24,
    name: "Bermuda",
    region: "North America",
  },
  {
    id: 25,
    name: "Bhutan",
    region: "Southern and Central Asia",
  },
  {
    id: 26,
    name: "Bolivia",
    region: "South America",
  },
  {
    id: 27,
    name: "Bosnia and Herzegovina",
    region: "Southern Europe",
  },
  {
    id: 28,
    name: "Botswana",
    region: "Southern Africa",
  },
  {
    id: 29,
    name: "Bouvet Island",
    region: "Antarctica",
  },
  {
    id: 30,
    name: "Brazil",
    region: "South America",
  },
  {
    id: 31,
    name: "British Indian Ocean Territory",
    region: "Eastern Africa",
  },
  {
    id: 32,
    name: "Brunei",
    region: "Southeast Asia",
  },
  {
    id: 33,
    name: "Bulgaria",
    region: "Eastern Europe",
  },
  {
    id: 34,
    name: "Burkina Faso",
    region: "Western Africa",
  },
  {
    id: 35,
    name: "Burundi",
    region: "Eastern Africa",
  },
  {
    id: 36,
    name: "Cambodia",
    region: "Southeast Asia",
  },
  {
    id: 37,
    name: "Cameroon",
    region: "Central Africa",
  },
  {
    id: 38,
    name: "Canada",
    region: "North America",
  },
  {
    id: 39,
    name: "Cape Verde",
    region: "Western Africa",
  },
  {
    id: 40,
    name: "Cayman Islands",
    region: "Caribbean",
  },
  {
    id: 41,
    name: "Central African Republic",
    region: "Central Africa",
  },
  {
    id: 42,
    name: "Chad",
    region: "Central Africa",
  },
  {
    id: 43,
    name: "Chile",
    region: "South America",
  },
  {
    id: 44,
    name: "China",
    region: "Eastern Asia",
  },
  {
    id: 45,
    name: "Christmas Island",
    region: "Australia and New Zealand",
  },
  {
    id: 46,
    name: "Cocos (Keeling) Islands",
    region: "Australia and New Zealand",
  },
  {
    id: 47,
    name: "Colombia",
    region: "South America",
  },
  {
    id: 48,
    name: "Comoros",
    region: "Eastern Africa",
  },
  {
    id: 49,
    name: "Congo",
    region: "Central Africa",
  },
  {
    id: 50,
    name: "Cook Islands",
    region: "Polynesia",
  },
  {
    id: 51,
    name: "Costa Rica",
    region: "Central America",
  },
  {
    id: 52,
    name: "Croatia",
    region: "Southern Europe",
  },
  {
    id: 53,
    name: "Cuba",
    region: "Caribbean",
  },
  {
    id: 54,
    name: "Cyprus",
    region: "Middle East",
  },
  {
    id: 55,
    name: "Czech Republic",
    region: "Eastern Europe",
  },
  {
    id: 56,
    name: "Denmark",
    region: "Nordic Countries",
  },
  {
    id: 57,
    name: "Djibouti",
    region: "Eastern Africa",
  },
  {
    id: 58,
    name: "Dominica",
    region: "Caribbean",
  },
  {
    id: 59,
    name: "Dominican Republic",
    region: "Caribbean",
  },
  {
    id: 60,
    name: "East Timor",
    region: "Southeast Asia",
  },
  {
    id: 61,
    name: "Ecuador",
    region: "South America",
  },
  {
    id: 62,
    name: "Egypt",
    region: "Northern Africa",
  },
  {
    id: 63,
    name: "El Salvador",
    region: "Central America",
  },
  {
    id: 64,
    name: "England",
    region: null,
  },
  {
    id: 65,
    name: "Equatorial Guinea",
    region: "Central Africa",
  },
  {
    id: 66,
    name: "Eritrea",
    region: "Eastern Africa",
  },
  {
    id: 67,
    name: "Estonia",
    region: "Baltic Countries",
  },
  {
    id: 68,
    name: "Ethiopia",
    region: "Eastern Africa",
  },
  {
    id: 69,
    name: "Falkland Islands",
    region: "South America",
  },
  {
    id: 70,
    name: "Faroe Islands",
    region: "Nordic Countries",
  },
  {
    id: 71,
    name: "Fiji Islands",
    region: "Melanesia",
  },
  {
    id: 72,
    name: "Finland",
    region: "Nordic Countries",
  },
  {
    id: 73,
    name: "France",
    region: "Western Europe",
  },
  {
    id: 74,
    name: "French Guiana",
    region: "South America",
  },
  {
    id: 75,
    name: "French Polynesia",
    region: "Polynesia",
  },
  {
    id: 76,
    name: "French Southern territories",
    region: "Antarctica",
  },
  {
    id: 77,
    name: "Gabon",
    region: "Central Africa",
  },
  {
    id: 78,
    name: "Gambia",
    region: "Western Africa",
  },
  {
    id: 79,
    name: "Georgia",
    region: "Middle East",
  },
  {
    id: 80,
    name: "Germany",
    region: "Western Europe",
  },
  {
    id: 81,
    name: "Ghana",
    region: "Western Africa",
  },
  {
    id: 82,
    name: "Gibraltar",
    region: "Southern Europe",
  },
  {
    id: 83,
    name: "Greece",
    region: "Southern Europe",
  },
  {
    id: 84,
    name: "Greenland",
    region: "North America",
  },
  {
    id: 85,
    name: "Grenada",
    region: "Caribbean",
  },
  {
    id: 86,
    name: "Guadeloupe",
    region: "Caribbean",
  },
  {
    id: 87,
    name: "Guam",
    region: "Micronesia",
  },
  {
    id: 88,
    name: "Guatemala",
    region: "Central America",
  },
  {
    id: 89,
    name: "Guinea",
    region: "Western Africa",
  },
  {
    id: 90,
    name: "Guinea-Bissau",
    region: "Western Africa",
  },
  {
    id: 91,
    name: "Guyana",
    region: "South America",
  },
  {
    id: 92,
    name: "Haiti",
    region: "Caribbean",
  },
  {
    id: 93,
    name: "Heard Island and McDonald Islands",
    region: "Antarctica",
  },
  {
    id: 94,
    name: "Holy See (Vatican City State)",
    region: "Southern Europe",
  },
  {
    id: 95,
    name: "Honduras",
    region: "Central America",
  },
  {
    id: 96,
    name: "Hong Kong",
    region: "Eastern Asia",
  },
  {
    id: 97,
    name: "Hungary",
    region: "Eastern Europe",
  },
  {
    id: 98,
    name: "Iceland",
    region: "Nordic Countries",
  },
  {
    id: 99,
    name: "India",
    region: "Southern and Central Asia",
  },
  {
    id: 100,
    name: "Indonesia",
    region: "Southeast Asia",
  },
  {
    id: 101,
    name: "Iran",
    region: "Southern and Central Asia",
  },
  {
    id: 102,
    name: "Iraq",
    region: "Middle East",
  },
  {
    id: 103,
    name: "Ireland",
    region: "British Isles",
  },
  {
    id: 104,
    name: "Israel",
    region: "Middle East",
  },
  {
    id: 105,
    name: "Italy",
    region: "Southern Europe",
  },
  {
    id: 106,
    name: "Ivory Coast",
    region: "Western Africa",
  },
  {
    id: 107,
    name: "Jamaica",
    region: "Caribbean",
  },
  {
    id: 108,
    name: "Japan",
    region: "Eastern Asia",
  },
  {
    id: 109,
    name: "Jordan",
    region: "Middle East",
  },
  {
    id: 110,
    name: "Kazakhstan",
    region: "Southern and Central Asia",
  },
  {
    id: 111,
    name: "Kenya",
    region: "Eastern Africa",
  },
  {
    id: 112,
    name: "Kiribati",
    region: "Micronesia",
  },
  {
    id: 113,
    name: "Kuwait",
    region: "Gulf",
  },
  {
    id: 114,
    name: "Kyrgyzstan",
    region: "Southern and Central Asia",
  },
  {
    id: 115,
    name: "Laos",
    region: "Southeast Asia",
  },
  {
    id: 116,
    name: "Latvia",
    region: "Baltic Countries",
  },
  {
    id: 117,
    name: "Lebanon",
    region: "Middle East",
  },
  {
    id: 118,
    name: "Lesotho",
    region: "Southern Africa",
  },
  {
    id: 119,
    name: "Liberia",
    region: "Western Africa",
  },
  {
    id: 120,
    name: "Libyan Arab Jamahiriya",
    region: "Northern Africa",
  },
  {
    id: 121,
    name: "Liechtenstein",
    region: "Western Europe",
  },
  {
    id: 122,
    name: "Lithuania",
    region: "Baltic Countries",
  },
  {
    id: 123,
    name: "Luxembourg",
    region: "Western Europe",
  },
  {
    id: 124,
    name: "Macao",
    region: "Eastern Asia",
  },
  {
    id: 125,
    name: "North Macedonia",
    region: "Southern Europe",
  },
  {
    id: 126,
    name: "Madagascar",
    region: "Eastern Africa",
  },
  {
    id: 127,
    name: "Malawi",
    region: "Eastern Africa",
  },
  {
    id: 128,
    name: "Malaysia",
    region: "Southeast Asia",
  },
  {
    id: 129,
    name: "Maldives",
    region: "Southern and Central Asia",
  },
  {
    id: 130,
    name: "Mali",
    region: "Western Africa",
  },
  {
    id: 131,
    name: "Malta",
    region: "Southern Europe",
  },
  {
    id: 132,
    name: "Marshall Islands",
    region: "Micronesia",
  },
  {
    id: 133,
    name: "Martinique",
    region: "Caribbean",
  },
  {
    id: 134,
    name: "Mauritania",
    region: "Western Africa",
  },
  {
    id: 135,
    name: "Mauritius",
    region: "Eastern Africa",
  },
  {
    id: 136,
    name: "Mayotte",
    region: "Eastern Africa",
  },
  {
    id: 137,
    name: "Mexico",
    region: "Central America",
  },
  {
    id: 138,
    name: "Micronesia, Federated States of",
    region: "Micronesia",
  },
  {
    id: 139,
    name: "Moldova",
    region: "Eastern Europe",
  },
  {
    id: 140,
    name: "Monaco",
    region: "Western Europe",
  },
  {
    id: 141,
    name: "Mongolia",
    region: "Eastern Asia",
  },
  {
    id: 142,
    name: "Montserrat",
    region: "Caribbean",
  },
  {
    id: 143,
    name: "Morocco",
    region: "Northern Africa",
  },
  {
    id: 144,
    name: "Mozambique",
    region: "Eastern Africa",
  },
  {
    id: 145,
    name: "Myanmar",
    region: "Southeast Asia",
  },
  {
    id: 146,
    name: "Namibia",
    region: "Southern Africa",
  },
  {
    id: 147,
    name: "Nauru",
    region: "Micronesia",
  },
  {
    id: 148,
    name: "Nepal",
    region: "Southern and Central Asia",
  },
  {
    id: 149,
    name: "Netherlands",
    region: "Western Europe",
  },
  {
    id: 150,
    name: "Netherlands Antilles",
    region: "Caribbean",
  },
  {
    id: 151,
    name: "New Caledonia",
    region: "Melanesia",
  },
  {
    id: 152,
    name: "New Zealand",
    region: "Australia and New Zealand",
  },
  {
    id: 153,
    name: "Nicaragua",
    region: "Central America",
  },
  {
    id: 154,
    name: "Niger",
    region: "Western Africa",
  },
  {
    id: 155,
    name: "Nigeria",
    region: "Western Africa",
  },
  {
    id: 156,
    name: "Niue",
    region: "Polynesia",
  },
  {
    id: 157,
    name: "Norfolk Island",
    region: "Australia and New Zealand",
  },
  {
    id: 158,
    name: "North Korea",
    region: "Eastern Asia",
  },
  {
    id: 159,
    name: "Northern Ireland",
    region: null,
  },
  {
    id: 160,
    name: "Northern Mariana Islands",
    region: "Micronesia",
  },
  {
    id: 161,
    name: "Norway",
    region: "Nordic Countries",
  },
  {
    id: 162,
    name: "Oman",
    region: "Gulf",
  },
  {
    id: 163,
    name: "Pakistan",
    region: "Southern and Central Asia",
  },
  {
    id: 164,
    name: "Palau",
    region: "Micronesia",
  },
  {
    id: 165,
    name: "Palestine",
    region: "Middle East",
  },
  {
    id: 166,
    name: "Panama",
    region: "Central America",
  },
  {
    id: 167,
    name: "Papua New Guinea",
    region: "Melanesia",
  },
  {
    id: 168,
    name: "Paraguay",
    region: "South America",
  },
  {
    id: 169,
    name: "Peru",
    region: "South America",
  },
  {
    id: 170,
    name: "Philippines",
    region: "Southeast Asia",
  },
  {
    id: 171,
    name: "Pitcairn",
    region: "Polynesia",
  },
  {
    id: 172,
    name: "Poland",
    region: "Eastern Europe",
  },
  {
    id: 173,
    name: "Portugal",
    region: "Southern Europe",
  },
  {
    id: 174,
    name: "Puerto Rico",
    region: "Caribbean",
  },
  {
    id: 175,
    name: "Qatar",
    region: "Gulf",
  },
  {
    id: 176,
    name: "Reunion",
    region: "Eastern Africa",
  },
  {
    id: 177,
    name: "Romania",
    region: "Eastern Europe",
  },
  {
    id: 178,
    name: "Russian Federation",
    region: "Eastern Europe",
  },
  {
    id: 179,
    name: "Rwanda",
    region: "Eastern Africa",
  },
  {
    id: 180,
    name: "Saint Helena",
    region: "Western Africa",
  },
  {
    id: 181,
    name: "Saint Kitts and Nevis",
    region: "Caribbean",
  },
  {
    id: 182,
    name: "Saint Lucia",
    region: "Caribbean",
  },
  {
    id: 183,
    name: "Saint Pierre and Miquelon",
    region: "North America",
  },
  {
    id: 184,
    name: "Saint Vincent and the Grenadines",
    region: "Caribbean",
  },
  {
    id: 185,
    name: "Samoa",
    region: "Polynesia",
  },
  {
    id: 186,
    name: "San Marino",
    region: "Southern Europe",
  },
  {
    id: 187,
    name: "Sao Tome and Principe",
    region: "Central Africa",
  },
  {
    id: 188,
    name: "Saudi Arabia",
    region: "Gulf",
  },
  {
    id: 189,
    name: "Scotland",
    region: null,
  },
  {
    id: 190,
    name: "Senegal",
    region: "Western Africa",
  },
  {
    id: 191,
    name: "Serbia",
    region: "Central and Southeast Europe",
  },
  {
    id: 192,
    name: "Seychelles",
    region: "Eastern Africa",
  },
  {
    id: 193,
    name: "Sierra Leone",
    region: "Western Africa",
  },
  {
    id: 194,
    name: "Singapore",
    region: "Southeast Asia",
  },
  {
    id: 195,
    name: "Slovakia",
    region: "Eastern Europe",
  },
  {
    id: 196,
    name: "Slovenia",
    region: "Southern Europe",
  },
  {
    id: 197,
    name: "Solomon Islands",
    region: "Melanesia",
  },
  {
    id: 198,
    name: "Somalia",
    region: "Eastern Africa",
  },
  {
    id: 199,
    name: "South Africa",
    region: "Southern Africa",
  },
  {
    id: 200,
    name: "South Georgia and the South Sandwich Islands",
    region: "Antarctica",
  },
  {
    id: 201,
    name: "South Korea",
    region: "Eastern Asia",
  },
  {
    id: 202,
    name: "South Sudan",
    region: "Eastern Africa",
  },
  {
    id: 203,
    name: "Spain",
    region: "Southern Europe",
  },
  {
    id: 204,
    name: "SriLanka",
    region: null,
  },
  {
    id: 205,
    name: "Sudan",
    region: "Northern Africa",
  },
  {
    id: 206,
    name: "Suriname",
    region: "South America",
  },
  {
    id: 207,
    name: "Svalbard and Jan Mayen",
    region: "Nordic Countries",
  },
  {
    id: 208,
    name: "Swaziland",
    region: "Southern Africa",
  },
  {
    id: 209,
    name: "Sweden",
    region: "Nordic Countries",
  },
  {
    id: 210,
    name: "Switzerland",
    region: "Western Europe",
  },
  {
    id: 211,
    name: "Syria",
    region: "Middle East",
  },
  {
    id: 212,
    name: "Tajikistan",
    region: "Southern and Central Asia",
  },
  {
    id: 213,
    name: "Tanzania",
    region: "Eastern Africa",
  },
  {
    id: 214,
    name: "Thailand",
    region: "Southeast Asia",
  },
  {
    id: 215,
    name: "The Democratic Republic of Congo",
    region: null,
  },
  {
    id: 216,
    name: "Togo",
    region: "Western Africa",
  },
  {
    id: 217,
    name: "Tokelau",
    region: "Polynesia",
  },
  {
    id: 218,
    name: "Tonga",
    region: "Polynesia",
  },
  {
    id: 219,
    name: "Trinidad and Tobago",
    region: "Caribbean",
  },
  {
    id: 220,
    name: "Tunisia",
    region: "Northern Africa",
  },
  {
    id: 221,
    name: "Turkey",
    region: "Middle East",
  },
  {
    id: 222,
    name: "Turkmenistan",
    region: "Southern and Central Asia",
  },
  {
    id: 223,
    name: "Turks and Caicos Islands",
    region: "Caribbean",
  },
  {
    id: 224,
    name: "Tuvalu",
    region: "Polynesia",
  },
  {
    id: 225,
    name: "Uganda",
    region: "Eastern Africa",
  },
  {
    id: 226,
    name: "Ukraine",
    region: "Eastern Europe",
  },
  {
    id: 227,
    name: "United Arab Emirates",
    region: "Gulf",
  },
  {
    id: 228,
    name: "United Kingdom",
    region: "British Isles",
  },
  {
    id: 229,
    name: "United States",
    region: "North America",
  },
  {
    id: 230,
    name: "United States Minor Outlying Islands",
    region: "Micronesia/Caribbean",
  },
  {
    id: 231,
    name: "Uruguay",
    region: "South America",
  },
  {
    id: 232,
    name: "Uzbekistan",
    region: "Southern and Central Asia",
  },
  {
    id: 233,
    name: "Vanuatu",
    region: "Melanesia",
  },
  {
    id: 234,
    name: "Venezuela",
    region: "South America",
  },
  {
    id: 235,
    name: "Vietnam",
    region: "Southeast Asia",
  },
  {
    id: 236,
    name: "Virgin Islands, British",
    region: null,
  },
  {
    id: 237,
    name: "Virgin Islands, U.S.",
    region: null,
  },
  {
    id: 238,
    name: "Wales",
    region: null,
  },
  {
    id: 239,
    name: "Wallis and Futuna",
    region: "Polynesia",
  },
  {
    id: 240,
    name: "Western Sahara",
    region: "Northern Africa",
  },
  {
    id: 241,
    name: "Yemen",
    region: "Middle East",
  },
  {
    id: 242,
    name: "Zambia",
    region: "Eastern Africa",
  },
  {
    id: 243,
    name: "Zimbabwe",
    region: "Eastern Africa",
  },
  {
    id: 244,
    name: "Libya",
    region: "Middle East",
  },
];

const diff: any = [
  {
    id: 8,
    name: "Antarctica",
    region: "Antarctica",
  },
  {
    id: 26,
    name: "Bolivia",
    region: "South America",
  },
  {
    id: 29,
    name: "Bouvet Island",
    region: "Antarctica",
  },
  {
    id: 32,
    name: "Brunei",
    region: "Southeast Asia",
  },
  {
    id: 45,
    name: "Christmas Island",
    region: "Australia and New Zealand",
  },
  {
    id: 46,
    name: "Cocos (Keeling) Islands",
    region: "Australia and New Zealand",
  },
  {
    id: 60,
    name: "East Timor",
    region: "Southeast Asia",
  },
  {
    id: 64,
    name: "England",
    region: null,
  },
  {
    id: 69,
    name: "Falkland Islands",
    region: "South America",
  },
  {
    id: 71,
    name: "Fiji Islands",
    region: "Melanesia",
  },
  {
    id: 74,
    name: "French Guiana",
    region: "South America",
  },
  {
    id: 76,
    name: "French Southern territories",
    region: "Antarctica",
  },
  {
    id: 86,
    name: "Guadeloupe",
    region: "Caribbean",
  },
  {
    id: 91,
    name: "Guyana",
    region: "South America",
  },
  {
    id: 93,
    name: "Heard Island and McDonald Islands",
    region: "Antarctica",
  },
  {
    id: 94,
    name: "Holy See (Vatican City State)",
    region: "Southern Europe",
  },
  {
    id: 101,
    name: "Iran",
    region: "Southern and Central Asia",
  },
  {
    id: 106,
    name: "Ivory Coast",
    region: "Western Africa",
  },
  {
    id: 115,
    name: "Laos",
    region: "Southeast Asia",
  },
  {
    id: 120,
    name: "Libyan Arab Jamahiriya",
    region: "Northern Africa",
  },
  {
    id: 125,
    name: "North Macedonia",
    region: "Southern Europe",
  },
  {
    id: 136,
    name: "Mayotte",
    region: "Eastern Africa",
  },
  {
    id: 139,
    name: "Moldova",
    region: "Eastern Europe",
  },
  {
    id: 150,
    name: "Netherlands Antilles",
    region: "Caribbean",
  },
  {
    id: 151,
    name: "New Caledonia",
    region: "Melanesia",
  },
  {
    id: 159,
    name: "Northern Ireland",
    region: null,
  },
  {
    id: 165,
    name: "Palestine",
    region: "Middle East",
  },
  {
    id: 176,
    name: "Reunion",
    region: "Eastern Africa",
  },
  {
    id: 178,
    name: "Russian Federation",
    region: "Eastern Europe",
  },
  {
    id: 180,
    name: "Saint Helena",
    region: "Western Africa",
  },
  {
    id: 183,
    name: "Saint Pierre and Miquelon",
    region: "North America",
  },
  {
    id: 184,
    name: "Saint Vincent and the Grenadines",
    region: "Caribbean",
  },
  {
    id: 189,
    name: "Scotland",
    region: null,
  },
  {
    id: 200,
    name: "South Georgia and the South Sandwich Islands",
    region: "Antarctica",
  },
  {
    id: 204,
    name: "SriLanka",
    region: null,
  },
  {
    id: 207,
    name: "Svalbard and Jan Mayen",
    region: "Nordic Countries",
  },
  {
    id: 215,
    name: "The Democratic Republic of Congo",
    region: null,
  },
  {
    id: 230,
    name: "United States Minor Outlying Islands",
    region: "Micronesia/Caribbean",
  },
  {
    id: 234,
    name: "Venezuela",
    region: "South America",
  },
  {
    id: 235,
    name: "Vietnam",
    region: "Southeast Asia",
  },
  {
    id: 236,
    name: "Virgin Islands, British",
    region: null,
  },
  {
    id: 237,
    name: "Virgin Islands, U.S.",
    region: null,
  },
  {
    id: 238,
    name: "Wales",
    region: null,
  },
  {
    id: 239,
    name: "Wallis and Futuna",
    region: "Polynesia",
  },
  {
    id: 240,
    name: "Western Sahara",
    region: "Northern Africa",
  },
];

export const getCountryId = (code: string) => {
  // find the name of the countrycode
  const countryName = countries[code];

  // getting the id of the country based on the name
  const selectedCountry = serverCountries.find((country) => {
    return country.name === countryName ? country.id : null;
  });

  return selectedCountry;
};

export const getCountryName = (code: string) => {
  return countries[code];
};
export default Object.keys(countries);
