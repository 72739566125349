import React, { useState, useEffect, CSSProperties } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
// import UserMessage from "./components/UserMessage";
// import Messages from "./components/Messages";
// import Input from "./components/Input";
import Header from "./components/Header";
// import AssistantMessage from "./components/AssistantMessage";
// import { OpenAiMessage, AssistantMessageList } from "src/types";
// import { getMessageList } from "src/api/assistant/query";
// import { useQuery } from "react-query";
import { useSelector, useDispatch } from "src/store";
// import { BarLoader, PropagateLoader } from "react-spinners";
// import { submitMessage } from "src/api/assistant/mutation";
import "./styles/SalAssistant.css";
// import { BASE_URL } from "src/configs/config";
// import { isTokenExpired } from "src/utils/fetchInterceptor";
import { get } from "src/api";
import { QuestionIcon } from "@chakra-ui/icons";
import { FAQ_ROUTE } from "src/routes";
import AssistantChat from "./components/AssistantChat";
import { toggleChatWindow } from "src/store/assistant";
import useLocale from "src/providers/useLocale";
import { FAQuestion } from "../FAQ/types";
import { useIntl } from "react-intl";

export default function SalAssistant({
  isOpen,
  onClose,
  style,
}: {
  isOpen: boolean;
  onClose: () => void;
  style?: CSSProperties;
}) {
  // const [messages, setMessages] = useState<JSX.Element[]>([]);
  // const [isInputDisabled, setIsInputDisabled] = useState(false);
  const dispatch = useDispatch();

  const [locale] = useLocale();
  const [faqs, setFaqs] = useState<any>([]);

  // const user = useSelector((state) => state.auth.user);
  const { isChatOpen } = useSelector((state) => ({
    isChatOpen: state.assistant.isChatOpen,
  }));

  // const { data: chatHistory, error } = useQuery<AssistantMessageList, Error>(
  //   ...getMessageList(user?.assistant_thread_id)
  // );

  // if (error) {
  //   console.error("Error fetching chat history:", error);
  //   setIsInputDisabled(false);
  // }

  // useEffect(() => {
  //   if (chatHistory) {
  //     const history = chatHistory.map((message: OpenAiMessage, index) => (
  //       <React.Fragment key={message.id}>
  //         {message.content.map((contentItem: any, contentIndex: number) => (
  //           <React.Fragment key={`${message.id}-${contentIndex}`}>
  //             {message.role === "user" ? (
  //               <UserMessage
  //                 key={`${message.id}-${contentIndex}`}
  //                 text={contentItem.text.value}
  //               />
  //             ) : (
  //               <AssistantMessage
  //                 key={`${message.id}-${contentIndex}`}
  //                 text={contentItem.text.value}
  //               />
  //             )}
  //           </React.Fragment>
  //         ))}
  //       </React.Fragment>
  //     ));
  //     setMessages(history);
  //   }
  // }, [chatHistory]);

  // useEffect(() => {
  //   const initSse = async () => {
  //     if (isTokenExpired(window.localStorage.getItem("__sal_auth"))) {
  //       console.log(
  //         "Access token expired, calling api for refresh process to kick-in."
  //       );
  //       await get("/user/me");
  //     }

  //     // Create an EventSource object
  //     const sse = new EventSource(
  //       `${BASE_URL}/sal-assistant/stream?token=${window.localStorage.getItem(
  //         "__sal_auth"
  //       )}`
  //     );

  //     sse.onerror = (error) => {
  //       console.error("Error:", error);
  //       sse.close();
  //     };

  //     sse.onmessage = (event) => {
  //       const { name, message: rMessage } = JSON.parse(event.data);

  //       if (name === "messageCreated") {
  //         setIsInputDisabled(false);
  //         setMessages((prevMessages) => {
  //           if (prevMessages.some((message) => message.key === rMessage.id))
  //             return prevMessages;
  //           return [
  //             ...prevMessages,
  //             <AssistantMessage
  //               key={rMessage.id}
  //               messageId={rMessage.id}
  //               eventSource={sse}
  //               text={""}
  //             />,
  //           ];
  //         });
  //       }
  //     };
  //   };
  //   initSse().catch((error) => console.error("SalAssistant.initSse", error));
  // }, [messages]);

  // const send = async (text: string): Promise<void> => {
  //   if (!text || text === "") return;
  //   setIsInputDisabled(true);

  //   // Append user message to the existing messages list
  //   const userMessage = [
  //     ...messages,
  //     <UserMessage key={messages.length + 1} text={text} />,
  //   ];
  //   setMessages(userMessage);
  //   // Send the message to the backend
  //   await submitMessage(text).catch((error) =>
  //     console.error("SalAssistant.submitMessage", error)
  //   );
  //   setIsInputDisabled(false);
  // };

  const { formatMessage } = useIntl();

  useEffect(() => {
    //method for fetching faqs data
    const fetchData = async () => {
      const response = await get<FAQuestion[]>("/faq?limit=3", locale);
      if (Array.isArray(response)) {
        const allTranslations = response
          .filter((q) => !q.isSalMagicFAQ)
          .map((q) => q.translations)
          .flat();
        setFaqs(allTranslations);
      }
    };
    fetchData();
  }, [locale]);

  return (
    <Box
      className="sal-assistant"
      width={{ base: "90vw", md: "45vw", lg: "25vw" }}
      height="95vh"
      style={{ visibility: isOpen ? "visible" : "hidden", ...style }}
    >
      <Header onClose={onClose} />
      <Box
        overflowY={isChatOpen ? "hidden" : "auto"}
        overflowX="hidden"
        position="relative"
        display="flex"
        flexFlow="column"
        height="100%"
      >
        <Flex flexDir="column" justifyContent="center" alignItems="center">
          <Box backgroundColor="#1F3F93" p={2} borderRadius="50%" mt={5}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800 800"
              height="35px"
              role="img"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#fff"
                d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z"
              ></path>
            </svg>
          </Box>

          <Text fontSize="xl" fontWeight="bold" mt={2}>
            {`${formatMessage({ id: "sal_assistant.welcome_message" })}`}
          </Text>
          <Text fontSize="md" color="gray.600">
            {`${formatMessage({ id: "sal_assistant.welcome_sub_message" })}`}
          </Text>
        </Flex>

        <Box w="95%" mx="auto" my={6}>
          <Flex alignItems="center" gap={2}>
            <Text my={4} textAlign="left" fontWeight="extrabold" fontSize="lg">
              {`${formatMessage({ id: "sal_assistant.faqs" })}`}
            </Text>
            <QuestionIcon color="#5c5d5f" />
          </Flex>

          <Accordion allowToggle>
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                border="1px solid #E1E4E7"
                mb={2}
                borderRadius="md"
                boxShadow="smaller"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      <Box
                        display="flex"
                        alignItems="center"
                        textAlign="left"
                        _hover={{ fill: "gray.300" }}
                        pb={2}
                        w="100%"
                      >
                        <Text
                          flex="1"
                          fontSize="md"
                          textColor="black"
                          noOfLines={isExpanded ? undefined : 1}
                        >
                          {faq.question}
                        </Text>
                        <AccordionIcon />
                      </Box>
                    </AccordionButton>
                    <AccordionPanel>
                      <Text textAlign="left" fontSize="md" textColor="#5c5d5f">
                        {faq.answer}
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>

          <Button
            onClick={() => (window.location.href = FAQ_ROUTE)}
            bg="#e8eaef"
            _hover={{ bg: "#eceef2" }}
            fontWeight="medium"
            w="100%"
            mx="auto"
            mt={2}
          >
            <Text fontSize="md">
              {`${formatMessage({ id: "sal_assistant.faqs_button" })}`}
            </Text>
          </Button>
        </Box>

        <Flex
          key="ai-assistant"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bgColor="#e8eaef"
          mt="auto"
          py={8}
          _hover={{ bg: "#eceef2" }}
          transition="transform 0.3s ease-in-out"
        >
          <Text fontSize="lg" textColor="#5c5d5f" mb={2}>
            {`${formatMessage({ id: "sal_assistant.pre_chat_message" })}`}
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            gap={2}
            cursor="pointer"
            onClick={() => dispatch(toggleChatWindow())}
          >
            <Text fontWeight="bold" fontSize="md" textColor="#151616">
              {`${formatMessage({
                id: "sal_assistant.pre_chat_message_button",
              })}`}
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
            </svg>
          </Flex>
        </Flex>
        <AssistantChat />
      </Box>
    </Box>
  );
}
