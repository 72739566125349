import { Heading } from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
interface Props {
  formatId?: string;
  marginTop?: string;
  fontSize?: any;
  width?: any;
  textAlign?: any;
  mt?: any;
}
const SubHeader: React.FC<Props> = ({
  mt,
  formatId,
  textAlign,
  width,
  fontSize,
  marginTop,
}) => {
  return (
    <Heading
      marginTop={marginTop}
      width={width && width}
      mt={mt}
      textAlign={textAlign && textAlign}
      fontSize={fontSize ? fontSize : "20px"}
      fontWeight={"500"}
      textStyle={"h5"}
    >
      <FormattedMessage id={formatId} />
    </Heading>
  );
};

export default SubHeader;
