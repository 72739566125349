import React, {
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import {
  Box,
  Link,
  Input,
  Select,
  FormLabel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import BaseView from "../../BaseView";
import { get } from "src/api/index";
import { useIntl } from "react-intl";
import Process from "./Process";
import { post } from "src/api/index";
import { useHistory, Link as ReactRouterLink } from "react-router-dom";
import { FaArchive } from "react-icons/fa";
import { ARCHIVE_ROUTE } from "src/routes";
import useLocale from "src/providers/useLocale";

function padTo2Digits(num) {
  return num.toString();
}

function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join("/");
}

export type Case = {
  id: number;
  name: string;
  sequence: string;
};

type SessionData = {
  name: string;
  caseId: number;
  date: string;
  note: string;
};

export type ProcessType = {
  iterations: Array<string>;
  min: number;
  max: number;
  current: number;
  id: number;
  break: number | string;
};

const Session = () => {
  //seeded cases
  const [cases, setCases] = useState<Case[]>([]);

  //current process
  const [process, setProcess] = useState<ProcessType>();

  //showing results status
  const [show, setShow] = useState(false);

  //toasting messages
  const toast = useToast();

  //used for comparison in process 1
  const [globalX, setGlobalX] = useState<number>(0);

  //history object
  const history = useHistory();

  //current locale
  const [locale] = useLocale();

  //process list
  const processList = [
    {
      iterations: ["kc", "ss", "mpr", "cb", "toxin"],
      min: 0,
      max: 10,
      current: 0,
      break: "globalX",
      id: 0,
    },
    {
      iterations: ["9g", "un", "un15", "ss9g", "cb9g", "toxin9g"],
      min: 0,
      max: 10,
      current: 0,
      break: 3,
      id: 1,
    },
  ];

  //end of session status
  const [end, setEnd] = useState(false);

  //function to end session
  const endSession = () => {
    //just set the variable to true now
    setEnd(true);
  };

  //current case
  const [currentCase, setCurrentCase] = useState<Case>();

  //process already added
  const [added, setAdded] = useState<{ name: string; value: string }[]>([]);
  const [firstSubmit, setFirstSubmit] = useState(false);
  //session data
  const [sessionData, setSessionData] = useState<SessionData>({
    name: "",
    date: "",
    note: "",
    caseId: 0,
  });

  //disclosures for weak case modal
  const disclosures = useDisclosure();

  //formatting messages
  const { formatMessage } = useIntl();

  //function to fetch cases
  const fetchCases = async () => {
    const response = await get("/session/case");
    if (Array.isArray(response)) setCases(response);
  };

  //function to handle input changes
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSessionData({ ...sessionData, [e.target.name]: e.target.value });
  };

  //function to handle key down for process
  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!process) return;
      const target = e.target as HTMLInputElement;
      // //if it's an empty iteration so it's the one inidicatin the global x
      // if (process && !process.iterations[process.current]) {
      //   setGlobalX(+target.value);
      // }
      //validating range
      if (
        isNaN(+target.value) ||
        +target.value > process.max ||
        +target.value < process.min
      ) {
        toast({
          title: formatMessage({ id: "session.range" }),
          status: "error",
          duration: 2000,
        });
        return;
      }
      const toCompare: number =
        process.break === "globalX" ? globalX - 2 : +process.break;
      if (+target.value <= toCompare) {
        //Next process
        const id = process ? process.id + 1 : 0;
        //If it is the last process show the results
        if (process.id === processList.length - 1) {
          setAdded([
            ...added,
            { name: process.iterations[process.current], value: target.value },
          ]);
          setShow(true);
        } else {
          setAdded([
            ...added,
            { name: process.iterations[process.current], value: target.value },
          ]);
          setProcess(processList[id]);
        }
      } else {
        //Next iteration in the same process
        if (!process) return;
        //if it's the last iteration end the session
        if (process.current === process.iterations.length - 1) {
          endSession();
          createSession();
          setAdded([
            ...added,
            { name: process.iterations[process.current], value: target.value },
          ]);
        }
        setProcess({ ...process, current: process.current + 1 });
        //add to the added process
        setAdded([
          ...added,
          { name: process.iterations[process.current], value: target.value },
        ]);
      }
      //Clear the input value
      (document.getElementById("process-input") as HTMLInputElement).value = "";
    }
  };

  //function to create session
  const createSession = async (redirect?: boolean) => {
    //validate name first
    if (!sessionData.name) {
      toast({
        title: formatMessage({ id: "session.name.error" }),
        status: "error",
        duration: 2000,
      });
      return;
    }
    const response: any = await post("/session", {
      ...sessionData,
      date: formatDate(new Date()),
    });
    if (response?.id) {
      //fire a success message
      toast({
        title: formatMessage({ id: "session.success" }),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      //redirect him to healing page
      if (redirect) history.push(ARCHIVE_ROUTE);
    } else {
      //fire an error
      toast({
        title: formatMessage({ id: "wentWrong" }),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  //first step submit
  const handleFirstSubmit = (e: KeyboardEvent) => {
    console.log("handleFirstSubmit");
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target as HTMLInputElement;
      setGlobalX(+target.value);
      //validate range
      if (+target.value < 0 || +target.value > 10) {
        toast({
          title: formatMessage({ id: "session.first.error" }),
          status: "error",
          duration: 2000,
        });
        return;
      }
      if (+target.value < 4) {
        disclosures.onOpen();
      } else {
        setCurrentCase(cases.find((c) => c.id === Number(sessionData.caseId)));
        setFirstSubmit(true);
        // setCurrentCase(cases.find((c) => c.id === Number(sessionData.caseId)));
        // setProcess(processList[0]);
      }
    }
  };

  //first step submit
  const handleSecondSubmit = (e: KeyboardEvent) => {
    console.log("handleSecondSubmit");
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target as HTMLInputElement;
      // setGlobalX(+target.value);
      //validate range
      if (+target.value < 0 || +target.value > 10) {
        toast({
          title: formatMessage({ id: "session.first.error" }),
          status: "error",
          duration: 2000,
        });
        return;
      }
      setCurrentCase(cases.find((c) => c.id === Number(sessionData.caseId)));
      if (+target.value >= globalX - 2) {
        setProcess(processList[0]);
      } else {
        setProcess(processList[1]);
      }
    }
  };

  //function to add note
  const addNote = (txt: string) => {
    setSessionData({ ...sessionData, note: txt });
  };

  //handle submission of form
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchCases();
  }, [locale]);

  useEffect(() => {
    //when the case id changes we set the current case to the corresponding object
    setCurrentCase(cases.find((c) => c.id === Number(sessionData.caseId)));
  }, [cases, sessionData.caseId]);

  return (
    <BaseView>
      <Box padding="8px 0 16px">
        <Box paddingBottom="30px" textAlign="start" fontSize="16px">
          <Link
            as={ReactRouterLink}
            to={ARCHIVE_ROUTE}
            color="brand.800"
            textDecoration="none"
            transition="250ms ease-out"
            _hover={{ color: "brand.900" }}
            d="flex"
            alignItems="center"
            fontWeight="bold"
          >
            <Text d="inline">{formatMessage({ id: "archiveSessions" })}</Text>
            <Box as="span" mx={2} d="inline">
              <FaArchive />
            </Box>
          </Link>
        </Box>
        <Box as="form" onSubmit={handleSubmit}>
          <Box my={3}>
            <FormLabel fontWeight="bold">
              {formatMessage({ id: "name" })}:{" "}
            </FormLabel>
            <Input
              background="white"
              name="name"
              onChange={handleChange}
              required
            />
          </Box>
        </Box>
        <Box my={3}>
          <Select
            defaultValue={0}
            background="white"
            name="caseId"
            onChange={handleChange}
          >
            <option value={0}>{formatMessage({ id: "case" })}</option>
            {cases.map((c) => (
              <option key={c.id} value={c.id} style={{ height: "20px" }}>
                {c.name}
              </option>
            ))}
          </Select>
        </Box>
        <Box my={3}>
          <Input
            dir="ltr"
            background="white"
            onKeyDown={handleFirstSubmit}
            type="number"
            min={3}
            max={10}
            disabled={
              !sessionData.caseId ||
              process !== undefined ||
              !sessionData.name ||
              firstSubmit
            }
            css={{ "&:disabled": { opacity: 1, background: "#e8ebe9" } }}
            placeholder="3-10"
            textAlign="center"
          />
        </Box>
      </Box>
      {firstSubmit && currentCase ? (
        <Box fontSize={22} textAlign="center" width="100%" whiteSpace="pre">
          <Text padding="5px 0" fontWeight="bold">
            {currentCase.sequence}
          </Text>
          <Input
            dir="ltr"
            background="white"
            onKeyDown={handleSecondSubmit}
            type="number"
            min={0}
            max={10}
            disabled={
              !sessionData.caseId || process !== undefined || !sessionData.name
            }
            css={{ "&:disabled": { opacity: 1, background: "#e8ebe9" } }}
            placeholder="0-10"
            textAlign="center"
          />
        </Box>
      ) : null}
      {process && sessionData.caseId && (
        <Process
          process={process}
          added={added}
          currentCase={currentCase}
          handler={keyDownHandler}
          show={show}
          createSession={createSession}
          addNote={addNote}
        />
      )}
      {end && (
        <Box marginTop="20px">
          <Text fontWeight="bold" textAlign="center">
            {formatMessage({ id: "session.end" })}
          </Text>
        </Box>
      )}
      <Modal {...disclosures} onClose={disclosures.onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={{ base: "90%", md: "450px" }}>
          <ModalHeader>
            <ModalCloseButton onClick={disclosures.onClose} />
          </ModalHeader>
          <ModalBody paddingBlock="20px">
            <Text fontSize={18}>{formatMessage({ id: "session.weak" })}</Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" paddingBottom="30px">
            <Button
              bg="brand.900"
              color="white"
              padding="5px 10px"
              onClick={disclosures.onClose}
            >
              {formatMessage({ id: "ok" })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </BaseView>
  );
};

export default Session;
