import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import PrimaryButton from "src/components/PrimaryButton";

import { FormattedMessage, useIntl } from "react-intl";

import RatingStars from "./RatingStars";

import { post, put, get } from "src/api";

function RatingModal({
  isOpen,
  onClose,
  entityName,
  entityId,
  fetchRatings,
  currentUserRating,
}) {
  const [myRating, setMyRating] = useState<any>();

  const fetchMyRating = useCallback(
    async (signal) => {
      try {
        const initialMyRating = await get(
          `/rating/myRating/${entityName}/${entityId}`,
          { signal }
        );
        if (initialMyRating) {
          setMyRating(initialMyRating);
        }
        return initialMyRating;
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Failed to fetch my rating:", error);
        }
      }
    },
    [entityId, entityName]
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (!myRating) {
      fetchMyRating(signal);
    }

    return () => {
      controller.abort();
    };
  }, [fetchMyRating, fetchRatings, myRating]);

  // This modal opens only for editing the current user's rating or creating his own one
  // so the initial value either the current rating (if existes) or just empty filds initalization
  // eslint-disable-next-line react-hooks/exhaustive-deps

  let initialFormData: any = {
    rating: 0,
    description: "",
    entityName,
    entityId: Number(entityId),
  };

  const intl = useIntl();
  const toast = useToast();
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (currentUserRating && Object.keys(currentUserRating).length !== 0) {
      // set the form data with the editing data if exists
      const { rating, description, entityName, entityId } = currentUserRating;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialFormData = {
        rating,
        description,
        entityName,
        entityId,
      };
      setFormData(initialFormData);
    } else if (myRating && Object.keys(myRating).length !== 0) {
      const { rating, description, entityName, entityId } = myRating;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialFormData = {
        rating,
        description,
        entityName,
        entityId,
      };
      setFormData(initialFormData);
    } else {
      setFormData({
        rating: 0,
        description: "",
        entityName,
        entityId: Number(entityId),
      });
    }
  }, [currentUserRating]);

  const resetFormFields = useCallback(() => {
    setFormData(formData);
  }, [formData]);

  const addRating = useCallback(() => {
    post("/rating", formData)
      .then((res: any) => {
        if (res?.id) {
          toast({
            title: intl.formatMessage({ id: "rating.success" }),
            status: "success",
            isClosable: true,
          });
          setFormData({
            rating: 0,
            description: "",
            entityName,
            entityId: Number(entityId),
          });
          fetchRatings();
        }

        if (res?.error) {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        }
        onClose();
      })
      .catch((err) => {
        toast({
          title: intl.formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      });
  }, [entityId, entityName, fetchRatings, formData, intl, onClose, toast]);

  const updateRating = useCallback(() => {
    put(`/rating/myRating/${entityName}/${entityId}`, {
      rating: formData.rating,
      description: formData.description,
    })
      .then((res: any) => {
        if (res?.success) {
          toast({
            title: intl.formatMessage({ id: "rating.success" }),
            status: "success",
            isClosable: true,
          });
          setFormData({
            rating: 0,
            description: "",
            entityName,
            entityId: Number(entityId),
          });
          fetchRatings();
        } else {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        }
        onClose();
      })
      .catch((err) => {
        toast({
          title: intl.formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      });
  }, [
    entityId,
    entityName,
    fetchRatings,
    formData.description,
    formData.rating,
    intl,
    onClose,
    toast,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const currentRating = await fetchMyRating(signal);

      if (Object.keys(currentRating as object).length) {
        updateRating();
      } else {
        addRating();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isCentered
      size={"xs"}
      isOpen={isOpen}
      onClose={() => {
        resetFormFields();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        p={"10px"}
        maxWidth={"550px"}
        width={"100%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //   if (
              //     Object.keys(currentUserRating).length ||
              //     Object.keys(myRating).length
              //   ) {
              //     updateRating();
              //   } else {
              //     addRating();
              //   }

              handleSubmit(e);
            }}
          >
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="18px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              mb="4"
            >
              <FormattedMessage id="addRating" />
            </Text>
            <RatingStars
              icon="star"
              scale={5}
              fillColor="gold"
              size={5}
              initialRating={formData.rating}
              onRatingChange={(rating) => {
                setFormData((current) => {
                  return { ...current, rating: Number(rating) };
                });
              }}
            />
            <Text
              d="block"
              mt="4"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="descripeYourExperience" />
            </Text>
            <Box>
              <Textarea
                minHeight={"32"}
                variant="flushed"
                placeholder={intl.formatMessage({ id: "yourMessage" })}
                maxLength={600}
                value={formData.description}
                onChange={(e) => {
                  setFormData((current) => {
                    return { ...current, description: e.target.value };
                  });
                }}
              />
            </Box>
            <Text
              d="inline-block"
              mt="4"
              // color="brand.900"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              letterSpacing="widest"
            >
              {`${formData?.description?.length}`}
            </Text>
            <Text d="inline-block"> /600</Text>
            <Center my={"32px"}>
              <PrimaryButton
                type="submit"
                disabled={
                  !formData.rating || !formData.entityId || !formData.entityName
                }
              >
                <FormattedMessage id="send" defaultMessage="ارسال" />
              </PrimaryButton>
            </Center>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RatingModal;
