import React, { useState, useEffect } from "react";
import { Box, Stack } from "@chakra-ui/react";
import useLocale from "src/providers/useLocale";
import { StarIcon } from "@chakra-ui/icons";

const RatingStars = React.forwardRef(
  (
    {
      size,
      scale,
      fillColor,
      strokeColor,
      isDisabled,
      initialRating,
      onRatingChange,
    }: any,
    ref
  ) => {
    const [rating, setRating] = useState(initialRating);
    const [locale] = useLocale();
    const buttons: any = [];

    useEffect(() => {
      setRating(initialRating);
    }, [initialRating]);

    const onClick = (idx) => {
      if (!isNaN(idx)) {
        // allow user to click first icon and set rating to zero if rating is already 1
        if (rating === 1 && idx === 1) {
          setRating(0);
          onRatingChange(0);
        } else {
          setRating(idx);
          onRatingChange(idx);
        }
      }
    };

    const RatingIcon = ({ fill }) => {
      return (
        <StarIcon
          name={"star"}
          // size={`${size}`}
          w={`${size}`}
          h={`${size}`}
          color={fill ? fillColor : "#d3d3d3"}
          stroke={strokeColor}
          onClick={onClick}
          fillOpacity={fill ? "100%" : "100%"}
        />
      );
    };

    const RatingButton = ({ idx, fill }) => {
      return (
        <Box
          as="button"
          aria-label={`Rate ${idx}`}
          height={`${size}`}
          width={`${size}`}
          d="flex"
          disabled={isDisabled}
          cursor={isDisabled ? "auto" : "pointer"}
          // variant={"unstyled"}
          alignItems="center"
          justifyContent="center"
          onClick={() => (isDisabled ? () => {} : onClick(idx))}
          style={
            locale === "ar"
              ? { margin: 0, marginRight: idx === 1 ? "0" : "8px" }
              : { margin: 0, marginLeft: idx === 1 ? "0" : "8px" }
          }
        >
          <RatingIcon fill={fill} />
        </Box>
      );
    };

    for (let i = 1; i <= scale; i++) {
      buttons.push(<RatingButton key={i} idx={i} fill={i <= rating} />);
    }

    // if (rating % 1 !== 0) {
    //     // rating has fraction
    //     const ratingFractionPercentage = Math.ceil((rating % 1) * 100) // fraction 1 to 99
    //     buttons.push(<RatingButton fill={true} idx={scale + 1} fillPercentage={ratingFractionPercentage} />)
    // }

    return (
      <Stack isInline>
        {/* <svg className="linear-gradient-template">
                    <linearGradient id="orange_red" x1="100%" y1="0%" x2="0%" y2="0%">
                        <stop offset="50%" style={{ stopColor: 'rgb(255, 193, 7)' }}></stop>
                        <stop offset="50%" style={{ stopColor: 'rgb(228, 229, 233)' }}></stop>
                    </linearGradient>
                </svg> */}
        <input name="rating" type="hidden" value={rating} />
        {buttons}
        {/* <Box width={`${size * 1.5}px`} textAlign="center">
                    <Text fontSize="sm" textTransform="uppercase">
                        Rating
                    </Text>
                    <Text fontSize="2xl" fontWeight="semibold" lineHeight="1.2em">
                        {rating}
                    </Text>
                </Box> */}
      </Stack>
    );
  }
);

RatingStars.displayName = "Rating";

export default RatingStars;
