import { QueryFunction } from "react-query";
import { get } from "src/api";
import { AssistantMessageList } from "src/types";

const getMessageList: (
  threadId?: string
) => [string[], QueryFunction<AssistantMessageList>] = (threadId) => [
  ["threadMessages"],
  () => get<AssistantMessageList>(`/sal-assistant/thread/${threadId}/messages`),
];

export { getMessageList };
