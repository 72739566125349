// WaveformPlayer.tsx
import React, { useMemo, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  IconButton,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import { HiPlay, HiPause } from "react-icons/hi";
import { BsInfoCircle } from "react-icons/bs";
import { useIntl } from "react-intl";
import { usePlayback } from "./PlaybackContext";
import useWaveSurfer from "./useWaveSurfer";
import StaticRatingStars from "src/components/Rating/StaticRatingStars";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PureToneInfoModal from "../PureToneInfoModal";
import RatingModal from "src/components/Rating/RatingModal";
import { useSelector } from "src/store";
import OtherSourcesInfoModal from "../OtherSourcesInfoModal";
import WaveSurferCache from "./WaveSurferCache";
import Waveform from "./WaveForm";

interface WaveformPlayerProps {
  entityName: string;
  trackId: number;
  trackName: string;
  trackCategory: string;
  trackCover: string;
  trackUrl: string;
  trackInfo: string;
  trackRating?: number;
}

const SalMagicAudioPlayer: React.FC<WaveformPlayerProps> = React.memo(
  ({
    entityName,
    trackUrl,
    trackName,
    trackCategory,
    trackInfo,
    trackId,
    trackCover,
    trackRating,
  }) => {
    const { isPlaying, togglePlayPause, isLoading } = usePlayback();

    const { waveSurferRef, containerRef, duration, currentTime } =
      useWaveSurfer(trackUrl);

    const [isMobile] = useMediaQuery("(max-width: 465px)");

    const { locale } = useIntl();
    const {
      isOpen: isInfoModalOpen,
      onOpen: onInfoModalOpen,
      onClose: onInfoModalClose,
    } = useDisclosure();
    const {
      isOpen: isRatingModalOpen,
      onOpen: onRatingModalOpen,
      onClose: onRatingModalClose,
    } = useDisclosure();

    const formatTime = (seconds: number) =>
      [seconds / 60, seconds % 60]
        .map((v) => `0${Math.floor(v)}`.slice(-2))
        .join(":");

    const currentTrackUrl = useSelector(
      (state) => state.playback.currentTrackUrl
    );

    const playPauseIcon = useMemo(() => {
      if (isLoading && currentTrackUrl === trackUrl)
        return <Spinner size="md" />;
      return isPlaying && currentTrackUrl === trackUrl ? (
        <HiPause
          size={"3rem"}
          color={"#2895ff"}
          style={{ margin: "0 5px" }}
          cursor="pointer"
        />
      ) : (
        <HiPlay
          size={"3rem"}
          color={"#2895ff"}
          style={{ margin: "0 5px" }}
          cursor="pointer"
        />
      );
    }, [isPlaying, currentTrackUrl, trackUrl, isLoading]);

    const handlePlayPause = () => {
      if (waveSurferRef.current) {
        togglePlayPause({ waveSurfer: waveSurferRef.current, url: trackUrl });
      }
    };

    const waveSurferCache = WaveSurferCache.getInstance();

    useEffect(() => {
      if (currentTrackUrl === trackUrl && isPlaying) {
        const playingTrack = waveSurferCache.get(trackUrl);
        playingTrack?.play();
      }
    }, [currentTrackUrl, currentTime, trackUrl, isPlaying, waveSurferCache]);

    return (
      <Flex
        alignItems="center"
        justifyContent="end"
        gap={5}
        position="relative"
        w="100%"
      >
        <Flex flexDir="column">
          <Box
            position="relative"
            backgroundImage={trackCover ?? "/gallery.png"}
            backgroundSize="cover"
            borderRadius={10}
            boxSize={100}
            mr={3}
          >
            <Flex
              position="relative"
              borderRadius={10}
              boxSize={100}
              justifyContent="center"
              alignItems="center"
              backgroundColor={trackCover ? "blackAlpha.400" : "transparent"}
            >
              <Box
                position="absolute"
                backgroundColor="white"
                boxSize="45px"
                boxSizing="border-box"
                border="solid 20px white"
                right={0}
                transform="translateX(50%)"
                borderRadius="50%"
              />
              <IconButton
                position="absolute"
                right={0}
                transform="translateX(50%)"
                onClick={handlePlayPause}
                aria-label={"Play/Pause"}
                icon={playPauseIcon}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _focusWithin={{ backgroundColor: "transparent" }}
                disabled={isLoading && currentTrackUrl === trackUrl}
              />
            </Flex>
          </Box>
          {entityName === "pure_tones" && (
            <Flex alignItems="center" justifyContent="center">
              <StaticRatingStars rating={trackRating} starSize={16} />
              <IconButton
                variant="button"
                color="gold"
                aria-label="add"
                onClick={onRatingModalOpen}
                icon={<AddCircleOutlineIcon />}
              />
            </Flex>
          )}
        </Flex>

        <Text fontSize="1.3rem" width={isMobile ? "100%" : "15%"} style={{ minWidth: isMobile ? "90px" : "" }}>
          {trackName}
        </Text>

        {isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <span>{locale === "en" ? formatTime(currentTime) : formatTime(duration)}</span>
            <span>{locale === "en" ? formatTime(duration) : formatTime(currentTime)}</span>
          </div>
        ) : null}

        <span
          style={
            isMobile
              ? { display: "none" }
              : {
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }
          }
        >
          <span>
            {locale === "en" ? formatTime(currentTime) : formatTime(duration)}
          </span>

          <div
            ref={containerRef}
            style={{ width: "75%", height: "100px", position: "relative" }}
          >
            {/* Display Waveform if loading or current track URL is not the same */}
            {(currentTrackUrl !== trackUrl || isLoading) &&
              !waveSurferCache.get(trackUrl) && <Waveform />}
          </div>

          <span>
            {locale === "en" ? formatTime(duration) : formatTime(currentTime)}
          </span>
        </span>

        <IconButton
          aria-label="Info"
          icon={<BsInfoCircle size={"1rem"} cursor={"pointer"} color="gray" />}
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          onClick={onInfoModalOpen}
        />

        {entityName === "pure_tones" ? (
          <PureToneInfoModal
            isOpen={isInfoModalOpen}
            onClose={onInfoModalClose}
            id={trackId}
            title={trackName}
            category={trackCategory}
            duration={formatTime(duration)}
            description={trackInfo}
          />
        ) : (
          <OtherSourcesInfoModal
            isOpen={isInfoModalOpen}
            onClose={onInfoModalClose}
            id={trackId}
            title={trackName}
            category={trackCategory}
            duration={formatTime(duration)}
            description={trackInfo}
          />
        )}

        {entityName === "pure_tones" && isRatingModalOpen && (
          <RatingModal
            isOpen={isRatingModalOpen}
            onClose={onRatingModalClose}
            entityId={trackId}
            entityName={"pure_tones"}
            fetchRatings={() => {}}
            currentUserRating={{}}
          />
        )}
      </Flex>
    );
  }
);

export default SalMagicAudioPlayer;
