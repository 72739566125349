import { Box } from "@chakra-ui/layout";
import {
  Editable,
  EditableInput,
  EditablePreview,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import usePlanApi from "../usePlanApi";

function PlanName(props) {
  const { planQuery, editPlanNameMutation } = usePlanApi(props.planId);
  const toast = useToast();
  const { formatMessage } = useIntl();
  const editPlanName = async (planId: number, name: string) => {
    try {
      await editPlanNameMutation({ planId: planId, name });
      toast({
        title: formatMessage({ id: "plan.edit.name.success" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.edit.name.failed" }),
        status: "error",
      });
    }
  };

  const [name, setName] = useState<string>(planQuery?.data?.name || "");
  useEffect(() => {
    planQuery?.data?.name && setName(planQuery.data.name);
  }, [planQuery.data]);
  return (<Box pl="1.5" d="flex" alignItems="center" justifyContent="center">
    <Editable
      isDisabled={props.isDisabled}
      textAlign="center"
      fontSize="24"
      fontWeight="bold"
      value={name}
      onChange={(e) => {
        setName(e);
      }}
      onSubmit={(e) => editPlanName(props.planId, e)}
      placeholder={"Add plan name ..."}
    >
      <EditablePreview />
      <EditableInput />
    </Editable>
  </Box>
  );
}

export default PlanName;
