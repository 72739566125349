import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const SecondaryButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      justifySelf="center"
      width="112px"
      h="32px"
      borderRadius="16px"
      color="brand.800"
      variant="outline"
      borderColor="brand.800"
      //   bgColor="brand.800"
      fontFamily="Calibri (Body)"
      fontSize="16px"
      fontWeight="normal"
      {...props}
    >
      {children}
    </Button>
  );
};
export default SecondaryButton;
