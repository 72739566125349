import React, { useEffect, useRef } from "react";
import { Flex } from "@chakra-ui/react";
import "../styles/Messages.css";
import { useSelector } from "src/store";

export default function Messages({ messages }) {
  const el = useRef<HTMLDivElement | null>(null);

  const { isChatOpen } = useSelector((state) => ({
    isChatOpen: state.assistant.isChatOpen,
  }));

  useEffect(() => {
    if (el.current && isChatOpen) {
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [messages, isChatOpen]);
  return (
    <Flex flexDir="column" px={2} pb={2} dir="ltr">
      {messages}
      <div ref={el} />
    </Flex>
  );
}
