import React, { useState, useEffect, useCallback } from "react";
import BaseView from "../BaseView";
import MaleHead from "src/assets/images/man-head.png";
import MaleAbs from "src/assets/images/man-abbs.png";
import MaleLegs from "src/assets/images/man-legs.png";
import FemaleHead from "src/assets/images/woman-head.png";
import FemaleAbs from "src/assets/images/woman-abbs.png";
import FemaleLegs from "src/assets/images/woman-legs.png";
import { useDispatch, useSelector } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import {
  HStack,
  Box,
  VStack,
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Text,
  ModalContent,
  useToast,
  Button,
} from "@chakra-ui/react";
import { get } from "src/api/index";
import { Disease } from "src/types";
import useLocale from "src/providers/useLocale";
import { FaCopy, FaUpload } from "react-icons/fa";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import CalibrationModal from "src/views/Affirmation/Calibration/CalibrationModal";
import { useHistory, useLocation, Link } from "react-router-dom";
import { INTENTION_ROUTE, SUBLIMINAL_ROUTE } from "src/routes";
import Calibration from "src/views/Affirmation/Calibration/Calibration";

//mapping for images
const map = {
  male: {
    head: MaleHead,
    abdomen: MaleAbs,
    limps: MaleLegs,
  },
  female: {
    head: FemaleHead,
    abdomen: FemaleAbs,
    limps: FemaleLegs,
  },
};
const Detail = () => {
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  //current locale
  const [locale] = useLocale();

  //current user
  const user = useSelector((state) => state.auth.user);

  //history
  const history = useHistory();

  //method for obtaining current organd and gender
  const getSlugs = () => {
    const gender = window.location.pathname.split("/")[4];
    const section = window.location.pathname.split("/")[5];
    const organ = window.location.pathname.split("/")[6];
    return {
      gender,
      section,
      organ,
    };
  };

  //Tracking diseases
  const [list, setList] = useState<Disease[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  //count of organs for current section
  const [organsCount, setOrgansCount] = useState<any>({});

  //function to fetch organs count
  const countOrgans = useCallback(async () => {
    const { gender, section } = getSlugs();
    const response = await get(`/healing/${gender}/${section}`);
    if (response) setOrgansCount(response);
  }, []);

  //current pathname
  const { pathname } = useLocation();

  //function to correct pathname
  const correctPath = () => {
    return pathname
      .split("/")
      .filter((_, idx, arr) => idx !== arr.length - 1)
      .join("/");
  };

  //function to return the map for section parts
  const getImageMap = () => {
    if (!organsCount) return;
    const section = getSlugs().section;
    //Depending on the section we return the map for it's corresponding parts
    switch (section) {
      case "head":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.forehead ? "#" : `${correctPath()}/forehead`}
              position="absolute"
              width="80%"
              height="100px"
              top="0"
              left="50%"
              transform="translate(-50%, 10px)"
            />
            <Box
              as={Link}
              to={!organsCount.eyes ? "#" : `${correctPath()}/eyes`}
              position="absolute"
              width="80%"
              height="40px"
              top="0"
              left="50%"
              transform="translate(-50%, 120px)"
            />
            <Box
              as={Link}
              to={!organsCount.nose ? "#" : `${correctPath()}/nose`}
              position="absolute"
              width="20%"
              height="60px"
              top="0"
              left="50%"
              transform="translate(-50%, 145px)"
            />
            <Box
              as={Link}
              to={!organsCount.mouth ? "#" : `${correctPath()}/mouth`}
              position="absolute"
              width="30%"
              height="40px"
              top="0"
              left="50%"
              transform="translate(-50%, 215px)"
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${correctPath()}/cheeks`}
              position="absolute"
              width="20%"
              height="80px"
              top="0"
              left="0"
              transform="translate(50px , 180px)"
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${correctPath()}/cheeks`}
              position="absolute"
              width="20%"
              height="80px"
              top="0"
              right="0"
              transform="translate(-50px, 180px)"
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${correctPath()}/ears`}
              position="absolute"
              width="10%"
              height="60px"
              top="0"
              left="0"
              transform="translate(0px, 140px)"
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${correctPath()}/ears`}
              position="absolute"
              width="10%"
              height="60px"
              top="0"
              right="0"
              transform="translate(-0px, 140px)"
            />
            <Box
              as={Link}
              to={!organsCount.neck ? "#" : `${correctPath()}/neck`}
              position="absolute"
              width="70%"
              height="60px"
              top="0"
              left="50%"
              transform="translate(-50%, 290px)"
            />
          </>
        );
      case "abdomen":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.chest ? "#" : `${correctPath()}/chest`}
              position="absolute"
              width="100%"
              height="140px"
              top="0"
              left="50%"
              transform="translate(-50%, 30px)"
            />
            <Box
              as={Link}
              to={!organsCount.belly ? "#" : `${correctPath()}/belly`}
              position="absolute"
              width="80%"
              height="180px"
              top="0"
              left="50%"
              transform="translate(-50%, 170px)"
            />
          </>
        );
      case "limps":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${correctPath()}/arms`}
              position="absolute"
              width="10%"
              height="100px"
              top="0"
              left="0"
              transform="translate(60px, -15px) rotate(30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${correctPath()}/arms`}
              position="absolute"
              width="10%"
              height="100px"
              top="0"
              right="0"
              transform="translate(-60px, -15px) rotate(-30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${correctPath()}/hands`}
              position="absolute"
              width="10%"
              height="30px"
              top="0"
              left="0"
              transform="translate(10px, 100px) rotate(30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${correctPath()}/hands`}
              position="absolute"
              width="10%"
              height="30px"
              top="0"
              right="0"
              transform="translate(-10px, 100px) rotate(-30deg)"
            />
            <Box
              as={Link}
              to={!organsCount.legs ? "#" : `${correctPath()}/upperLegs`}
              position="absolute"
              width="40%"
              height="150px"
              top="0"
              left="50%"
              transform="translate(-50%, 100px)"
            />
            <Box
              as={Link}
              to={!organsCount.feet ? "#" : `${correctPath()}/lowerLegs`}
              position="absolute"
              width="40%"
              height="100px"
              top="0"
              left="50%"
              transform="translate(-50%, 200px)"
            />
          </>
        );
    }
  };
  //calibration modal
  const [open, setOpen] = useState(false);

  //current opened modal
  const [current, setCurrent] = useState<Disease>();

  //method for fetching list
  const getList = useCallback(async () => {
    const { gender, organ, section } = getSlugs();
    // Functions to map organs
    const getCorrectOrgan = (organ) => {
      switch (organ) {
        case "upperLegs":
          return "legs";
        case "lowerLegs":
          return "feet";
        default:
          return organ;
      }
    };
    const response = await get(
      `/healing/${gender}/${section}/${getCorrectOrgan(organ)}`,
      locale === "ar" || locale === "en" ? locale : "ar",
      user?.accessToken
    );
    setLoading(false);
    if (response && Array.isArray(response)) setList(response);
    else setError("Failed to fetch data!");
  }, [locale, user?.accessToken]);
  //Copy Toast
  const intel = useIntl();
  const toast = useToast();
  const fireToast = () => {
    toast({
      position: "top",
      render: () => (
        <Box
          color="white"
          width={"200px"}
          py={"5px"}
          textAlign={"center"}
          bg="brand.800"
        >
          {intel.formatMessage({ id: "values.copy" })}
        </Box>
      ),
    });
  };
  //fetch list once component mounts
  useEffect(() => {
    getList();
    countOrgans();
  }, [countOrgans, getList, locale, pathname]);

  //visibility control for modal
  const disclosureProps = useDisclosure();

  //method to open modal
  const openModal = (obj: Disease) => {
    setCurrent(obj);
    disclosureProps.onOpen();
  };

  //method for copying to clippoard
  const copyToClipboad = (value: string) => {
    if (value) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value);
        return fireToast();
      } else {
        let copyText: HTMLInputElement | null =
          document.querySelector("#printTextD");
        copyText!.select();
        document.execCommand("copy");
        return fireToast();
      }
    }
  };

  //method to go for intention sending
  const goToIntention = () => {
    //User have to be calibrated before redirection
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${INTENTION_ROUTE}?sym=${current?.id}`);
    }
  };

  const goToSubliminal = () => {
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${SUBLIMINAL_ROUTE}?sym=${current?.id}&idx=2`);
    }
  }

  return (
    <BaseView>
      <Input
        id={"printTextD"}
        opacity={0}
        defaultValue={current?.description}
      />
      <VStack width="100%" paddingTop="50px" paddingBottom="50px">
        <HStack
          display="flex"
          flexDir={{ base: "column", xl: "row-reverse" }}
          alignItems={{ base: "center", xl: "flex-start" }}
          width="100%"
          justifyContent={{ xl: "flex-end" }}
        >
          <Box
            position="relative"
            width="300px"
            height="350px"
            transform={{ xl: "translateX(200px)" }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              alt={"detail"}
              src={
                map[getSlugs().gender as keyof typeof map][
                  getSlugs().section as keyof typeof map.male &
                    keyof typeof map.female
                ]
              }
            />
            {getImageMap()}
          </Box>
          <Box
            display="flex"
            flexDir="column"
            alignItems="stretch"
            paddingTop={"30px"}
            width={{ base: "90%", md: "100%", xl: "500px", "2xl": "600px" }}
          >
            {loading || error ? (
              <Box padding={"20px"}>
                {loading && "Loading..."}
                {error && error}
              </Box>
            ) : (
              list.map((item) => (
                <Box
                  key={item.id}
                  padding={"10px"}
                  background={"#fcfafa"}
                  borderRadius="10px"
                  boxShadow="-2px 3px 5px silver"
                  transition="250ms ease-out"
                  border="2px solid transparent"
                  _hover={{ borderColor: "#2895ff" }}
                  cursor="pointer"
                  margin="10px 0"
                  onClick={() => openModal(item)}
                >
                  {item.name}
                </Box>
              ))
            )}
          </Box>
          <Modal {...disclosureProps} onClose={disclosureProps.onClose}>
            <ModalOverlay />
            <ModalContent
              paddingBlock="20px"
              paddingInline="25px"
              width={{ base: "90%" }}
              background="white"
              marginTop={"200px"}
              transform="translate(-50%, -50%)"
              minWidth={{ md: "600px" }}
              borderRadius="20px"
            >
              <ModalHeader padding={"0"} />
              <ModalCloseButton />
              <ModalBody
                minHeight="250px"
                display="flex"
                flexDir="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {current && (
                  <>
                    <Box>
                      <Text fontSize={"28px"} color="#1f3f93" fontWeight="bold">
                        {current.name}
                      </Text>
                      <Text marginTop="10px" fontSize="14px">
                        {current.description}
                      </Text>
                    </Box>
                    <Box padding="30px 0">
                      <Text
                        fontSize={"18px"}
                        color="#1f3f93"
                        fontWeight="bold"
                        textTransform="capitalize"
                        marginBottom="10px"
                      >
                        <FormattedMessage id="affSentence" />
                      </Text>
                      <Box padding="0 30px" color="gray.400">
                        {current.affirmations?.map(
                          (affirmation: string, index: number) => (
                            <Text key={index} padding="5px 0">
                              {affirmation}
                            </Text>
                          )
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box
                        onClick={() =>
                          copyToClipboad(current.affirmations?.join("\n") || "")
                        }
                      >
                        <Box
                          padding="20px"
                          borderRadius="5px"
                          background="#f2eded"
                          cursor="pointer"
                          transition="250ms ease-out"
                          _hover={{ background: "#e0dcdc" }}
                        >
                          <FaCopy size={24} color="#2895ff" />
                        </Box>
                        <Text
                          color="#c9c3c3"
                          textAlign="center"
                          cursor="pointer"
                        >
                          <FormattedMessage id="copy" />
                        </Text>
                      </Box>
                      <Box onClick={goToIntention}>
                        <Box
                          padding="20px"
                          borderRadius="5px"
                          background="#f2eded"
                          cursor="pointer"
                          transition="250ms ease-out"
                          _hover={{ background: "#e0dcdc" }}
                          margin={"0 30px"}
                        >
                          <FaUpload size={24} color="#2895ff" />
                        </Box>
                        <Text
                          color="#c9c3c3"
                          textAlign="center"
                          cursor="pointer"
                        >
                          <FormattedMessage id="sendIntention" />
                        </Text>
                      </Box>
                      <Box style={{ position:"relative", left:"-30px" }} onClick={goToSubliminal}>
                        <Box
                          padding="20px"
                          borderRadius="5px"
                          background="#f2eded"
                          cursor="pointer"
                          transition="250ms ease-out"
                          _hover={{ background: "#e0dcdc" }}
                          margin={"0 30px"}
                        >
                          <FaUpload size={24} color="#2895ff" />
                        </Box>
                        <Text
                          color="#c9c3c3"
                          textAlign="center"
                          cursor="pointer"
                        >
                          <FormattedMessage id="Subliminal" />
                        </Text>
                      </Box>
                    </Box>
                  </>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <Calibration />
          <CalibrationModal
            children={
              <Button
                onClick={() => {
                  calibrationFunc && calibrationFunc();
                  setOpen(false);
                }}
                color={"white"}
                width={
                  localStorage.getItem("locale") === "ar" ? "168px" : "176px"
                }
                height={"40px"}
                background={"#2895ff"}
                fontFamily={"tajwal"}
                fontSize={"16px"}
                fontWeight={"normal"}
                mt={"20px"}
                rightIcon={
                  <AiOutlinePlayCircle
                    fontSize={"24px"}
                    style={
                      localStorage.getItem("locale") === "ar"
                        ? { marginRight: "1rem" }
                        : { marginLeft: "1px" }
                    }
                  />
                }
              >
                <FormattedMessage
                  id="calibration.start"
                  defaultMessage={"بدء المعايرة"}
                />
              </Button>
            }
            open={open}
            modalClose={() => setOpen(false)}
          />
        </HStack>
      </VStack>
    </BaseView>
  );
};

export default Detail;
