/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import BaseView from "../BaseView";
import usePlanApi from "./usePlanApi";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  useDisclosure,
  useToast,
  Text,
  Editable,
  EditablePreview,
  EditableInput,
  IconButton,
  Container,
  VStack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { valuesBg } from "src/assets/icons";
import { PlanAspect, PlanAspectGoal, PlanGoalAction } from "src/types";
import GoalActionsTable from "./containers/GoalActionsTable";
import NewItemCard from "src/components/NewItemCard/NewItemCard";
import { RiDeleteBin6Line } from "react-icons/ri";
import PlanDetailsIcons from "./containers/PlanDetailsIcons";
import Filters from "./containers/Filters";
import { FiltersContext } from "./containers/FiltersContext";
import useLocale from "src/providers/useLocale";
import { useLocation } from "react-router-dom";
import EditMenu from "./containers/EditButton";
import PlanName from "./containers/planName";
import { useAppContext } from "../../../Providers";
import { useSelector } from "src/store";
import { MdDragIndicator } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

// import Modal from "src/components/Modal";
import SharingModal from "src/components/SharingModal/SharingModal";
import AccessPermission from "../../../components/AccessPermission/AccessPermission";
import { useQueryClient } from "react-query";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PieStatistics from "./PieStatistics";
import { actionsToPieStatistics } from "./converters/actionsToPieStatistics";

import { BarStatistics } from "./BarStatistics";
import { actionsToBarStatistics } from "./converters/actionsToBarStatistics";
import { getAllActions } from "./converters/planToActions";
import { checkPoints } from "./converters/checkPoints";
import { checkAnyActions } from "./converters/checkAnyActions";

const PlanContent = (props) => {
  const user = useSelector((state) => state.auth.user);
  const context = useAppContext();
  useEffect(() => {
    context.background?.set("#fff");
  });
  const editAspectDisclosure = useDisclosure();
  const createGoalDisclosure = useDisclosure();
  const createActionDisclosure = useDisclosure();
  const goalDetailsDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentPlan = {
    id: props.match.params.id,
    name: "",
  };
  const { formatMessage } = useIntl();
  const {
    planQuery,
    deleteGoalMutation,
    deleteAspectMutation,
    editGoalMutation,
    editAspectMutation,
    deletePlanMutation,
    sharedListQuery,
    reorderAspectsMutation,
    reorderAspectGoalsMutation,
  } = usePlanApi(props.match.params.id);
  const { endDate, excutor, startDate } = useContext(FiltersContext);
  const [locale] = useLocale();
  // new item forms
  const [showNewActionForm, setShowNewActionForm] = useState(false);

  // Temp values for onChange events
  const [goalName, setGoalName] = useState("");
  const [newAspectName, setNewAspectName] = useState("");
  const { state: sharedPlanProps, pathname } = useLocation<any>();
  const permission = sharedListQuery.data?.find(
    (plan) => plan?.id === sharedPlanProps?.shareId
  )?.permission;
  // console.log(permission)
  // if no permission then can edit (bug happened because permission was undefined)
  const isReadOnlyAccess = permission === "read";
  // Related to bottom icons
  const [textToCopy, setTextToCopy] = useState("");

  const [allActionsCount, setAllActionsCount] = useState<PlanGoalAction[]>([]);
  const {
    isOpen: isSharingModalOpen,
    onClose: onSharingModalClose,
    onOpen: onSharingModalOpen,
  } = useDisclosure();

  const openCreateAction = () => {
    createActionDisclosure.onOpen();
  };

  const openEditAspect = () => {
    editAspectDisclosure.onOpen();
  };

  const openGoalDetails = () => {
    goalDetailsDisclosure.onOpen();
  };

  const toast = useToast();

  const removeGoal = async (goalId: number) => {
    try {
      await deleteGoalMutation(goalId);
      refetchData();
      toast({
        status: "success",
        title: formatMessage({ id: "goal.delete.success" }),
      });
    } catch (error) {
      toast({
        status: "error",
        title: formatMessage({ id: "goal.delete.failed" }),
      });
    }
  };

  const removeAspect = async (aspectId: number) => {
    try {
      await deleteAspectMutation(aspectId);
      refetchData();
      toast({
        status: "success",
        title: formatMessage({ id: "aspect.delete.success" }),
      });
    } catch (error) {
      toast({
        status: "error",
        title: formatMessage({ id: "aspect.delete.failed" }),
      });
    }
  };

  const openCreateGoal = () => {
    createGoalDisclosure.onOpen();
  };

  const editGoalName = async (goal: PlanAspectGoal) => {
    try {
      await editGoalMutation({ goal });
      toast({
        status: "success",
        title: formatMessage({ id: "goal.edit.name.success" }),
      });
    } catch (error) {
      toast({
        status: "error",
        title: formatMessage({ id: "goal.edit.name.failed" }),
      });
    }
  };

  const editAspectName = async (aspect: PlanAspect, name: string) => {
    try {
      await editAspectMutation({ aspect: { name, id: aspect.id, goals: [] } });
      toast({
        status: "success",
        title: formatMessage({ id: "aspect.edit.name.success" }),
      });
    } catch (error) {
      toast({
        status: "error",
        title: formatMessage({ id: "aspect.edit.name.failed" }),
      });
    }
  };

  const deleteClickHandler = async (id: number) => {
    try {
      await deletePlanMutation(id);
      toast({
        title: formatMessage({ id: "plan.delete.success" }),
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "plan.delete.failed" }),
        description: error.message,
        status: "success",
        isClosable: true,
      });
    }
  };
  const refetchData = () => {
    planQuery.refetch();
  };
  React.useEffect(() => {
    let valuesString: any = [];
    let allActions: PlanGoalAction[] = [];
    valuesString.push(`${planQuery.data?.name}:-`);
    let isHeaderRenderdOnce = false; // to prevent printing the header for each table
    planQuery?.data?.aspects &&
      // eslint-disable-next-line array-callback-return
      planQuery.data.aspects.map((aspect, idx) => {
        valuesString.push(
          `${idx + 1}: ${formatMessage({ id: "purpose.create.aspect" })} ${
            aspect.name
          }`
        );
        valuesString.push(``);
        // eslint-disable-next-line array-callback-return
        aspect.goals.map((goal) => {
          valuesString.push(` ${formatMessage({ id: "goal" })} ${goal.name}`);
          if (!isHeaderRenderdOnce) {
            valuesString.push(
              `  #  ┃ ${formatMessage({ id: "measure" })} ┃ ${formatMessage({
                id: "plan.goal.action.create.quantity",
              })} ┃ ${formatMessage({
                id: "plan.goal.action.create.excutor",
              })} ┃${formatMessage({
                id: "action.startdate",
              })} ┃ ${formatMessage({
                id: "action.endDate",
              })} ┃ ${formatMessage({ id: "done" })}`
            );
            isHeaderRenderdOnce = true;
          }
          // eslint-disable-next-line array-callback-return
          goal.actions?.map((action) => {
            allActions.push(action);
            valuesString.push(
              ` # ${allActions.indexOf(action) + 1} ┃ ${action.name} ┃ ${
                action.quantity
              } ┃ ${action.executor} ┃ ${new Date(action.startDate)
                .toISOString()
                .slice(0, 10)} ┃ ${new Date(action.endDate)
                .toISOString()
                .slice(0, 10)} ┃ ${
                action.complete
                  ? formatMessage({ id: "copmleted" })
                  : formatMessage({ id: "notCompleted" })
              }  `
            );
          });
        });
        valuesString.push(``); // consider this line an empty line in the copied text
        valuesString.push(``);
      });
    setTextToCopy(valuesString.join("\n"));
    setAllActionsCount([...allActions]);
    return () => {
      setTextToCopy("");
    };
  }, [planQuery?.data, locale, pathname]);

  const queryClient = useQueryClient();

  const handleDropAspect = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    let updatedAspects = [...planQuery.data?.aspects];

    //Remove dragged item
    const [reorderedItem] = updatedAspects.splice(droppedItem.source.index, 1);

    // Add dropped item
    updatedAspects.splice(droppedItem.destination.index, 0, reorderedItem);

    //loop through updatedAspects and create array of aspects id
    const aspectsIds = updatedAspects.map((aspect) => aspect.id || -1);

    // Update order in db
    reorderAspectsMutation({
      aspects: aspectsIds,
      planId: planQuery.data?.id!,
    });

    // Update State
    queryClient.setQueryData(["plan", props.match.params.id], {
      ...planQuery.data,
      aspects: updatedAspects,
    });
  };

  const handleDropGoal = (droppedItem, aspectId) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    let updatedGoals = [
      ...planQuery.data?.aspects?.find((aspect) => aspect.id === aspectId)
        ?.goals,
    ];

    if (!updatedGoals || updatedGoals.length === 0) return;

    //Remove dragged item
    const [reorderedItem] = updatedGoals.splice(droppedItem.source.index, 1);

    // Add dropped item
    updatedGoals.splice(droppedItem.destination.index, 0, reorderedItem);

    //loop through updatedGoals and create array of goals id
    const goalsIds = updatedGoals.map((goal) => goal.id || -1);

    // Update order in db
    reorderAspectGoalsMutation({
      goals: goalsIds,
      aspectId,
    });

    // Update State
    queryClient.setQueryData(["plan", props.match.params.id], {
      ...planQuery.data,
      aspects: planQuery.data?.aspects?.map((aspect) => {
        if (aspect.id === aspectId) {
          return { ...aspect, goals: updatedGoals };
        }
        return aspect;
      }),
    });
  };

  const currentPlanJsx = (
    <Box className="change-direction-on-print">
      <Box>
        {sharedPlanProps ? (
          <Box>
            <div>
              <p>
                <span>{formatMessage({ id: "sharedBy" })}</span>{" "}
                <span>{sharedPlanProps.owner.name}</span>
              </p>
              <small>{sharedPlanProps.owner.email}</small>
            </div>
            <AccessPermission permission={permission} />
          </Box>
        ) : null}
      </Box>
      <Box position="relative" paddingY="4">
        <Box position="absolute">
          {sharedPlanProps ? (
            sharedPlanProps && sharedPlanProps.permission === "update" ? (
              <EditMenu onClick={onOpen} />
            ) : null
          ) : (
            <EditMenu onClick={onOpen} />
          )}
        </Box>
        <PlanName
          planId={props.match.params.id}
          isDisabled={isReadOnlyAccess}
        />
        {sharedPlanProps && user?.id === sharedPlanProps.owner.id ? (
          <IconButton
            position="absolute"
            top="2"
            right="4"
            color="#e97575"
            // visibility={planDeleteIconVisibilty}
            onClick={(e: any) => {
              e.stopPropagation();
              deleteClickHandler(currentPlan.id!);
              window.history.back();
            }}
            variant="link"
            aria-label="delete"
            fontSize="23px"
            minW={1}
            icon={<RiDeleteBin6Line className="remove-on-print" />}
            h="5"
          />
        ) : null}
      </Box>
      <Box textAlign="center">
        <Text>{user?.name}</Text>
      </Box>

      {planQuery.data && (
        <HStack
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          borderRadius="10px"
          overflow="scroll"
        >
          {checkAnyActions(planQuery.data) && (
            <PieStatistics
              data={actionsToPieStatistics(getAllActions(planQuery.data))}
            />
          )}

          {checkPoints(planQuery.data) && (
            <BarStatistics
              data={actionsToBarStatistics(getAllActions(planQuery.data))}
            />
          )}
        </HStack>
      )}

      <DragDropContext onDragEnd={handleDropAspect}>
        {
          //@ts-ignore
        }
        <Droppable droppableId="aspects-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {planQuery.data?.aspects
                ?.map((aspect) => {
                  let validGoals = aspect.goals
                    .map((goal) => {
                      let validActions = goal.actions?.filter((action) => {
                        let isValid: boolean[] = []; // no filters applied
                        // console.log('no Filter', !excutor&&!startDate&&!endDate )
                        if (!excutor && !startDate && !endDate) {
                          return true;
                        } else {
                          if (excutor) {
                            isValid.push(
                              action.executor
                                .trim()
                                .toLocaleLowerCase()
                                .includes(excutor.trim().toLocaleLowerCase())
                            );
                          }

                          if (startDate) {
                            // return start date
                            isValid.push(
                              new Date(action.startDate)
                                .toLocaleDateString("en-GB")
                                .slice(0, 10) ===
                                startDate
                                  ?.toLocaleDateString("en-GB")
                                  .slice(0, 10)
                            );
                            // console.log(startDate?.toLocaleDateString('en-GB').slice(0,10),new Date( action.startDate).toLocaleDateString('en-GB').slice(0,10))
                          }

                          if (endDate) {
                            // return end date
                            isValid.push(
                              new Date(action.endDate)
                                .toLocaleDateString("en-GB")
                                .slice(0, 10) ===
                                endDate
                                  ?.toLocaleDateString("en-GB")
                                  .slice(0, 10)
                            );
                            // console.log(new Date().getTimezoneOffset()) // is it ??
                          }
                          return isValid.every((value) => value) && action;
                        }
                      });
                      return validActions?.length
                        ? { ...goal, actions: validActions }
                        : { ...goal, actions: [] };
                    })
                    .filter((goal) => {
                      if (!excutor && !startDate && !endDate) {
                        return goal;
                      }
                      return goal.actions?.length;
                    });
                  // console.log('valid',validGoals)
                  return { ...aspect, goals: validGoals };
                })
                .filter((aspect) => {
                  if (!excutor && !startDate && !endDate) {
                    return aspect;
                  }
                  return aspect.goals.length;
                })
                .map((aspect, idx) => {
                  return (
                    <Draggable
                      key={aspect.id}
                      draggableId={`${aspect.id}`}
                      index={idx}
                    >
                      {(provided) => (
                        <div
                          className="item-container"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {
                            <Container maxWidth="1400px">
                              <Accordion
                                defaultIndex={[0]}
                                allowMultiple
                                my={2}
                                key={aspect.id}
                                __css={{ borderColor: "white" }}
                                mb="16"
                                borderRadius="10px"
                                marginBottom="2rem"
                              >
                                <AccordionItem borderRadius="10px">
                                  {({ isExpanded }) => (
                                    <>
                                      <AccordionButton
                                        as="div"
                                        borderRadius="10px"
                                        p={0}
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          width="100%"
                                          alignItems="center"
                                        >
                                          <div {...provided.dragHandleProps}>
                                            <MdDragIndicator size={24} />
                                          </div>

                                          <NewItemCard
                                            // py=""
                                            borderRadius="10px"
                                            bg="#FFF"
                                            py="2"
                                            boxShadow=""
                                            hoverStyles={false}
                                            title={`${formatMessage({
                                              id: "aspect",
                                            })}${idx + 1}:`}
                                            edit={() => openEditAspect()}
                                            remove={
                                              isReadOnlyAccess
                                                ? null
                                                : () => removeAspect(aspect.id!)
                                            }
                                            newItem={{
                                              text: formatMessage({
                                                id: "plan.aspect.goal.create",
                                              }),
                                              onClick: () => openCreateGoal(),
                                            }}
                                          >
                                            <Editable
                                              borderRadius="10px"
                                              defaultValue={aspect.name}
                                              isDisabled={isReadOnlyAccess}
                                              onChange={(value) => {
                                                setNewAspectName(value);
                                              }}
                                              onSubmit={() => {
                                                if (newAspectName.trim() === "")
                                                  return;
                                                editAspectName(
                                                  aspect,
                                                  newAspectName
                                                );
                                              }}
                                              color="#4A5568"
                                              fontSize="18"
                                              fontWeight="bold"
                                              placeholder="Add aspect name ..."
                                            >
                                              <EditablePreview />
                                              <EditableInput />
                                            </Editable>
                                          </NewItemCard>
                                          {isExpanded ? (
                                            <IoIosArrowUp
                                              cursor="pointer"
                                              size={30}
                                            />
                                          ) : (
                                            <IoIosArrowDown
                                              cursor="pointer"
                                              fontSize="12px"
                                              size={30}
                                            />
                                          )}
                                        </Box>
                                      </AccordionButton>
                                      <AccordionPanel p={0}>
                                        <DragDropContext
                                          onDragEnd={(e) => {
                                            handleDropGoal(e, aspect.id);
                                          }}
                                        >
                                          <Droppable droppableId="goals-container">
                                            {(provided) => (
                                              <div
                                                className="list-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {aspect.goals?.map(
                                                  (goal, idx) => (
                                                    <Draggable
                                                      key={goal.id}
                                                      draggableId={`${
                                                        goal.id || idx
                                                      }`}
                                                      index={idx}
                                                    >
                                                      {(provided) => (
                                                        <div
                                                          className="item-container"
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                        >
                                                          {
                                                            <Accordion
                                                              defaultIndex={[0]}
                                                              allowMultiple
                                                              key={goal.id}
                                                              m="3.5"
                                                              backgroundColor="#f0f0f0"
                                                              borderRadius="10px"
                                                              mb="8"
                                                              width="100%"
                                                              margin="10px 0"
                                                            >
                                                              <AccordionItem
                                                                borderRadius="10px"
                                                                bg="#f0f0f0"
                                                              >
                                                                {({
                                                                  isExpanded,
                                                                }) => (
                                                                  <>
                                                                    <AccordionButton
                                                                      as="div"
                                                                      bg="#f0f0f0"
                                                                      my={0}
                                                                      borderRadius="10px"
                                                                    >
                                                                      <Box
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        width="100%"
                                                                        alignItems="center"
                                                                      >
                                                                        <div
                                                                          {...provided.dragHandleProps}
                                                                        >
                                                                          <MdDragIndicator
                                                                            size={
                                                                              24
                                                                            }
                                                                          />
                                                                        </div>
                                                                        <NewItemCard
                                                                          counterText={`${
                                                                            locale ===
                                                                            "en"
                                                                              ? "Actions"
                                                                              : "إجراءات"
                                                                          } : ${
                                                                            goal
                                                                              .actions
                                                                              .length
                                                                          }`}
                                                                          bg="#f0f0f0"
                                                                          boxShadow=""
                                                                          hoverStyles={
                                                                            false
                                                                          }
                                                                          p="0"
                                                                          borderRadius="10px"
                                                                          // deleteIconVisibility={goalDeleteIconVisibility}
                                                                          title={`${formatMessage(
                                                                            {
                                                                              id: "goal",
                                                                            }
                                                                          )}${
                                                                            idx +
                                                                            1
                                                                          }:`}
                                                                          // title={formatMessage({id: 'goal'})}
                                                                          newItem={{
                                                                            onClick:
                                                                              () =>
                                                                                setShowNewActionForm(
                                                                                  !showNewActionForm
                                                                                ),
                                                                            text: formatMessage(
                                                                              {
                                                                                id: "plan.aspect.measure.create",
                                                                              }
                                                                            ),
                                                                          }}
                                                                          key={
                                                                            goal.id
                                                                          }
                                                                          edit={() =>
                                                                            openGoalDetails()
                                                                          }
                                                                          remove={
                                                                            isReadOnlyAccess
                                                                              ? null
                                                                              : () =>
                                                                                  removeGoal(
                                                                                    goal.id!
                                                                                  )
                                                                          }
                                                                          shadow="none"
                                                                        >
                                                                          <Editable
                                                                            defaultValue={
                                                                              goal.name
                                                                            }
                                                                            onChange={(
                                                                              value
                                                                            ) => {
                                                                              setGoalName(
                                                                                value
                                                                              );
                                                                            }}
                                                                            isDisabled={
                                                                              isReadOnlyAccess
                                                                            }
                                                                            onSubmit={() => {
                                                                              if (
                                                                                goalName.trim() ===
                                                                                ""
                                                                              )
                                                                                return;
                                                                              editGoalName(
                                                                                {
                                                                                  ...goal,
                                                                                  name: goalName,
                                                                                }
                                                                              );
                                                                            }}
                                                                            color="#4A5568"
                                                                            fontSize="18"
                                                                            placeholder="Add goal name ..."
                                                                          >
                                                                            <EditablePreview />
                                                                            <EditableInput />
                                                                          </Editable>
                                                                        </NewItemCard>

                                                                        {isExpanded ? (
                                                                          <IoIosArrowUp
                                                                            cursor="pointer"
                                                                            size={
                                                                              30
                                                                            }
                                                                          />
                                                                        ) : (
                                                                          <IoIosArrowDown
                                                                            cursor="pointer"
                                                                            fontSize="12px"
                                                                            size={
                                                                              30
                                                                            }
                                                                          />
                                                                        )}
                                                                      </Box>
                                                                    </AccordionButton>
                                                                    <AccordionPanel
                                                                      px="3.5"
                                                                      //overflow={["initial", "initial", "initial"]}
                                                                      //overflow="scroll"
                                                                      width="100%"
                                                                      maxWidth="100%"
                                                                    >
                                                                      <VStack width="100%">
                                                                        <Box
                                                                          width="100%"
                                                                          as="div"
                                                                          overflow="scroll"
                                                                        >
                                                                          <GoalActionsTable
                                                                            reload={
                                                                              refetchData
                                                                            }
                                                                            allActionsCount={
                                                                              allActionsCount
                                                                            }
                                                                            planId={
                                                                              currentPlan.id!
                                                                            }
                                                                            goal={
                                                                              goal
                                                                            }
                                                                            aspect={
                                                                              aspect
                                                                            }
                                                                            actions={
                                                                              goal.actions
                                                                            }
                                                                            isDisabled={
                                                                              isReadOnlyAccess
                                                                            }
                                                                            openDetails={() => {
                                                                              openCreateAction();
                                                                            }}
                                                                          />
                                                                        </Box>
                                                                      </VStack>
                                                                    </AccordionPanel>
                                                                  </>
                                                                )}
                                                              </AccordionItem>
                                                            </Accordion>
                                                          }
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </AccordionPanel>
                                    </>
                                  )}
                                </AccordionItem>
                              </Accordion>
                            </Container>
                          }
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}

          {}
          {/* {<NewActionForm goalId={30} planId={20} />} */}
        </Droppable>
      </DragDropContext>
    </Box>
  );

  const [isOpenAddWeight, setIsOpenAddWeight] = useState(false);

  return (
    <BaseView imageSrc={valuesBg}>
      <Box>
        <Box my="auto" mt="3.5">
          <Filters />
        </Box>

        {currentPlan.id !== 0 && !planQuery.isLoading ? (
          currentPlanJsx
        ) : (
          <Box
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="xl" />
          </Box>
        )}
        {currentPlan.id !== 0 && (
          <PlanDetailsIcons
            children={currentPlanJsx}
            isOpen={isOpen}
            onClose={onClose}
            startAgain={false}
            textToCopy={textToCopy}
            planId={currentPlan.id}
            reload={refetchData}
            canShare={!sharedPlanProps}
            shareCb={() => {
              onSharingModalOpen();
            }}
          />
        )}
      </Box>
      <SharingModal
        isOpen={isSharingModalOpen}
        onClose={onSharingModalClose}
        sharedEntity={{ name: "salplan", id: currentPlan.id }}
      />
    </BaseView>
  );
};

export default PlanContent;
