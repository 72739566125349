import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
} from "@chakra-ui/react";
import { MdCategory, MdOutlineTimelapse } from "react-icons/md";
import DOMPurify from "dompurify";
import { FormattedMessage, useIntl } from "react-intl";
import PureToneReviews from "./PureToneReviews";

const ENTITY_NAME = "pure_tones";

interface DescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  title: string;
  category: string;
  duration: string;
  description: string;
}

const PureToneInfoModal: React.FC<DescriptionModalProps> = ({
  isOpen,
  onClose,
  id,
  title,
  category,
  duration,
  description,
}) => {
  const intl = useIntl();

  const createMarkup = useCallback((text: string) => {
    return {
      __html:
        DOMPurify.sanitize(text).replace(/href/g, `target="_blank" href`) ||
        " ",
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text>{title}</Text>
            <Flex gap={2} alignItems="center">
              <MdCategory size="18px" />
              <Text fontSize={14} fontWeight="semibold">
                {category}
              </Text>
              <MdOutlineTimelapse size="18px" />
              <Text fontSize={14} fontWeight="semibold">
                {duration === "00:00" ? (
                  <FormattedMessage id={"loading"} />
                ) : (
                  duration
                )}
              </Text>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>
                {intl.formatMessage({ id: "salmagic.description.title" })}
              </Tab>
              <Tab>{intl.formatMessage({ id: "salmagic.reviews.title" })}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {description?.length > 0 ? (
                  <Box
                    dangerouslySetInnerHTML={createMarkup(description)}
                    style={{ padding: "20px", whiteSpace: "pre-wrap" }}
                    boxSize="fit-content"
                  />
                ) : (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={"/description_placeholder.png"}
                      w={"10rem"}
                      h={"9rem"}
                    />
                    <Text>
                      {intl.formatMessage({ id: "salmagic.no_description" })}
                    </Text>
                  </Flex>
                )}
              </TabPanel>
              <TabPanel>
                <PureToneReviews entityId={id} entityName={ENTITY_NAME} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PureToneInfoModal;
