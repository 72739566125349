import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import {
  CreateEditDisclosureProps,
  DetailsDisclosureProps,
} from "../Missions/useUIState";

type UseUIStateReturn = {
  createEditProps: CreateEditDisclosureProps;
  detailsProps: DetailsDisclosureProps;
  topWishesProps: DetailsDisclosureProps;
  allWishesProps: DetailsDisclosureProps;
  currentId?: number;
  setCurrentId: (id?: number) => void;
  isEdit?: boolean;
  // setIsEdit: (isEdit?: boolean) => void;
};
export const useUIState: () => UseUIStateReturn = () => {
  const [isEdit, setIsEdit] = useState<boolean>();
  const [currentId, setCurrentId] = useState<number>();
  const createEditDisclosure = useDisclosure();
  const topWishesDisclosure = useDisclosure();
  const allWishesDisclosure = useDisclosure();
  const visionDetailsDisclosure = useDisclosure();
  const createEditOnOpen = (isEdit?: boolean, id?: number) => {
    setIsEdit(isEdit);
    setCurrentId(id);
    createEditDisclosure.onOpen();
  };
  const visionDetailsOnOpen = (id: number) => {
    setCurrentId(id);
    visionDetailsDisclosure.onOpen();
  };
  const topWishesOnOpen = (id: number) => {
    setIsEdit(true);
    setCurrentId(id);
    topWishesDisclosure.onOpen();
  };
  const allWishesOnOpen = (id: number) => {
    setCurrentId(id);
    allWishesDisclosure.onOpen();
  };
  const onClose = (cb: () => void) => {
    return () => {
      setCurrentId(undefined);
      setIsEdit(false);
      cb();
    };
  };
  return {
    createEditProps: {
      ...createEditDisclosure,
      onOpen: createEditOnOpen,
      onClose: onClose(createEditDisclosure.onClose),
    },
    detailsProps: {
      ...visionDetailsDisclosure,
      onOpen: visionDetailsOnOpen,
      onClose: onClose(visionDetailsDisclosure.onClose),
    },
    topWishesProps: {
      ...topWishesDisclosure,
      onOpen: topWishesOnOpen,
      onClose: onClose(topWishesDisclosure.onClose),
    },
    allWishesProps: {
      ...allWishesDisclosure,
      onOpen: allWishesOnOpen,
      onClose: onClose(allWishesDisclosure.onClose),
    },
    isEdit,
    setIsEdit,
    currentId,
    setCurrentId,
  };
};
