import { Box, Button, Input } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { FiltersContext } from "./FiltersContext";
import ActionDatePicker from "./ActionDatePicker";
// type filterprops ={
//         values:{
//             excutor?:string,
//             startDate?:Date,
//             endDate?:Date,
//             setExcutor?:React.Dispatch<React.SetStateAction<string>>;
//             setStartDate?:React.Dispatch<React.SetStateAction<Date>>;
//             setEndDate?:React.Dispatch<React.SetStateAction<Date>>;
//         }
// }

const Filters = () => {
  const { formatMessage } = useIntl();
  const {
    endDate,
    excutor,
    startDate,
    setEndDate,
    setExcutor,
    setStartDate,
    resetFilter,
  } = useContext(FiltersContext);
  // const [reload,setReload] = useState<boolean>(false) //to trigger reload for the filter only
  // useEffect(() => {
  //         console.log('reload',reload)

  // }, [reload])
  // const [startDate,setStartDate] = useState<Date>(new Date());
  // const [endDate,setEndDate] = useState<Date>(new Date());
  // const [executorFilterValue,setExecutorFilterValue] = useState<string>("")

  return (
    <Box
      d="flex"
      flexDir="row"
      alignItems="baseline"
      pb="5"
      minW="100%"
      flexWrap="wrap"
    >
      <Box mx="1em" />
      {/* <Checkbox isChecked={isOnlyMe} onChange={(e)=>{setIsOnlyMe(e.target.checked)}}>Only me</Checkbox> */}
      <Box>
        <p style={{ textTransform: "capitalize" }}>Filter</p>
        <Input
          borderRadius="5px"
          p="1"
          pl="2"
          bg="#f0f0f0"
          minW={"180px"}
          maxW={"200px"}
          variant="unstyled"
          placeholder={formatMessage({
            id: "plan.goal.action.create.excutor.search",
          })}
          value={excutor}
          onChange={(e) => {
            setExcutor!(e.target.value);
          }}
        />
      </Box>
      {/* <Box mx="1em">
            <p style={{textTransform:"capitalize"}}>{formatMessage({id:"action.startdate"})}</p>
        <ReactDatePicker
        disabled={isFilterEnabled}
        onSelect={(date)=>{
            // console.log(date)
            setStartDate!(date)
        }}
        selected={new Date()}
        onChange={(date, e) => {
            console.log(date, e);

        }}
        customInput={
          <InputGroup>
            <Input
              style={{textAlign:'center', height:"31px",}}

              variant="flushed"
              // minW="65px"
              color="#8b8b8b"
              w="80px"
              // dateFormat="MM-DD-YYYY"
              value={new Date(startDate!).toLocaleDateString()}
              //   {...inputProps}
              />
          </InputGroup>
        }
        />
        </Box>
        <Box mx="1em">
        <p style={{textTransform:"capitalize"}}>{formatMessage({id:"action.endDate"})}</p>
        <ReactDatePicker

        onSelect={(date)=>{
            // console.log(date)
            setEndDate!(date)
        }}
        selected={new Date()}
        onChange={(date, e) => {
            console.log(date, e);

        }}
        customInput={
          <InputGroup>
            <Input
              style={{textAlign:'center', height:"31px"}}
              variant="flushed"
              // minW="65px"
              color="#8b8b8b"
              w="80px"
              // dateFormat="MM-DD-YYYY"
              value={new Date(endDate!).toLocaleDateString()}
              //   {...inputProps}
              isDisabled={true}
              />
          </InputGroup>
        }
        />
        </Box> */}
      {/* <Box  mx="10px" bg="#f0f0f0" borderRadius="10px" px=".6em">
            <p>{formatMessage({id:"action.startdate"})}</p>
            <input type="date" style={{background:"#f0f0f0"}} onChange={(e)=>{setStartDate!(new Date (e.target.value))}} value={startDate?startDate.toISOString().slice(0,10):"" } />
        </Box>
        <Box  mx="10px" bg="#f0f0f0" borderRadius="10px" px=".6em">
            <p>{formatMessage({id:"action.endDate"})}</p>
            <input type="date" style={{background:"#f0f0f0"}} onChange={(e)=>{setEndDate!(new Date ( e.target.value ))}}  value={endDate?endDate.toISOString().slice(0,10):"" }/>
        </Box> */}

      <Box position="relative" m="2">
        <p>{formatMessage({ id: "action.startdate" })}</p>
        <ActionDatePicker
          // onSelect={submitEditAction}
          onChange={(date) => {
            if (setStartDate && date !== null) {
              setStartDate(date as Date);
            }
          }}
          value={startDate ? startDate : undefined}
          inputProps={{
            placeholder: "تاريخ البدء",
          }}
        />
      </Box>
      <Box position="relative" m="2">
        <p>{formatMessage({ id: "action.endDate" })}</p>
        <ActionDatePicker
          onChange={(date) => {
            if (setEndDate && date !== null) {
              setEndDate(date as Date);
            }
          }}
          value={endDate ? endDate : undefined}
          inputProps={{
            placeholder: "تاريخ الانتهاء",
          }}
        />
      </Box>
      <Box>
        <Button
          onClick={() => {
            resetFilter!();
            // setReload(reload=>!reload)
          }}
          textTransform="capitalize"
          size="md"
          mx="1.6em"
        >
          {"reset"}
        </Button>
      </Box>

      {/* <Checkbox isChecked={isToday} onChange={(e)=>{setIsToday(e.target.checked)}}>{formatMessage({id:'today'})}</Checkbox> */}
    </Box>
  );
};

// filters hook

export default Filters;
