import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { QueryObserverResult } from "react-query";
import { Purpose } from "src/types";
import ProgressBar from "./ProgressBar";
import StepFour, { StepFourProps } from "./StepFour";
import StepOne, { StepOneProps } from "./StepOne";
import StepThree, { StepThreeProps } from "./StepThree";
import StepTwo, { StepTwoProps } from "./StepTwo";
import { useEditPurpose } from "./useEditPurpose";

type PurposesEditProps = {
  disclosureProps: Omit<UseDisclosureReturn, "onOpen"> & {
    onOpen: (id: number) => void;
  };
  restoreFn: (id: number) => Promise<void>;
  isReset: number;
  purposeQuery: QueryObserverResult<Purpose, Error>;
  step1Props: StepOneProps;
  step2Props: StepTwoProps;
  step3Props: StepThreeProps;
  step4Props: StepFourProps;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
  setPurposeStep?: (id) => Promise<void>
};
const PurposeEditModal: React.FC<PurposesEditProps> = ({
  disclosureProps,
  purposeQuery,
  step1Props,
  step2Props,
  step3Props,
  step4Props,
  restoreFn,
  isReset,
  isSharedWithMe,
  canUpdate,
  setPurposeStep
}) => {
  const [editModalVisiblity, setEditModalVisiblity] = useState(true);
  const [step, setStep] = useState<number>(0);
  const [controlStepLocal, setControlStepLocal] = useState(false);
  const [canClose, setCanClose] = useState(false);
  // const editMu = useEditPurpose()

  useEffect(() => {
    if (purposeQuery.data?.content && purposeQuery.data?.step === 3)
      setCanClose(true);
    else setCanClose(false);
    // if (isReset === purposeQuery?.data?.id) setStep(0);
    if (
      purposeQuery &&
      purposeQuery.data &&
      !controlStepLocal &&
      disclosureProps.isOpen &&
      isReset !== purposeQuery?.data?.id
    ) {
      setStep(purposeQuery.data.step);
      setControlStepLocal(true);
    }
    if (purposeQuery.data && purposeQuery.data?.step !== step) {
      setStep(purposeQuery.data.step);
    }
  }, [purposeQuery, controlStepLocal, disclosureProps, isReset]);

  const nextStep = () => {
    step === 0 && step1Props && step1Props.nextStep().then(() => {
      controlStepLocal && setStep((step) => step! + 1)
    })
    if (step === 1) {
      step2Props.nextStep()
      controlStepLocal && setStep((step) => step! + 1)
    }
    if (step === 2) {
      step2Props.nextStep()
    }
    if (step === 3) {
      step2Props.nextStep()
    }
  };
  const body = ({ isSharedWithMe, canUpdate }) => {
    if (!purposeQuery.data) return <></>;
    const { data, isLoading, error } = purposeQuery;
    if (purposeQuery.isIdle) return <></>;
    if (isLoading) return "Loading...";
    if (error) return error.message;
    return step === 0 ? (
      <StepOne
        {...step1Props}
        aspects={data!.selectedAspects}
        setEditModalVisiblity={setEditModalVisiblity}
        nextLocalStep={nextStep}
        closeModal={disclosureProps.onClose}
      />
    ) : step === 1 ? (
      <StepTwo
        {...step2Props}
        nextLocalStep={nextStep}
        closeModal={disclosureProps.onClose}
      />
    ) : step === 2 ? (
      <StepThree
        {...step3Props}
        finishPurpose={() => controlStepLocal && setStep(3)}
      />
    ) : (
      <StepFour
        {...step4Props}
        closeModal={disclosureProps.onClose}
        isSharedWithMe={isSharedWithMe}
        canUpdate={canUpdate}
        restoreModal={async () => {
          if (purposeQuery?.data?.id) {
            await restoreFn(purposeQuery.data.id);
            disclosureProps.onOpen(purposeQuery.data.id)
            setStep(0);
          }
        }}
      />
    );
  };
  return (
    <Modal
      {...disclosureProps}
      onClose={() => {
        disclosureProps.onClose();
        setControlStepLocal(false);
        setStep(0);
      }}
      closeOnOverlayClick={canClose}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        px={["12px", "24px", "72px"]}
        py={"30px"}
        borderRadius="30px"
        maxW="50rem"
        visibility={editModalVisiblity ? "hidden" : "unset"}
        overflowY="auto"
      >
        <ModalHeader>
          {canClose && <ModalCloseButton onClick={disclosureProps.onClose} />}
          {!Boolean(isSharedWithMe) ?
            <ProgressBar step={step!} setStep={setPurposeStep} />
            : null}
        </ModalHeader>
        <ModalBody padding={"20px 0"}>{body({ isSharedWithMe, canUpdate })}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default PurposeEditModal;
