import {
  HStack,
  Text,
  VStack,
  UseDisclosureReturn,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import MainModal from "src/components/MainModal/MainModal";
import { useIntl } from "react-intl";
import { PlanAspectGoal, PlanGoalAction } from "src/types";
import TextArea from "src/components/Textarea";

type ViewActionProps = {
  aspectName?: string;
  goal?: PlanAspectGoal;
  modalDisclosure: UseDisclosureReturn;
  editActionValues?: PlanGoalAction;
  withSaveButton?: boolean;
  action: PlanGoalAction;
  onSubmit: (fieldName?: string, value?: any) => {};
};

const ViewAction: React.FC<ViewActionProps> = ({
  modalDisclosure,
  action,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const [comment, setComment] = useState<string>(action.note);

  return (
    <MainModal
      modalDisclosure={modalDisclosure}
      modalHeader={
        <VStack alignItems="start" spacing="0">
          <HStack></HStack>
          <HStack mb="15px">
            <Text
              fontSize="18px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              color="brand.900"
            >
              {formatMessage({ id: "comment" })}
            </Text>
          </HStack>
          <TextArea
            fontSize="18px"
            fontFamily="Calibri (Body)"
            variant="flushed"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              onSubmit("note", comment);
              modalDisclosure.onClose();
            }}
          >
            {formatMessage({ id: "save" })}
          </Button>
        </VStack>
      }
    ></MainModal>
  );
};

export default ViewAction;
