import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  useToast,
  Box,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  HStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PlanAspect, PlanAspectGoal, PlanGoalAction } from "src/types";
import usePlanApi from "../usePlanApi";
import ActionTableRow from "./ActionTableRow";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

type GoalActionsTableProps = {
  actions?: PlanGoalAction[];
  planId: number;
  goal: PlanAspectGoal;
  aspect: PlanAspect;
  openDetails?: (action: PlanGoalAction) => void;
  tableNo?: number;
  allActionsCount?: PlanGoalAction[];
  reload?: () => void;
  isDisabled?: boolean;
};

const GoalActionsTable: React.FC<GoalActionsTableProps> = ({
  actions,
  goal,
  planId,
  openDetails = () => {},
  allActionsCount,
  reload = () => {},
  isDisabled,
}) => {
  //state to hold actions
  const [actionsState, setActionsState] = useState<PlanGoalAction[]>(
    actions || []
  );

  //effect to update actions state when actions props change
  useEffect(() => {
    setActionsState(actions || []);
  }, [actions]);

  const { reorderGoalActionsMutation } = usePlanApi(planId);

  const handleDropAction = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let updatedList = [...actionsState];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    //create array of goals ids
    const actionsIds = updatedList.map((action) => action.id || -1);

    // update order in db
    reorderGoalActionsMutation({
      goalId: goal.id || -1,
      actions: actionsIds,
    });

    // Update State
    setActionsState(updatedList);
  };

  const { deletePlanAction, planQuery, addWeightMutation } = usePlanApi(planId);
  const toast = useToast();
  const { formatMessage } = useIntl();
  const remove = async (id: number) => {
    try {
      await deletePlanAction(id);
      reload();
      toast({
        title: formatMessage({ id: "plan.goal.action.deleted.success" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: "فشل حذف الإجراء",
        description: error.message,
        status: "error",
      });
    }
  };

  const getBackgroundColor = (action: PlanGoalAction) => {
    if (action.complete) return "#68D391"; //If task done ----- Dark Green
    if (new Date().getTime() > new Date(action.endDate).getTime())
      return "rgba(233, 117, 117, 0.6)"; //If End Date <= today ----- Red

    const diffInDays = Math.floor(
      (new Date(action.endDate).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24)
    );
    const diffInDaysStart = Math.floor(
      (new Date(action.startDate).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24)
    );

    if (diffInDays >= 0 && diffInDays <= 2) return "rgb(255,255,102)"; //If End Date <= today+3days ---- Yellow
    if (diffInDaysStart <= -1 && diffInDays >= -1) return "#C6F6D5"; // today is in range but there are more thane 3 days to the end date of the task
    return "";
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [importanceValue, setImportanceValue] = useState(1);
  const [timingValue, setTimingValue] = useState(1);
  const [activeActionId, setActiveActionId] = useState<number | undefined>(
    undefined
  );

  const handleAddWeight = async (e) => {
    e.preventDefault();
    try {
      await addWeightMutation({
        id: activeActionId!,
        weight: importanceValue * timingValue,
      });
      onClose();
      toast({
        title: formatMessage({ id: "Weight Added Successfully" }),
        status: "success",
      });
      reload();
    } catch (error) {
      toast({
        title: formatMessage({ id: "Error" }),
        description: error.message,
        status: "error",
      });
    }
  };

  const handleRemoveWeight = async (e) => {
    e.preventDefault();
    try {
      await addWeightMutation({
        id: activeActionId!,
        weight: 0,
      });
      onClose();
      toast({
        title: formatMessage({ id: "Weight Removed Successfully" }),
        status: "success",
      });
      reload();
    } catch (error) {
      toast({
        title: formatMessage({ id: "Error" }),
        description: error.message,
        status: "error",
      });
    }
  };

  return actions?.length ? (
    <Box as="div">
      <Table variant="simple" className="actions-table">
        <Thead className="actions-table-header">
          <Tr>
            <Th padding="3px">
              <Text textAlign="center" fontSize={16}>
                #
              </Text>
            </Th>
            <Th px={1} fontFamily="Calibri, 'Gill Sans'" width="fit-content">
              <Text fontSize={16} textTransform="capitalize">
                <FormattedMessage id="measure" />
              </Text>
            </Th>
            <Th px={1} fontFamily="Calibri, 'Gill Sans'" width="fit-content">
              <Text fontSize={16} textTransform="capitalize" textAlign="center">
                <FormattedMessage id="weight" />
              </Text>
            </Th>
            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            >
              <Text fontSize={16}>
                <FormattedMessage id="plan.goal.action.create.quantity" />
              </Text>
            </Th>
            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            >
              <Text fontSize={16}>
                <FormattedMessage id="plan.goal.action.create.excutor" />
              </Text>
            </Th>
            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            >
              <Text fontSize={16}>
                <FormattedMessage id="action.startdate" />
              </Text>
            </Th>
            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            >
              <Text fontSize={16}>
                <FormattedMessage id="action.endDate" />
              </Text>
            </Th>
            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            >
              <Text fontSize={16} px="2">
                <FormattedMessage id="done" />
              </Text>
            </Th>

            <Th
              px={1}
              textAlign="center"
              textTransform="capitalize"
              fontFamily="Calibri, 'Gill Sans'"
            ></Th>
          </Tr>
        </Thead>

        <DragDropContext onDragEnd={handleDropAction}>
          <Droppable droppableId="table-body">
            {(provided) => (
              <Tbody
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {actionsState.map((action, index) => (
                  <Draggable
                    key={action.id}
                    draggableId={`${action.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <ActionTableRow
                        reload={planQuery.refetch}
                        index={
                          allActionsCount
                            ? allActionsCount.indexOf(action) + 1
                            : undefined
                        } //for view  only
                        planId={planId}
                        isDisabled={isDisabled}
                        onRemove={() => remove(action.id!)}
                        key={action.id}
                        action={action}
                        onEdit={() => openDetails(action)}
                        provided={provided}
                        openWeightModal={onOpen}
                        setActiveActionId={() => {
                          setActiveActionId(action.id);
                        }}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="Add Weight" />
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleAddWeight}>
            <ModalBody>
              <FormControl>
                <HStack>
                  <Box>
                    <FormLabel>
                      <FormattedMessage id="Importance Value" />
                    </FormLabel>
                    <Select
                      value={importanceValue}
                      placeholder={formatMessage({
                        id: "Select Importance Value",
                      })}
                      onChange={(e) => {
                        setImportanceValue(Number(e.target.value));
                      }}
                    >
                      <option value={1}>
                        {formatMessage({
                          id: "Normal",
                        })}
                      </option>
                      <option value={2}>
                        {formatMessage({
                          id: "Important",
                        })}
                      </option>
                      <option value={3}>
                        {formatMessage({
                          id: "Very Important",
                        })}
                      </option>
                    </Select>
                  </Box>
                  <Box>
                    <FormLabel>
                      <FormattedMessage id="Timing Value" />
                    </FormLabel>
                    <Select
                      value={timingValue}
                      placeholder={formatMessage({ id: "Select Timing Value" })}
                      onChange={(e) => {
                        setTimingValue(Number(e.target.value));
                      }}
                    >
                      <option value={1}>
                        {formatMessage({
                          id: "Done within one day",
                        })}
                      </option>
                      <option value={2}>
                        {formatMessage({
                          id: "Done within 3 days",
                        })}
                      </option>
                      <option value={3}>
                        {formatMessage({
                          id: "Done within a week",
                        })}
                      </option>
                      <option value={4}>
                        {formatMessage({
                          id: "Done within 2 weeks",
                        })}
                      </option>
                    </Select>
                  </Box>
                </HStack>
              </FormControl>
            </ModalBody>

            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button colorScheme="blue" type="submit">
                <FormattedMessage id="Add Weight" />
              </Button>
              <Button colorScheme="red" onClick={handleRemoveWeight}>
                <FormattedMessage id="Remove Weight" />
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  onClose();
                }}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  ) : (
    <></>
  );
};

export default GoalActionsTable;
