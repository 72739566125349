import React, { useEffect, useState } from "react";
import ItemCard from "src/components/ItemCard";
import BaseView from "../BaseView";
import MissionsCreate from "./MissionsCreate";
import MissionDetails from "./MissionDetails";
import { useMission } from "./useMission";
import { useMissionApi } from "./useMissionApi";
import { useUIState } from "./useUIState";
import { valuesBg } from "src/assets/icons";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useAppContext } from "../../../Providers";
import SharingModal from "src/components/SharingModal/SharingModal";

const Missions = () => {
  const context = useAppContext();
  const { isOpen: isSharingModalOpen, onClose: onSharingModalClose, onOpen: onSharingModalOpen } = useDisclosure();
  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" })
  const onShare: any = ({ id, name }) => {
    onSharingModalOpen()
    setCurrentList({ id, name })
  }

  useEffect(() => {
    context.background?.set("#fff");
  });
  const { missionListQuery, setCurrentMission, sharedMissionListQuery, ...missionApi } =
    useMissionApi();
  const { createEditDisclosure, detailsDisclosure } = useUIState({
    setCurrentMission,
  });
  const missionState = useMission({
    api: missionApi,
    ui: { createEditDisclosure, detailsDisclosure },
  });

  const itemOnClickHandler = (id: number) => {
    detailsDisclosure.onOpen(id);
  };
  const editOnClickHandler = (id: number) => {
    createEditDisclosure.onOpen(true, id);
  };

  const deleteClickHandler = async (id: number) => {
    try {
      await missionApi.deleteMission(id);
      detailsDisclosure.onClose();
    } catch { }
  };
  return (
    <BaseView imageSrc={valuesBg}>
      <MissionsCreate
        disclosureProps={createEditDisclosure}
        missionProps={missionState}
      />
      <MissionDetails
        disclosureProps={detailsDisclosure}
        missionQuery={missionApi.currentMissionQuery}
        resort={editOnClickHandler}
        remove={deleteClickHandler}
        isSharedWithMe={Boolean(currentList.shared)}
        sharingDetails={currentList.shared}
        canDelete={!Boolean(currentList.shared)}
        canRestart={!Boolean(currentList.shared) || (Boolean(currentList.shared) && Boolean(currentList.shared.permission === "update"))}
      />

      {/* List rendering */}
      {missionListQuery.isLoading || sharedMissionListQuery.isLoading ? (
        <div>Loading....</div>
      ) : missionListQuery.isError || sharedMissionListQuery.isError ? (
        missionListQuery.error?.message || sharedMissionListQuery.error?.message
      ) : (
        <Box d="flex" flexDir={"column-reverse"}>
          {missionListQuery.data?.concat(sharedMissionListQuery.data || []).sort((item1, item2) => Number(item1.createdAt) - Number(item2.createdAt)).map((mission) => (
            <ItemCard
              onClick={(e) => { e.stopPropagation(); setCurrentList(mission); itemOnClickHandler(mission.id!) }}
              key={mission.id}
              mb={5}
              resort={() => editOnClickHandler(mission.id!)}
              remove={() => {
                deleteClickHandler(mission.id!);
              }}
              share={() => onShare({ id: mission.id, name: mission.name })}
              // douplicate={() => { console.log('doublicate mission') }}
              style={{
                width: "85vw",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
              isSharedWithMe={Boolean(mission.shared)}
              permission={mission.shared?.permission}
            >
              <Box style={{ display: "block", maxWidth: "75vw" }}>
                <p>{mission.name}</p> 
              </Box>
            </ItemCard>
          ))}
        </Box>
      )
      }
      <SharingModal isOpen={isSharingModalOpen} onClose={onSharingModalClose} sharedEntity={{ name: "mission", id: currentList.id }} showOptions={true} />
    </BaseView >
  );
};
export default Missions;
