import React, { useReducer, useRef, useState, useCallback } from "react";
import ReactPlayer from "react-player";
import {
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import getReadableTime from "src/helpers/getReadableTime";
import { FaVolumeHigh } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";

interface SalMagicVideoPlayerProps {
  src: string | undefined;
  loop?: boolean;
  disableSeeking?: boolean;
  muted?: boolean;
  noControls?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  autoPlay?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const SalMagicVideoPlayer: React.FC<SalMagicVideoPlayerProps> = ({
  src,
  loop = false,
  disableSeeking = false,
  muted = false,
  noControls = false,
  onEnded,
  onPlay,
  onPause,
  autoPlay = false,
  isOpen,
  onClose,
}) => {
  const [isPlay, togglePlay] = useReducer(
    (state, next) => (next == null ? !state : next),
    autoPlay
  );
  const [volume, setVolume] = useState(muted ? 0 : 1);
  const [error, setError] = useState<string | null>(null);
  const ref = useRef<ReactPlayer>(null);
  const handle = useFullScreenHandle();
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const handlePlay = useCallback(() => {
    try {
      if (!isPlay) togglePlay(true);
      onPlay && onPlay();
    } catch (err) {
      setError("An error occurred while playing the video.");
    }
  }, [isPlay, onPlay]);

  const handlePause = useCallback(() => {
    try {
      if (isPlay) togglePlay(false);
      onPause && onPause();
    } catch (err) {
      setError("An error occurred while pausing the video.");
    }
  }, [isPlay, onPause]);

  const handleProgress = useCallback(({ playedSeconds }) => {
    setProgress(playedSeconds);
  }, []);

  const handleEnded = useCallback(() => {
    try {
      onEnded && onEnded();
      togglePlay(false);
      setTimeout(() => {
        setProgress(duration);
      }, 1000);
    } catch (err) {
      setError("An error occurred when the video ended.");
    }
  }, [duration, onEnded]);

  const handleVolumeChange = useCallback((value) => {
    setVolume(value / 100);
  }, []);

  const handleSeekChange = useCallback(
    (value) => {
      try {
        const newTime = (value / 100) * duration;
        ref.current?.seekTo(newTime);
        setProgress(newTime);
      } catch (err) {
        setError("An error occurred while seeking.");
      }
    },
    [duration]
  );

  const handlePlayerError = useCallback((e: any) => {
    setError("An error occurred while loading the video.");
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="salmagic.demo.title" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={0}>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FullScreen handle={handle}>
            <ReactPlayer
              onPlay={handlePlay}
              onPause={handlePause}
              onDuration={setDuration}
              onProgress={handleProgress}
              onError={handlePlayerError}
              width={{ base: "100%", md: "100%" }}
              height="50vh"
              ref={ref}
              style={{
                padding: "0 2px",
                backgroundColor: "black",
                pointerEvents: disableSeeking ? "none" : "auto",
              }}
              volume={volume}
              url={`${src}?loop=${loop ? 1 : 0}`}
              loop={loop}
              playing={isPlay}
              controls={false}
              onEnded={handleEnded}
            />
          </FullScreen>
        </ModalBody>

        {!noControls && (
          <ModalFooter dir="ltr">
            <Flex
              width="100%"
              gap={15}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                onClick={() => togglePlay(!isPlay)}
                backgroundColor="transparent"
              >
                {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
              </Button>
              <Text color={handle.active ? "white" : "black"}>
                {getReadableTime(progress)}
              </Text>
              <Slider
                aria-label="audio"
                value={(progress / duration) * 100}
                pointerEvents={disableSeeking ? "none" : "auto"}
                onChange={handleSeekChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  backgroundColor={handle.active ? "white" : "red"}
                />
              </Slider>
              <Text color={handle.active ? "white" : "black"}>
                {getReadableTime(duration)}
              </Text>
              <FaVolumeHigh size={"2rem"} />
              <Slider
                width="20%"
                aria-label="volume"
                value={volume * 100}
                onChange={handleVolumeChange}
                marginEnd="10px"
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  backgroundColor={handle.active ? "white" : "black"}
                />
              </Slider>
              <Button
                backgroundColor="transparent"
                alignSelf="start"
                onClick={() => {
                  if (handle.active) {
                    handle.exit().catch(() => null);
                  } else {
                    handle.enter().catch(() => null);
                  }
                }}
              >
                {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SalMagicVideoPlayer;
