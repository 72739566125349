import { Font } from "@react-pdf/renderer";
import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { login, finishLoading } from "./store/auth";
import { get } from "./api";
import store from "src/store";
import { load as loadCalibration } from "./store/calibration";
import { UserType } from "./types";

const DroidFont = require("./fonts/droid.ttf").default;
Font.register({ family: "Droid", src: DroidFont });
window.confirm = (params) => true; // this to disable all confirm messages in the app
export const validateToken = async () => {
  const token = window.localStorage.getItem("__sal_auth");
  if (token) {
    const response: UserType = await get("/user/me", undefined, token);
    if (response?.id) {
      const redirectUrl = response.subscription?.id
        ? window.location.pathname + window.location.search
        : "/subscription";
      store.dispatch(login({ user: { ...response, accessToken: token } }));
      if (redirectUrl && window.location.pathname !== redirectUrl) {
        // window.location.replace(redirectUrl);
      }
      if (navigator.serviceWorker.controller) {
        const message = window.localStorage.getItem("__sal_auth");
        navigator.serviceWorker.controller.postMessage({
          type: "fromPage",
          message: message,
        });
      }
    } else {
      // window.localStorage.removeItem("__sal_auth");
      // window.location.replace('/login');
    }
  } else {
    store.dispatch(finishLoading());
  }
};
if (typeof window !== "undefined") {
  //validate token before initializing react for faster loading time
  validateToken();
  store.dispatch(loadCalibration());
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Service Worker - trigger install event
// window.addEventListener("beforeinstallprompt", (e: any) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//   e.prompt();
//   e.userChoice.then(() => { });
// });
reportWebVitals();
