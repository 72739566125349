import React from "react";
import { Box, Button, HStack, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import playImage from "./play.svg";
import stopImage from "./stop.svg";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia } from "src/store/media";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";

const SubliminalAudio = ({ audioList }) => {
  // const id = useSelector((state) => state.media.id);
  // const dispatch = useDispatch();
  // const closeMedia = () => dispatch(stopMedia());
  // const handlePlay = (source: string, id: number) => {
  //   dispatch(
  //     playMedia({
  //       source: source,
  //       name: "subliminal",
  //       type: "audio",
  //       id: `${id + 1}`,
  //       loop: true,
  //     })
  //   );
  // };
  // const handleStop = () => {
  //   closeMedia();
  // };

  // OLD AUDIO IMPLEMENTATION (IT WAS STATIC)
  // const audios: Record<string, { name: string; src: string }[]> = {
  //   'en': [
  //     { name: formatMessage({ id: 'subliminal.audio.4' }), src: `${BASE_MEDIA}/media/flowsubliminal.mp3` },
  //     { name: formatMessage({ id: 'subliminal.audio.1' }), src: `${BASE_MEDIA}/media/10yearssubliminal.mp3` },
  //     { name: formatMessage({ id: 'subliminal.audio.2' }), src: `${BASE_MEDIA}/media/facesubliminal.mp3` },
  //     { name: formatMessage({ id: 'subliminal.audio.3' }), src: `${BASE_MEDIA}/media/subliminalsleep.mp3` },
  //   ],
  //   'ar': [
  //     { name: formatMessage({ id: 'subliminal.audio.ar' }), src: `https://saladmin.smartsway.com:3000/static/subliminal_ar.mp3` },
  //   ]
  // }
  // {name: formatMessage({id: 'subliminal.audio.3'}), src: `${BASE_MEDIA}/media/subliminalsleep.mp3`},

  // const [locale] = useLocale();

  const {pathname} = useLocation();

  const { formatMessage, locale } = useIntl();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      
      <Box
        display={"flex"}
        justifyContent={["center", "center", "center"]}
        alignItems={["center"]}
        width="100%"
        flexDir="column"
        flexWrap={"wrap"}
      >      
      <Button style={{ position: "relative", width: "fit-content" }} onClick={onOpen}>
        {formatMessage({ id: "use-subliminal" })}
      </Button>
      <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          allowPinchZoom
          size="full"
          blockScrollOnMount={false}
        >
          <ModalContent>
            <ModalHeader />
            <ModalBody boxSizing="border-box">
              <ModalCloseButton color="black" bg="white" mt="50" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="Solfeggio description"
                  src={
                    require(`src/assets/images/how-use-subliminal-${locale}.webp`).default
                  }
                  style={{ width: "100%", height: "100vh", objectFit: "contain" }}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Box
          display={"flex"}
          justifyContent={["center", "center", "center"]}
          width="100%"
          flexDir="row"
          flexWrap={"wrap"}
        >
          {audioList.map(
            ({ name, id, imageURL }, idx) =>
              name && (
                <Box
                  // margin={{
                  //   base: "10px 10px 30px 10px !important",
                  //   lg: "15px 15px !important",
                  //   xl: "30px 30px !important",
                  // }}
                  margin={["20px 10px", "20px 15px", "20px 15px"]}
                  width={"150px"}     
                  height={"fit-content"}
                >
                  <Link
                    to={
                        `${pathname}/audio/${id}`
                    }
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                      }}
                      src={imageURL}
                      alt={"img"}
                    />
                  </Link>
                  <Box d="flex" alignItems={"center"} justifyContent={"center"} px="4px">
                      <Text as={'b'} fontSize="md">{name}</Text>
                  </Box>
                </Box>
                // <Box
                //   bg={"#f9f9f9"}
                //   boxShadow={"0 3px 6px 0 rgb(0 0 0 / 16%)"}
                //   borderRadius={"10px"}
                //   p={"0px 16px"}
                //   // maxH={'46px'}
                //   my={"1rem"}
                //   style={{ minHeight: 46 }}
                // >
                //   <HStack
                //     align={"center"}
                //     justify={"space-between"}
                //     style={{ minHeight: 44 }}
                //   >
                //     <Text>{name}</Text>
                //     <HStack>
                //       {id !== idx + 1 && (
                //         <Button
                //           pr={"0"}
                //           _hover={{ bg: "transparent" }}
                //           _focus={{ bg: "transparent" }}
                //           _active={{ bg: "transparent" }}
                //           maxH={"16.8px"}
                //           bg={"transparent"}
                //           onClick={() => handlePlay(url, idx)}
                //         >
                //           <img src={playImage} alt={"Play audio"} />
                //         </Button>
                //       )}
                //       <Button
                //         pl={"0"}
                //         _hover={{ bg: "transparent" }}
                //         _focus={{ bg: "transparent" }}
                //         _active={{ bg: "transparent" }}
                //         maxH={"17px"}
                //         bg={"transparent"}
                //         onClick={handleStop}
                //       >
                //         <img src={stopImage} alt={"Stop audio"} />
                //       </Button>
                //     </HStack>
                //   </HStack>
                // </Box>
              )
          )}
        </Box>
        {/* {isPlay &&
            <OrderedList position={'absolute'} bottom={'0'} right={'30%vw'} textAlign={'left'} my={'2rem'}>
              <ListItem color={'#101010	'} my={'5px'}>لغة عربيه</ListItem>
              <ListItem color={'#101010'} my={'5px'}>Lorem ipsum dolor sit amet</ListItem>
              <ListItem color={'#101010'} my={'5px'}>Lorem ipsum dolor sit amet</ListItem>
              <ListItem color={'#101010'} my={'5px'}>Lorem ipsum dolor sit amet</ListItem>
            </OrderedList>
          } */}
      </Box>
    </>
  );
};

export default SubliminalAudio;