import React from "react";
import { VideoType } from "./Videos";
import styles from "./videos.module.css";
import ReactPlayer from "react-player";
import VideoDescriptionModal from "./Modal";
import { IconButton, useDisclosure } from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
interface VideoProps {
  video: VideoType;
}
const Video = ({ video }: VideoProps) => {
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();
  const { url, text, html, title, lecturerName } = video;
  if (!url) {
    return null;
  }

  return (
    <>
      <div className={styles.videoContainer}>
        <ReactPlayer
          preload="none"
          key={video.id}
          className="react-player fixed-bottom"
          url={video.url}
          controls
          width="100%"
          height="100%"
          style={{ maxWidth: "50%", maxHeight: "100px" }}
        />
        <div className={styles.videoContent}>
          <div className={styles.videoTitleContainer}>
            <h2 className={styles.videoTitle}>{title}</h2>
            <BsInfoCircle
              size={"1rem"}
              style={{ marginTop: "5px" }}
              cursor={"pointer"}
              color="gray"
              onClick={onInfoModalOpen}
              className="infoIcon"
            />
          </div>
          <div>
            <p className={styles.videoLecturer}>{lecturerName}</p>
          </div>
          <p className={styles.videoDescription}>{text}</p>
        </div>
      </div>
      <VideoDescriptionModal
        isOpen={isInfoModalOpen}
        onClose={onInfoModalClose}
        title={title}
        description={html}
        lecturerName={lecturerName}
      />
    </>
  );
};

export default Video;
