import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

const Card: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      w="100%"
      px={4}
      py={3}
      borderRadius={"10px"}
      bg="gray.100"
      boxShadow="0 3px 6px 0 rgba(0,0,0,0.16);"
      {...props}
    >
      {children}
    </Box>
  );
};
export default Card;
