import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { affirmationBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";
import CreateProblem from "./CreateProblem";
import ItemCard from "src/components/ItemCard";
import { getList } from "src/api/affirmation/problem/query";
import ViewProblem from "./ViewProblem";
import { ProblemList } from "src/types";
import * as mutation from "src/api/affirmation/problem/mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
const Problem: React.FC = ({ children }) => {
  const client = useQueryClient();
  const { data } = useQuery<ProblemList, Error, ProblemList>(...getList);
  const { mutateAsync: deleteMutation } = useMutation(mutation.deleteProblem, {
    onSuccess: () => client.invalidateQueries("listProblem"),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [problemName, setProblemName] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | string>(new Date());
  const [firstStep, setFirstStep] = useState<string>("");

  const { formatDate } = useIntl();

  const openModalView = (...args: any) => {
    setProblemName(args[0]);
    setGoal(args[1]);
    setFirstStep(args[2]);
    setStartDate(formatDate(args[3]));
    setOpen(true);
  };

  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />
      <CreateProblem />

      <>
        {data?.map((problem: any, idx: any) => {
          return (
            <ItemCard
              view={() =>
                openModalView(
                  problem.name,
                  problem.goal,
                  problem.firstStep,
                  problem.startDate
                )
              }
              key={problem.id}
              mb={5}
              // onClick={() => {}}

              remove={() => deleteMutation(problem.id)}
            >
              {problem.name}
            </ItemCard>
          );
        })}
      </>
      <ViewProblem
        open={open}
        close={() => setOpen(false)}
        problemName={problemName}
        goal={goal}
        startDate={startDate}
        firstStep={firstStep}
      />
    </Box>
  );
};

export default Problem;
