import Content from "../layout/Content";
import React from "react";
import DarkHeader from "src/layout/DarkHeader";

const IndexContent: React.FC = () => {
  return (
    <>
      <DarkHeader />
      <div
        style={{
          display: "flex",
          minHeight: "calc(100vh - 56px)",
          flexDirection: "column",
        }}
      >
        <Content />
      </div>
    </>
  );
};

export default IndexContent;
