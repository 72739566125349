import React, { useState, useRef, ChangeEvent } from "react";
import { userType } from "src/providers/AuthProvider";
import {
  Box,
  Text,
  Avatar,
  CircularProgress,
  useToast,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import RoundedBtn from "src/components/RoundedCameraBtn/RoundedCameraBtn";
import { useDispatch, useSelector } from "src/store";
import { uploadFile, put, BASE_MEDIA } from "src/api/index";
import Editable from "src/components/Editable/Editable";
import PasswordChange from "./PasswordChange";

type UserSettingsProps = {
  user: userType;
  update: (data: Partial<userType>) => void;
};

const UserSettings: React.FC<UserSettingsProps> = () => {
  //disclosures for password change modal
  const disclosures = useDisclosure();
  const user = useSelector((state) => state.auth.user);

  //uploading value for profile picture
  const [uploading, setUploading] = useState<number>(0);

  //intl formatting messages
  const { formatMessage } = useIntl();

  //toasting success and error messages
  const toast = useToast();

  //function to handle input change
  const handleFileChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];
      if (!file) return;
      const successCallback = async (url: string) => {
        const response: userType = await put(
          "/user/me",
          { picture: url },
          undefined,
          user.accessToken
        );
        if (response?.id) {
          toast({
            status: "success",
            title: formatMessage({ id: "saved" }),
            duration: 4000,
          });
          update({ picture: url });
        } else
          toast({
            status: "error",
            title: formatMessage({ id: "wentWrong" }),
            duration: 4000,
          });
      };
      const failureCallback = () => {
        toast({
          status: "success",
          title: formatMessage({ id: "saved" }),
          duration: 4000,
        });
      };
      uploadFile(
        file,
        setUploading,
        successCallback,
        failureCallback,
        user.accessToken
      );
    }
  };

  //file input ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  //values the user can edit in his profile
  const editables = [
    {
      name: "name",
      value: user.name,
      styles: { fontWeight: "bold" } as React.CSSProperties,
      type: "text",
    },
    { name: "email", value: user.email, type: "email" },
    {
      name: formatMessage({ id: "changePassword" }),
      value: formatMessage({ id: "changePassword" }),
      openModal: disclosures.onOpen,
      type: "password",
    },
    { name: "phone", value: user.phone, type: "text" },
    { name: "country", value: user.country?.name, type: "select" },
  ];

  return (
    <Box textAlign={{ base: "center", lg: "start" }}>
      <Text color="brand.900" fontWeight="bold" fontSize={36}>
        <FormattedMessage id="settings" />
      </Text>
      <Box
        marginTop="30px"
        d="flex"
        flexDir={{ base: "column", lg: "row" }}
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Box pos="relative" d="flex" flexDir="column" alignItems="center">
          {uploading !== 0 && (
            <CircularProgress
              margin="0 auto 30px auto"
              value={uploading}
              color="brand.900"
              trackColor="gray.200"
              size={10}
            />
          )}
          <Avatar
            src={`${BASE_MEDIA}/${user.picture}`}
            size={"2xl"}
            cursor="pointer"
            onClick={() => fileInputRef.current?.click()}
          />
          <Box
            pos="absolute"
            left="50%"
            transform="translateX(-50%)"
            bottom="-10px"
            onClick={() => fileInputRef.current?.click()}
          >
            <RoundedBtn
              styles={{ position: "static", top: "unset", right: "unset" }}
            />
          </Box>
          <input
            style={{ display: "none", pointerEvents: "none" }}
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
        <Box
          d="flex"
          alignItems={{ base: "center", lg: "flex-start" }}
          flexDir="column"
          marginTop={{ base: "30px", lg: 0 }}
          marginInline={{ lg: "20px" }}
        >
          {editables.map((editable) => {
            const { value, styles, name, type } = editable;
            if (type === "password") return null;
            if (type === "email")
              return (
                <Editable
                  key={editable.name}
                  {...editable}
                  endpoint="/user/me"
                />
              );
            return (
              <Box d="flex" alignItems="center" margin="5px 0">
                {
                  <Input
                    variant={"unstyled"}
                    my="2"
                    type={type}
                    value={value}
                    required
                    name={name}
                    style={styles}
                    minWidth={{ lg: "200px" }}
                    dir="auto"
                    textAlign="left"
                  />
                }

                {/* <IconButton type="submit" color="brand.900" margin="0 5px" icon={<FaSave />} size={"sm"} variant="ghost" aria-label="icon-button" disabled={processing}/> */}
              </Box>
            );
          })}
          {editables.map((editable) =>
            editable.type === "password" ? (
              <Editable key={editable.name} {...editable} endpoint="/user/me" />
            ) : null
          )}
        </Box>
      </Box>
      <PasswordChange disclosures={disclosures} user={user} />
    </Box>
  );
};

export default UserSettings;
