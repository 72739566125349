import React from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import { useGetProtection } from "src/newApi/hooks";
import Videos from "src/components/Videos";

const Personal = () => {
  const { isLoading, error, data: videos } = useGetProtection();
  const { formatMessage } = useIntl();
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />
      {isLoading ? (
        <Center minHeight={"80vh"}>
          <Spinner size="xl" />
        </Center>
      ) : error || videos === undefined || videos === null ? (
        formatMessage({ id: "fetchError" })
      ) : (
        <Videos
          videos={videos.sort((a, b) => a.id - b.id)}
          center={false}
          responsive={true}
        />
      )}
    </Box>
  );
};
export default Personal;
