import React from "react";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import Link from "src/components/Link/Link";
import { FormattedMessage } from "react-intl";
import { Box, HStack } from "@chakra-ui/react";
import { MAGIC_ROUTE, PROBLEM_ROUTE, STORY_ROUTE } from "src/routes";
const CreatePossibility = () => {
  return (
    <Box width="90vmin">
      <BaseView imageSrc={affirmationBg} />
      <HStack mt={"5rem"} justify={"center"} width={"100%"}>
        <Link to={STORY_ROUTE} borderRadius={15} py={1} isSecondary>
          <FormattedMessage id="newstory" />
        </Link>
        <Link my={1} to={MAGIC_ROUTE} borderRadius={15} py={1} isSecondary>
          <FormattedMessage id="transfigurationmagic" />
        </Link>
        <Link my={1} to={PROBLEM_ROUTE} borderRadius={15} py={1} isSecondary>
          <FormattedMessage id="problemsolving" />
        </Link>
      </HStack>
    </Box>
  );
};

export default CreatePossibility;
