import { HStack, Box, IconButton, Text, useToast } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AiOutlineReload, AiFillPrinter, AiOutlineShareAlt } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { useIntl, FormattedMessage } from "react-intl";
import { useReactToPrint } from "react-to-print";
import { emailMePlan } from "src/api/index";
import useLocale from "src/providers/useLocale";
import EditPlanModal from "./EditPlan";
import ExcelFile from "./ExcelPlan";
import { FiltersContext } from "./FiltersContext";

const PlanDetailsIcons = (props: any) => {
  const { children, startAgain, planId, reload, shareCb } = props;
  const { endDate, excutor, startDate } = useContext(FiltersContext);
  const intl = useIntl();
  const [locale] = useLocale();
  const getPrintComp = () => {
    return children;
  };

  const componentRef = React.useRef<any>(getPrintComp());

  const handleDocBeforePrint = () => {
    const tables = document.querySelectorAll(
      ".actions-table"
    ) as NodeListOf<HTMLElement>;

    tables.forEach((table, idx) => {
      if (idx + 1 > Math.ceil(tables.length / 2)) {
        table.style.direction = locale === "ar" ? "rtl" : "ltr";
      }
    });

    const fields = document.querySelectorAll(".chakra-editable__preview");
    fields.forEach((field) => {
      if (
        [
          "Add action name ...",
          "Add quantity ...",
          "Add executor ...",
        ].includes(field.innerHTML)
      ) {
      }
    });

    const PlanDoc: any = document.querySelectorAll(
      ".change-direction-on-print"
    );

    const PlanDocIndex = Math.trunc(PlanDoc.length / 2);

    for (let i = PlanDoc.length - 1; i >= PlanDocIndex; i--) {
      PlanDoc[i].style.direction = locale === "ar" ? "rtl" : "ltr";
    }
  };

  const handlePrint = useReactToPrint({
    onBeforeGetContent: handleDocBeforePrint,
    content: () => componentRef.current,
    onAfterPrint: () => { },
  });

  const toast = useToast();
  const handleEmail = async () => {
    const response: any = await emailMePlan(planId, {
      startDate,
      endDate,
      executor: excutor,
      timezoneOffset: new Date().getTimezoneOffset() / -60,
    });
    if (response?.message) {
      toast({
        title: intl.formatMessage({ id: "emailSuccess" }),
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: intl.formatMessage({ id: "wentWrong" }),
        status: "error",
        duration: 2000,
      });
    }
  };

  return (
    <Box width={"100%"} mb="40">
      <HStack
        alignItems="baseline"
        justifyContent={["center"]}
        margin="0 auto"
        // width={["260px", "fit-content"]}
        wrap="wrap"
      >
        {startAgain && (
          <Box d="flex" flexDir="column" alignItems="center" py={1}>
            <IconButton
              bg={"#e6e6e6"}
              fontSize="30px"
              w="70px"
              h="70px"
              aria-label="print"
              color="brand.800"
              icon={<AiOutlineReload />}
              text={intl.formatMessage({ id: "salplan.resort" })}
              onClick={() => {
                props.detailDisclosure?.onClose();
                props.onEdit();
              }}
            />
            <Text mt={"10px"} fontSize={"18px"} color="gray.900">
              <FormattedMessage
                id="salplan.resort"
                defaultMessage={"ابدء من جديد"}
              />
            </Text>
          </Box>
        )}
        <Box d="flex" flexDir="column" alignItems="center" py={1}></Box>
        {props.canShare ? <Box d="flex" flexDir="column" alignItems="center" py={1}>
          <IconButton
            bg={"#e6e6e6"}
            fontSize="30px"
            w="70px"
            h="70px"
            aria-label={intl.formatMessage({ id: "share", defaultMessage: "مشاركة" })}
            color="brand.800"
            icon={<AiOutlineShareAlt />}
            text={intl.formatMessage({ id: "share", defaultMessage: "مشاركة" })}
            onClick={async () => {
              await componentRef.current;
              shareCb();
            }}
          />
          <Text mt={"10px"} fontSize={"18px"} color="gray.900">
            <FormattedMessage id={"share"} defaultMessage={"مشاركة"} />
          </Text>
        </Box> : null}
        <Box d="flex" flexDir="column" alignItems="center" py={1}>
          <IconButton
            bg={"#e6e6e6"}
            fontSize="30px"
            w="70px"
            h="70px"
            aria-label="print"
            color="brand.800"
            icon={<AiFillPrinter />}
            text={intl.formatMessage({ id: "print", defaultMessage: "طباعة" })}
            onClick={async () => {
              await componentRef.current;
              handlePrint!();
            }}
          />
          <Text mt={"10px"} fontSize={"18px"} color="gray.900">
            <FormattedMessage id={"print"} defaultMessage={"طباعة"} />
          </Text>
        </Box>
        <Box d="flex" flexDir="column" alignItems="center" py={1}>
          <IconButton
            bg={"#e6e6e6"}
            fontSize="30px"
            w="70px"
            h="70px"
            aria-label="print"
            color="brand.800"
            icon={<MdEmail />}
            text="Email"
            onClick={handleEmail}
          />
          <Text mt={"10px"} fontSize={"18px"} color="gray.900">
            EMAIL
          </Text>
        </Box>
        <ExcelFile />
      </HStack>
      <Box
        display="none"
        overflow={"scroll"}
        position={"absolute"}
        top="0"
        left="0"
        zIndex={10000}
      >
        <div ref={componentRef}>{getPrintComp()}</div>
      </Box>
      <EditPlanModal
        isModalOpen={props.isOpen}
        onClose={props.onClose}
        reload={reload}
      />
    </Box>
  );
};

export default PlanDetailsIcons;
