import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
  VStack,
  Heading,
  HStack,
  Text,
} from "@chakra-ui/react";
import ProgressBar from "src/views/Prepration/Missions/ProgressBar";
import { FormattedMessage, useIntl } from "react-intl";
import TextLoop from "react-text-loop";
import Link from "src/components/Link/Link";
import TextArea from "src/components/Textarea";
import { MEDIA_URL } from "src/api";

const ViewGoalModal = (props: any) => {
  const intl = useIntl();
  const { open, closeModal, activities, problems, note } = props;
  const [stepState, setStepState] = useState<number>(0);
  const audioRef = React.useRef<any>(null);
  useEffect(() => {
    const audio = audioRef.current;
    open && audio.play();
    return () => {
      if (audio && audio.currentTime !== 0) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [open]);
  const modalCloseLocal = () => {
    closeModal();
    setStepState(0);
  };

  return (
    <>
      <audio ref={audioRef}>
        <source
          src={`${MEDIA_URL}/static/magicmanifest.mp3`}
          type="audio/mpeg"
        />
      </audio>
      <Modal isCentered size={"3xl"} isOpen={open} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent
          px={"10px"}
          maxWidth={"720px"}
          width={"100%"}
          borderRadius={"30px"}
        >
          <ModalCloseButton />
          <ModalBody width={"100%"}>
            <VStack maxW={"812px"} justifyContent={"center"} width={"100%"}>
              <Box width={"100%"} maxWidth={"440px"} textAlign={"right"}>
                <ProgressBar
                  mt={"40px"}
                  magic
                  step={stepState}
                  setStep={setStepState}
                />
              </Box>
              {stepState === 0 && (
                <Box width={"100%"} textAlign={"center"} mt={"74px"}>
                  <Heading width={"100%"} textStyle={"h5"}>
                    <FormattedMessage
                      id={"magic.imagin"}
                      defaultMessage={"تخيل المطلوب"}
                    />
                  </Heading>
                  <TextLoop interval={10000}>
                    <Heading
                      className={"blink_intention"}
                      width={"100%"}
                      my={"30px"}
                      fontWeight={"normal"}
                      color={"#2895ff"}
                      textStyle={"h2"}
                    >
                      <FormattedMessage
                        id={"magic.howdoesitlook"}
                        defaultMessage={"ما هو شكله؟"}
                      />
                    </Heading>
                    <Heading
                      width={"100%"}
                      className={"blink_intention"}
                      my={"30px"}
                      fontWeight={"normal"}
                      color={"#2895ff"}
                      textStyle={"h2"}
                    >
                      <FormattedMessage
                        id={"magic.looptext1"}
                        defaultMessage={"ما رائحته؟"}
                      />
                    </Heading>
                    <Heading
                      width={"100%"}
                      className={"blink_intention"}
                      my={"30px"}
                      fontWeight={"normal"}
                      color={"#2895ff"}
                      textStyle={"h2"}
                    >
                      <FormattedMessage
                        id={"magic.looptext2"}
                        defaultMessage={"كيف الشعور معه؟"}
                      />
                    </Heading>
                    <Heading
                      width={"100%"}
                      className={"blink_intention"}
                      my={"30px"}
                      fontWeight={"normal"}
                      color={"#2895ff"}
                      textStyle={"h2"}
                    >
                      <FormattedMessage
                        id={"magic.looptext3"}
                        defaultMessage={" ما ذوقه؟"}
                      />
                    </Heading>
                    <Heading
                      width={"100%"}
                      className={"blink_intention"}
                      my={"30px"}
                      fontWeight={"normal"}
                      color={"#2895ff"}
                      textStyle={"h2"}
                    >
                      <FormattedMessage
                        id={"magic.looptext4"}
                        defaultMessage={"كيف الصوت معه؟"}
                      />
                    </Heading>
                  </TextLoop>
                </Box>
              )}
              {stepState === 1 && (
                <Box width={"100%"} textAlign={"center"} mt={"74px"}>
                  <Heading
                    width={"100%"}
                    my={"30px"}
                    fontWeight={"normal"}
                    color={"#2895ff"}
                    textStyle={"h2"}
                  >
                    <FormattedMessage
                      id={"magic.feelinggood"}
                      defaultMessage={"أشعر بشعور جميل الأن!"}
                    />
                  </Heading>
                </Box>
              )}
              {stepState === 2 && (
                <Box textAlign={"left"} width={"100%"}>
                  {/* <Heading fontSize={'24px'} textStyle={'h2'} >
                    <FormattedMessage id={'magic.allowtransfiguration'} defaultMessage={'السماح بالتجلي'} />
                  </Heading> */}
                  <Heading fontSize={"24px"} textStyle={"h2"}>
                    <FormattedMessage
                      id={"magic.activities"}
                      defaultMessage={"الأنشطة "}
                    />
                  </Heading>
                  {/* <Heading mt={'24px'} textStyle={'h5'} >
                    <FormattedMessage id={'magic.writeenjoyableactivites'} defaultMessage={"أكتب أنشطة أو اعمالاً تقوم بها تجعلك فى حالة إستمتاع"} />
                  </Heading> */}
                  <TextArea
                    value={activities}
                    borderRadius={"20px"}
                    bg={"#e6e6e6"}
                    isDisabled={true}
                  />
                </Box>
              )}
              {stepState === 3 && (
                <>
                  <HStack justify={"space-between"} width={"75%"}>
                    <Text fontFamily={"tajwal"}>
                      {intl.formatMessage({ id: "magic.obstacles" })}
                    </Text>
                    <Text fontFamily={"tajwal"}>
                      {intl.formatMessage({ id: "magic.solutions" })}
                    </Text>
                  </HStack>

                  {problems?.map((problem: any, id: any) => {
                    return (
                      <HStack justify={"space-between"} width={"70%"}>
                        <Text color={"#8b8b8b"} fontFamily={"tajwal"}>
                          {problem.obstacle}
                        </Text>
                        <Box
                          display={"flex"}
                          bg={"#2895ff"}
                          color={"white"}
                          borderRadius={"50%"}
                        >
                          <Box width={"10px"} height={"16px"} />
                          {id + 1}
                          <Box width={"10px"} height={"16px"} />
                        </Box>
                        <Text color={"#8b8b8b"} fontFamily={"tajwal"}>
                          {problem.solution}
                        </Text>
                      </HStack>
                    );
                  })}
                </>
              )}
              {stepState === 4 && (
                <Box textAlign={"left"} width={"100%"}>
                  <Heading fontSize={"24px"} textStyle={"h2"}>
                    <FormattedMessage
                      id={"magic.notes"}
                      defaultMessage={"الملاحظات "}
                    />
                  </Heading>
                  {/* <Heading mt={'24px'} textStyle={'h5'} >
                    <FormattedMessage id={'magic.notesheader'} defaultMessage={"أكتب هنا بعض الملاحظات واترك هذا الهدف يتجلي وأحفظه فى الصندوق السحري"} />
                  </Heading> */}
                  <TextArea
                    value={note}
                    borderRadius={"20px"}
                    bg={"#e6e6e6"}
                    isDisabled={true}
                  />
                </Box>
              )}
              <div>
                <Link
                  mb={"33px"}
                  onClick={() =>
                    stepState === 4
                      ? modalCloseLocal()
                      : setStepState(stepState + 1)
                  }
                  asBtn={true}
                >
                  {stepState !== 4 ? (
                    <FormattedMessage id="next" defaultMessage={"التالى"} />
                  ) : (
                    <FormattedMessage id="exit" defaultMessage={"خروج"} />
                  )}
                </Link>
              </div>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewGoalModal;
