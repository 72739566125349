import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { PurposesTypes } from "./purposesTypes";

export const usePurposeUI = () => {
  const [currentId, setCurrentId] = useState<number>();
  const currentListTypeState = useState(PurposesTypes.PURPOSES);

  const createDisclosureProps = useDisclosure();

  const editDisclosureProps = useDisclosure();
  const editOnOpen = (id: number) => {
    setCurrentId(id);
    editDisclosureProps.onOpen();
  };

  const showDisclosureProps = useDisclosure();
  const showOnOpen = (id: number) => {
    setCurrentId(id);
    showDisclosureProps.onOpen();
  };
  return {
    currentId,
    setCurrentId,
    currentListTypeState,
    createDisclosureProps,
    editDisclosureProps: { ...editDisclosureProps, onOpen: editOnOpen },
    showDisclosureProps: { ...showDisclosureProps, onOpen: showOnOpen },
  };
};
