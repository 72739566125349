import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { Purpose, PurposeListItem } from "src/types";

const getList: [string, QueryFunction<PurposeListItem[]>] = [
  "listPurpose",
  () => get<PurposeListItem[]>("/message"),
];

const getSharedList: [string, QueryFunction<PurposeListItem[]>] = [
  "sharedListPurpose",
  () => get<PurposeListItem[]>("/shared/details?entityName=message"),
];

const getOne = (id: number | undefined) =>
  [
    ["purpose", id],
    () => get<Purpose>(`/message/${id}`),
    { enabled: !(id === undefined) },
  ] as [
    [string, number],
    QueryFunction<Purpose>,
    UseQueryOptions<Purpose, Error>
  ];

export { getList, getOne, getSharedList };
