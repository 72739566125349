import {
  Box,
  HStack,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "src/components/Modal";
import PrimaryButton from "src/components/PrimaryButton";
import { FormattedMessage } from "react-intl";
import DeleteModel from "src/components/DeleteModel";

type ValueItemProps = {
  value: string;
  remove?: () => void;
  edit?: (v: string) => void;
  canEdit?:boolean;
  isComplete?: boolean;
};
const ValueItem: React.FC<ValueItemProps & { idx: number }> = ({
  value,
  remove,
  edit,
  idx,
  isComplete,
  canEdit = true
}) => {
  const [editValue, setEditValue] = useState(value);
  const [disablebtn, setDisablebtn] = useState<boolean>(false);
  useEffect(() => {
    setEditValue(value);
  }, [value]);
  const editDiscloureProps = useDisclosure();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const inputElem = document.querySelector(
        `#input-edit-op-${idx}`
      )! as HTMLInputElement;
      if (e.code === "Enter" && document.activeElement === inputElem) {
        edit && editValue && edit(editValue);
        setDisablebtn(true);
        editDiscloureProps.onClose();
      }
    },
    [edit, editDiscloureProps, editValue, idx]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Box my='4px'>
      <Text d='inline-block'>{value}</Text>
      <Box d='inline-block' float='right'>
        <HStack>
          {edit ? (
            <Modal
              disclosureProps={editDiscloureProps}
              btnProps={{
                rightIcon: <BiEdit />,
                variant: "link",
                fontSize: "20px",
                color: "brand.800",
                disabled: !canEdit,
                minW: 1,
              }}
              bodyProps={{
                children: (
                  <Box mt={5}>
                    <Input
                      value={editValue}
                      onChange={(e) => {
                        if (editValue === "") {
                          setDisablebtn(false);
                          setEditValue(e.target.value);
                        } else {
                          setEditValue(e.target.value);
                        }
                      }}
                      id={`input-edit-op-${idx}`}
                    />
                    <Box d='flex' justifyContent='center' mt={5}>
                      <PrimaryButton
                        disabled={disablebtn}
                        onClick={() => {
                          edit(editValue);
                          editDiscloureProps.onClose();
                        }}>
                        <FormattedMessage id='edit' defaultMessage='تعديل' />
                      </PrimaryButton>
                    </Box>
                  </Box>
                ),
              }}
            />
          ) : null}
          {remove ? (
            <DeleteModel
              remove={remove}
            />
          ) : null}
        </HStack>
      </Box>
    </Box>
  );
};
export default ValueItem;
