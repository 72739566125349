import { deleteMethod, post, put } from "src/api";

// =====================================================

export const create: (slug: string) => Promise<{ id: number }> = (slug) =>
  post(
    "/poster",
    { slug, halign: "center", valign: "center" },
    undefined
  ) as unknown as Promise<{ id: number }>;

// =====================================================

export const deletePoster: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/poster/${id!}`, undefined);

// =====================================================

export const edit: (poster: any) => Promise<any> = (poster) => {
  return poster?.id
    ? put<any>(`/poster/${poster.id}`, poster, undefined)
    : Promise.reject(null);
};

// =====================================================

export const editGoal: (poster: any) => Promise<any> = (poster) => {
  return put<any>(`/poster/goal/${poster.id}`, poster, undefined);
};

export const duplicatePoster: (id: string) => Promise<any> = (id) => {
  return post<any>(`/poster/duplicate/${id}`, { posterId: id });
};
