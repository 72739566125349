import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Modal from "src/components/Modal/Modal";
import { FiPlus } from "react-icons/fi";
import ItemCard from "src/components/ItemCard";
import ValueCreate from "./ValueCreate";
import ValueSort from "./ValueSort";
import ValuesShow from "./ValuesShow";
import BaseView from "../BaseView";
import { ValuesList } from "src/types";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { edit, create, deleteValue, douplicateValue } from "src/api/prepration/values/mutation";
import { getList, getOne, getSharedList } from "src/api/prepration/values/query";
import VirtualTable from "./VirtualTable";
import SharingModal from "src/components/SharingModal/SharingModal";

const Values: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const client = useQueryClient();
  const [currentId, setCurrentId] = useState<number>();
  const [currentName, setCurrentName] = useState<string>();
  const [valuesList, setValuesList] = useState<ValuesList>();
  const [isShared, setIsShared] = useState(false);
  const [permission, setPermission] = useState();
  const [owner, setOwner] = useState()
  const createDisclosureProps = useDisclosure();
  const sortDisclosureProps = useDisclosure();
  const showDisclosureProps = useDisclosure();

  const { mutateAsync: editMutation } = useMutation(edit, {
    onSuccess: () => {
      client.refetchQueries(getList[0]);
    },
  });

  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => {
      client.refetchQueries(getList[0]);
    },
  });

  const { mutateAsync: deleteMutation } = useMutation(deleteValue, {
    onSuccess: () => {
      client.refetchQueries(getList[0]);
    },
  });

  const { mutateAsync: douplicateMutation } = useMutation(douplicateValue, {
    onSuccess: () => {
      client.refetchQueries(getList[0]);
    },
  });

  const { data: valuesListsList, isLoading: listLoading, error: listError } = useQuery(...getList);
  const { data: sharedValuesListsList, isLoading: sharedListLoading, error: sharedListError } = useQuery(...getSharedList);

  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { data: oneValuesList } = useQuery(...getOne(currentId));
  const { isOpen: isSharingModalOpen, onClose: onSharingModalClose, onOpen: onSharingModalOpen } = useDisclosure();
  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" })
  const onShare: any = ({ id, name }) => {
    onSharingModalOpen()
    setCurrentList({ id, name })
  }

  useEffect(() => {
    if (oneValuesList) {
      const sortedOne = { ...oneValuesList };
      sortedOne?.values?.sort((a, b) => a.order - b.order);
      setValuesList(sortedOne);
    }
  }, [oneValuesList]);

  const submit = async (submitValuesList: ValuesList, create = false) => {
    setIsComplete(true);
    sortDisclosureProps.onClose();
    setCurrentId(undefined);
    try {
      if (create) {
        const res = await createMutation(submitValuesList);
        setValuesList(res);
      } else {
        editMutation(submitValuesList);
      }
    } catch { }
  };
  return (
    <BaseView>
      <Modal
        disclosureProps={createDisclosureProps}
        btnProps={{
          mt: "2",
          mb: "4",
          children: formatMessage({
            id: "values.create",
            defaultMessage: "إضافة مصفوفة جديدة",
          }),
          color: "brand.800",
          variant: "link",
          rightIcon: <FiPlus />,
        }}
        contentProps={{
          maxWidth: "90vw",
          width: "580px",
        }}
        bodyProps={{
          children: (
            <ValueCreate
              key={locale + "-value-create"}
              isComplete={isComplete}
              onSubmit={(valuesList) => {
                setValuesList(valuesList);
                createDisclosureProps.onClose();
                submit(valuesList, true).then(() => {
                  setIsComplete(false);
                  sortDisclosureProps.onOpen();
                });
              }}
            />
          ),
        }}
      />
      <ValueSort
        disclosureProps={sortDisclosureProps}
        valuesList={valuesList}
        submit={submit}
      />
      <ValuesShow
        valuesList={valuesList}
        currentName={currentName}
        isShared={isShared}
        permission={permission}
        owner={owner}
        setValuesList={setValuesList}
        disclosureProps={showDisclosureProps}
        onResortClicked={() => {
          sortDisclosureProps.onOpen();
          showDisclosureProps.onClose();
        }}
        onDeleteClicked={() => {
          deleteMutation(currentId!);
          showDisclosureProps.onClose();
          sortDisclosureProps.onClose();
        }}
        onEditClicked={async (value: ValuesList) => {
          await editMutation(value);
        }}
      />
      <div>{
        valuesListsList &&
        sharedValuesListsList &&
        [...valuesListsList, ...sharedValuesListsList.map((item: any) => {
          return { ...item, isShared: true, permission: item.shared.permission, owner: item.shared.owner, }
        })].sort((item1, item2) => Number(item2.createdAt) - Number(item1.createdAt)).map((list) => {
          // console.log(list)
          return (
            <ItemCard
              key={list.id}
              onClick={() => {
                showDisclosureProps.onOpen();
                setCurrentId(list.id);
                setCurrentName(list.name);
                setIsShared(list.isShared)
                setPermission(list.permission)
                setOwner(list.owner)
              }}
              resort={() => {
                setCurrentId(list.id);
                sortDisclosureProps.onOpen();
              }}
              remove={async () => {
                deleteMutation(list.id);
              }}
              permission={list.permission}
              share={!list.isShared ? () => { onShare({ id: list.id, name: "value" }) } : undefined} // name here is the name of the section
              // douplicate={() => { douplicateMutation(`${list.id}`); }} 
              isSharedWithMe={list.isShared}
            >
              {list.name}
            </ItemCard>
          );
        })
      }</div>
      <SharingModal isOpen={isSharingModalOpen} onClose={onSharingModalClose} sharedEntity={{ name: "value", id: currentList.id }} showOptions={true} />
    </BaseView>
  );
};

export default Values;
