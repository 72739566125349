//function that takes plan and returns all actions in it

import { PlanGoalAction, Plan } from "src/types";

export const getAllActions = (plan: Plan): PlanGoalAction[] => {
  const actions: PlanGoalAction[] = [];

  if (!plan.aspects) return actions;

  for (const aspect of plan.aspects) {
    for (const goal of aspect.goals) {
      if (!goal.actions) continue;

      for (const action of goal.actions) {
        actions.push({ ...action });
      }
    }
  }

  return actions;
};
