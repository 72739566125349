import { PlanGoalAction, BarStatisticsDataElement } from "src/types";

export const actionsToBarStatistics = (
  actions: PlanGoalAction[]
): BarStatisticsDataElement[] => {
  const myMap = new Map<string, number>();

  for (const action of actions) {
    if (!action.weight) {
      action.weight = 0;
    }

    const executor = action?.executor?.toLowerCase() || "";

    if (myMap.has(executor)) {
      myMap.set(executor, (myMap.get(executor) ?? 0) + action.weight);
    } else {
      myMap.set(executor, action.weight);
    }
  }

  const result: BarStatisticsDataElement[] = [];

  for (const [key, value] of myMap) {
    result.push({ name: key, "Points Per Executer": value });
  }

  return result;
};
