import { useEffect, useRef, useState } from "react";
import { ValuesList } from "src/types";

type useValueSortParams = {
  initialValuesList: ValuesList | undefined;
  submit: (valuesList: ValuesList) => void;
};
export default function useValueSort({
  initialValuesList,
  submit,
}: useValueSortParams) {
  //Used to index the non ordered stack
  const [i, setI] = useState(1);
  //Used to index the ordered stack
  const [j, setJ] = useState(0);

  const isInitialValuesListSet = useRef(false);

  const [valuesList, setValuesList] = useState<ValuesList>();
  const [orderedValuesList, setOrderedValuesList] = useState<ValuesList>();

  useEffect(() => {
    if (initialValuesList?.values && !isInitialValuesListSet.current) {
      //sort them according to the order property first before setting them
      setValuesList(initialValuesList);
      //Ordered stack initially contains the first element in the unordered one
      setOrderedValuesList({
        ...initialValuesList,
        values: [initialValuesList.values[0]],
      });
      isInitialValuesListSet.current = true;
    }
  }, [initialValuesList, initialValuesList?.values, isInitialValuesListSet]);

  useEffect(() => {
    if (orderedValuesList?.values.length! === 10) {
      submit(orderedValuesList!);
      setOrderedValuesList(undefined);
      setValuesList(undefined);
      isInitialValuesListSet.current = false;
    }
  }, [initialValuesList, orderedValuesList, submit]);

  const choose = (isIBigger: boolean) => {
    // submit(orderedValuesList);
    if (!orderedValuesList?.values || !valuesList?.values) return;
    if (isIBigger) {
      //If I is bigger so we basically insert it before then one we were comparing it to
      const newValues = [
        ...orderedValuesList.values.slice(0, j),
        valuesList.values[i],
        ...orderedValuesList.values.slice(j),
      ];
      setOrderedValuesList({ ...orderedValuesList, values: newValues });
      //set the j to 0 to start the comparison of it's following element
      setJ(0);
      //But make sure there is a one!!
      if (i + 1 < valuesList.values.length) {
        setI(i + 1);
      }
    } else {
      //if not bigger we have two cases
      if (j + 1 === orderedValuesList.values.length) {
        //if it's the last element in the ordered stack so we just push the current element to the end of the stack
        const newValues = [...orderedValuesList.values, valuesList.values[i]];
        setOrderedValuesList({ ...orderedValuesList, values: newValues });
        //set the j to 0 to start the loop again
        setJ(0);
        //Also make sure that there is another element available for comparison before incrementing
        if (i + 1 < valuesList.values.length) {
          setI(i + 1);
        }
      } else {
        //if not just increment the j to complete comparing with the rest of
        //the elements in the ordered stack
        setJ(j + 1);
      }
    }
  };

  const reset = () => {
    setI(1);
    setJ(0);
    isInitialValuesListSet.current = false;
  };

  return { choose, valuesList, i, j, reset, orderedValuesList };
}
