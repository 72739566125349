import {
  Center,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
  useBoolean,
  IconButton,
} from "@chakra-ui/react";
import React, { forwardRef, useImperativeHandle } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FormattedMessage } from "react-intl";

const DeleteButton = forwardRef(({ remove }, ref) => {
  const [isDeleteModelOpen, setOpenDeleteModel] = useBoolean();

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpenDeleteModel.on();
    }
  }))

  const handleDelete = () => {
    remove();
    setOpenDeleteModel.off();
  };

  return (
    <>
      <IconButton
        mx={2}
        mt={1}
        color='#e97575'
        onClick={(e) => {
          e.stopPropagation();
          setOpenDeleteModel.on();
        }}
        variant='link'
        aria-label='delete'
        fontSize='23px'
        minW={1}
        icon={<RiDeleteBin6Line />}
        h='5'
      />
      <Modal
        isCentered
        onClose={setOpenDeleteModel.off}
        isOpen={isDeleteModelOpen}
        motionPreset='slideInBottom'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id='deleteItem' />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Text d='flex' flexDirection={"column"} textAlign='center'>
                <FormattedMessage id='itemDeleteConfirmationMessage' />
              </Text>
            </Center>
          </ModalBody>
          <ModalFooter d='flex' justifyContent='space-between'>
            <Button width='20' m='2' onClick={handleDelete} bg='#e97575' color='white'>
              <FormattedMessage id='ok' />
            </Button>
            <Button width='20' m='2' onClick={setOpenDeleteModel.off} variant='outline'>
              <FormattedMessage id='cancel' />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default DeleteButton;
