import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const CalibrationModal = (props: any) => {
  const { open, modalClose, children } = props;
  return (
    <Modal isCentered size={"xs"} isOpen={open} onClose={modalClose}>
      <ModalOverlay />
      <ModalContent
        px={"10px"}
        maxWidth={"609px"}
        width={"90%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          <Text py={"15px"} textAlign={"center"} textStyle={"h5"}>
            <FormattedMessage
              id="calibration.modaltext"
              defaultMessage={
                " يتوجب عليك عمل معايرة حتى تتمكن من الدخول لهذا القسم "
              }
            />
          </Text>
          {children && (
            <Box width={"100%"} textAlign={"center"}>
              {children}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CalibrationModal;
