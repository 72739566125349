import React, { useEffect } from "react";
import Spinner from "@material-ui/core/CircularProgress";
import { Box, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { post } from "src/api/index";
import { useDispatch } from "src/store";
import { logout } from "src/store/auth";

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const logoutUser = async () => {
      //send a request to inform the server that the user logged out from a device
      //call it first cause it uses the token stored in local storage, logout() removes it!
      await post("/auth/logout", {});
      dispatch(logout());
      window.localStorage.removeItem("_sal-Calibra");
    };
    logoutUser();
  }, [dispatch]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop="250px"
    >
      <Spinner size={20} />
      <Text margin="0 10px" color="grey">
        <FormattedMessage id="loggingOut" />
      </Text>
    </Box>
  );
};

export default Logout;
