import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { ValuesList } from "src/types";
import useValueSort from "./useValueSort";

type ValueSortType = {
  disclosureProps: UseDisclosureReturn;
  valuesList?: ValuesList;
  isEdit?: boolean;
  submit: (sortedValuesList: ValuesList) => Promise<void>;
};
const ValueSort: React.FC<ValueSortType> = ({
  disclosureProps,
  valuesList,
  submit,
}) => {
  const {
    i,
    j,
    choose,
    valuesList: sortingValuesList,
    reset,
    orderedValuesList,
  } = useValueSort({
    initialValuesList: valuesList,
    submit,
  });
  useEffect(() => {
    if (!disclosureProps.isOpen) reset();
  }, [disclosureProps.isOpen, reset]);
  if (!valuesList) return null;
  return (
    <Modal {...disclosureProps} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="25px">
        <ModalCloseButton />
        <ModalHeader
          fontFamily="Calibri (Body)"
          fontSize="18px"
          fontWeight="bold"
          color="brand.900"
        >
          <FormattedMessage
            id="values.choose_answer"
            defaultMessage="اختار الاجابة"
          />
        </ModalHeader>

        <ModalBody
          p="6"
          color="gray.900"
          fontSize="18px"
          fontFamily="Calibri (Body)"
        >
          <FormattedMessage
            id={"values.sort"}
            defaultMessage={
              "تخيل قمة ({index}) مع انعدام ({jndex}) او قمة({jndex} )مع انعدام({index}) أيهما أولى ؟"
            }
            values={{
              index: sortingValuesList?.values[i].name,
              jndex: orderedValuesList?.values[j].name,
            }}
          />
        </ModalBody>
        <ModalFooter p="0">
          <Box d="flex" flex="1">
            <Button
              d="flex"
              flex="1"
              borderTopWidth="1px"
              variant="outline"
              borderTopEndRadius="0px"
              borderTopRadius="0px"
              borderBottomEndRadius="0px"
              borderBottom="0px"
              borderStart="0px"
              borderBottomStartRadius="25px"
              textColor="brand.800"
              onClick={() => choose(true)}
            >
              {sortingValuesList?.values[i].name}
            </Button>
            <Button
              d="flex"
              flex="1"
              variant="outline"
              borderTopRadius="0px"
              borderBottom="0px"
              borderEnd="0px"
              borderBottomStartRadius="0px"
              borderBottomEndRadius="25px"
              textColor="brand.800"
              onClick={() => choose(false)}
            >
              {orderedValuesList?.values[j].name}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ValueSort;
