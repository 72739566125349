import React from "react";
import { Box, Text, Button, VStack } from "@chakra-ui/react";
import UserSettings from "./UserSettings";
import { Redirect } from "react-router-dom";
import DarkHeader from "src/layout/DarkHeader";
import SubscriptionDetails from "./SubscriptionDetails";
import { useSelector } from "src/store";

const Settings: React.FC = () => {
  const user = useSelector((state) => state.auth.user);
  if (user?.subscriptionStatus !== "approved") return <Redirect to="/" />;

  return (
    <Box>
      <DarkHeader />
      <Box
        d="flex"
        flexDir={{ base: "column", lg: "row" }}
        alignItems={{ base: "center" }}
        justifyContent={{ lg: "space-around" }}
        pt="20px"
        pb="120px"
      >
        <UserSettings />        
        <SubscriptionDetails user={user} />
      </Box>
    </Box>
  );
};

export default Settings;
