import React from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { HStack, Box } from "@chakra-ui/react";
import { BarStatisticsDataElement } from "src/types";
import useLocale from "src/providers/useLocale";

type BarStatisticsProps = {
  data: BarStatisticsDataElement[];
};

export const BarStatistics = ({ data }: BarStatisticsProps) => {
  const [locale] = useLocale();

  //translate the key called "Points Per Executer" to arabic if locale is arabic

  const translatedData = data.map((item) => {
    if (!item.name) return item;
    if (locale === "ar") {
      return {
        name: item.name[0].toUpperCase() + item.name.slice(1),
        "نقاط لكل منفذ": item["Points Per Executer"],
      };
    } else {
      return {
        name: item.name[0].toUpperCase() + item.name.slice(1),
        "Points Per Executer": item["Points Per Executer"],
      };
    }
  });

  return (
    <HStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      borderRadius="10px"
      style={{ direction: "ltr" }}
    >
      <BarChart
        width={400}
        height={300}
        data={translatedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          barSize={20}
          dataKey={locale === "ar" ? "نقاط لكل منفذ" : "Points Per Executer"}
          stackId="a"
          fill="#2895ff"
        />
      </BarChart>
    </HStack>
  );
};
