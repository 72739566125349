import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { focusPoster, focusListItems } from "src/types";

const getList: [string, QueryFunction<focusListItems>] = [
  "listPoster",
  () => get<focusListItems>("/poster"),
];
const getSharedList: [string, QueryFunction<focusListItems>] = [
  "sharedListPoster",
  () => get<focusListItems>("/shared/details?entityName=poster"),
];
// getSharedList
const getOne = (id: number | undefined) =>
  [
    ["poster", id],
    () => (id ? get<focusPoster>(`/poster/${id}`) : Promise.reject(null)),
    { enabled: !(id === undefined) },
  ] as [
    [string, number],
    QueryFunction<focusPoster>,
    UseQueryOptions<focusPoster, Error>
  ];

export { getList, getOne, getSharedList };
