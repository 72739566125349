import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import PrimaryButton from "src/components/PrimaryButton";
import { post } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";

function SendNote({ isOpen, onClose }) {
  const location = useLocation();
  const intl = useIntl();
  const toast = useToast();
  const formatPathName = useCallback(
    (pathname: string) => {
      const directory = pathname
        .split("/")
        .filter((string) => string !== "content")
        .filter((segment) => segment !== "");
      const localizedDirctoryArray = directory.map((section) =>
        intl.formatMessage({ id: section })
      );

      return `${intl.formatMessage({ id: "home" })}${
        directory.length ? " > " : ""
      }${localizedDirctoryArray.join(directory.length ? " > " : "")}`;
    },
    [intl]
  );
  const initialFormData = {
    location: formatPathName(location.pathname),
    message: "",
  };

  const [feedbackLocation, setFeedbackLocation] = useState<
    "current" | "custom"
  >("current");
  const [formData, setFormData] = useState(initialFormData);
  let locale = useLocale()[0];
  useEffect(() => {
    setFormData((current) => {
      return { ...current, location: formatPathName(location.pathname) };
    });
    // location:formatPathName(location.pathname),
  }, [location.pathname, locale, formatPathName]);

  const resetFormFields = () => {
    setFormData(initialFormData);
  };
  return (
    <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        p={"10px"}
        maxWidth={"550px"}
        width={"100%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              post("/feedback/send", {
                message: formData.message,
                section: formData.location,
                sub_section: "N/A",
              })
                .then((res: any) => {
                  if (res?.message === "Saved") {
                    toast({
                      title: intl.formatMessage({ id: "feedback.sent" }),
                      status: "success",
                      isClosable: true,
                    });
                    resetFormFields();
                  }

                  if (res?.error) {
                    toast({
                      title: intl.formatMessage({ id: "wentWrong" }),
                      status: "error",
                      isClosable: true,
                    });
                  }
                  onClose();
                })
                .catch((err) => {
                  toast({
                    title: intl.formatMessage({ id: "wentWrong" }),
                    status: "error",
                    isClosable: true,
                  });
                });
            }}
          >
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="18px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              mb="4"
            >
              <FormattedMessage id="feedback.title" />
            </Text>
            <Text
              mb="4"
              d="block"
              // color="brand.900"
              fontSize="18x"
              // fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="feedback.discribtion" />
            </Text>
            <Text
              d="block"
              // color="brand.900"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="section" />
            </Text>
            <RadioGroup
              onChange={(e: any) => {
                setFeedbackLocation(e);
                e === "current" &&
                  setFormData((current) => {
                    return {
                      ...current,
                      location: formatPathName(location.pathname),
                    };
                  });
              }}
              value={feedbackLocation}
            >
              <Stack direction="row">
                <Radio value="current">
                  <FormattedMessage id="thisSection" />
                </Radio>
                <Radio value="custom">
                  <FormattedMessage id="anotherSection" />
                </Radio>
              </Stack>
            </RadioGroup>
            <Input
              mt="1.5"
              variant={feedbackLocation === "current" ? "unstyled" : "flushed"}
              placeholder={intl.formatMessage({ id: "where" })}
              isDisabled={feedbackLocation === "current"}
              value={formData.location}
              onChange={(e) => {
                setFormData((current) => {
                  return { ...current, location: e.target.value };
                });
              }}
            />
            <Text
              d="block"
              mt="4"
              // color="brand.900"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="message" />
            </Text>
            <Box>
              <Textarea
                variant="flushed"
                placeholder={intl.formatMessage({ id: "yourMessage" })}
                maxLength={270}
                value={formData.message}
                onChange={(e) => {
                  setFormData((current) => {
                    return { ...current, message: e.target.value };
                  });
                }}
              />
            </Box>

            <Text
              d="inline-block"
              mt="4"
              // color="brand.900"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              letterSpacing="widest"
            >
              {`${formData.message.length}`}
            </Text>
            <Text d="inline-block"> /270</Text>
            <Center my={"32px"}>
              <PrimaryButton
                type="submit"
                disabled={!formData.location.trim() || !formData.message.trim()}
              >
                <FormattedMessage id="send" defaultMessage="ارسال" />
              </PrimaryButton>
            </Center>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SendNote;
