import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1f3f93",
    800: "#2895ff",
    500: "#2895ff",
    400: "#a2c1e0",
  },
  gray: {
    300: "#e6e6e6",
    100: "#f9f9f9",
    900: "#9f9f9f",
  },
};

const fontSizes = {
  "3xl": "1.875rem",
};
const textStyles = {
  smallBold: {
    fontFamily: "Calibri (Body)",
    fontWeight: "bold",
    fontSize: ".875rem",
    color: "brand.900",
  },
  h2: {
    color: "brand.900",
    fontSize: "3xl",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.47,
    fontWeight: "bold",
  },
  h5: {
    color: "brand.900",
    fontSize: "18px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.44,
    fontWeight: "bold",
  },
  intentionMessageHeader: {
    color: "gray.900",
    fontSize: "18px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.44,
    fontWeight: "bold",
  },
  intentionMessage: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.38,
    fontWeight: "normal",
  },
  intentionDisplayText: {
    color: "#5f926d",
    fontSize: "24px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.38,
    fontWeight: "normal",
  },
  intentionModalTime: {
    color: "brand.500",
    fontSize: "18px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.44,
    fontWeight: "bold",
  },
  subliminalFormHeader: {
    color: "#1f3f93",
    fontSize: "24px",
    fontFamily: "Calibri (Body)",
    lineHeight: 1.38,
  },
};
const components = {
  Popover: {
    variants: {
      responsive: {
        popper: {
          maxWidth: "unset",
          width: "unset",
        },
      },
    },
  },
  ModalContent: {
    baseStyle: {
      maxWidth: "90%",
    },
  },
};

const theme = extendTheme({ colors, textStyles, fontSizes, components });

export default theme;
