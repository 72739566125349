import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import modalSpinner from "src/components/modalSpinner";
import NewItemButton from "src/components/NewItemButton";
import ProgressBar from "./ProgressBar";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { ReturnUseMission } from "./useMission";
import { CreateEditDisclosureProps } from "./useUIState";

type MissionsCreateProps = {
  disclosureProps: CreateEditDisclosureProps;
  missionProps: ReturnUseMission;
};

const PurposesCreate: React.FC<MissionsCreateProps> = ({
  disclosureProps,
  missionProps,
}) => {
  const [step, setStep] = useState(0);
  const nextStep = () => setStep((step) => step + 1);
  const modalBody =
    step === 0 ? (
      <Step1 nextStep={nextStep} {...missionProps} />
    ) : step === 1 ? (
      <Step2 nextStep={nextStep} {...missionProps} />
    ) : step === 2 ? (
      <Step3 nextStep={nextStep} {...missionProps} />
    ) : step === 3 ? (
      <Step4 {...missionProps} />
    ) : null;

  useEffect(() => {
    //once it closes reset step to 0
    if (!disclosureProps.isOpen) setStep(0);
  }, [disclosureProps]);

  return (
    <>
      <Modal
        {...disclosureProps}
        onClose={() => {
          disclosureProps.onClose();
          setStep(0);
          missionProps.clear();
        }}
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent px={"10px"} borderRadius="30px" maxW="50rem" mt={"40px"}>
          <ModalHeader>
            <ModalCloseButton />
            <ProgressBar step={step} setStep={setStep} />
          </ModalHeader>
          <ModalBody>
            {disclosureProps.isEdit
              ? missionProps.missionLoading
                ? modalSpinner
                : modalBody
              : modalBody}
          </ModalBody>
        </ModalContent>
      </Modal>
      <NewItemButton onClick={() => disclosureProps.onOpen(false, undefined)}>
        <FormattedMessage
          id="missions.create"
          defaultMessage="إضافة مهمة جديدة"
        />
      </NewItemButton>
    </>
  );
};
export default PurposesCreate;
