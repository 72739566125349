import React from "react";
import { useIntl } from "react-intl";

const AccessPermission = ({ permission }) => {
  const { formatMessage } = useIntl();


  switch (permission) {
    case "read":
      return <p>{formatMessage({ id: "readOnlyAccess" })}</p>;
    case "update":
      return <p>{formatMessage({ id: "updateAccess" })}</p>;
    default:
      return null;
  }
};

export default AccessPermission;
