import { Center, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import PrimaryButton from "src/components/PrimaryButton";
import { Goal } from "src/types";
import GoalItem from "./GoalItem";
import { ReturnUseMission } from "./useMission";

type Step3Props = { nextStep: () => void } & ReturnUseMission;
const Step3: React.FC<Step3Props> = ({
  addGoal,
  editGoal,
  deleteGoal,
  setFinancialGoal,
  nextStep,
  mission: { goals, financialGoal },
}) => {
  const [goal, setGoal] = useState<Goal>({ name: "" });
  return (
    <>
      <Text
        d="inline-block"
        color="brand.900"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="Calibri (Body)"
        padding={"20px 0"}
      >
        <FormattedMessage
          id="missions.create.step3.title"
          defaultMessage="قم بادخال أهم 5 أهداف تريد تحقيقها خلال عام"
        />
      </Text>

      <Text
        d="inline-block"
        color="gray.900"
        fontFamily="Calibri (Body)"
      >{`${goals.length}/5`}</Text>
      <InputWithAdd
        inputDisabled={goals.length === 5}
        disabled={goal.name.trim() === "" || goals.length === 5}
        value={goal.name}
        onChange={(e: any) => setGoal({ name: e.target.value })}
        onClick={() => {
          if (!goal.name) return;
          addGoal(goal);
          setGoal({ name: "" });
        }}
        mb={"24.5px"}
      />
      {/* List of goals */}
      {goals.map((goal, idx) => (
        <GoalItem
          key={idx}
          edit={(goal) => editGoal(idx, goal)}
          remove={() => {
            deleteGoal(idx);
          }}
          goal={goal}
        />
      ))}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (goals.length < 5) return;
          nextStep();
        }}
      >
        <Text
          d="inline-block"
          color="brand.900"
          fontSize="18px"
          fontWeight="bold"
          fontFamily="Calibri (Body)"
          mt={"1.5rem"}
        >
          <FormattedMessage
            id="missions.create.step3.subtitle"
            defaultMessage="أدخل هدفًا ماليًا محددًا تريد تحقيقه في غضون عام واحد - (إختيارى)"
          />
        </Text>
        <Input
          variant="flushed"
          value={financialGoal}
          onChange={(e) => setFinancialGoal(e.target.value)}
          mb={"27.5px"}
        />
      </form>
      <Center my={"32px"}>
        <PrimaryButton onClick={nextStep} disabled={goals.length < 5}>
          <FormattedMessage id="next" defaultMessage="التالي" />
        </PrimaryButton>
      </Center>
    </>
  );
};
export default Step3;
