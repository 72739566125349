import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { UseMutateAsyncFunction } from "react-query";
import { Vision, Wish } from "src/types";

type UseVisionParams = {
  currentId?: number;
  createMutation: UseMutateAsyncFunction<
    Partial<Vision>,
    unknown,
    Partial<Vision>,
    unknown
  >;
  editMutation: UseMutateAsyncFunction<Vision, unknown, Vision, unknown>;
  dismissCreateEditModal: () => void;
  openTopWishesModal: (id: number) => void;
  dismissTopWishesModal: () => void;
  currentVisionFromApi?: Vision;
  isEdit?: boolean;
};
type UseVisionReturn = {
  submitEditCreate: () => Promise<void>;
  vision: Vision;
  addWish: (wish: Wish) => void;
  editWish: (idx: number, wish: Wish) => void;
  removeWish: (idx: number) => void;
  setVisionName: (name: string) => void;
  submitTopTen: (top10: [Wish]) => Promise<Vision>;
};

const initialVision: Vision = {
  name: "",
  wishes: [],
};

export const useVision: (params: UseVisionParams) => UseVisionReturn = ({
  currentId,
  createMutation,
  editMutation,
  dismissCreateEditModal,
  openTopWishesModal,
  currentVisionFromApi,
  dismissTopWishesModal,
  isEdit,
}) => {
  const [vision, setVision] = useState<Vision>(initialVision);
  useEffect(() => {
    if (currentVisionFromApi) {
      setVision(currentVisionFromApi);
    } else setVision(initialVision);
  }, [currentVisionFromApi, isEdit]);
  const submitEditCreate = async (goToTopWishes?: boolean) => {
    const op = isEdit ? editMutation : createMutation;
    try {
      const { id } = await op(vision);
      //toast success
      dismissCreateEditModal();

      if (goToTopWishes) {
        openTopWishesModal(id!);
      }
    } catch {}
  };

  const addWish = (wish: Wish) => {
    setVision((old) => {
      return {
        ...old,
        wishes: [...old.wishes, { ...wish, id: nanoid() }],
      };
    });
  };

  const editWish = (idx: number, wish: Wish) =>
    setVision((old) => {
      return {
        ...old,
        wishes: [
          ...old.wishes.slice(0, idx),
          wish,
          ...old.wishes.slice(idx + 1),
        ],
      };
    });

  const removeWish = (idx: number) =>
    setVision((old) => {
      return {
        ...old,
        wishes: [...old.wishes.slice(0, idx), ...old.wishes.slice(idx + 1)],
      };
    });

  const setVisionName = (name: string) =>
    setVision((old) => {
      return { ...old, name };
    });

  const submitTopTen = async (top10: [Wish]) => {
    dismissTopWishesModal();
    return await editMutation({ ...vision, top10 });
  };
  return {
    submitEditCreate,
    vision,
    addWish,
    editWish,
    removeWish,
    setVisionName,
    submitTopTen,
  };
};
