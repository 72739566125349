import React from "react";
import { VStack, Box, Link } from "@chakra-ui/react";
import WCode from "src/assets/images/woman-code.png";
import MCode from "src/assets/images/man-code.png";
import BaseView from "../BaseView";
import { Link as ReactRouterLink } from "react-router-dom";

const Parts = () => {
  //get slug from url
  const getSlug = () => {
    const slug = window.location.pathname.split("/")[4];
    return slug;
  };

  //get image either male or female
  const getImage = () => {
    const slug = getSlug();
    const map = {
      male: MCode,
      female: WCode,
    };
    if (slug === "male" || slug === "female") return map[slug];
  };

  return (
    <BaseView>
      <VStack width="100%" display="flex" flexDir={{ base: "column" }}>
        <Box position="relative" width={{ base: "250px" }} margin={"60px 0"}>
          <img style={{ width: "100%" }} src={getImage()} alt={"body part"} />
          {getSlug() === "male" ? (
            <>
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/head`}
                width="60px"
                height="90px"
                borderRadius="50%"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 0)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/abdomen`}
                width="100px"
                height="150px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 50%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="150px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="0"
                transform="translate(120%, 50%) rotate(-20deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="150px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                right="0"
                transform="translate(-120%, 50%) rotate(20deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="100px"
                height="250px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 90%)"
              />
            </>
          ) : (
            <>
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/head`}
                width="60px"
                height="90px"
                borderRadius="50%"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 0)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/abdomen`}
                width="100px"
                height="180px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 45%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="150px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="0"
                transform="translate(140%, 60%) rotate(-20deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="150px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                right="0"
                transform="translate(-140%, 60%) rotate(20deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="100px"
                height="250px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 100%)"
              />
            </>
          )}
        </Box>
      </VStack>
    </BaseView>
  );
};

export default Parts;
