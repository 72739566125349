import React, { useState, useEffect } from "react";
import BaseView from "../BaseView";
import { Box, HStack, Text, Tooltip, IconButton, useDisclosure } from "@chakra-ui/react";
import { get } from "../../../api";
import useLocale from "src/providers/useLocale";
import { Balance } from "src/types";
import { FormattedMessage } from "react-intl";
import { FaPlay, FaStop } from "react-icons/fa";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia } from "src/store/media";
import { MdPlaylistAdd } from "react-icons/md";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";

const BalanceGate = () => {
  //current locale
  const [locale] = useLocale();

  //audios
  const [audios, setAudios] = useState<Array<Balance>>([]);
  const [loading, setLoading] = useState(true);
  const [currentTrack, setCurrentTrack] = useState<Balance | undefined>();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const id = useSelector((state) => state.media.id);
  const closeMedia = () => dispatch(stopMedia());
  const {
    isOpen: isAddToPlaylistOpen,
    onOpen: onAddToPlaylistOpen,
    onClose: onAddToPlaylistClose,
  } = useDisclosure();
  //fetch audios upon mounting component
  useEffect(() => {
    const fetchAudios = async () => {
      const response = await get<Array<Balance>>(
        "/balance",
        locale === "ar" || locale === "en" ? locale : undefined
      );
      setLoading(false);
      if (response && Array.isArray(response)) {
        setAudios(response);
      } else setError("Failed to fetch data!");
    };
    fetchAudios();
  }, [locale]);

  //function to play media
  const play = (source: string, id: number) => {
    dispatch(
      playMedia({
        id: `balance-${id}`,
        source: source,
        type: "audio",
        loop: true,
        name: "BalanceGate",
        baBtn:true
      })
    );
  };

  //function to close media
  const close = () => {
    closeMedia();
  };

  return (
    <BaseView>
      <HStack
        paddingBottom={"50px"}
        display={"flex"}
        flexDir={"column"}
        alignItems="flex-start"
        paddingTop={"30px"}
      >
        <HStack>
          <Box>
            <Text color="grey" display="inline">
              <FormattedMessage id="balanceGateDesc" />
            </Text>
            <Text color="blue.300" d="inline">
              <a
                href="https://www.newworldmusic.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                (https://www.newworldmusic.com)
              </a>
            </Text>
          </Box>
        </HStack>
        {loading || error ? (
          <div style={{ padding: "30px", textAlign: "center" }}>
            {loading && "Loading..."}
            {error && error}
          </div>
        ) : (
          <>
            <Box
              display={"flex"}
              flexDir="column"
              alignItems="center"
              width={"100%"}
              maxWidth={"1200px"}
              paddingTop={"30px"}
            >
              {audios.map((audio) => (
                <Tooltip
                  key={audio.id}
                  label={audio.name}
                  color="white"
                  bg="brand.900"
                  placement={locale === "ar" ? "top-end" : "top-start"}
                >
                  <HStack
                    cursor="pointer"
                    width={{ base: "90%", md: "100%" }}
                    borderRadius={"10px"}
                    background={"#fcfafa"}
                    padding={"10px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    margin={"5px 0"}
                    boxShadow={"-3px 3px 5px silver"}
                    transition="250ms ease-out"
                    border="2px solid transparent"
                    _hover={{ borderColor: "#2895ff" }}
                  >
                    <h6 style={{ overflow: "hidden", whiteSpace: "pre" }}>
                      {audio.name}
                    </h6>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {id === `balance-${audio.id}` ? (
                        <FaStop
                          color={"#2895ff"}
                          onClick={close}
                          style={{ margin: "0 5px" }}
                          cursor="pointer"
                        />
                      ) : (
                        <span
                          style={
                            locale === "ar"
                              ? {
                                transform: "rotateY(180deg)",
                                display: "block",
                              }
                              : { display: "block" }
                          }
                        >
                          <FaPlay
                            color={"#2895ff"}
                            onClick={() => play(audio.url!, audio.id!)}
                            style={{ margin: "0 5px" }}
                            cursor="pointer"
                          />
                        </span>
                      )}
                      <IconButton
                        className="remove-on-print"
                        onClick={() => {
                          onAddToPlaylistOpen()
                          setCurrentTrack(audio)
                        }}
                        // marginStart={"10px"}
                        size="md"
                        ml="4"
                        border="2px solid #E2E8F0"
                        aria-label="add"
                        icon={<MdPlaylistAdd />}
                      />
                    </div>
                  </HStack>
                </Tooltip>
              ))}
            </Box>
          </>
        )}
      </HStack>
      <AddToPlaylistModal isOpen={isAddToPlaylistOpen} onClose={onAddToPlaylistClose} track={makeTrackFromBalancePortal(currentTrack)} entityName={ENTITY_NAME} />
    </BaseView>
  );
};

export default BalanceGate;
const ENTITY_NAME = "balancePortal"

const makeTrackFromBalancePortal = (balance: Balance | undefined) => {
  if (!balance) return
  return {
    ...balance,
    entityName: ENTITY_NAME,
    entityId: balance.id
  }
}