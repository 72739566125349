import { VStack, Wrap, WrapItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SubHeader from "src/components/SubHeader";
import GeneralCards from "./GeneralCards";
import GeneralLinks from "./GeneralLinks";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";

const GeneralIntentions = (props: any) => {
  const { subliminal, onClick } = props;
  const [state, setState] = React.useState<any>();
  const handleGeneralLinks = (state: any) => {
    setState(state);
  };
  const [categories, setCategories] = useState<{ id: string; name: string }[]>(
    []
  );

  //current locale
  const [locale] = useLocale();

  useEffect(() => {
    const fetchCats = async () => {
      const response = await get(
        `/${subliminal ? "subliminal" : "intention"}/category`
      );
      if (Array.isArray(response)) setCategories(response);
    };
    fetchCats();
  }, [locale, subliminal]);

  return (
    <VStack width={"100%"} maxWidth={"352px"} alignItems={"start"}>
      <SubHeader
        marginTop={".3rem"}
        formatId={subliminal ? "subliminal.general" : "intentions.general"}
      />
      <Wrap minHeight="70px">
        {categories.map((category) => (
          <WrapItem key={category.id}>
            <GeneralLinks
              subliminal={subliminal}
              onClick={() => handleGeneralLinks(category)}
              isActive={state?.name === category.name}
              formatId={category.name}
            />
          </WrapItem>
        ))}
      </Wrap>
      <GeneralCards onClick={onClick} subliminal={subliminal} state={state} />
    </VStack>
  );
};

export default GeneralIntentions;
