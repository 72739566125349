import { PlanGoalAction, PieStatisticsDataElement } from "src/types";

const getBackgroundColor = (action: PlanGoalAction) => {
  const passed = new Date().getTime() > new Date(action.endDate).getTime();

  const diffInDays = Math.floor(
    (new Date(action.endDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );
  const diffInDaysStart = Math.floor(
    (new Date(action.startDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );
  const isTodayInRange = diffInDaysStart <= -1 && diffInDays >= -1;
  const isthreeDaysLeft = diffInDays >= 0 && diffInDays <= 2;

  if (action.complete) return "#68D391"; //If task done ----- Dark Green
  if (new Date(action.startDate).getTime() > new Date().getTime())
    return "#f0f0f0"; //If Start Date > today ----- no color (planned)
  if (passed) return "rgba(233, 117, 117, 0.6)"; //If End Date <= today ----- Red
  if (isthreeDaysLeft) return "rgb(255,255,102)"; //If End Date <= today+3days ---- Yellow
  if (isTodayInRange) return "#C6F6D5"; // today is in range but there are more thane 3 days to the end date of the task
  return "";
};

export const actionsToPieStatistics = (
  actions: PlanGoalAction[]
): PieStatisticsDataElement[] => {
  let yellowCount = 0;
  let redCount = 0;
  let darkGreenCount = 0;
  let lightGreenCount = 0;
  let grayCount = 0;

  actions.forEach((action) => {
    const backgroundColor = getBackgroundColor(action);
    if (backgroundColor === "#f0f0f0") grayCount++;
    if (backgroundColor === "#68D391") darkGreenCount++;
    if (backgroundColor === "rgba(233, 117, 117, 0.6)") redCount++;
    if (backgroundColor === "rgb(255,255,102)") yellowCount++;
    if (backgroundColor === "#C6F6D5") lightGreenCount++;
  });

  let result = [
    { name: "Done", value: darkGreenCount },
    { name: "Started", value: lightGreenCount },
    { name: "Finish in 3 days", value: yellowCount },
    { name: "Delayed", value: redCount },
    { name: "Planned", value: grayCount },
  ].filter((item) => item.value > 0);

  return result;
};
