import { reorderPlayList } from "src/api/prepration/playlists/mutation";
import { useMutation } from "react-query";

export const usePlaylist = () => {
  const { mutateAsync: reorderMutation } = useMutation(reorderPlayList);

  return {
    reorderMutation,
  };
};
