import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Button } from "@chakra-ui/react";
import { Description } from "src/types";
import Link from "src/components/Link/Link";
import { get, titleSize, descSize } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import {
  INTENTION_ROUTE,
  SUBLIMINAL_ROUTE,
  CREATEPOSSIBILITY_ROUTE,
  CLEAR_AND_PROTECT_ROUTE,
} from "src/routes";
import CalibrationModal from "./Calibration/CalibrationModal";
import Intention from "./Intentions";
import Story from "./Story";
import Magic from "./Magic";
import Problem from "./Problem";
import Subliminal from "./Subliminal";
import CreatePossibility from "./CreatePossibility";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import { AiOutlinePlayCircle } from "react-icons/ai";
import Calibration from "./Calibration";
import { useDispatch } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import ClearAndProtect from "./ClearAndProtect/ClearAndProtect";
import Area from "./ClearAndProtect/Area";
import Personal from "./ClearAndProtect/Personal";
const Affirmation = () => {
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  const [content, setContent] = useState("Loading ...");

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    get<Description>("/description/affirmation").then((obj) => {
      setContent(obj?.content || formatMessage({ id: "failFetch" }));
    });
  }, [formatMessage, locale]);

  return (
    <Box>
      <BaseView noBread imageSrc={affirmationBg} />
      <VStack alignItems="start">
        <Box>
          <Text fontSize={titleSize} fontWeight="bold" color="#1f3f93">
            <FormattedMessage id="affirmation" />
          </Text>
        </Box>
        <Box>
          <Text color="#8b8b8b" fontSize={descSize}>
            {content}
          </Text>
          <Button
            onClick={calibrationFunc}
            color={"white"}
            width={locale === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"35px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={locale ? { marginRight: "1rem" } : { marginLeft: "1px" }}
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
          <Calibration />
        </Box>
        <VStack w="100%" marginTop="30px !important">
          <HStack flexWrap="wrap" alignItems="center">
            <Link
              onClick={() =>
                window.localStorage.getItem("_sal-Calibra") !== "done" &&
                calibrationFunc()
              }
              to={
                window.localStorage.getItem("_sal-Calibra") === "done"
                  ? INTENTION_ROUTE
                  : "#"
              }
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="sendingintention" />
            </Link>
            <Link
              to={CREATEPOSSIBILITY_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="createpossibility" />
            </Link>
            <Link
              maxW={"200px"}
              to={CLEAR_AND_PROTECT_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="clearandprotect" />
            </Link>
            <Link
              maxW={"112px"}
              onClick={() => {
                if (window.localStorage.getItem("_sal-Calibra") !== "done") {
                  calibrationFunc();
                }
              }}
              to={
                window.localStorage.getItem("_sal-Calibra") === "done"
                  ? SUBLIMINAL_ROUTE
                  : "#"
              }
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="subliminal" />
            </Link>
          </HStack>
        </VStack>
        <CalibrationModal
          open={modalOpen}
          modalClose={() => setModalOpen(false)}
        />
      </VStack>
    </Box>
  );
};

Affirmation.Intention = Intention;
Affirmation.Story = Story;
Affirmation.Magic = Magic;
Affirmation.Problem = Problem;
Affirmation.Subliminal = Subliminal;
Affirmation.CreatePossibility = CreatePossibility;
Affirmation.ClearAndProtect = ClearAndProtect;
Affirmation.Personal = Personal;
Affirmation.Area = Area;

export default Affirmation;
