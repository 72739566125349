import { Box, BoxProps, Button, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiDeleteBin6Line, RiShareBoxLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { BiCopy } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import DeleteButton from "src/components/DeleteModel";

type VisionItemProps = {
  repeat?: () => void;
  remove?: () => void;
  edit?: () => void;
  isComplete?: boolean;
  count?: number;
  id: number;
  duplicate?: () => any;
  openTopWishes: (id: number) => void;
  share?: () => void;
  isSharedWithMe?: boolean;
  permission?: any;
} & Omit<BoxProps, "id">;
const VisionItem: React.FC<VisionItemProps> = ({
  remove,
  children,
  edit,
  isComplete,
  onClick,
  id,
  openTopWishes,
  count,
  duplicate,
  share,
  isSharedWithMe,
  permission,
  ...props
}) => {
  const canUpdate = permission === "update"
  const { formatMessage } = useIntl();
  return (
    <Box mb={2} {...props}>
      <Box
        onClick={onClick}
        d='inline-block'
        fontFamily='Calibri (Body)'
        lineHeight='22px'
        color={isComplete ? "black" : "gray.900"}>
        {children}{" "}
        {isComplete ? (
          ""
        ) : (
          <>
            <Text fontWeight='bold' display='inline-block'>
              {count}
            </Text>
          </>
        )}
      </Box>
      <Box d='flex' float='right'>
        {isSharedWithMe && <Text px='2'>{formatMessage({ id: "shared" })}</Text>}
        {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && edit ? (
          <Button
            variant='link'
            color='brand.800'
            disabled={!isComplete}
            fontFamily='Calibri (Body)'
            fontWeight='bold'
            fontSize='16px'
            lineHeight='24px'
            onClick={() => openTopWishes(id)}
            rightIcon={<RiAddFill fontSize='24px' />}>
            <FormattedMessage id='vision.add_vision' defaultMessage='إضافة رؤية' />
          </Button>
        ) : null}
        {duplicate ? (
          <IconButton
            mx={2}
            color='brand.800'
            onClick={duplicate}
            fontSize='20px'
            minW='1rem'
            variant='link'
            aria-label='delete'
            me={1}
            // width="15px"
            icon={<BiCopy />}
          />
        ) : null}
        {(!isSharedWithMe || (isSharedWithMe && canUpdate)) && edit ? (
          <IconButton
            mx={2}
            color='brand.800'
            onClick={edit}
            fontSize='20px'
            minW='1rem'
            variant='link'
            aria-label='delete'
            me={1}
            // width="15px"
            icon={<BiEdit />}
          />
        ) : null}
        {!isSharedWithMe && share && (
          <IconButton
            // visibility={shareIconVisibility}
            // color="#e97575"
            onClick={(e: any) => {
              e.stopPropagation();
              share();
            }}
            variant='link'
            aria-label='delete'
            fontSize='23px'
            minW={1}
            icon={<RiShareBoxLine className='remove-on-print' />}
            h='5'
          />
        )}
        {remove && !isSharedWithMe ? <DeleteButton remove={remove} /> : null}
      </Box>
    </Box>
  );
};
export default VisionItem;
