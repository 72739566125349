import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { Mission } from "src/types";

const getList: [string, QueryFunction<Mission[]>] = [
  "listMission",
  () => get<Mission[]>("/mission"),
];

const getSharedList: [string, QueryFunction<Mission[]>] = [
  "sharedListMission",
  () => get<Mission[]>("/shared/details?entityName=mission"),
];

const mission = (id: number | undefined) =>
  [
    ["mission", id],
    () => get<Mission>(`/mission/${id}`),
    { enabled: !(id === undefined) },
  ] as [
    [string, number],
    QueryFunction<Mission>,
    UseQueryOptions<Mission, Error>
  ];

export { getList, mission, getSharedList };
