import { VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import InputWithAdd from "src/components/InputWithAdd";
import SubHeader from "src/components/SubHeader";
import MessagesCard from "./MessagesCard";
import {
  createIntention,
  createSubliminal,
  deleteIntention,
  deleteSubliminal,
} from "src/api/affirmation/intentions/mutations";
import { IntentionsSavedList } from "src/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getList,
  getListSubliminal,
  getSharedList
} from "src/api/affirmation/intentions/query";
import { useIntl } from "react-intl";
interface Props {
  onClick?: any;
  onBlur?: any;
  name?: string;
  subliminal?: any;
  share?: () => void;
}

const SpecialIntentions: React.FC<Props> = ({
  onClick,
  onBlur,
  subliminal,
  name,
  share
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [added, setAdded] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const client = useQueryClient();
  const intl = useIntl();
  const deleteMessage = async (component?: string, id?: number) => {
    if (component === "sub") {
      await deleteSubliminal(id!);
      setDeleteAction(!deleteAction);
      return;
    }
    await deleteIntention(id!);
    setDeleteAction(!deleteAction);
  };

  let determineDataFunc = !subliminal ? getList : getListSubliminal;
  let determineSharedDataFunc = !subliminal ? getSharedList : getListSubliminal;

  const { data } = useQuery<IntentionsSavedList, Error, IntentionsSavedList>(
    ...determineDataFunc
  );

  const { data: sharedData, refetch } = useQuery<any>(
    ...determineSharedDataFunc
  );
  useEffect(() => { }, [subliminal]);

  const { mutateAsync: createIntentionMutation } = useMutation(
    createIntention,
    {
      onSuccess: () => client.invalidateQueries("listIntention"),
    }
  );
  const { mutateAsync: createSubliminalMutation } = useMutation(
    createSubliminal,
    {
      onSuccess: () => client.invalidateQueries("listSubliminal"),
    }
  );
  const createMessage = () => {
    const createdMessage = { name: inputValue };
    subliminal
      ? createSubliminalMutation(createdMessage)
      : createIntentionMutation(createdMessage);
    setAdded(!added);
    setInputValue("");
  };

  React.useEffect(() => {
    name && setInputValue(name);
  }, [added, deleteAction, name]);

  function removeDuplicateIds(arr) {
    let uniqueIds = new Set();
    return arr.filter(obj => {
        if (uniqueIds.has(obj.id)) {
            return false;
        } else {
            uniqueIds.add(obj.id);
            return true;
        }
      });
  }
  return (
    <VStack width={"100%"} maxWidth={"352px"} alignItems={"start"}>
      <SubHeader
        marginTop={".3rem"}
        formatId={subliminal ? "subliminal.special" : "intentions.special"}
      />
      <InputWithAdd
        disabled={inputValue === ""}
        onKeyDown={(event: any) => {
          if (event.which === 13 || event.keyCode === 13) {
            createMessage();
          }
        }}
        id={"intentions"}
        value={inputValue}
        onClick={() => createMessage()}
        onChange={(e: any) => {
          setInputValue(e.target.value);
        }}
        marginBottom={"32px"}
        width={"352px"}
        placeholder={
          subliminal
            ? intl.formatMessage({ id: "subliminal.generalmessage" })
            : intl.formatMessage({ id: "intentions.mymessage" })
        }
      />
      {/* <button onClick={refetch}>refetc</button> */}
      {data && sharedData ? <MessagesCard
        onDelete={deleteMessage}
        deleteAction={deleteAction}
        data={removeDuplicateIds([...data, ...sharedData])}
        addInput={added}
        subliminalSpecial={subliminal}
        onBlur={onBlur}
        onClick={onClick}
        remove={true}
        share={share}
      /> : null}
    </VStack>
  );
};

export default SpecialIntentions;
