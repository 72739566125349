import {
  FOCUS_POSTER_ROUTE,
  INTENTION_ROUTE,
  CREATEPOSSIBILITY_ROUTE,
  MISSIONS_ROUTE,
  PURPOSES_ROUTE,
  SALPLAN_ROUTE,
  SUBLIMINAL_ROUTE,
  VALUES_ROUTE,
  VISIONS_ROUTE,
  HEALING_ROUTE,
  WAVES_ROUTE,
  SOLFEGGIO_ROUTE,
  CHAKRAS_ROUTE,
  BALANCE_GATE_ROUTE,
  MANIFESTATION_GATE_ROUTE,
  CLEAR_AND_PROTECT_ROUTE,
} from "src/routes";

export const PreparationSection = [
  {
    name: "values",
    route: VALUES_ROUTE,
  },
  {
    name: "purposes",
    route: PURPOSES_ROUTE,
  },
  {
    name: "visions",
    route: VISIONS_ROUTE,
  },
  {
    name: "missions",
    route: MISSIONS_ROUTE,
  },
  {
    name: "salplan",
    route: SALPLAN_ROUTE,
  },
  {
    name: "focusposter",
    route: FOCUS_POSTER_ROUTE,
  },
];

export const AffirmationsSection = [
  {
    name: "sendingintention",
    route: INTENTION_ROUTE,
  },
  {
    name: "createpossibility",
    route: CREATEPOSSIBILITY_ROUTE,
  },
  {
    name: "clearandprotect",
    route: CLEAR_AND_PROTECT_ROUTE,
  },
  {
    name: "subliminal",
    route: SUBLIMINAL_ROUTE,
  },
];

export const CommunicationSection = [
  {
    name: "healingguide",
    route: HEALING_ROUTE,
  },
];

export const FrequenciesSection = [
  {
    name: "chakras",
    route: CHAKRAS_ROUTE,
  },
  {
    name: "solfeggio",
    route: SOLFEGGIO_ROUTE,
  },
  {
    name: "waves",
    route: WAVES_ROUTE,
    new: true, // change the key to "new" to get it ready to production
  },
];
export const GatesSection = [
  {
    name: "balance Portal",
    route: BALANCE_GATE_ROUTE,
  },
  {
    name: "transfigurationgate",
    route: MANIFESTATION_GATE_ROUTE,
  },
];
