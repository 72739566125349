import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import WaveSurfer from "wavesurfer.js";
import { useDispatch, useSelector } from "src/store";
import { play, pause, updateCurrentTime } from "src/store/playback";
import { activateSmButton, deactivateSmButton } from "src/store/media";
import WaveSurferCache from "./WaveSurferCache";

interface PlaybackContextProps {
  currentTrack: { waveSurfer: WaveSurfer; url: string } | null;
  setCurrentTrack: (
    track: { waveSurfer: WaveSurfer; url: string } | null
  ) => void;
  isPlaying: boolean;
  play: (track: { waveSurfer: WaveSurfer; url: string }) => void;
  pause: () => void;
  togglePlayPause: (track: { waveSurfer: WaveSurfer; url: string }) => void;
  isLoading: boolean;
}

const PlaybackContext = createContext<PlaybackContextProps | undefined>(
  undefined
);

export const PlaybackProvider: React.FC = ({ children }) => {
  const [currentTrack, setCurrentTrackState] = useState<{
    waveSurfer: WaveSurfer;
    url: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const dispatch = useDispatch();
  const isMagicPlaying = useSelector((state) => state.playback.isPlaying);
  const currentTrackTime = useSelector((state) => state.playback.currentTime);
  const isPlaying = useSelector((state) => state.media.playing);

  const cache = WaveSurferCache.getInstance();

  useEffect(() => {
    const updateTime = () => {
      if (audioRef.current && audioRef.current.paused === false) {
        dispatch(updateCurrentTime(audioRef.current.currentTime));
      }
    };

    const interval = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(interval);
  }, [dispatch, audioRef.current?.currentTime]);

  const setCurrentTrack = useCallback(
    (track: { waveSurfer: WaveSurfer; url: string } | null) => {
      if (currentTrack && currentTrack.waveSurfer !== track?.waveSurfer) {
        currentTrack.waveSurfer.stop();
        currentTrack.waveSurfer.seekTo(0);
      }
      setCurrentTrackState(track);
    },
    [currentTrack]
  );

  const playTrack = useCallback(
    (track: { waveSurfer: WaveSurfer; url: string }) => {
      setIsLoading(true);

      if (currentTrack !== track) setCurrentTrack(track);
      dispatch(play(track.url));

      if (audioRef.current) {
        if (audioRef.current.src === track.url) {
          audioRef.current.currentTime = currentTrackTime;
        } else {
          audioRef.current.currentTime = 0;
          audioRef.current.src = track?.url || "";
        }

        const cachedAudio = cache.get(track.url);

        if (cachedAudio) {
          if (currentTrack?.url === cachedAudio.getMediaElement().src)
            cachedAudio.seekTo(currentTrackTime);

          cachedAudio.play();
          audioRef.current?.play();

          setIsLoading(false);
          dispatch(activateSmButton());
          return;
        }

        track.waveSurfer
          .load(track.url)
          .then(() => {
            audioRef.current?.load();
          })
          .finally(() => {
            audioRef.current?.play();
            track.waveSurfer.play();

            setIsLoading(false);
            dispatch(activateSmButton());

            cache.set(track.url, track.waveSurfer);
          });
      }
    },
    [setCurrentTrack, dispatch, currentTrackTime, currentTrack, cache]
  );

  const pauseTrack = useCallback(() => {
    if (currentTrack) {
      currentTrack.waveSurfer.pause();
      if (audioRef.current) {
        audioRef.current.pause();
      }
      dispatch(pause());
      dispatch(deactivateSmButton());
    }
  }, [currentTrack, dispatch]);

  const togglePlayPause = useCallback(
    (track: { waveSurfer: WaveSurfer; url: string }) => {
      if (!currentTrack || currentTrack.waveSurfer !== track.waveSurfer) {
        playTrack(track);
      } else if (isMagicPlaying) {
        pauseTrack();
      } else {
        playTrack(track);
      }
    },
    [currentTrack, isMagicPlaying, playTrack, pauseTrack]
  );

  // useEffect(() => {
  //   if (currentTrack) {
  //     currentTrack.waveSurfer.on("finish", () => {
  //       dispatch(updateCurrentTime(0));
  //     });
  //   }
  // }, [currentTrack, dispatch]);

  useEffect(() => {
    if (isPlaying) {
      dispatch(pause());

      if (isPlaying && currentTrack) {
        currentTrack.waveSurfer.stop();
      }

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isPlaying, currentTrack, dispatch]);

  return (
    <PlaybackContext.Provider
      value={{
        currentTrack,
        setCurrentTrack,
        isPlaying: isMagicPlaying,
        play: playTrack,
        pause: pauseTrack,
        togglePlayPause,
        isLoading,
      }}
    >
      <audio ref={audioRef} style={{ display: "none" }} />
      {children}
    </PlaybackContext.Provider>
  );
};

export const usePlayback = (): PlaybackContextProps => {
  const context = useContext(PlaybackContext);
  if (!context) {
    throw new Error("usePlayback must be used within a PlaybackProvider");
  }
  return context;
};
