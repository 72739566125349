export const scrollBarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    width: "8px",
    background: "silver",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "grey",
    borderRadius: "24px",
  },
  "scroll-behavior": "smooth",
};
