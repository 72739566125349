import React, { useState, useEffect } from "react";
import {
  VStack,
  HStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  Heading,
} from "@chakra-ui/react";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { useHistory } from "react-router-dom";
import { FAQuestion } from "./types";
import { FormattedMessage } from "react-intl";
import SalMagicGate from "../Frequency/SalMagic/components/SalMagicGate";
import { useMediaQuery } from "@chakra-ui/react";
const SalMagicFAQ = () => {
  const [locale] = useLocale();
  const history = useHistory();
  const [initialContent, setInitialContent] = useState<any>([]);
  const [isMobile] = useMediaQuery("(max-width: 465px)");

  useEffect(() => {
    //method for fetching data
    const fetchData = async () => {
      const response = await get<FAQuestion[]>("/faq", locale);
      if (Array.isArray(response)) {
        //filter the only complete entries
        // @ts-ignore
        const allTranslations = response
          .filter((q) => q.isSalMagicFAQ)
          .map((q) => q.translations)
          .flat(); // this variable holds the real data in different languages
        // const renderData = allTranslations.filter((q) => q.locale === locale);
        setInitialContent(allTranslations);
        return;
      }
      history.push("/");
    };
    fetchData();
  }, [history, locale]);

  const handleAnswerHyperlink = (answer: string) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const parts = answer.split(regex);

    return parts.map((part, index) => {
      if (part.match(regex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue" }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <Box>
      <ChakraBreadcrumb
        color="gray.900"
        textTransform="capitalize"
        separator=" "
        mb={10}
      >
        <BreadcrumbItem
          textStyle="h2"
          isCurrentPage
          w="100%"
          justifyContent="space-between"
          alignItems="start"
        >
          <Heading as="h2" textStyle={"h2"}>
            <FormattedMessage
              id={
                isMobile && locale === "en"
                  ? "salmagic.faqs.short"
                  : "salmagic.faqs"
              }
            />
          </Heading>
          <SalMagicGate radius={105} />
        </BreadcrumbItem>
      </ChakraBreadcrumb>

      <VStack
        width={{ base: "100%", sm: "80vw", md: "60vw" }}
        display="flex"
        flexDir="column"
        alignItems="center"
        overflow="hidden"
        paddingTop="50px"
        margin="0 auto"
        paddingBottom="50px"
      >
        <HStack paddingBottom="20px" minW="100%">
          {/* <Input type='text' placeholder={intl.formatMessage({ id: 'search' })} width="100%" onChange={search} /> */}
          <Accordion defaultIndex={[0]} allowMultiple w="100%">
            {initialContent.map((item) => {
              return (
                <AccordionItem key={item.id}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {item.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {handleAnswerHyperlink(item.answer)}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </HStack>
      </VStack>
    </Box>
  );
};

export default SalMagicFAQ;
