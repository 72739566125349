import React from "react";
import { Box, BoxProps, Progress, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const ProgressBar: React.FC<
  BoxProps & { step: number; setStep: (step: number) => Promise<void> }
> = ({ step, setStep, ...props }) => {
  return (
    <Box
      position="relative"
      px="32px"
      pb="29px"
      pt="10px"
      {...props}
      display={["none", "block"]}
    >
      <Progress value={(100 / 3) * step} colorScheme="brand" />
      <Box
        display="flex"
        top="0px"
        left="16px"
        position="absolute"
        justifyContent="space-between"
        h={"51px"}
        w={"calc(100% - 32px)"}
        // flex="1"
      >
        <Box>
          <Box
            backgroundColor="brand.800"
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor="pointer"
            onClick={() => step > 0 && setStep(0)}
          />
          {step >= 0 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontFamily="Calibri (Body)"
              fontWeight="normal"
              fontSize="14px"
              lineHeight="46px"
            >
              <FormattedMessage
                defaultMessage="تحديد الجوانب"
                id="purpose.progressbar.choose_aspects"
              />
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={step >= 1 ? "brand.800" : "gray.100"}
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor="pointer"
            onClick={() => step > 1 && setStep(1)}
          />
          {step >= 1 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              <FormattedMessage
                defaultMessage="الغايات"
                id="purposes.desires"
              />
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={step >= 2 ? "brand.800" : "gray.100"}
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor="pointer"
            onClick={() => step > 2 && setStep(2)}
          />
          {step >= 2 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              <FormattedMessage
                defaultMessage="القيم"
                id="purpose.progressbar.value"
              />
            </Text>
          ) : null}
        </Box>
        <Box>
          <Box
            backgroundColor={step >= 3 ? "brand.800" : "gray.100"}
            h={"32px"}
            w={"32px"}
            borderRadius={"16px"}
            cursor="pointer"
            onClick={() => step > 3 && setStep(3)}
          />
          {step >= 3 ? (
            <Text
              position="absolute"
              color="brand.800"
              fontWeight="normal"
              fontFamily="Calibri (Body)"
              fontSize="14px"
              lineHeight="46px"
            >
              <FormattedMessage
                defaultMessage="الرسالة"
                id="purpose.progressbar.content"
              />
            </Text>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
export default ProgressBar;
