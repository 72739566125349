import { deleteMethod, post, put } from "src/api";
import { Plan, PlanAspect, PlanAspectGoal, PlanGoalAction } from "src/types";

const create: (plan: Plan) => Promise<Plan> = (plan) =>
  post<Plan>("/plan", plan);

const douplicate: (param: {
  planId: string;
  groupId?: string;
}) => Promise<any> = ({ planId, groupId }) =>
  post<any>(`/plan/duplicate/${planId}`, { planId, groupId });

const douplicateShared: (param: {
  planId: string;
  groupId?: string;
}) => Promise<any> = ({ planId, groupId }) =>
  post<any>(`/shared/duplicate/${planId}`, { id: planId, groupId });

const addAspect: (param: {
  aspect: PlanAspect;
  planId: number;
}) => Promise<Plan> = ({ aspect, planId }) =>
  post<Plan>(`/plan/${planId}/aspect`, aspect);

const addGoal: (param: {
  goal: PlanAspectGoal;
  aspectId: number;
}) => Promise<PlanAspectGoal> = ({ goal, aspectId }) =>
  post<PlanAspectGoal>(`/plan/aspect/${aspectId}/goal`, goal);

const editPlanName: (param: {
  planId: number;
  name: string;
}) => Promise<Plan> = ({ name, planId }) =>
  put<Plan>(`/plan/${planId}`, { name });

const editAspect: (param: { aspect: PlanAspect }) => Promise<Plan> = ({
  aspect,
}) => put<Plan>(`/plan/aspect/${aspect.id}`, { ...aspect, id: undefined });

const editAction: (param: { action: PlanGoalAction }) => Promise<Plan> = ({
  action,
}) =>
  put<PlanGoalAction>(`/plan/aspect/goal/action/${action.id}`, {
    ...action,
    id: undefined,
  });

const editGoal: (param: { goal: PlanAspectGoal }) => Promise<Plan> = ({
  goal,
}) =>
  put<PlanAspectGoal>(`/plan/aspect/goal/${goal.id}`, {
    ...goal,
    id: undefined,
  });

const addGoalAction: (param: {
  action: PlanGoalAction;
  goalId: number;
}) => Promise<Plan> = ({ action, goalId }) =>
  post<Plan>(`/plan/aspect/goal/${goalId}/action`, action);

const deletePlan: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/plan/${id!}`);

const deleteAction: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/plan/aspect/goal/action/${id!}`);

const deletePlanGoal: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/plan/aspect/goal/${id!}`);

const deletePlanAspect: (id: number) => Promise<void> = (id) =>
  deleteMethod(`/plan/aspect/${id!}`);

const reorderAspects = (param: { planId: number; aspects: number[] }) =>
  put<{ newOrder: number[] }>(`/plan/${param.planId}/aspect/reorder`, {
    newOrder: param.aspects,
  });

const reorderAspectGoals = (param: { aspectId: number; goals: number[] }) =>
  put<{ newOrder: number[] }>(`/plan/aspect/${param.aspectId}/goal/reorder`, {
    newOrder: param.goals,
  });

const reorderGoalActions = (param: { goalId: number; actions: number[] }) =>
  put<{ newOrder: number[] }>(
    `/plan/aspect/goal/${param.goalId}/action/reorder`,
    {
      newOrder: param.actions,
    }
  );

const addNewGroup = (param: { name: string }) =>
  post<{ name: string }>(`/entity-groups/create`, { name: param.name });

const deleteGroup = (param: { id: number }) =>
  deleteMethod(`/entity-groups/${param.id}/remove`);

const addWeight = (param: { id: number; weight: number }) => {
  return put<{ weight: number }>(`/plan/aspect/goal/action/${param.id}`, {
    weight: param.weight,
  });
};

const addPlanToGroup = (param: {
  planId: number;
  groupId?: number;
  entityType: string;
}) => {
  return put<{
    entityId: number;
    entityType: string;
  }>(`/entity-groups/${param.groupId}/move`, {
    entityId: param.planId,
    entityType: param.entityType,
  });
};

const removePlanFromGroup = (param: { planId: number; entityType: string }) => {
  return put<{ entityType: string }>(
    `/entity-groups/${param.planId}/remove-entity`,
    {
      entityType: param.entityType,
    }
  );
};

export {
  douplicate,
  deletePlan,
  create,
  addAspect,
  addGoalAction,
  deleteAction,
  editAction,
  deletePlanGoal,
  deletePlanAspect,
  addGoal,
  editGoal,
  editPlanName,
  editAspect,
  reorderAspects,
  reorderAspectGoals,
  reorderGoalActions,
  addNewGroup,
  deleteGroup,
  addWeight,
  addPlanToGroup,
  removePlanFromGroup,
  douplicateShared,
};
