import {
  Box,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import AccessPermission from "src/components/AccessPermission/AccessPermission";
import { Vision } from "src/types";
import { DetailsDisclosureProps } from "../Missions/useUIState";

type WishListModalProps = {
  disclosureProps: DetailsDisclosureProps;
  vision?: Vision;
  isLoading?: boolean;
  error: Error | null;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
  owner?: any;
};
const WishListModal: React.FC<WishListModalProps> = ({
  disclosureProps,
  vision,
  isLoading,
  error,
  isSharedWithMe,
  canUpdate,
  owner
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal {...disclosureProps}>
      <ModalOverlay />
      <ModalContent px={"72px"} borderRadius="30px" maxW="50rem">
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            "Loading..."
          ) : error ? (
            error.message
          ) : vision ? (
            <>
              {isSharedWithMe ? <Box mb="4">
                <Box>
                  <div>
                    <p>
                      <span>{formatMessage({ id: "sharedBy" })}</span> <span>{owner?.name}</span>
                    </p>
                    <small>{owner?.email}</small>
                  </div>
                  <AccessPermission permission={canUpdate ? "update" : "read"} />
                </Box>
              </Box> : null}
              <Text
                d="inline-block"
                color="brand.900"
                fontSize="18px"
                fontWeight="bold"
                lineHeight="26px"
                fontFamily="Calibri (Body)"
                mb={"16px"}
              >
                {vision!.name}
              </Text>
              <OrderedList
                fontSize={"16px"}
                fontFamily="Calibri (Body)"
                lineHeight="22px"
                color="gray.900"
                spacing={2}
                mb={"32px"}
              >
                {vision.wishes.map((w, idx) => (
                  <ListItem key={idx}>{w.name}</ListItem>
                ))}
              </OrderedList>
            </>
          ) : (
            <></>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default WishListModal;
