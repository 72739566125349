import { Accordion, VStack } from "@chakra-ui/react";
import React from "react";

export default function Categories(props: {
  categories: any;
  filter: (category: any) => any;
  element: (waveCategory, idx) => JSX.Element;
}) {
  return (
    <VStack
      display={"flex"}
      wrap={"wrap"}
      margin={"50px auto"}
      width="100%"
      flexDir="row"
      justifyContent="center"
      alignItems="center"
    >
      <Accordion allowMultiple w="100%">
        {props.categories?.filter(props.filter)?.map(props.element).reverse()}
      </Accordion>
    </VStack>
  );
}
