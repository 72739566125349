import Preparation from "./views/Prepration";
import Affirmation from "./views/Affirmation";
import Communication from "./views/Communication/Communication";
import Frequency from "./views/Frequency/Frequency";
import Gates from "./views/Gates/Gates";
import Learning from "./views/Learning/Learning";
import Train from "./views/Train/Train";
import Playlists from "./views/Playlists/Playlists";
import FAQ from "./views/FAQ/FAQ";
import Description from "./views/Description/Description";
import AutoLogin from "./views/AutoLogin/AutoLogin";
import Portal from "./views/Portal";
import SalMagicFAQ from "./views/FAQ/SalMagicFAQ";
import PureTones from "./views/Frequency/SalMagic/PureTones";
import OtherSources from "./views/Frequency/SalMagic/OtherSources";

export const CONTENT = "/content";

export const PREPARATION_ROUTE = CONTENT + "/preparation";
// make routes depend on parent routes so if we changed the parent route, we would change the child route as well
export const VALUES_ROUTE = PREPARATION_ROUTE + "/values";
export const PURPOSES_ROUTE = PREPARATION_ROUTE + "/purposes";
export const VISIONS_ROUTE = PREPARATION_ROUTE + "/visions";
export const MISSIONS_ROUTE = PREPARATION_ROUTE + "/missions";
export const SALPLAN_ROUTE = PREPARATION_ROUTE + "/salplan";
export const SALPLAN_ROUTE_DETAILS = SALPLAN_ROUTE + "/:id";
export const FOCUS_POSTER_ROUTE = PREPARATION_ROUTE + "/focus";
export const FOCUS_POSTER_ROUTE_DETAILS = FOCUS_POSTER_ROUTE + "/:id";

export const AFFIRMATION_ROUTE = CONTENT + "/affirmation";
export const INTENTION_ROUTE = AFFIRMATION_ROUTE + "/sendingintention";
export const CREATEPOSSIBILITY_ROUTE = AFFIRMATION_ROUTE + "/createpossibility";
export const SUBLIMINAL_ROUTE = AFFIRMATION_ROUTE + "/subliminal";
export const SUBLIMINAL_AUDIO_ROUTE = SUBLIMINAL_ROUTE + "/audio/:id";
export const STORY_ROUTE = CREATEPOSSIBILITY_ROUTE + "/story";
export const MAGIC_ROUTE = CREATEPOSSIBILITY_ROUTE + "/magic";
export const PROBLEM_ROUTE = CREATEPOSSIBILITY_ROUTE + "/problem";
export const CLEAR_AND_PROTECT_ROUTE = AFFIRMATION_ROUTE + "/clearandprotect";
export const AREA_ROUTE = CLEAR_AND_PROTECT_ROUTE + "/area";
export const PERSONAL_ROUTE = CLEAR_AND_PROTECT_ROUTE + "/personal";

//Communications Routes
export const COMMUNICATION_ROUTE = CONTENT + "/communication";
export const HEALING_ROUTE = COMMUNICATION_ROUTE + "/healingguide";
export const TAPPING_ROUTE = HEALING_ROUTE + "/tapping";
export const NINE_G_ROUTE = HEALING_ROUTE + "/9g";
export const DISEASE_ROUTE = COMMUNICATION_ROUTE + "/symptoms";
export const PARTS_ROUTE = DISEASE_ROUTE + "/:gender";
export const INNER_ROUTE = PARTS_ROUTE + "/:section";
export const DETAIL_ROUTE = INNER_ROUTE + "/:organ";
export const SESSION_ROUTE = COMMUNICATION_ROUTE + "/session";
export const ARCHIVE_ROUTE = COMMUNICATION_ROUTE + "/archiveSessions";

export const FREQUENCY_ROUTE = CONTENT + "/frequency";
export const WAVES_ROUTE = FREQUENCY_ROUTE + "/waves";
export const WAVE_DETAIL_ROUTE = WAVES_ROUTE + "/category/:categoryId/wave/:id";
export const SOLFEGGIO_ROUTE = FREQUENCY_ROUTE + "/solfeggio";
export const CHAKRAS_ROUTE = FREQUENCY_ROUTE + "/chakras";

export const PURE_TONES_ROUTE = CONTENT + "/sal-magic/pure-tones";
export const OTHER_SOURCES_ROUTE = CONTENT + "/sal-magic/other-sources";

export const GATE_ROUTE = CONTENT + "/gate";
export const BALANCE_GATE_ROUTE = GATE_ROUTE + "/balancegate";
export const MANIFESTATION_GATE_ROUTE = GATE_ROUTE + "/transfigurationgate";

export const LEARN_ROUTE = CONTENT + "/learn";
export const TRAIN_ROUTE = CONTENT + "/train";
export const PLAYLISTS_ROUTE = CONTENT + "/playlists";
export const DESCRIPTION_ROUTE = CONTENT + "/description";

export const FAQ_ROUTE = CONTENT + "/faq";
export const SAL_MAGIC_FAQ_ROUTE = CONTENT + "/sal-magic/faq";

export const PORTAL_ROUTE = "/portal";

export const REGISTER_ROUTE = "/register";
export const SUBSCRIPTION_ROUTE = "/subscription";
export const LOGIN_ROUTE = "/login";
export const LOGIN_SMARTSWAY_ROUTE = "/autologin";
export const LOGOUT_ROUTE = "/logout";
export const FORGET_ROUTE = "/forget";
export const FORGET_RESET_ROUTE = "/forget/reset";
export const EMAIL_VERIFICATION_ROUTE = "/verify";
export const PAYMENT_DONE_ROUTE = "/payment/success";
export const SETTINGS_ROUTE = "/settings";
export const PRELOADING_ROUTE = "/preloading";

export const LANDING_PAGE_ROUTE = "https://landing.sal125.smartsway.com/";

export const routes = [
  {
    path: PORTAL_ROUTE,
    component: Portal,
  },
  {
    path: VALUES_ROUTE,
    component: Preparation.Values,
  },
  {
    path: PURPOSES_ROUTE,
    component: Preparation.Purposes,
  },
  {
    path: VISIONS_ROUTE,
    component: Preparation.Visions,
  },
  {
    path: MISSIONS_ROUTE,
    component: Preparation.Missions,
  },
  // Upcoming Feature
  {
    path: SALPLAN_ROUTE_DETAILS,
    component: Preparation.SalPlan.PlanContent,
  },
  {
    path: SALPLAN_ROUTE,
    component: Preparation.SalPlan,
  },
  {
    path: FOCUS_POSTER_ROUTE_DETAILS,
    component: Preparation.FocusPoster.PosterDetails,
  },
  {
    path: FOCUS_POSTER_ROUTE,
    component: Preparation.FocusPoster,
  },
  {
    path: PREPARATION_ROUTE,
    component: Preparation,
  },
  {
    path: INTENTION_ROUTE,
    component: Affirmation.Intention,
  },
  {
    path: STORY_ROUTE,
    component: Affirmation.Story,
  },
  {
    path: MAGIC_ROUTE,
    component: Affirmation.Magic,
  },
  {
    path: PROBLEM_ROUTE,
    component: Affirmation.Problem,
  },
  {
    path: AREA_ROUTE,
    component: Affirmation.Area,
  },
  {
    path: PERSONAL_ROUTE,
    component: Affirmation.Personal,
  },
  {
    path: CLEAR_AND_PROTECT_ROUTE,
    component: Affirmation.ClearAndProtect,
  },
  {
    path: SUBLIMINAL_AUDIO_ROUTE,
    component: Affirmation.Subliminal.AudioDetail,
  },
  {
    path: SUBLIMINAL_ROUTE,
    component: Affirmation.Subliminal,
  },
  {
    path: CREATEPOSSIBILITY_ROUTE,
    component: Affirmation.CreatePossibility,
  },
  {
    path: AFFIRMATION_ROUTE,
    component: Affirmation,
  },
  {
    path: ARCHIVE_ROUTE,
    component: Communication.Archive,
  },
  {
    path: SESSION_ROUTE,
    component: Communication.Session,
  },
  {
    path: TAPPING_ROUTE,
    component: Communication.Healing.Tapping,
  },
  {
    path: NINE_G_ROUTE,
    component: Communication.Healing.NineG,
  },
  {
    path: HEALING_ROUTE,
    component: Communication.Healing,
  },
  {
    path: DETAIL_ROUTE,
    component: Communication.Healing.Detail,
  },
  {
    path: INNER_ROUTE,
    component: Communication.Healing.Inner,
  },
  {
    path: PARTS_ROUTE,
    component: Communication.Healing.Parts,
  },
  {
    path: DISEASE_ROUTE,
    component: Communication.Healing.Disease,
  },
  {
    path: COMMUNICATION_ROUTE,
    component: Communication,
  },
  {
    path: WAVE_DETAIL_ROUTE,
    component: Frequency.Waves.WaveDetail,
  },
  {
    path: WAVES_ROUTE,
    component: Frequency.Waves,
  },
  {
    path: SOLFEGGIO_ROUTE,
    component: Frequency.Solfeggio,
  },
  {
    path: CHAKRAS_ROUTE,
    component: Frequency.Chakras,
  },
  {
    path: FREQUENCY_ROUTE,
    component: Frequency,
  },
  {
    path: BALANCE_GATE_ROUTE,
    component: Gates.BalanceGate,
  },
  {
    path: MANIFESTATION_GATE_ROUTE,
    component: Gates.ManifestationGate,
  },
  {
    path: GATE_ROUTE,
    component: Gates,
  },
  {
    path: LOGIN_SMARTSWAY_ROUTE,
    component: AutoLogin,
  },
  {
    path: LEARN_ROUTE,
    component: Learning,
  },
  {
    path: TRAIN_ROUTE,
    component: Train,
  },
  {
    path: PLAYLISTS_ROUTE,
    component: Playlists,
  },
  {
    path: FAQ_ROUTE,
    component: FAQ,
  },
  {
    path: SAL_MAGIC_FAQ_ROUTE,
    component: SalMagicFAQ,
  },
  {
    path: DESCRIPTION_ROUTE,
    component: Description,
  },
  {
    path: PURE_TONES_ROUTE,
    component: PureTones,
  },
  {
    path: OTHER_SOURCES_ROUTE,
    component: OtherSources,
  },
];
