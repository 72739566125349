import React from "react";
import { Box, Button, HStack } from "@chakra-ui/react";
import { PurposesTypes } from "./purposesTypes";
import { FormattedMessage } from "react-intl";

const NavBar: React.FC<{
  state: [PurposesTypes, (value: PurposesTypes) => void];
}> = ({ state: [current, setCurrent] }) => {
  return (
    <Box d="flex" justifyContent="center" h="80px">
      <HStack gridGap="5">
        <Button
          variant="link"
          color="gray.900"
          fontWeight="normal"
          aria-current={current === PurposesTypes.PURPOSES ? "page" : "false"}
          value="yo"
          onClick={() => setCurrent(PurposesTypes.PURPOSES)}
          _activeLink={{ color: "brand.800", textDecor: "underline" }}
        >
          <FormattedMessage id="purposes.purposes" defaultMessage="الرسائل" />
        </Button>
        <Button
          variant="link"
          fontWeight="normal"
          color="gray.900"
          aria-current={current === PurposesTypes.ARCHIVE ? "page" : "false"}
          _activeLink={{ color: "brand.800", textDecor: "underline" }}
          onClick={() => setCurrent(PurposesTypes.ARCHIVE)}
        >
          <FormattedMessage id="purposes.archive" defaultMessage="الارشيف" />
        </Button>
      </HStack>
    </Box>
  );
};
export default NavBar;
