import React, { useState } from "react";
import { Box, Text, Tag, useToast, Image } from "@chakra-ui/react";
import ItemCard from "src/components/ItemCard";
import { useIntl } from "react-intl";
import usePlaylists from "./usePlaylists";
import useLocale from "src/providers/useLocale";

const PlaylistItem = ({
  name,
  slug,
  frequency,
  trackType,
  tag,
  url,
  id,
  play,
}) => {
  // const [play, setPlay] = useState(false)
  const {
    deleteTrackMutation,
    playlistsQuery: { refetch: refetchPlaylists },
  } = usePlaylists();

  const { formatMessage } = useIntl();
  const toast = useToast();
  const [locale] = useLocale();
  const deleteTrack = async (trackId) => {
    try {
      await deleteTrackMutation(trackId);
      toast({
        title: formatMessage({ id: "deleted" }),
        status: "success",
      });
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.edit.name.failed" }),
        status: "error",
      });
    }
  };
  return (
    <Box as="span" flex="1" textAlign="left">
      <ItemCard
        remove={() => {
          deleteTrack(id);
        }}
        onPlay={() => {
          play();
        }}
        onPause={() => {
          console.log("pause");
        }}
        // isPlaying={play}
      >
        <Box
          d="flex"
          alignItems={"center"}
          alignContent={["column", "column", "row"]}
          flexDirection={["column", "column", "row"]}
        >
          <Text
            m={"0"}
            // color={"#8b8b8b"}
            fontFamily={"tajwal"}
          >
            {name || slug || frequency}
          </Text>

          <Tag
            size={"lg"}
            borderRadius="full"
            variant="solid"
            mx={["0", "0", "3"]}
            alignSelf="flex-start"
            textTransform={"capitalize"}
            // textAlign="center"
            // px="auto"
            // colorScheme='blue'
          >
            {formatMessage({ id: tag.replace(/([a-z])([A-Z])/g, "$1 $2") })}
          </Tag>
          {tag === "waves" ? (
            trackType ? (
              <>
                {trackType === "music" ? (
                  <Image
                    loading="lazy"
                    alt="SAL Logo"
                    src="/wave.png"
                    me="auto"
                    height="15px"
                    marginRight={1}
                    color={"#2895ff"}
                    style={{
                      cursor: "pointer",
                      opacity: 0.6,
                      transform: locale === "ar" ? "scaleX(-1)" : "",
                    }}
                  />
                ) : (
                  <Image
                    loading="lazy"
                    alt="SAL Logo"
                    src="/pure-tone.png"
                    me="auto"
                    height="15px"
                    marginRight={1}
                    color={"#2895ff"}
                    style={{
                      cursor: "pointer",
                      opacity: 0.6,
                    }}
                  />
                )}
              </>
            ) : (
              <Image
                loading="lazy"
                alt="SAL Logo"
                src="/wave.png"
                me="auto"
                height="15px"
                marginRight={1}
                color={"#2895ff"}
                style={{
                  cursor: "pointer",
                  opacity: 0.6,
                }}
              />
            )
          ) : (
            <></>
          )}
        </Box>
      </ItemCard>
    </Box>
  );
};

export default PlaylistItem;
