import {
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PrimaryButton from "src/components/PrimaryButton";
import { useQuery } from "react-query";
import { getList } from "src/api/prepration/values/query";

type PurposesCreateProps = {
  disclosureProps: UseDisclosureReturn;
  createHandler: (name: string) => Promise<void>;
};
const PurposeCreateModal: React.FC<PurposesCreateProps> = ({
  disclosureProps,
  createHandler,
}) => {
  const [tempName, setTempName] = useState("");
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const { data: valuesList } = useQuery(...getList);

  useEffect(() => {
    if (!disclosureProps.isOpen) {
      setTempName("");
      setDisableBtn(false);
    }
  }, [disclosureProps.isOpen]);
  return (
    <Modal {...disclosureProps}>
      <ModalOverlay />
      <ModalContent px={"72px"} borderRadius="30px" maxW="50rem">
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody padding="25px 0">
          {valuesList && valuesList.length > 0 ? (
            <form
              onSubmit={(e: FormEvent) => {
                e.preventDefault();

                createHandler(tempName);
                setDisableBtn(true);
              }}
            >
              <Text
                d="inline-block"
                color="brand.900"
                fontSize="18px"
                fontWeight="bold"
                fontFamily="Calibri (Body)"
              >
                <FormattedMessage
                  id="message.create.title"
                  defaultMessage="ادخل عنوان الرسالة"
                />
              </Text>
              <Input
                variant="flushed"
                mb={4}
                value={tempName}
                onChange={(e) => setTempName(e.target.value)}
              />
              <Center>
                <PrimaryButton type="submit" disabled={!tempName || disableBtn}>
                  <FormattedMessage
                    id="message.create.button"
                    defaultMessage="إنشاء"
                  />
                </PrimaryButton>
              </Center>
            </form>
          ) : (
            <div style={{ padding: "30px 10px", color: "grey" }}>
              <FormattedMessage
                id="purposes.error.create_matrix"
                defaultMessage="من فضلك قم بإنشاء مصفوفة فيم واحده على الاقل"
              />
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default PurposeCreateModal;
