import {
  Box,
  Button,
  Text,
  useToast,
  IconButton,
  Center,
  Image,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ModalSpinner from "src/components/modalSpinner";
import usePlaylists from "src/views/Playlists/usePlaylists";
import { MdMusicOff, MdMusicNote } from "react-icons/md";

const PlaylistsList = ({ entityName, track, onAdd }) => {
  const {
    playlistsQuery: { data: playlists, isLoading, error },
    addToPlaylistMutation,
  } = usePlaylists();
  const { formatMessage } = useIntl();
  const toast = useToast();
  const addToPlaylist = useCallback(
    async (
      playlistId: number,
      entityName: string,
      entityId: number,
      trackType?: string
    ) => {
      const res = await addToPlaylistMutation({
        playlistId,
        entityName,
        entityId,
        trackType,
      });
      if (res?.id) {
        toast({
          title: formatMessage({ id: "saved" }),
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      }
    },
    [addToPlaylistMutation, formatMessage, toast]
  );
  if (error) return null;
  if (isLoading) return ModalSpinner;
  return (
    <Box>
      {playlists.length ? (
        playlists.map((playlist) => {
          let index = playlist.tracks.findIndex((item) => {
            if (item.entityName === "waves") {
              return item.tracksIds.includes(track.entityId);
            }
            const trackCompositeId = track.entityName + track.entityId;
            const itemCompositeId = item.entityName + item.entityId;

            return itemCompositeId === trackCompositeId;
          });
          const isButtonDisabled = index !== -1;
          return (
            <Box d="flex" justifyContent={"space-between"} alignItems="center">
              <Text>{playlist.name}</Text>

              {entityName === "waves" ? (
                <Box d="flex">
                  {track.withMusicUrl && (
                    <Button
                      disabled={isButtonDisabled}
                      onClick={() => {
                        addToPlaylist(
                          playlist.id,
                          entityName,
                          track.entityId,
                          "music"
                        );
                      }}
                    >
                      {isButtonDisabled ? (
                        <FormattedMessage id={"added"} />
                      ) : (
                        <>
                          <FormattedMessage id={"add"} />
                          <Image
                            marginLeft={2}
                            loading="lazy"
                            alt="SAL Logo"
                            src="/wave.png"
                            me="auto"
                            height="15px"
                            marginRight={1}
                            color={"#2895ff"}
                            style={{
                              cursor: "pointer",
                              opacity: 0.6,
                            }}
                          />
                        </>
                      )}
                    </Button>
                  )}

                  {track.musicFreeUrl && (
                    <Button
                      disabled={isButtonDisabled}
                      onClick={() => {
                        addToPlaylist(
                          playlist.id,
                          entityName,
                          track.entityId,
                          "pure"
                        );
                      }}
                    >
                      {isButtonDisabled ? (
                        <FormattedMessage id={"added"} />
                      ) : (
                        <>
                          <FormattedMessage id={"add"} />
                          <Image
                            marginLeft={2}
                            loading="lazy"
                            alt="SAL Logo"
                            src="/pure-tone.png"
                            me="auto"
                            height="15px"
                            marginRight={1}
                            color={"#2895ff"}
                            style={{
                              cursor: "pointer",
                              opacity: 0.6,
                            }}
                          />
                        </>
                      )}
                    </Button>
                  )}
                </Box>
              ) : (
                <>
                  <Button
                    disabled={isButtonDisabled}
                    onClick={() => {
                      addToPlaylist(
                        playlist.id,
                        entityName,
                        track.entityId,
                        undefined
                      );
                    }}
                  >
                    <FormattedMessage id={isButtonDisabled ? "added" : "add"} />
                  </Button>
                </>
              )}
            </Box>
          );
        })
      ) : (
        <Center h="300px" d="flex" flexDir={"column"}>
          <Text textAlign={"center"}>
            <FormattedMessage id={"noPlaylists"} />
          </Text>
          <IconButton
            className="remove-on-print"
            onClick={onAdd}
            size="lg"
            border="2px solid #E2E8F0"
            aria-label="add"
            m="5"
            icon={<AddIcon />}
          />
        </Center>
      )}
    </Box>
  );
};

export default PlaylistsList;
