import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";

const getList: [string, QueryFunction<any>] = [
  "playlists",
  async () => {
    return await get<any>("/playlist");
  },
];

const getOne = (id: number | undefined) =>
  [
    ["playlists", id],
    () => (id ? get<any>(`/playlist/${id}`) : Promise.reject(null)),
    { enabled: !(id === undefined) },
  ] as [[string, number], QueryFunction<any>, UseQueryOptions<any, Error>];

export { getList, getOne };
