import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonProps,
  ModalProps as ChakraModalProps,
  ModalHeader,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalContent,
  ModalContentProps,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";

type ModalProps = {
  btnProps: ButtonProps;
  modalProps?: ChakraModalProps;
  bodyProps: ModalBodyProps;
  headerProps?: ModalHeaderProps;
  footerProps?: ModalFooterProps;
  disclosureProps: UseDisclosureReturn;
  contentProps?: ModalContentProps;
};
const Modal: React.FC<ModalProps> = ({
  btnProps,
  modalProps,
  bodyProps,
  headerProps,
  footerProps,
  disclosureProps,
  contentProps,
}) => {

  return (
    <>
      <Button onClick={disclosureProps.onOpen} {...btnProps} />
      <ChakraModal {...modalProps} {...disclosureProps} motionPreset='none' isCentered size="xl">
        <ModalOverlay />
        <ModalContent borderRadius="25px" {...contentProps}>
          <ModalCloseButton />
          {headerProps ? <ModalHeader {...headerProps} /> : null}
          <ModalBody p="6" {...bodyProps} />
          {footerProps ? <ModalFooter {...footerProps} p="0" /> : null}
        </ModalContent>
      </ChakraModal>
    </>
  );
};
export default Modal;
