import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ActionBar from "../ActionBar";
import { MissionQuery } from "./useMissionApi";
import { DetailsDisclosureProps } from "./useUIState";
import modalSpinner from "src/components/modalSpinner";
import { useIntl } from "react-intl";
import AccessPermission from "src/components/AccessPermission/AccessPermission";

type MissionViewProps = {
  disclosureProps: DetailsDisclosureProps;
  missionQuery: MissionQuery;
  resort: (id: number) => void;
  remove: (id: number) => void;
  canDelete?: boolean;
  canRestart?: boolean;
  isSharedWithMe?: boolean;
  sharingDetails?: any;
};
const MissionView: React.FC<MissionViewProps> = ({
  disclosureProps,
  missionQuery: { data: mission, isLoading, isError, error, isIdle },
  remove,
  resort,
  canDelete,
  canRestart,
  isSharedWithMe,
  sharingDetails
}) => {
  const [textToCopy, setTextToCopy] = useState<string>("");
  const { formatMessage } = useIntl();
  useEffect(() => {
    let valuesString: any = [];

    if (mission) {
      valuesString.push(mission.note);
    }
    setTextToCopy(valuesString.join("\n"));
  }, [mission]);

  const intl = useIntl();

  if (isIdle) return null;
  if (isError) {
    return null;
  }
  if (!mission) return <></>;

  return (
    <Modal {...disclosureProps}>
      <ModalOverlay />
      <ModalContent
        px={["12px", "24px", "72px"]}
        borderRadius="30px"
        maxW="50rem"
        paddingBottom="20px"
      >
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            modalSpinner
          ) : (
            <>
              {isSharedWithMe ? <Box mb="4">
                <Box>
                  <div>
                    <p>
                      <span>{formatMessage({ id: "sharedBy" })}</span> <span>{sharingDetails?.owner?.name}</span>
                    </p>
                    <small>{sharingDetails?.owner?.email}</small>
                  </div>
                  <AccessPermission permission={sharingDetails?.permission} />
                </Box>
              </Box> : null}
              <Box
                d="inline-block"
                color="brand.900"
                fontSize="24px"
                fontWeight="bold"
                fontFamily="Calibri (Body)"
                mb={"1.5rem"}
                dir="auto"
                style={{
                  width: "100%",
                  wordWrap: "break-word",
                  height: "100px",
                }}
              >
                {mission!.name}
              </Box>
              <Text
                color="#000"
                fontFamily="Calibri (Body)"
                mb={"3rem"}
                fontWeight="bold"
              >
                {mission!.note}
              </Text>
              <ActionBar
                pb={"1rem"}
                onDelete={() => remove(mission.id!)}
                textToCopy={textToCopy}
                canDelete={canDelete}
                canRestart={canRestart}
                onResort={() => resort(mission.id!)}
                title={intl.formatMessage(
                  { id: "print.mission" },
                  { name: mission.name }
                )}
                keyTitle="mission"

              />
            </>
          )}
        </ModalBody>
        <div style={{ display: "none" }}>
          <Box
            id="text-to-mail"
            padding="20px"
            data-subject={intl.formatMessage({ id: "missions" })}
          >
            <Box dir="auto">
              <Text
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  fontSize: "18px",
                }}
              >
                {intl.formatMessage({ id: "missions" })}: ({mission.name})
              </Text>
            </Box>
            <Box dir="auto" style={{ textAlign: "center", paddingTop: "20px" }}>
              <p>{mission.note}</p>
            </Box>
          </Box>
        </div>
      </ModalContent>
    </Modal>
  );
};
export default MissionView;
