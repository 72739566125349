import React from "react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

function SliderChakra(props: any) {
  return (
    <Slider
      aria-label="slider-ex-1"
      defaultValue={30}
      onChange={(val) => {
        props.onChangeSize(val);
      }}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxShadow="0 0 10px 5px silver" />
    </Slider>
  );
}

export default SliderChakra;
