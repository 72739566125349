import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React, {
  // useCallback,
  useState,
  useEffect,
} from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useIntl } from "react-intl";
import { useDispatch } from "src/store";
import PlaylistItem from "./PlaylistItem";
import PlaylistName from "./PlaylistName";
// import usePlaylists from './usePlaylists';
import { playPlaylist, playMedia } from "src/store/media";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { usePlaylist } from "./usePlaylist";

const Playlist = ({
  id,
  name,
  tracks,
  playlistId,
  setCurrentPlaylist,
  onDelete,
}) => {
  const [play, setPlay] = useState(false);
  const [tracksState, setTracksState] = useState(tracks);
  const dispatch = useDispatch();
  const { reorderMutation } = usePlaylist();
  //effect to update tracks state
  useEffect(() => {
    setTracksState(tracks);
  }, [tracks]);

  // const toast = useToast();
  // const { deleteMutation } = usePlaylists()
  const { formatMessage } = useIntl();
  // const deletePlaylist = useCallback(async (id) => {
  //     const res = await deleteMutation(id)
  //     if (res) {
  //         toast({
  //             title: formatMessage({ id: "deleted" }),
  //             status: "success",
  //             isClosable: true,
  //         });
  //     } else {
  //         toast({
  //             title: formatMessage({ id: "wentWrong" }),
  //             status: "error",
  //             isClosable: true,
  //         });
  //     }
  // }, [deleteMutation, formatMessage, toast],)

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...tracksState];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    //create order array to update the backend
    const order = updatedList.map((item, index) => `${item.id}`);

    //update db
    reorderMutation({ playlistId, body: { newOrder: order } });

    // Update State
    setTracksState(updatedList);
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          d="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          as="div"
        >
          <Box>
            <PlaylistName id={id} name={name} />
          </Box>
          <Box d="flex" alignItems={"center"} justifyContent="center">
            <IconButton
              mx={2}
              color="brand.800"
              onClick={(e) => {
                e.stopPropagation();
                tracksState.length &&
                  dispatch(playPlaylist({ playlist: tracksState, playlistId }));
                setCurrentPlaylist({ id: playlistId, name });
              }}
              variant="link"
              aria-label="delete"
              fontSize="23px"
              minW={1}
              icon={play ? <PauseIcon /> : <PlayArrowIcon />}
              h="5"
            />
            <IconButton
              mx={2}
              color="#e97575"
              onClick={(e) => {
                e.stopPropagation();
                // deletePlaylist(id)
                setCurrentPlaylist({ id: playlistId, name });
                onDelete();
              }}
              variant="link"
              aria-label="delete"
              fontSize="23px"
              minW={1}
              icon={<RiDeleteBin6Line />}
              h="5"
            />
          </Box>
        </AccordionButton>
      </h2>

      <AccordionPanel pb={4}>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tracksState.map((track, idx) => (
                  <Draggable
                    key={`${track.id}`}
                    draggableId={`${track.id}`}
                    index={idx}
                  >
                    {(provided) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        {
                          <PlaylistItem
                            key={track.id}
                            id={track.id}
                            name={track.name}
                            slug={track.slug}
                            frequency={track.frequency}
                            tag={track.entityName}
                            url={track.audioUrl}
                            trackType={track.trackType}
                            play={() => {
                              dispatch(
                                playPlaylist({
                                  playlist: tracksState,
                                  startIndex: idx,
                                  playlistId,
                                })
                              );
                            }}
                          />
                        }
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {tracksState.length ? (
          <></>
        ) : (
          <Text textAlign={"center"}>
            {formatMessage({ id: "emptyPlaylist" })}
          </Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Playlist;
