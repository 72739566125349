import { Box, HStack, Text, Checkbox, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { MagicGoalList } from "src/types";
import { getList } from "src/api/affirmation/magic/query";
import * as mutation from "src/api/affirmation/magic/mutation";
import ViewGoalModal from "./ViewGoalModal";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineReload } from "react-icons/ai";
import DeleteButton from "src/components/DeleteModel";

const MagicBox = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [problems, setProblems] = useState([]);
  const [activities, setActivities] = useState<string>("");

  const { data } = useQuery<MagicGoalList, Error, MagicGoalList>(...getList);
  const client = useQueryClient();
  const { mutateAsync: deleteMutation } = useMutation(mutation.deleteGoal, {
    onSuccess: () => client.invalidateQueries("listMagic"),
  });
  const { mutateAsync: editMutation } = useMutation(mutation.editGoal, {
    onSuccess: () => client.refetchQueries(getList[0]),
  });
  const resortFunc = async (...args: any) => {
    await setName(args[0]);
    await setActivities(args[1]);
    await setProblems(args[2]);
    await setNote(args[3]);
    setOpenModal(true);
  };

  return (
    <Box textAlign={"left"} overflowX='scroll'>
      <Table variant='simple' size={"sm"} minW={"680px"}>
        <Thead className='actions-table-header'>
          <Tr>
            <Th fontFamily="Calibri, 'Gill Sans'">
              <Text fontSize={16} textTransform='capitalize'>
                <FormattedMessage id={"magicbox.goal"} defaultMessage={"هدف"} />
              </Text>
            </Th>
            <Th textTransform='capitalize' fontFamily="Calibri, 'Gill Sans'">
              <Text fontSize={16}>
                <FormattedMessage id={"magicbox.startdate"} defaultMessage={"تاريخ البدء"} />
              </Text>
            </Th>
            <Th textTransform='capitalize' fontFamily="Calibri, 'Gill Sans'">
              <Text fontSize={16}>
                <FormattedMessage id={"magicbox.checked"} defaultMessage={"تم إنجازه؟"} />
              </Text>
            </Th>
            <Th textTransform='capitalize' fontFamily="Calibri, 'Gill Sans'"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            {/* <Td>inches</Td>
            <Td>millimetres (mm)</Td>
            <Td isNumeric>25.4</Td>
            <Td isNumeric>25.4</Td> */}
          </Tr>
          {data &&
            data.map((e: any, idx: number) => {
              return (
                <Tr>
                  <Td>{e.name}</Td>
                  <Td>{e.startDate.slice(0, 10).split("-").reverse().join("/")}</Td>
                  <Td>
                    {e.endDate ? (
                      <Text m={"0"} color={"#8b8b8b"} fontFamily={"tajwal"}>
                        {e.endDate.slice(0, 10).split("-").reverse().join("/")}
                      </Text>
                    ) : (
                      <Checkbox
                        onChange={async () => {
                          editMutation({
                            endDate: new Date().toISOString(),
                            name: e.name,
                            id: e.id,
                            activity: e.activity,
                            problems: e.problems,
                            note: e.note,
                          });
                        }}
                        bg={"white"}
                        border={".3px solid rgba(40, 149, 255, 0.6)"}
                        borderRadius={"6px"}
                      />
                    )}
                  </Td>
                  <Td>
                    <HStack>
                      <IconButton
                        me='1'
                        minW={1}
                        onClick={() => {
                          resortFunc(e.name, e.activity, e.problems, e.note);
                        }}
                        color='brand.800'
                        variant='link'
                        aria-label='re-answer'
                        fontSize='23px'
                        icon={<AiOutlineReload />}
                        h='5'
                      />
                      <DeleteButton remove={() => deleteMutation(e.id)} />
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      <ViewGoalModal
        open={openModal}
        name={name}
        activities={activities}
        problems={problems}
        note={note}
        closeModal={() => setOpenModal(false)}
      />
    </Box>
  );
};

export default MagicBox;
