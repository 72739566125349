import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AccessPermission from "src/components/AccessPermission/AccessPermission";
import { Vision } from "src/types";
import ActionBar from "../ActionBar";
import { DetailsDisclosureProps } from "../Missions/useUIState";

type WishesDetailsProps = {
  disclosureProps: DetailsDisclosureProps;
  vision?: Vision;
  isLoading?: boolean;
  error: Error | null;
  isSharedWithMe?: boolean;
  canUpdate?: boolean;
  owner?: any;
};
const VisionDetailsModal: React.FC<
  WishesDetailsProps & {
    remove: (id: number) => void;
    openWishesModal: (id: number) => void;
  }
> = ({
  disclosureProps,
  vision,
  isLoading,
  error,
  openWishesModal,
  remove,
  isSharedWithMe,
  canUpdate,
  owner
}) => {
    const intl = useIntl();
    const [textToCopy, setTextToCopy] = useState<string>("");

    useEffect(() => {
      let valuesString: any = [];
      if (!vision?.top10) return;
      valuesString.push("");
      vision.top10.forEach((value) => {
        valuesString.push(value.name);
        valuesString.push("");
      });
      setTextToCopy(valuesString.join("\n"));
      return () => {
        setTextToCopy("");
      };
    }, [vision]);
    const { formatMessage } = useIntl();
    // console.log(isSharedWithMe);

    return (
      <Modal {...disclosureProps}>
        <ModalOverlay />
        <ModalContent px={"72px"} borderRadius="30px" maxW="50rem">
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              "Loading..."
            ) : error ? (
              error.message
            ) : vision ? (
              <>
                {isSharedWithMe ? <Box mb="4">
                  <Box>
                    <div>
                      <p>
                        <span>{formatMessage({ id: "sharedBy" })}</span> <span>{owner?.name}</span>
                      </p>
                      <small>{owner?.email}</small>
                    </div>
                    <AccessPermission permission={canUpdate ? "update" : "read"} />
                  </Box>
                </Box> : null}
                <Text
                  d="inline-block"
                  color="brand.900"
                  fontSize="18px"
                  fontWeight="bold"
                  lineHeight="26px"
                  fontFamily="Calibri (Body)"
                  mb={"16px"}
                >
                  {vision!.name}
                </Text>
                <Text
                  fontSize={"18px"}
                  fontFamily="Calibri (Body)"
                  lineHeight="25px"
                  color="gray.900"
                  mb={"4px"}
                >
                  <FormattedMessage
                    id="visions.details.order"
                    defaultMessage="ترتيب الأمنيات كالآتي"
                  />
                </Text>
                <OrderedList
                  fontSize={"16px"}
                  fontFamily="Calibri (Body)"
                  lineHeight="22px"
                  color="gray.900"
                  spacing={2}
                  mb={"32px"}
                >
                  {vision!.top10
                    ?.sort((a, b) => a.order! - b.order!)
                    .map((w) => {
                      return vision.wishes
                        ?.sort((a, b) => a.order! - b.order!)
                        ?.filter((e) => w.id === e.id)
                        .map((e: any) => {
                          return <ListItem key={e.id}>{e.name}</ListItem>;
                        });
                    })}
                </OrderedList>
                <ActionBar
                  onDelete={() => {
                    remove(vision.id || 0);
                    disclosureProps.onClose();
                  }}
                  textToCopy={textToCopy}
                  onResort={() => {
                    disclosureProps.onClose();
                    openWishesModal(vision.id!);
                  }}
                  title={intl.formatMessage(
                    { id: "print.vision" },
                    { name: vision.name }
                  )}
                  canRestart={(Boolean(isSharedWithMe) && canUpdate) || !Boolean(isSharedWithMe)}
                  canDelete={(Boolean(isSharedWithMe) && canUpdate) || !Boolean(isSharedWithMe)}
                />
              </>
            ) : (
              <></>
            )}
          </ModalBody>
          <div style={{ display: "none" }}>
            <Box
              id="text-to-mail"
              padding="20px"
              data-subject={intl.formatMessage({ id: "visions" })}
            >
              <Box dir="auto">
                <Text
                  style={{
                    fontWeight: "bold",
                    padding: "10px",
                    fontSize: "18px",
                  }}
                >
                  {intl.formatMessage({ id: "visions" })}: ({vision?.name})
                </Text>
              </Box>
              <Box dir="auto" style={{ textAlign: "start", paddingTop: "20px" }}>
                {textToCopy?.split("\n").map((txt, idx) => (
                  <Text display="block" fontSize="16px" key={idx}>
                    {txt}
                  </Text>
                ))}
              </Box>
            </Box>
          </div>
        </ModalContent>
      </Modal>
    );
  };
export default VisionDetailsModal;
