import { useCallback, useEffect, useState } from "react";
import { Goal, Mission, Skill } from "src/types";
import { nanoid } from "nanoid";
import { useIntl } from "react-intl";
import { UseMissionApiReturn } from "./useMissionApi";
import { UseUIStateReturn } from "./useUIState";
import { format } from "date-fns";

//function to get default date value
const getDefaultDate = () => {
  const date = new Date();
  //add one year head to the date
  date.setDate(date.getDate() + 365);
  //remove the time zone from the date string to set the value of the input to it
  return date.toJSON().split("T")[0];
};

const initialMission: Mission = {
  dueDate: getDefaultDate(),
  name: "",
  goals: [],
  financialGoal: "",
  skills: [],
  note: "",
};

type UseMissionParams = {
  api: Partial<UseMissionApiReturn>;
  ui: UseUIStateReturn;
};
export type ReturnUseMission = {
  mission: Mission;
  missionLoading?: boolean;
  setName: (name: string) => void;
  addSkill: (skill: Skill) => void;
  editSkill: (idx: number, skill: Skill) => void;
  deleteSkill: (idx: number) => void;
  addGoal: (goal: Goal) => void;
  editGoal: (idx: number, goal: Goal) => void;
  deleteGoal: (idx: number) => void;
  setFinancialGoal: (financialGoal: string) => void;
  setDueDate: (dueDate: string) => void;
  toggleGoal: (idx: number) => void;
  toggleSkill: (idx: number) => void;
  clear: () => void;
  submit: (note: string) => void;
  topGoalsCount: number;
  topSkillsCount: number;
  setMission: any;
};

export const useMission: (params: UseMissionParams) => ReturnUseMission = ({
  api,
  ui,
}) => {
  const [mission, setMission] = useState<Mission>(initialMission);
  useEffect(() => {
    if (ui.createEditDisclosure.isEdit && api.currentMissionQuery?.data) {
      setMission({
        ...api.currentMissionQuery?.data,
        dueDate: format(
          new Date(api.currentMissionQuery?.data.dueDate),
          "yyyy-MM-dd"
        ),
      });
      setTopGoalsCount(3);
      setTopSkillsCount(3);
    }
  }, [ui.createEditDisclosure.isEdit, api.currentMissionQuery?.data]);

  const missionLoading = api.currentMissionQuery?.isLoading;

  const [topGoalsCount, setTopGoalsCount] = useState(0);
  const [topSkillsCount, setTopSkillsCount] = useState(0);
  const { formatMessage } = useIntl();

  const setName = (name: string) =>
    setMission((old) => {
      return { ...old, name };
    });

  const addSkill = (skill: Skill) =>
    setMission((old) => {
      return {
        ...old,
        skills: [...old.skills, { ...skill, localId: nanoid() }],
      };
    });

  const editSkill = (idx: number, skill: Skill) => {
    setMission((old) => {
      const newSkills = old.skills.map((item, index) => {
        if (idx === index) {
          return { ...item, ...skill };
        } else {
          return item;
        }
      });
      return {
        ...old,
        skills: newSkills,
      };
    });
  };

  const deleteSkill = (idx: number) =>
    setMission((old) => {
      return {
        ...old,
        skills: [...old.skills.slice(0, idx), ...old.skills.slice(idx + 1)],
      };
    });

  const addGoal = (goal: Goal) =>
    setMission((old) => {
      return {
        ...old,
        goals: [...old.goals, goal],
        localId: nanoid(),
      };
    });

  const editGoal = (idx: number, goal: Goal) => {
    setMission((old) => {
      const newGoals = old.goals.map((item, index) => {
        if (idx === index) {
          return { ...item, ...goal };
        } else {
          return item;
        }
      });
      return {
        ...old,
        goals: newGoals,
      };
    });
  };
  const deleteGoal = (idx: number) =>
    setMission((old) => {
      return {
        ...old,
        goals: [...old.goals.slice(0, idx), ...old.goals.slice(idx + 1)],
      };
    });

  const setFinancialGoal = (financialGoal: string) =>
    setMission((old) => {
      return { ...old, financialGoal };
    });

  const setDueDate = (dueDate: string) =>
    setMission((old) => {
      return { ...old, dueDate };
    });

  const toggleGoal = (idx: number) => {
    if (topGoalsCount === 3 && !mission.goals[idx].topThree) return;
    const before = mission.goals[idx].topThree;
    setTopGoalsCount(before ? (old) => old - 1 : (old) => old + 1);
    setMission((old) => {
      return {
        ...old,
        goals: [
          ...old.goals.slice(0, idx),
          { ...old.goals[idx], topThree: !before },
          ...old.goals.slice(idx + 1),
        ],
      };
    });
  };

  const toggleSkill = (idx: number) => {
    if (topSkillsCount === 3 && !mission.skills[idx].topThree) return;
    const before = mission.skills[idx].topThree;
    setTopSkillsCount(before ? (old) => old - 1 : (old) => old + 1);
    setMission((old) => {
      return {
        ...old,
        skills: [
          ...old.skills.slice(0, idx),
          { ...old.skills[idx], topThree: !before },
          ...old.skills.slice(idx + 1),
        ],
      };
    });
  };

  const generateNote = useCallback(() => {
    const topSkills = mission.skills.filter((s) => s.topThree);
    const topGoals = mission.goals.filter((g) => g.topThree);
    if (topSkills.length !== 3 || topGoals.length !== 3) return;
    const message1 = formatMessage(
      {
        id: "mission.note_template1",
        defaultMessage:
          "أن أكون {skill1name}، {skill2name}، {skill3name} من خلال {skill1property}، {skill2property}، {skill3property}",
      },
      {
        skill1name: topSkills[0].name,
        skill2name: topSkills[1].name,
        skill3name: topSkills[2].name,
        skill1property: topSkills[0].property,
        skill2property: topSkills[1].property,
        skill3property: topSkills[2].property,
      }
    );
    const message2 = formatMessage(
      {
        id: "mission.note_template2",
        defaultMessage: "لتحقيق {goal1}، {goal2}، {goal3}",
      },
      {
        goal1: topGoals[0].name,
        goal2: topGoals[1].name,
        goal3: topGoals[2].name,
      }
    );

    const message3 =
      mission.financialGoal !== ""
        ? formatMessage({
            id: "mission.note_template3",
            defaultMessage: "والحصول على مبلغ",
          }) +
          " " +
          mission.financialGoal
        : "";
    const message4 =
      formatMessage({ id: "mission.note_template4", defaultMessage: "في" }) +
      " " +
      mission.dueDate.split("-").reverse().join("-") +
      ".";
    const note = message1 + " " + message2 + " " + message3 + " " + message4;

    setMission((old) => {
      return { ...old, note };
    });
  }, [mission.skills, mission.goals, mission.financialGoal, mission.dueDate, formatMessage]);

  useEffect(() => {
    if (topGoalsCount === 3 && topSkillsCount === 3 && mission.dueDate !== "")
      generateNote();
  }, [
    generateNote,
    mission.dueDate,
    mission.goals,
    mission.name,
    mission.skills,
    mission.financialGoal,
    topGoalsCount,
    topSkillsCount,
  ]);

  const clear = () => {
    setMission(initialMission);
    setTopGoalsCount(0);
    setTopSkillsCount(0);
  };

  const submit = async (note: string) => {
    if (mission.goals.length > 5)
      mission.goals = mission.goals.slice(
        mission.goals.length - 5,
        mission.goals.length
      );
    if (mission.skills.length > 5)
      mission.skills = mission.skills.slice(
        mission.skills.length - 5,
        mission.skills.length
      );
    try {
      const op = ui.createEditDisclosure.isEdit
        ? api.editMission!({ ...mission, note: note || mission.note })
        : api.createMission!({ ...mission, note: note || mission.note });
      const { id } = await op;
      //toast success
      ui.createEditDisclosure.onClose();
      ui.detailsDisclosure.onOpen(id!);
      clear();
    } catch {}
  };

  return {
    mission,
    missionLoading,
    topGoalsCount,
    topSkillsCount,
    setName,
    addSkill,
    editSkill,
    deleteSkill,
    addGoal,
    editGoal,
    deleteGoal,
    setFinancialGoal,
    setDueDate,
    toggleGoal,
    toggleSkill,
    clear,
    setMission,
    submit,
  };
};
