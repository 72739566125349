import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { useIntl } from "react-intl";
import styles from "./videos.module.css";
interface DescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  lecturerName: string;
}

const VideoDescriptionModal: React.FC<DescriptionModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  lecturerName,
}) => {
  const intl = useIntl();
  const createMarkup = useCallback((text: string) => {
    return {
      __html:
        DOMPurify.sanitize(text).replace(/href/g, `target="_blank" href`) ||
        " ",
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>
            {intl.locale === "ar"
              ? `${intl.formatMessage({ id: "desc" }).concat(" ")}`
              : ""}
            {title}
            {intl.locale === "en"
              ? ` ${intl.formatMessage({ id: "desc" })}`
              : ""}
          </Text>
          <Text as="span" fontSize="sm" color="gray.500">
            {lecturerName}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {description?.length > 0 ? (
            <Box
              dangerouslySetInnerHTML={createMarkup(description)}
              paddingX={"5"}
              paddingBottom={"3"}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoDescriptionModal;
