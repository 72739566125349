import React, { useState, useEffect } from "react";
import { get, deleteMethod } from "src/api/index";
import {
  Box,
  Text,
  Link,
  Table,
  Tr,
  Td,
  Th,
  Thead,
  Tbody,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import BaseView from "../BaseView";
import { FormattedMessage, useIntl } from "react-intl";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";
import { SESSION_ROUTE } from "src/routes";
import { Session } from "src/types";
import DeleteModel from "src/components/DeleteModel";

const Archive: React.FC = () => {
  //fetched sessions
  const [sessions, setSessions] = useState<Array<Session>>([]);

  //formatting messages
  const { formatMessage } = useIntl();

  //error status
  const [error, setError] = useState("");

  //function to fetch sessions
  const fetchSessions = async () => {
    const response = await get("/session");
    if (Array.isArray(response)) {
      setSessions(response);
    } else {
      setError("failFetch");
    }
  };

  //toasting messages
  const toast = useToast();

  //function to delete session
  const deleteArchive = async (id: number) => {
    const response: any = await deleteMethod(`/session/${id}`);
    if (response?.success) {
      toast({
        title: formatMessage({ id: "session.delete.success" }),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      //update sessions list
      fetchSessions();
    } else {
      toast({
        title: formatMessage({ id: "session.delete.error" }),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  return (
    <Box>
      <BaseView />
      <Box margin='8px 0 16px'>
        <Link
          as={ReactRouterLink}
          to={SESSION_ROUTE}
          color='brand.800'
          textDecoration='none'
          transition='250ms ease-out'
          _hover={{ color: "brand.900" }}
          d='flex'
          alignItems='center'
          fontWeight='bold'
          fontSize='16px'>
          <Text d='inline'>
            <FormattedMessage id='session.create' />
          </Text>
          <Box as='span' mx={2} d='inline'>
            <FiPlus />
          </Box>
        </Link>
      </Box>
      {error ? (
        <Box padding='50px 0'>
          <Text color='red.600'>
            <FormattedMessage id={error} />
          </Text>
        </Box>
      ) : (
        <Box w='90vw' margin={{ base: "50px auto", md: "50px 0" }} overflowX='auto'>
          <Table tableLayout='fixed'>
            <Thead whiteSpace='pre'>
              <Tr>
                <Th padding='10px 15px' fontSize='14px' minW='180px'>
                  <FormattedMessage id='session.name' />
                </Th>
                <Th padding='10px 15px' fontSize='14px' minW='180px'>
                  <FormattedMessage id='session.case' />
                </Th>
                <Th padding='10px 15px' fontSize='14px' minW='180px'>
                  <FormattedMessage id='session.date' />
                </Th>
                <Th padding='10px 15px' fontSize='14px' minW='180px'>
                  <FormattedMessage id='session.note' />
                </Th>
                <Th padding='10px 15px' fontSize='14px'></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sessions.map((session) => (
                <Tr key={session.id} color='gray.400'>
                  <Td padding='5px 15px' fontSize='14px' minW='180px'>
                    {session.name}
                  </Td>
                  <Td padding='5px 15px' fontSize='14px' minW='180px'>
                    {session.case.name}
                  </Td>
                  <Td padding='5px 15px' fontSize='14px' minW='180px'>
                    {new Date(session.date).toLocaleDateString()}
                  </Td>
                  <Td padding='5px 15px' fontSize='14px' minW='180px'>
                    {session.note}
                  </Td>
                  <Td padding='5px 15px'>
                    <DeleteModel remove={() => deleteArchive(session.id)} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default Archive;
