import { Box } from "@chakra-ui/react";
import React from "react";
import { affirmationBg } from "src/assets/icons";
import BaseView from "src/views/Prepration/BaseView";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import MagicMain from "./MagicMain";
import MagicBox from "./MagicBox";
const Magic: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleTabsChange = (index: any) => {
    setTabIndex(index);
  };
  return (
    <Box>
      <BaseView imageSrc={affirmationBg} />

      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        mt={"55px"}
        align={"center"}
        border={"none"}
      >
        <TabList border={"none"}>
          <Tab
            px={0}
            color={"#8b8b8b"}
            fontFamily={"Calibri (Body)"}
            fontSize={"18px"}
            lineHeight={"1.38"}
            mx={"1.5rem"}
            _selected={{ color: "#2895ff", borderBottom: "1px solid #2895ff" }}
          >
            <FormattedMessage id="magic.main" defaultMessage={"سحر التجلي"} />
          </Tab>
          <Tab
            px={0}
            color={"#8b8b8b"}
            fontFamily={"Calibri (Body)"}
            fontSize={"18px"}
            lineHeight={"1.38"}
            mx={"1.5rem"}
            _selected={{ color: "#2895ff", borderBottom: "1px solid #2895ff" }}
          >
            <FormattedMessage
              id="magic.box"
              defaultMessage={"الصندوق السحري"}
            />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MagicMain changeTab={() => setTabIndex(1)} />
          </TabPanel>
          <TabPanel>
            <MagicBox />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Magic;
