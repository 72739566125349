import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PlaybackState {
  isPlaying: boolean;
  currentTrackUrl: string | null;
  currentTime: number;
}

const playbackSlice = createSlice({
  name: "playback",
  initialState: {
    isPlaying: false,
    currentTrackUrl: null,
    currentTime: 0,
  } as PlaybackState,
  reducers: {
    play: (state, action) => {
      state.isPlaying = true;
      state.currentTrackUrl = action.payload;
    },
    pause: (state) => {
      state.isPlaying = false;
    },
    updateCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
  },
});

export const { play, pause, updateCurrentTime } = playbackSlice.actions;
export default playbackSlice.reducer;
