import React from "react";
import { Box } from "@chakra-ui/react";
import "../styles/UserMessage.css";
import "../styles/Messages.css";

export default function UserMessage({ text }: { text: string }) {
  const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]/;

  return (
    <Box className="message-container">
      <Box
        className={
          arabicRegex.test(text)
            ? "user-message ar-message"
            : "user-message en-message"
        }
      >
        {text}
      </Box>
    </Box>
  );
}
