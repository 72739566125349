import React, { useEffect, useState } from "react";
import Section from "./Section";
import styles from "./videos.module.css";
import { useIntl } from "react-intl";
export type VideoType = {
  id: number;
  url: string;
  title: string;
  text: string;
  html: string;
  lecturerName: string;
};
type VideosType = {
  name: string;
  trainings: VideoType[];
};
const Videos = ({
  videos,
  center = true,
  responsive = false,
}: {
  videos: VideosType[];
  center?: boolean;
  responsive?: boolean;
}) => {
  const [validVideos, setValidVideos] = useState<VideosType[]>([]);
  const intl = useIntl();

  useEffect(() => {
    // Sort the videos when the component mounts or when `videos` prop changes
    const validVideosArray: [VideosType, VideosType, VideosType] = [
      {} as VideosType,
      {} as VideosType,
      {} as VideosType,
    ];
    switch (intl.locale) {
      case "en":
        for (let i = 0; i < videos.length; i++) {
          videos[i].name === "Basic Training" &&
            (validVideosArray[0] = videos[i]);
          videos[i].name === "Updates" && (validVideosArray[1] = videos[i]);
          videos[i].name === "Workshops" && (validVideosArray[2] = videos[i]);
        }

        setValidVideos(
          validVideosArray.filter(
            (videos) => videos?.trainings?.filter((vid) => vid?.url)?.length > 0
          )
        );
        break;
      case "ar":
        for (let i = 0; i < videos.length; i++) {
          videos[i].name === "التدريب الأساسي" &&
            (validVideosArray[0] = videos[i]);
          videos[i].name === "التحديثات" && (validVideosArray[1] = videos[i]);
          videos[i].name === "ورش العمل" && (validVideosArray[2] = videos[i]);
        }

        setValidVideos(
          validVideosArray.filter(
            (videos) => videos?.trainings?.filter((vid) => vid?.url)?.length > 0
          )
        );
        break;

      default:
        break;
    }
  }, [videos]);

  return (
    <div className={styles.sections}>
      {validVideos.map((section, index) => {
        return (
          <React.Fragment key={section.name}>
            <Section
              title={section?.name}
              videos={section.trainings.filter((vid) => vid.url)}
            />
            {index !== validVideos.length - 1 ? (
              <div className={styles.lineSeparatorContainer}>
                <span className={styles.lineSeparator}></span>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Videos;
