import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mediaReducer from "./media";
import authReducer from "./auth";
import calibrationReducer from "./calibration";
import playbackReducer from "./playback";
import assistantReducer from "./assistant";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

const combinedReducers = combineReducers({
  auth: authReducer,
  media: mediaReducer,
  calibration: calibrationReducer,
  playback: playbackReducer,
  assistant: assistantReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    window.localStorage.removeItem("_sal-Calibra");
    window.localStorage.removeItem("__sal_auth");
    window.localStorage.removeItem("__sal_auth_refresh");
    window.localStorage.removeItem("isCalibrated");
    window.localStorage.removeItem("intentionStartDate");
    window.localStorage.removeItem("intentionMessage");
    state = undefined;
    window.location.replace("/");
  }
  return combinedReducers(state, action);
};
const store = configureStore({
  reducer: rootReducer,
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export default store;
