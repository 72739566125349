import { Box, Center, Input, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
// import { AiOutlineConsoleSql } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import Card from "src/components/Card";
import PrimaryButton from "src/components/PrimaryButton";
import SelectItem from "src/components/SelectItem";
import Textarea from "src/components/Textarea";
import { ReturnUseMission } from "./useMission";

type Step4Props = {
  nextStep?: () => void;
} & ReturnUseMission;
const Step4: React.FC<Step4Props> = ({
  submit,
  mission: { skills, goals, name, dueDate, note },
  setDueDate,
  toggleGoal,
  toggleSkill,
  topGoalsCount,
  topSkillsCount,
  setMission,
}) => {
  const [goalBackGround, setGoalBackGround] = useState<boolean>(false);
  const [skillBackGround, setSkillBackGround] = useState<boolean>(false);
  const [pastDate, setPastDate] = useState<boolean>(false);
  const [missionNote, setMissionNote] = useState("");
  useEffect(() => {
    topGoalsCount === 3 ? setGoalBackGround(true) : setGoalBackGround(false);
    topSkillsCount === 3 ? setSkillBackGround(true) : setSkillBackGround(false);
  }, [topGoalsCount, topSkillsCount]);
  useEffect(() => {
    setMissionNote(note);
  }, [note, topGoalsCount, topSkillsCount, skills, goals, name, dueDate]);
  const renderSkillsCard = () => (
    <Card
      w={undefined}
      flex={["unset", "unset", 1]}
      me={["unset", "unset", "1.5rem"]}
      pt={"1.5rem"}
      pb={"1.5rem"}
      mb={["1.5rem", "1.5rem", "unset"]}
    >
      <Text
        fontSize={"18px"}
        fontFamily="Calibri (Body)"
        fontWeight="bold"
        color="brand.900"
        d="inline-block"
        mb={"2rem"}
      >
        <FormattedMessage
          id={"missions.step4.skills_card"}
          defaultMessage="قم باختيار افضل ثلاث صفات"
        />
      </Text>

      {skills.map((skill, idx) => (
        <SelectItem
          borderColor={skillBackGround ? "gray.100" : "#2895ff"}
          cursor={"pointer"}
          selected={skill.topThree}
          key={idx}
          mb={"1rem"}
          onClick={() => toggleSkill(idx)}
        >
          <p style={{ width: "100%", display: "block" }}>{skill.name}</p>
        </SelectItem>
      ))}
    </Card>
  );

  const renderGoalsCard = () => (
    <Card
      w={undefined}
      flex={["unset", "unset", 1]}
      me={["unset", "unset", "1.5rem"]}
      pt={"1.5rem"}
      pb={"1.5rem"}
    >
      <Text
        fontSize={"18px"}
        fontFamily="Calibri (Body)"
        fontWeight="bold"
        color="brand.900"
        d="inline-block"
        mb={"2rem"}
      >
        <FormattedMessage
          id={"missions.step4.goals_card"}
          defaultMessage="قم باختيار افضل ثلاث اهداف"
        />
      </Text>
      {goals.map((goal, idx) => {
        return (
          <SelectItem
            borderColor={goalBackGround ? "gray.100" : "#2895ff"}
            cursor={"pointer"}
            selected={goal.topThree}
            key={idx}
            mb={"1rem"}
            onClick={() => toggleGoal(idx)}
          >
            <p style={{ width: "100%", display: "block" }}>{goal.name}</p>
          </SelectItem>
        );
      })}
    </Card>
  );

  //function to handle date change
  const handleDateChange = (e: any) => {
    const val = e.target.value;
    const today = new Date();
    if (new Date(val).setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
      setPastDate(true);
    } else {
      setDueDate(val);
      setPastDate(false);
    }
  };

  const renderReachGoal = () => (
    <Box>
      {pastDate && (
        <Box d="block">
          <Text color="red.400" d="block">
            <FormattedMessage id="pastDate" />
          </Text>
        </Box>
      )}
      <Box
        d="flex"
        flexDir="row"
        flex="1"
        alignItems="baseline"
        marginTop="10px"
      >
        <Text
          fontWeight="bold"
          fontSize="1.125rem"
          color="brand.900"
          fontFamily="Calibri (Body)"
          d="inline-block"
          me="1rem"
        >
          <FormattedMessage
            id={"missions.step4.reach_goal"}
            defaultMessage="سأصل إلى هدفي في"
          />
        </Text>
        <Input
          type="date"
          w="177px"
          variant="flushed"
          value={dueDate}
          onChange={handleDateChange}
        />
        {/* <DateInput w="177px" /> */}
      </Box>
      <Textarea
        // variant={"filled"}
        bgColor={"red"}
        value={missionNote}
        onChange={(e) => setMissionNote(e.target.value)}
        // placeholder='Here is a sample placeholder'
        size="sm"
      />
    </Box>
  );

  return (
    <>
      <Text
        fontSize={"24px"}
        fontFamily="Calibri (Body)"
        fontWeight="bold"
        color="brand.900"
        mb={"1rem"}
      >
        {name}
      </Text>
      <Box d="flex" flexDir={["column", "column", "row"]} mb={"1.5rem"}>
        {renderSkillsCard()}
        {renderGoalsCard()}
      </Box>
      {renderReachGoal()}
      <Center my={"32px"}>
        <PrimaryButton
          onClick={() => {
            if (!pastDate) {
              submit(missionNote);
            }
          }}
          disabled={
            dueDate === "" || topGoalsCount !== 3 || topSkillsCount !== 3
          }
        >
          <FormattedMessage id="save" defaultMessage="حفظ" />
        </PrimaryButton>
      </Center>
    </>
  );
};
export default Step4;
