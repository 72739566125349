import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createAspect,
  addTarget,
  create,
  edit,
  setValuesMatrix,
  deletePurpose,
  selectTarget,
  resetPurpose,
} from "src/api/prepration/purpose/mutation";
import {
  getList,
  getOne,
  getSharedList,
} from "src/api/prepration/purpose/query";
import { Aspect, Purpose, PurposeListItem } from "src/types";

export const usePurposeApi = (currentId: number | undefined) => {
  const client = useQueryClient();
  //One
  const currentPurposeQuery = useQuery(...getOne(currentId));

  //List
  const [listLoading, setListLoading] = useState(true);

  const {
    data: list,
    error: listError,
    isLoading: listApiLoading,
  } = useQuery<PurposeListItem[], Error, PurposeListItem[]>(...getList);

  const {
    data: sharedList,
    error: sharedListError,
    isLoading: sharedListApiLoading,
  } = useQuery<PurposeListItem[], Error, PurposeListItem[]>(...getSharedList);

  const [completedList, setCompletedList] = useState<PurposeListItem[]>([]);

  const [notCompletedList, setNotCompletedList] = useState<PurposeListItem[]>(
    []
  );

  const [archiveList, setArchiveList] = useState<PurposeListItem[]>([]);

  useEffect(() => {
    if (listApiLoading) setListLoading(true);
    if (Array.isArray(list)) {

      const sharedCompleted = sharedList
        ?.filter((item) => item.status === "completed")
        .map(({ id, name, status, shared, createdAt }) => {
          return { id, name, status, isShared: true, shared, createdAt };
        });
      if (sharedCompleted?.length) {
        setCompletedList([
          ...list.filter((item) => item.status === "completed"),
          ...sharedCompleted,
        ]);
      } else {
        setCompletedList(list.filter((item) => item.status === "completed"));
      }

      const sharedInCompleted = sharedList
        ?.filter((item) => item.status === "in-completed")
        .map(({ id, name, status, shared, createdAt }) => {
          return { id, name, status, isShared: true, shared, createdAt };
        });
      if (sharedInCompleted?.length) {
        setNotCompletedList([
          ...list.filter((item) => item.status === "in-completed"),
          ...sharedInCompleted,
        ]);
      } else {
        setNotCompletedList(
          list.filter((item) => item.status === "in-completed")
        );
      }

      const sharedArchived = sharedList
        ?.filter((item) => item.status === "archived")
        .map(({ id, name, status, shared, createdAt }) => {
          return { id, name, status, isShared: true, shared, createdAt };
        });
      if (sharedArchived?.length) {
        setArchiveList([
          ...list.filter((item) => item.status === "archived"),
          ...sharedArchived,
        ]);
      } else {
        setArchiveList(list.filter((item) => item.status === "archived"));
      }

      setListLoading(false);
    }
    if (listError) setListLoading(false);
  }, [listError, list, listApiLoading, sharedList]);

  //Mutations

  const { mutateAsync: createMutation } = useMutation(create, {
    onSuccess: () => client.invalidateQueries(getList[0]),
  });

  const { mutateAsync: editMutation } = useMutation(edit, {
    onSuccess: (_, vars) => {
      client.invalidateQueries(getList[0]);
      client.invalidateQueries(getOne(vars.id)[0]);
    },
  });

  const { mutateAsync: mutateValuesMatrix } = useMutation(setValuesMatrix, {
    onSuccess: (_, vars) => {
      client.invalidateQueries(getOne(vars.id)[0]);
    },
  });

  const { mutateAsync: addAspectMutation } = useMutation(createAspect, {
    onSuccess: (_, vars) => {
      client.invalidateQueries(getOne(currentId)[0]);
    },
  });
  const queryKey = getOne(currentId)[0];

  const { mutateAsync: addTargetMutation } = useMutation(addTarget, {
    onSuccess: () => client.invalidateQueries(queryKey),
    // onMutate: async (target) => {},
    onError: (e, target, context) => {
      //@ts-ignore
      client.setQueryData(queryKey, context.previousPurpose);
    },
    onMutate: async (newTarget) => {
      await client.cancelQueries(queryKey);
      const previousPurpose = client.getQueryData(queryKey);
      client.setQueryData<Purpose>(queryKey, (old) => {
        const aspect = old?.selectedAspects[
          old.selectedAspects.length - 1
        ] as Aspect;

        const cachedTargets = aspect?.targets
          ? [...aspect.targets, { ...newTarget, cached: true }]
          : [{ ...newTarget, cached: true }];
        return {
          ...old,
          selectedAspects: [
            ...old!.selectedAspects.slice(0, old!.selectedAspects.length - 1),
            { ...aspect, targets: cachedTargets },
          ],
        } as Purpose;
      });
      return { previousPurpose };
    },
  });

  const { mutateAsync: selectTargetMutation } = useMutation(selectTarget, {
    onSuccess: () => client.invalidateQueries(queryKey),
    // onSuccess: () => {},
  });

  const { mutateAsync: deletePurposeMutation } = useMutation(deletePurpose, {
    onSuccess: () => client.invalidateQueries(getList[0]),
  });

  const saveNoteMutation = (content: string) =>
    editMutation(
      { ...currentPurposeQuery.data!, content },
      {
        onSuccess: () => client.invalidateQueries(getList[0]),
      }
    );

  const archiveMutation = (id: number) =>
    editMutation(
      {
        status: "archived",
        id,
      },
      {
        onSuccess: () => client.invalidateQueries(getList[0]),
      }
    );

  const unarchiveMutation = (id: number) =>
    editMutation(
      {
        // ...currentPurposeQuery.data!,
        status: "completed",
        id,
      },
      {
        onSuccess: () => client.invalidateQueries(getList[0]),
      }
    );

  const { mutateAsync: purposeResetMutation } = useMutation(resetPurpose, {
    onSuccess: () => client.invalidateQueries(getList[0]),
  });
  //Grouping

  const listQuery = {
    completedList,
    archiveList,
    notCompletedList,
    loading: listLoading,
    error: listError,
  };

  const mutations = {
    createMutation,
    editMutation,
    mutateValuesMatrix,
    addAspectMutation,
    addTargetMutation,
    deletePurposeMutation,
    selectTargetMutation,
    saveNoteMutation,
    archiveMutation,
    unarchiveMutation,
    purposeResetMutation,
  };

  return { currentPurposeQuery, listQuery, mutations };
};
