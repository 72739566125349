import {
  Input,
  InputGroup,
  InputGroupProps,
  ButtonProps,
  InputRightElement,
  IconButton,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { RiDeleteBin6Line, RiShareBoxLine } from "react-icons/ri";
import * as mutation from "src/api/affirmation/intentions/mutations";
import { useQueryClient, useMutation } from "react-query";
import { FormattedMessage } from "react-intl";
import DeleteButton from "src/components/DeleteModel";

type InputWithDeleteProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  remove?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  messageRef?: any;
  inputDisabled?: boolean;
  buttonProps?: ButtonProps;
  addInput?: any;
  onBlur?: any;
  data?: any;
  subliminal?: any;
  deleteHandler: any;
  deleteAction: any;
  share?: (item) => void;
} & InputGroupProps;

const Message: React.FC<InputWithDeleteProps> = ({
  value,
  onChange,
  onClick,
  inputDisabled,
  disabled,
  buttonProps,
  remove,
  onBlur,
  addInput,
  subliminal,
  deleteHandler,
  deleteAction,
  data,
  share,
  ...props
}) => {
  React.useEffect(() => {}, [data]);
  const client = useQueryClient();
  const { mutateAsync: deleteIntentionMutation } = useMutation(mutation.deleteIntention, {
    onSuccess: () => client.invalidateQueries("listIntention"),
  });
  const { mutateAsync: deleteSubliminalMutation } = useMutation(mutation.deleteSubliminal, {
    onSuccess: () => client.invalidateQueries("listSubliminal"),
  });
  return (
    <>
      {data &&
        data
          ?.slice(0)
          // .reverse()
          .sort((item1, item2) => Number(item2.createdAt) - Number(item1.createdAt))
          .map((intention: any, idx: any) => {
            const isSharedWithMe = Boolean(intention.shared);
            return (
              <InputGroup
                key={intention.id}
                d='flex'
                alignItems={"center"}
                justifyContent='start'
                maxWidth={"320px"}
                width={"100%"}
                height='auto'
                // my="10px"
                {...props}>
                <Input
                  as='button'
                  style={{ resize: "none" }}
                  textAlign='left'
                  my='5px'
                  mr='1'
                  className={"message-Input"}
                  id={idx}
                  onClick={onClick}
                  children={intention.name}
                  value={intention.name}
                  isReadOnly
                  textStyle={"intentionMessage"}
                  cursor={"pointer"}
                  // pb="10px"
                  // minH={"48px"}
                  // h={24 + intention?.name?.length + "px"}
                  width='240px'
                  h='auto'
                  py='2'
                  borderRadius={"16px"}
                  backgroundColor={"white"}
                />
                <Box d='flex' alignItems={"center"} h='100%'>
                  {!isSharedWithMe && share && (
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        share(intention);
                      }}
                      variant='link'
                      aria-label='delete'
                      fontSize='23px'
                      minW={1}
                      my='auto'
                      icon={<RiShareBoxLine className='remove-on-print' />}
                      h='5'
                    />
                  )}
                  {remove && !isSharedWithMe ? (
                    // <IconButton
                    //   variant="link"
                    //   disabled={disabled}
                    //   color="#e97575"
                    //   fontSize="23px"
                    //   aria-label="Delete"
                    //   onClick={() => {
                    //     subliminal
                    //       ? deleteSubliminalMutation(intention.id)
                    //       : deleteIntentionMutation(intention.id);
                    //   }}
                    //   icon={<RiDeleteBin6Line />}
                    //   {...buttonProps}
                    // />
                    <DeleteButton
                      remove={() => {
                        subliminal
                          ? deleteSubliminalMutation(intention.id)
                          : deleteIntentionMutation(intention.id);
                      }}
                    />
                  ) : null}
                  {isSharedWithMe ? <FormattedMessage id={"shared"} /> : null}
                </Box>
              </InputGroup>
            );
          })}
    </>
  );
};

export default Message;
