import React from "react";
import Link from "src/components/Link/Link";
import { INTENTION_ROUTE, SUBLIMINAL_ROUTE } from "../../../routes";
interface Props {
  path?: string;
  isActive?: boolean;
  formatId?: string;
  subliminal?: any;
  onClick?: () => any;
}
const GeneralLinks: React.FC<Props> = ({
  path,
  subliminal,
  isActive,
  formatId,
  onClick,
}) => {
  return (
    <Link
      to={
        subliminal
          ? `${SUBLIMINAL_ROUTE}/${formatId}`
          : `${INTENTION_ROUTE}/${formatId}`
      }
      onClick={onClick}
      maxWidth={"112px"}
      fontSize={"14px"}
      borderRadius={15}
      py={1}
      isSecondary={!isActive}
    >
      {formatId}
    </Link>
  );
};

export default React.memo(GeneralLinks);
