import { useDispatch, useSelector } from "src/store";
import {
  Flex,
  Tooltip,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { setFocusProgram } from "src/store/assistant";
import { SAL125Programs } from "./ProgramSelector";
import { useIntl } from "react-intl";

const ProgramToggler: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { focusProgram: currentFocusProgram } = useSelector((state) => ({
    focusProgram: state.assistant.focusProgram,
  }));

  const { formatMessage } = useIntl();

  console.log("currentFocusProgram", currentFocusProgram);

  return (
    <Flex alignItems="center">
      <Tooltip
        label={formatMessage({ id: "sal_assistant.section.selection.hint" })}
        aria-label="action-info"
        fontSize="14px"
        padding="5px"
        borderRadius="md"
        placement="left"
        shadow="lg"
        hasArrow
        p={2}
      >
        <Box py={2} px={1}>
          <BsInfoCircle cursor="pointer" />
        </Box>
      </Tooltip>
      <Text fontSize={"smaller"} m={1}>
        {formatMessage({ id: "sal_assistant.selected.section" })}
      </Text>
      <Menu closeOnSelect={false}>
        <MenuButton textColor="#fff" p={1} borderRadius="full">
          <Flex alignItems="center" justifyContent="center" gap={1}>
            <Text fontSize={"smaller"}>
              {formatMessage({ id: currentFocusProgram! })}
            </Text>
            <ChevronDownIcon />
          </Flex>
        </MenuButton>
        <MenuList
          zIndex={99999}
          position="absolute"
          left={-9}
          bgColor="#fff"
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          borderRadius="md"
        >
          <MenuOptionGroup defaultValue={currentFocusProgram!} type="radio">
            {SAL125Programs.map((program) => (
              <MenuItemOption
                key={program}
                value={program}
                onClick={() => dispatch(setFocusProgram(program))}
                color="#1F3F93"
              >
                <Text textColor="#454555">
                  {formatMessage({ id: program })}
                </Text>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProgramToggler;
